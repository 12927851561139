import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-standardized-tests',
  templateUrl: './step-standardized-tests.component.html',
  styleUrls: ['./step-standardized-tests.component.scss']
})
export class StepStandardizedTestsComponent implements OnInit {

  @Input() form: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  get testFormArray(): FormArray {
    return this.form.get('standardizedTests') as FormArray;
  }

  toggleTest(index: number) {
    const testGroup = this.testFormArray.at(index) as FormGroup;
    const notTakenControl = testGroup.get('notTaken');
    const scoreControl = testGroup.get('score');
  
    if (notTakenControl?.value) {
      scoreControl?.disable();
      scoreControl?.setValue('');
      scoreControl?.setErrors(null); // Clear errors when disabled
    } else {
      scoreControl?.enable();
      scoreControl?.markAsTouched(); // Mark the input as touched
      scoreControl?.updateValueAndValidity(); // Revalidate when enabled
    }
  }
  

}


