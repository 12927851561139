import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NavigationService } from '../services/navigation.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { S3Service } from '../services/s3.service';
import * as moment from 'moment';
import csc from 'country-state-city';
import { ChangeDetectorRef } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { state } from '@angular/animations';

@Component({
  selector: 'ngx-colegios-edit',
  templateUrl: './colegios-edit.component.html',
  styleUrls: ['./colegios-edit.component.scss'],
})
export class ColegiosEditComponent implements OnInit {
  public fechaInicioA: any;
  public fechaFinA: any;
  public fechaInicioB: any;
  public fechaFinB: any;
  public finSemestreB: any;
  public nombreColegio: string = '';
  public nivelAcademico: any;
  public disciplina: any;
  public fechaFin: any;
  public fechaInicio: any;
  datepickerConfig: Partial<BsDatepickerConfig>;
  public radarValues: any;
  showComponent: boolean = false;
  showCrearUsuario: boolean = false;
  schoolId: any;
  studentData: any;
  idOfSchool: any;
  currentPage = 1;
  algo: 'hi';
  countries: any;
  states: any[] = [];
  cities: any[] = [];
  selectedState: any;
  selectedCountry: any;
  city: string;
  optionsTypeOfSchool: any[] = [];
  optionsOfNumber: any;
  optionsOfCertificate: any;
  optionsOFHighSchoolType: any;
  optionsOfTypesOfGender: any;
  optionsOfGrades: any;
  optionsOfEducationalLevels: any;
  matchingGradeLevels: any;
  gradesData: { [grade: string]: string[] } = {};
  optionsOfAverageTuitions: any;
  selectedGrade: string;
  selectedLevel: string;
  citiesOfSelectedState: any;
  selectedGrades: string[] = [];
  gradesOfSchool: string[] = [];
  showEstudiantesComponent = false;
  optionOfRanges: any;
  gradeLevels: { grade: string; levels: string[] }[] = [];
  selectedLevels: { [grade: string]: string } = {};
  schoolGradesResponse: any;
  newLevels: { [grade: string]: string } = {};
  imagen: any;
  readonly API_COLEGIO = 'api/colegio';
  readonly API_CIUDADES = 'api/ciudad';
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';
  readonly USER_API = 'api/user';

  allCities: any[];
  colegioForm: FormGroup;

  colegioData = {
    id: '',
    primerNombre: '',
    ciudad: '',
    logo: '',
    semestre: 0,
  };
  user = JSON.parse(localStorage.getItem('user'));
  nombre: string;
  params: any;
  colegioId: any;
  observable: any;
  spinner: boolean;
  dataColegio: any;
  dataRespaldo: any;
  schoolGradeLevels: any;
  filteredSchoolGrades: any;
  schoolGradeLevelsStructure: any;
  selectedCity: { id: string; name: string; state_id: string };
  ListDisciplinas: { value: string; name: string; text: string }[];
  readonly getTestColegioGeneralAll = 'api/colegioPuntuacionAll/';
  graficaGeneral: {
    datasets: { data: any; borderColor: any; backgroundColor: any }[];
    labels: any;
  };
  graficaLiderazgo: {
    datasets: { data: any; borderColor: any; backgroundColor: any }[];
    labels: any;
  };
  graficaGlobal_mind: {
    datasets: { data: any; borderColor: any; backgroundColor: any }[];
    labels: any;
  };
  graficaImpacto_social: {
    datasets: { data: any; borderColor: any; backgroundColor: any }[];
    labels: any;
  };
  graficaCompetencia_academica: {
    datasets: { data: any; borderColor: any; backgroundColor: any }[];
    labels: any;
  };
  graficaLogro_accion: {
    datasets: { data: any; borderColor: any; backgroundColor: any }[];
    labels: any;
  };
  readonly optionsOfMultiSelect: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };
  readonly optionsEducationalLevelsMultiSelect: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    itemsShowLimit: 2,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };
  options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontColor: 'rgb(255, 99, 132)',
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            labelString: '',
            display: false,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,

            callback: function (val, index, values) {
              let s_obj = new String(val);
              if (s_obj.length > 50) {
                return s_obj.slice(0, 50) + '...';
              } else {
                return s_obj;
              }
            },
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '',
          },
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };
  slugColegio: any;
  nuevaClave: any;

  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private formBuilderService: FormBuilder,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private s3Service: S3Service,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private ngxSpinner: NgxSpinnerService,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
    
  ) {
    this.datepickerConfig = {
      containerClass: 'theme-dark-blue custom-datepicker',
      dateInputFormat: 'DD/MM/YYYY',
    };
    this.ListDisciplinas = [
      {
        value: 'Arts, Design and Architecture',
        name: 'Home.arts-architecture',
        text: 'Arts,-Design-and-Architecture',
      },
      {
        value: 'Business and Management',
        name: 'Home.business',
        text: 'Business-and-Management',
      },
      {
        value: 'Computer Sciences and IT',
        name: 'Home.computer-science',
        text: 'Computer-Sciences-and-IT',
      },
      {
        value: 'Engineering',
        name: 'Home.engineering',
        text: 'Engineering',
      },
      {
        value: 'Sciences and Mathematics',
        name: 'Home.sciences-math',
        text: 'Sciences-and-Mathematics',
      },
      {
        value: 'Social Sciences and Humanities',
        name: 'Home.social-sciences',
        text: 'Social-Sciences-and-Humanities',
      },
      {
        value: 'Education and Sport',
        name: 'Home.education',
        text: 'Education-and-Sport',
      },
      {
        value: 'Community Services',
        name: 'Home.community',
        text: 'Community-Services',
      },
      {
        value: 'Environmental Studies and Earth Sciences',
        name: 'Home.env-studies',
        text: 'Environmental-Studies-and-Earth-Sciences',
      },
      {
        value: 'Health',
        name: 'Home.health',
        text: 'Health',
      },
      {
        value: 'Journalism and Media',
        name: 'Home.journalism',
        text: 'Journalism-and-Media',
      },
      {
        value: 'Law and Politic Sciences',
        name: 'Home.law-politics',
        text: 'Law-and-Politic-Sciences',
      },
      {
        value: 'Languages',
        name: 'Home.languages',
        text: '',
      },
      {
        value: 'Tourism and Hospitality',
        name: 'Home.tourism-hospitality',
        text: 'Tourism-and-Hospitality',
      },
    ];
  }

  ngOnInit(): void {
    this.getSchoolId();
    this.getSchoolTypes();
    this.getTypesOfCertificate();
    this.getTypesOfHighSchool();
    this.getTypesOfSchoolGender();
    this.getGradesConfig();
    this.getAverageTuition();
    this.getEducationalLevels();
    this.countries = csc.getAllCountries();
    this.showComponent = false;
    this.getCities();
    this.getColegio({ id: this.schoolId });
    this.getGradeLevelsBySchoolId(this.schoolId);


  }

  async getColegio({ id }): Promise<void> {
  try {
    // Mostrar indicador de carga
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    // 1. Obtener los datos del colegio desde la API
    const response = await this.apiService.get({ api: `${this.API_COLEGIO}/${id}` }).toPromise();
    console.log('Response del colegio:', response);

    // Asignar valores iniciales del colegio
    this.slugColegio = response.slug;
    this.setColegioData({ colegio: response });

    this.onSelectCountry(response.country.id);
    this.onSelectState(response.department.id);
    this.onSelectedCity(response.department.id);
    localStorage.setItem('user_id', response.idAdmin);
    localStorage.setItem('school_name', response.primerNombre);
  

    Swal.close();
  } catch (error) {
    // Manejo de errores
    console.error('Error al cargar el colegio:', error);
    Swal.fire('Error', 'No se pudo cargar la información del colegio', 'error');
  }
}

  setColegioData({ colegio }) {
    this.dataRespaldo = colegio.logo;
    if (colegio) {
      this.dataColegio = colegio;
    }

    this.dataColegio.foundationDate = new Date(this.dataColegio.foundationDate);

    this.schoolId = colegio._id;
  }

  async setFormData() {
    const school = this.colegioData;
    await this.colegioForm.get('nombreColegio').setValue(school.primerNombre);
  }

  async onSelectCountry(id) {
    try {
      const states = await csc.getStatesOfCountry(id);
      states.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.cities = states;
    } catch (error) {
      this.alertService.showError({ msg: error });
    }
    const selectedCountry = this.countries.find(country => country.id === id);
    this.selectedCountry = selectedCountry
      ? { id: selectedCountry.id, name: selectedCountry.name }
      : { id: "", name: "" };
  }
  onSelectState(selectedState: any): void {
    const stateObject = this.cities.find(state => state.name === selectedState);
    this.selectedState = stateObject
      ? { id: stateObject.id, name: stateObject.name }
      : { id: "", name: "" };

    this.onSelectedCity(this.selectedState.id);
  }
  onSelectedCity(selectedState: any): void {
    this.citiesOfSelectedState = csc.getCitiesOfState(selectedState);
    this.citiesOfSelectedState.sort((a, b) => (a.name < b.name ? -1 : 1));
    
    this.selectedCity = this.citiesOfSelectedState.length > 0
      ? { id: this.citiesOfSelectedState[0].id, name: this.citiesOfSelectedState[0].name, state_id: selectedState.id }
      : { id: "", name: "", state_id: selectedState.id };
  
    // Forzar la detección de cambios
    this.cdRef.detectChanges();
  }

  getSchoolTypes() {
    this.spinner = true;
    this.apiService.get({ api: 'api/getSchoolTypes' }).subscribe(
      (response) => {
        this.optionsTypeOfSchool = response.map((item) => item.option);
      },
      (error) => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }
  openEstudiantes(): void {
    this.showEstudiantesComponent = !this.showEstudiantesComponent;
  }
  getStudentsByGrade() {
    this.spinner = true;
    this.apiService.get({ api: 'api/getNumberOfStudents' }).subscribe(
      (response) => {
        this.optionsOfNumber = response.map((item) => item.numberOfStudent);
      },
      (error) => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }

  getSchoolId() {
    this.schoolId = localStorage.getItem('schoolId');
  }

  getTypesOfCertificate() {
    this.spinner = true;
    this.apiService.get({ api: 'api/getTypesOfCertificate' }).subscribe(
      (response) => {
        this.optionsOfCertificate = response.map((item) => item.certificateType);
      },
      (error) => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }
  getTypesOfHighSchool() {
    this.spinner = true;
    this.apiService.get({ api: 'api/getHighSchoolTypes' }).subscribe(
      (response) => {
        this.optionsOFHighSchoolType = response.map((item) => item.highSchoolType);
      },
      (error) => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }
  getTypesOfSchoolGender() {
    this.spinner = true;
    this.apiService.get({ api: 'api/getTypesOfGender' }).subscribe(
      (response) => {
        this.optionsOfTypesOfGender = response.map((item) => item.typeOfGender);
      },
      (error) => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }
  getGradesConfig() {
    this.spinner = true;
    this.apiService.get({ api: "api/getGrades" }).subscribe(
      response => {
        this.optionsOfGrades = response
          .sort((a, b) => a.grade - b.grade)
          .map(item => item.grade);
      },
      error => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }
  getAverageTuition() {
    this.spinner = true;
    this.apiService.get({ api: 'api/getAverageTuitions' }).subscribe(
      (response) => {
        this.optionOfRanges = this.formatRangesOfTuition(response);
      },
      (error) => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }
  formatRangesOfTuition(data: any[]): string[] {
    return data.map((item) => `${item.startRange}-${item.endRange}`);
  }
  getEducationalLevels() {
    this.spinner = true;
    this.apiService.get({ api: "api/getEducationalLevel" }).subscribe(
      response => {
        this.optionsOfEducationalLevels = response.map(
          item => item.educationalLevelType
        );
      },
      error => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }

  getGradeLevelsBySchoolId(schoolId: string) {
    this.spinner = true;
    const apiUrl = `api/getGradeLevelsById/${schoolId}`;
    this.apiService.get({ api: apiUrl }).subscribe(
      (response: any) => {
        if (response && response.gradesLevels) {
          this.schoolGradeLevels = response.gradesLevels;
          this.gradesData = {}; // Inicializamos el objeto vacío
  
          // Iterar sobre cada grado
          this.schoolGradeLevels.forEach((gradeInfo: any) => {
            const grade = gradeInfo.grade[0]; // Extraemos el grado (e.g., 9, 10)
            const levels = gradeInfo.levels.map((level: any) => level[0]); // Extraemos los niveles (e.g., 'A', 'B')
            
            // Asignar al objeto gradesData
            this.gradesData[grade] = levels;
          });
  
          console.log("gradesData:", this.gradesData);
          console.log("schoolGradeLevels:", this.schoolGradeLevels);
        } else {
          console.error("No gradesLevels in response:", response);
        }
      },
      error => {
        this.alertService.showError({ msg: error });
      },
      () => {
        this.spinner = false;
      }
    );
  }

  
  formatDate(date: Date): string {
    if (date) {
      const isoString = date.toISOString();
      return isoString.split('T')[0];
    }
    return '';
  }
  public cambiarClave() {
    let update = {
      email: this.dataColegio.emailUsuario,
      password: this.nuevaClave,
    };
    this.observable = this.apiService.post({
      api: 'user/reset',
      data: update,
    });
    this.observable.subscribe(
      (response) => {
        this.spinner = false;
        if (response.success) {
          let text = 'Se ha cambiado con éxito';
          this.alertService.showSuccess({ msg: text });
        } else {
          let text = 'Error al actualizar, vuelve a intentarlo';
          this.alertService.showError({ msg: text });
        }
      },

      (error) => {
        this.alertService.showError({ msg: error });
      },

      () => {
        this.spinner = false;
      }
    );
  }
  public ejecutarFiltro() {
    this.getTestFiltro(this.schoolId, this.fechaInicio, this.fechaFin, this.disciplina, this.nivelAcademico);
  }
  getTestFiltro(idCol, inicio, fin, disciplina, nivelAcademico) {
    let dis = 'null';
    let nivel = 'null';
    if (disciplina) {
      dis = disciplina;
    }
    if (nivelAcademico) {
      nivel = nivelAcademico;
    }
    this.apiService
      .get({
        api: this.getTestColegioGeneralAll + idCol + '/' + inicio + '/' + fin + '/' + dis + '/' + nivel,
      })
      .subscribe(
        (response) => {
          let X = [];
          let Y = [];
          let X_liderazgo = [];
          let Y_liderazgo = [];
          let X_Global = [];
          let Y_Global = [];
          let X_Impacto = [];
          let Y_Impacto = [];
          let X_Competencia = [];
          let Y_Competencia = [];
          let X_Logro = [];
          let Y_Logro = [];
          for (let dat of response) {
            let dataLabel = '';
            if (dat.semestre == 0) {
              dataLabel =
                moment(this.dataColegio.inicioSemestreA).format('YYYY-MM-DD') +
                ' / ' +
                moment(this.dataColegio.finSemestreA).format('YYYY-MM-DD');
            } else if (dat.semestre == 1) {
              dataLabel =
                moment(this.dataColegio.inicioSemestreB).format('YYYY-MM-DD') +
                ' / ' +
                moment(this.dataColegio.finSemestreB).format('YYYY-MM-DD');
            }
            X.push(dataLabel);
            Y.push(dat.puntaje_general);
            X_liderazgo.push(dataLabel);
            Y_liderazgo.push(dat.liderazgo);
            X_Global.push(dataLabel);
            Y_Global.push(dat.gobal_mind);
            X_Impacto.push(dataLabel);
            Y_Impacto.push(dat.impacto_social);
            X_Competencia.push(dataLabel);
            Y_Competencia.push(dat.competencia_academica);
            X_Logro.push(dataLabel);
            Y_Logro.push(dat.logro_accion);
          }
          this.graficaGeneral = this.graficoPie({ labels: X, data: Y });
          this.graficaLiderazgo = this.graficoPie({
            labels: X_liderazgo,
            data: Y_liderazgo,
          });
          this.graficaGlobal_mind = this.graficoPie({
            labels: X_Global,
            data: Y_Global,
          });
          this.graficaImpacto_social = this.graficoPie({
            labels: X_Impacto,
            data: Y_Impacto,
          });
          this.graficaCompetencia_academica = this.graficoPie({
            labels: X_Competencia,
            data: Y_Competencia,
          });
          this.graficaLogro_accion = this.graficoPie({
            labels: X_Logro,
            data: Y_Logro,
          });
          this.radarValues = {
            type: 'radar',
            data: {
              labels: [
                'Liderazgo',
                'Global Mind',
                'Impacto social',
                'Logro y accion',
                'Compentecia Academica / Aprendizaje',
              ],
              datasets: [
                {
                  label: 'Puntuacion de competencias',
                  data: [Y_liderazgo[1], Y_Global[1], Y_Impacto[1], Y_Logro[1], Y_Competencia[1]],
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: true,
              scale: {
                ticks: {
                  min: 0,
                  max: 100,
                },
              },
            },
          };
        },
        (err) => {
          return false;
        }
      );
  }

  graficoPie({ labels, data }) {
    let datos = {
      datasets: [
        {
          label: '',
          data: data,

          backgroundColor: 'transparent',
          borderColor: 'rgb(115,113,156)',
        },
      ],
      labels: labels,
    };
    return datos;
  }
  /**
   * handles changes on general documents (passport, grades, diplomas) file inputs
   * @param param0 Change event for input type file
   */
  onUploadChange({ event, type }) {
    if (event.target.files.length > 1) {
      this.alertService.showError({ msg: 'Debe subir sólo un archivo' });
    } else {
      const file = event.target.files[0];
      let key = '';
      if (type === 'image') {
        key = 'medical_services/file/' + event.target.files[0].name;
      }

      const callback = (err, data) => {
        if (err) {
          alert('No fue posible subir el archivo, por favor intente más tarde');
          this.spinner = false;
        } else {
          this.spinner = false;
          if (type === 'image') {
            this.imagen = data.Location;
          }
        }
      };
      this.spinner = true;
      this.s3Service.uploadFile({ file: file, key: key, callback });
    }
  }

  nextPage() {
    if (this.currentPage < 3) {
      this.currentPage++;
    }
  }
  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  get getNombreColegio() {
    return this.colegioForm.get('nombreColegio');
  }

  get getCiudadColegio() {
    return this.colegioForm.get('ciudadColegio');
  }

  get getSemestreColegio() {
    return this.colegioForm.get('semestreColegio');
  }

  getCities() {
    this.apiService.get({ api: this.API_CIUDADES }).subscribe((response) => {
      this.setupCities({ cities: response });
    });
  }
  setupCities({ cities }) {
    this.allCities = cities;
  }
  cambiarLogo() {
    this.dataColegio.logo = null;
  }
  devolverCambio() {
    this.dataColegio.logo = this.dataRespaldo;
  }
  updateColegio() {
    const id = this.dataColegio._id;
  
    // Actualiza el colegio
    this.apiService.put({ api: this.API_COLEGIO + '/' + id, data: this.dataColegio }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.alertService.showSuccess({
            msg: 'Colegio actualizado correctamente',
          });
  
          // Actualiza los niveles de grado
          const gradeLevelsObject = {
            schoolId: id,
            gradesLevels: this.filteredSchoolGrades,
          };
  
          this.apiService.put({ api: `api/updateGradeLevels/${id}`, data: gradeLevelsObject }).subscribe(
            (gradeResponse) => {
              if (gradeResponse && !gradeResponse.errors) {
                this.alertService.showSuccess({ 
                  msg: 'Niveles de grado actualizados correctamente',
                });
  
                // Actualiza el usuario administrador
                const idAdmin = this.dataColegio.idAdmin; // Asegúrate de que `idAdmin` está en `dataColegio`
                if (idAdmin) {
                  const userData = {
                    primerNombre: this.dataColegio.primerNombre,
                    email: this.dataColegio.correo,
                  };
  
                  this.updateUserInfo({ data: userData, idAdmin });
                } else {
                  this.alertService.showError({
                    msg: 'El ID del administrador no está disponible',
                  });
                }
              } else {
                this.alertService.showError({
                  msg: 'Error al actualizar los niveles de grado',
                });
              }
            },
            (err) => {
              console.error('Hubo un problema actualizando los niveles de grado', err);
              this.alertService.showError({
                msg: 'Error al actualizar los niveles de grado',
              });
            }
          );
        } else {
          this.alertService.showError({
            msg: 'Error al actualizar el colegio',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema actualizando el colegio', err);
        this.alertService.showError({
          msg: 'Error al actualizar el colegio',
        });
      }
    );
  }

  onGradeSelected() {
    this.filteredSchoolGrades = this.schoolGradeLevels.filter(gradeLevel =>
      this.dataColegio.selectedGrades.includes(gradeLevel.grade[0])
    );

    this.selectedLevel = "";
  }

  addLevel(grade: string) {
    let levelToBeAdded = this.newLevels[grade];

    if (
      Array.isArray(this.schoolGradeLevels) &&
      this.schoolGradeLevels.length > 0
    ) {
      const matchingGrade = this.schoolGradeLevels.find(
        (g: any) => g && g.grade && g.grade[0] === parseInt(grade)
      );

      if (matchingGrade) {
        this.matchingGradeLevels = matchingGrade.levels;

        if (!matchingGrade.levels) {
          matchingGrade.levels = [];
        }

        const levelExists = matchingGrade.levels.some((level: any) =>
          Array.isArray(level)
            ? level[0] === levelToBeAdded
            : level === levelToBeAdded
        );

        if (!levelExists) {
          matchingGrade.levels.push([levelToBeAdded]);
        } else {
        }
      } else {
        this.filteredSchoolGrades.push({
          grade: [parseInt(grade)],
          levels: [levelToBeAdded],
        });
      }

      this.newLevels[grade] = "";

      if (this.dataColegio.selectedGrades.includes(grade)) {
        this.gradesData[grade] = matchingGrade
          ? matchingGrade.levels.map((level: any) => level[0].toString())
          : [levelToBeAdded];
      }
    } else {
    }
  }
  removeLastLevel(grade: string) {
    console.log('Removing last level for grade:', grade);
    console.log('filteredSchoolGrades:', this.filteredSchoolGrades);
  
    const matchingGrade = this.filteredSchoolGrades.find(
      (g: any) =>
        g &&
        g.grade &&
        Array.isArray(g.grade) &&
        g.grade[0] === parseInt(grade)
    );
  
    console.log('Matching grade found:', matchingGrade);
  
    if (
      matchingGrade &&
      Array.isArray(matchingGrade.levels) &&
      matchingGrade.levels.length > 0
    ) {
      matchingGrade.levels.pop();
      console.log('Updated levels:', matchingGrade.levels);
  
      this.gradesData[grade] = matchingGrade.levels
        .map((level: any) => (level && level[0] ? level[0].toString() : ''))
        .filter((level: string) => level);
      console.log('Updated gradesData:', this.gradesData);
    } else {
      console.warn(
        `No matching grade found or levels are empty for grade: ${grade}`
      );
    }
  }
  
  updateUserInfo({ data, idAdmin }: { data: any; idAdmin: string }): void {
    this.apiService.put({ api: this.USER_API + '/' + idAdmin, data }).subscribe(
      (response) => {
        if (response && response.success === false) {
          this.alertService.showError({
            msg: this.translate.instant('Profile.upload-image-error'),
          });
        } else {
          this.alertService.showSuccess({
            msg: 'Usuario actualizado correctamente',
          });
  
          // Actualiza el almacenamiento local si es necesario
          if (response.updatedUser) {
            localStorage.setItem('user', JSON.stringify(response.updatedUser));
          }
  
          // Vuelve a inicializar el componente si es necesario
          this.ngOnInit();
        }
      },
      (err) => {
        console.error('Hubo un problema actualizando el usuario', err);
        this.alertService.showError({
          msg: this.translate.instant('Profile.upload-image-error'),
        });
      }
    );
  }
}
