import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-root',
  templateUrl: './simulationChartViewModel.component.html',
  styleUrls: ['./simulationChartViewModel.component.scss'],
})
export class SimulationChartComponentViewModel implements OnInit, AfterViewInit  {
  constructor(
    public dialogRef: MatDialogRef<SimulationChartComponentViewModel>,
    @Inject(MAT_DIALOG_DATA) public simulationChartData: any
  ) {}
  ngOnInit(): void {
    console.log("Datos iniciales:", this.simulationChartData);
  }

  ngAfterViewInit(): void {
    console.log("Datos después de la vista:", this.simulationChartData);
  }
}
