<div class="tab-content tableWrapper">
  <div class="tab-pane fade show active" id="infoTabContent">
    <div class="card mt-3">
      <div class="card-header header">Edita tu perfil</div>
      <div class="card-body" *ngIf="dataColegio">
        <div class="form-group">
          <div class="row h-100">
            <div class="col-6 my-auto">
              <div class="row" *ngIf="!dataColegio.logo">
                <label>Logo</label>
                <div class="col-12">
                  <nb-icon
                    icon="arrow-ios-back-outline"
                    [options]="{ animation: { type: 'zoom' } }"
                    class="btn-delete"
                    (click)="devolverCambio()"
                    style="margin-left: 0rem"></nb-icon>
                </div>
              </div>
              <div class="row" *ngIf="!dataColegio.logo">
                <div class="col-12" style="margin-top: 2rem">
                  Subir imagen
                  <input
                    id="imagen"
                    type="file"
                    class="image-input"
                    (change)="onUploadChange({ event: $event, type: 'image' })" />
                </div>
              </div>
              <div class="row">
                <div class="text-center" *ngIf="dataColegio.logo">
                  <img [src]="dataColegio.logo" alt="Logo Colegio" height="200px" />
                  <nb-icon
                    icon="close-circle-outline"
                    [options]="{ animation: { type: 'zoom' } }"
                    class="btn-delete"
                    (click)="cambiarLogo()"></nb-icon>
                </div>
              </div>
            </div>
            <div class="col-6 my-auto">
              <div class="row">
                <div class="col-12">
                  <label>Nombre colegio</label>
                  <input [(ngModel)]="dataColegio.primerNombre" type="text" class="form-control" />
                </div>
              </div>
              <div class="row h-100" hidden>
                <div class="col-8 my-auto">
                  <label>Clave</label>
                  <input [(ngModel)]="nuevaClave" type="text" class="form-control" />
                </div>
                <div class="col-4 my-auto">
                  <button (click)="cambiarClave()">Cambiar clave</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label>Email</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.correo"
                placeholder="Email" />
            </div>

            <div class="col-6">
              <label>Dirección</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.address"
                placeholder="Dirección" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label>Teléfono</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.phone"
                placeholder="Teléfono" />
            </div>
            <div class="col-6">
              <label for="country">País:</label><br />
              <select
                class="form-control"
                id="country"
                [(ngModel)]="dataColegio.country.id"
                (change)="onSelectCountry($event.target.value)"
              >
                <option *ngFor="let country of countries" [value]="country.id">
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label for="state">Estado:</label><br />
              <select
                class="form-control"
                [(ngModel)]="dataColegio.department.name"
                id="state"
                name="state"
                (ngModelChange)="onSelectState($event)"
              >
                <option *ngFor="let state of cities" [value]="state.name">
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="col-6">
              <label for="city">Ciudad:</label><br />
              <select
                class="form-control"
                [(ngModel)]="dataColegio.ciudad.name"
                id="city"
              >
                <option
                  *ngFor="let city of citiesOfSelectedState"
                  [value]="city"
                >
                  {{ city.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <mat-radio-group [(ngModel)]="dataColegio.calendarType">
                <mat-label>Calendario escolar:</mat-label>
                <div>
                  <mat-radio-button class="calendarOptions" value="A" id="publicSchool">A</mat-radio-button>
                  <mat-radio-button class="calendarOptions" value="B" id="privateSchool">B</mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <div class="col-6">
              <label>Fecha de fundación</label>
              <input
                type="text"
                class="modal-input form-control"
                bsDatepicker
                [bsConfig]="datepickerConfig"
                [(ngModel)]="dataColegio.foundationDate"
                placeholder="Fecha de fundación" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label>Rango de matrícula:</label>
              <div>
                <ng-multiselect-dropdown
                  placeholder="Seleccionar matrícula"
                  [settings]="optionsOfMultiSelect"
                  [data]="optionOfRanges"
                  [(ngModel)]="dataColegio.averageTuitionRange"></ng-multiselect-dropdown>
              </div>
            </div>
            <div class="col-6">
              <label>Cantidad de Estudiantes Total Colegio</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.numberOfStudents"
                placeholder="Cantidad de Estudiantes Total Colegio" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label for="highSchoolType">Tipo de bachillerato</label>
              <select id="highSchoolType" class="form-control" [(ngModel)]="dataColegio.typeOfHighSchool">
                <option *ngFor="let option of optionsOFHighSchoolType" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="col-6">
              <label for="certificateType">Tipo de certificado</label>

              <ng-multiselect-dropdown
                [placeholder]="'Selecciona un tipo de certificado'"
                [data]="optionsOfCertificate"
                [settings]="optionsOfMultiSelect"
                [(ngModel)]="dataColegio.englishTestCertify"></ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label>Niveles educativos</label>
              <ng-multiselect-dropdown
                placeholder="Seleccionar nivel"
                [settings]="optionsEducationalLevelsMultiSelect"
                [data]="optionsOfEducationalLevels"
                [(ngModel)]="dataColegio.educationalLevels"></ng-multiselect-dropdown>
            </div>
            <div class="col-6">
              <mat-radio-group [(ngModel)]="dataColegio.typeOfSchool">
                <mat-label>Tipo de colegio:</mat-label>
                <div class="schoolType">
                  <mat-radio-button
                    *ngFor="let option of optionsTypeOfSchool"
                    class="scholTypeOptions"
                    [value]="option">
                    {{ option }}
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label for="grade">Grados</label>
              <ng-multiselect-dropdown
                placeholder="Seleccionar universidad"
                [settings]="optionsOfMultiSelect"
                [data]="optionsOfGrades"
                [(ngModel)]="dataColegio.selectedGrades"
                (ngModelChange)="onGradeSelected()"></ng-multiselect-dropdown>
            </div>
            <div class="col-6">
              <label for="genderSelect">Género del Colegio:</label>
              <select
                id="genderSelect"
                class="form-control"
                placeholder="Género del Colegio"
                [(ngModel)]="dataColegio.selectedGender">
                <option *ngFor="let option of optionsOfTypesOfGender" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="levelInputsWrapper">
          <div class="levelInputsContainer" *ngFor="let grade of dataColegio.selectedGrades">
            <div class="levelInputItem">
              <label for="levelInput">Niveles para {{ grade }}:</label>
              <div class="d-flex p-2">
                <button
                  *ngIf="gradesData[grade] && gradesData[grade].length > 0"
                  type="button"
                  class="btn btn-outline-primary custom-btn"
                  (click)="removeLastLevel(grade)">
                  <img class="nextIcon" src="../../../assets/profile/eliminar.png" />
                </button>
                <input type="text" id="levelInput" [(ngModel)]="newLevels[grade]" />
                <button type="button" class="btn btn-outline-primary custom-btn" (click)="addLevel(grade)">
                  <img class="nextIcon" src="../../../assets/school-dashboard/create-activo.png" />
                </button>
              </div>
              <div *ngIf="gradesData && gradesData[grade] && gradesData[grade].length > 0">
                <p>Niveles: {{ gradesData[grade].join(", ") }}</p>
              </div>
              <div *ngIf="gradesData && (!gradesData[grade] || gradesData[grade].length === 0)">
                <p>No levels available for {{ grade }}.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label>Nombre rector</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.headMasterName"
                placeholder="Nombre rector" />
            </div>
            <div class="col-6">
              <label>Número Telefónico Rector</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.headMasterPhone"
                placeholder="Numero Rector " />
            </div>
            <div class="col-6">
              <label>Email Rector</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.headMasterEmail"
                placeholder="Email Rector " />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label>Nombre Director Bachillerato.</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.highSchoolPrincipalName"
                placeholder="Nombre director" />
            </div>
            <div class="col-6">
              <label>Numero Telefónico Director Bachillerato</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.highSchoolPrincipalPhone"
                placeholder="Numero Director " />
            </div>
            <div class="col-6">
              <label>Email Director Bachillerato</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.highSchoolPrincipalEmail"
                placeholder="Email Director" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label>Nombre psicologo</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.psychologistName"
                placeholder="Nombre psicologo " />
            </div>
            <div class="col-6">
              <label>Numero del psicologo</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.psychologistPhoneNumber"
                placeholder="Numero del psicologo" />
            </div>
            <div class="col-6">
              <label>Email psicologo</label>
              <input
                class="modal-input form-control"
                type="text"
                [(ngModel)]="dataColegio.emailPsychologist"
                placeholder="Email psicologo" />
            </div>
          </div>
        </div>
        <button
          class="btn btn-primary"
          nbButton
          size="small"
          type="submit"
          status="success"
          style="float: right"
          (click)="updateColegio()">
          Actualizar
        </button>
      </div>
    </div>
  </div>
</div>
