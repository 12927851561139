<section>
    <div class="loading-container" *ngIf="isLoading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>

    
    <ng-container *ngIf="!isLoading">
        <div class="countries-list">
            <app-countries-card *ngFor="let country of countries" [country]="country" (favoriteToggled)="onFavoriteToggled($event)"></app-countries-card>
        </div>
    </ng-container>

</section>