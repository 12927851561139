<div class="step-seven">

    <mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>

    <ng-container *ngIf="!isLoading">
        <app-initial-form-dropdown label="Elige el área de estudio o facultad que más te interesa"
            [control]="form.get('mainCareerInterest')" [options]="universityStudyFieldOptions"
            [multiSelect]="false"></app-initial-form-dropdown>

        <app-initial-form-dropdown label="Elige hasta dos áreas de estudio o facultades adicionales que también te interesen"
            [control]="form.get('careerInterest')" [options]="universityStudyFieldOptions"
            [multiSelect]="true"></app-initial-form-dropdown>

        <app-initial-form-dropdown label='Elige la carrera específica que más te gustaría estudiar. Si no tienes una en mente, selecciona "Ninguna" o "No sé"'
            [control]="form.get('desiredCareer')" [enableSearch]="true"
            [options]="universityCareerOptions"></app-initial-form-dropdown>
    </ng-container>

</div>