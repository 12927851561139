import { Component, OnInit } from '@angular/core';
import { StepperService } from '../../services/stepper.service';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';
import { STEPS_CONFIG, STEPS_UPDATE_PERSONAL_INFORMATION } from './initial-form.constants';

@Component({
  selector: 'app-initial-form',
  templateUrl: './initial-form.component.html',
  styleUrls: ['./initial-form.component.scss']
})
export class InitialFormComponent implements OnInit {
  stepperForm: FormGroup | null = null;
  currentStep = 0;
  isLoading = true;

  steps = STEPS_CONFIG;
  stepsUpdatePersonalInformation = STEPS_UPDATE_PERSONAL_INFORMATION;

  constructor(private stepperService: StepperService, private dialogRef: MatDialogRef<InitialFormComponent>) { }

  ngOnInit(): void {
    this.currentStep = this.stepperService.getStep();
    this.stepperService.loadUserData().subscribe(() => {
      this.isLoading = false;
    });
    this.stepperForm = this.stepperService.getStepperForm();
  }

  nextStep() {
    if (this.currentStep < this.steps.length) {
      const currentStepForm = this.getCurrentStepForm();

      if (currentStepForm) {
        currentStepForm.markAllAsTouched();

        if (currentStepForm.invalid) {
          return;
        }
      }

      this.saveProgress();
      this.currentStep++;
      this.stepperService.setCurrentStep(this.currentStep);
    }
  }

  prevStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.stepperService.setCurrentStep(this.currentStep);
    }
  }

  saveProgress() {
    const currentStepKey = this.steps[this.currentStep].key;

    if (this.stepsUpdatePersonalInformation.includes(currentStepKey)) {
      this.stepperService.saveProgress();
      this.stepperService.savePersonalInformation();
      return;
    }

    this.stepperService.saveProgress();
  }

  isNextStepDisabled(): boolean {
    if (this.isLoading) return true;

    const currentStepForm = this.getCurrentStepForm();

    if (!currentStepForm) return false;

    if (currentStepForm.invalid && currentStepForm.touched) {
      return true;
    }

    return false;

  }

  getCurrentStepForm(): FormGroup | null {
    if (!this.stepperForm) return null;

    return this.stepperForm.get(this.steps[this.currentStep].key) as FormGroup;
  }

  publishForm() {
    const currentStepForm = this.getCurrentStepForm();

    if (currentStepForm) {
      currentStepForm.markAllAsTouched();

      if (currentStepForm.invalid) {
        return;
      }
    }

    this.stepperService.publishForm().subscribe(
      () => {
        Swal.fire({
          title: 'Éxito',
          text: 'Tu formulario ha sido publicado exitosamente',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          this.dialogRef.close(); 
        }, (error) => {
          Swal.fire({
            title: 'Error',
            text: 'Ha ocurrido un error al publicar tu formulario',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        });
      }
    );
  }
}
