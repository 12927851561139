<div class="filter">
  <form class="filter__form">
    
    <div class="filter__group">
      <mat-form-field  class="filter__form-field">
        <mat-label>Nombre</mat-label>
        <input matInput (input)="onNameChange($event.target.value)" placeholder="Buscar por nombre">
      </mat-form-field>
    </div>

    <div class="filter__group">
      <mat-form-field class="filter__form-field">
        <mat-label>Facultad</mat-label>
        <mat-select multiple (selectionChange)="onFacultyChange($event.value)" placeholder="Selecciona una facultad">
          <mat-option *ngFor="let faculty of faculties" [value]="faculty._id">{{ faculty.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </form>
</div>