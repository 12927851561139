// progression-chart.component.ts
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';
import { ApexOptions } from 'ng-apexcharts';
import { Console } from 'console';

@Component({
  selector: 'app-root',
  templateUrl: './progressionChart.component.html',
  styleUrls: ['./progressionChart.component.scss'],
})
export class ProgressionChartComponent implements OnInit {
  userId = JSON.parse(localStorage.getItem('user'));
  readonly getSkill = 'api/getSkill/';
  readonly skillsGeneralScore = 'api/generalSkillTestScore/';
  readonly schoolApi = 'api/colegio';
  readonly plannedExperiences = `api/experiences/user/${this.userId._id}`;
  skillsScoreByQuarter: any;
  selectedSkill: string = 'Promedio';
  selectedQuarterMonths: any;
  chartOptions: ApexOptions;
  selectedQuarter = parseInt(this.userId.grade || '0', 10);
  categories: any;
  schoolCalendarType: any;
  series: any;
  skills: any;
  levels: any;
  skillObjects: any;
  skillsForFilter: any;
  grades: any;

  quarterOptions = [
    { value: 'FirstQuarter', viewValue: 'Primer Periodo' },
    { value: 'SecondQuarter', viewValue: 'Segundo Periodo' },
    { value: 'ThirdQuarter', viewValue: 'Tercer Periodo' },
  ];

  skillsEmptyValues = {
    "Aprendizaje Continuo": 0.1,
    "Creatividad E Innovación": 0.1,
    "Global Mind": 0.1,
    "Impacto y Responsabilidad Social": 0.1,
    "Inteligencia Emocional": 0.1,
    "Liderazgo": 0.1,
    "Orientación a Resultados": 0.1,
    "Desempeño Global Competencias": 0.1,
    "Pensamiento Crítico": 0.1,
  };

  emptySkills = {
    FirstQuarter: this.skillsEmptyValues,
    SecondQuarter: this.skillsEmptyValues,
    ThirdQuarter: this.skillsEmptyValues,
    FourthQuarter: this.skillsEmptyValues,
  };

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.getColegio({ id: this.userId.school }).then(() => {
      this.getSkills();
      this.getUserExperienceSkills();
    });
  }

  getColegio({ id }) {
    return new Promise<void>((resolve, reject) => {
      Swal.fire({
        title: 'Cargando...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      this.apiService.get({ api: this.schoolApi + '/' + id }).subscribe(
        (response) => {
          this.schoolCalendarType = response.calendarType;
          this.grades = response.selectedGrades;
          Swal.close();
          resolve(); // Ensure next steps only execute after this is done
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });
          console.error('Error fetching school data:', error);
          reject(error);
        }
      );
    });
  }



  calculateSkillsByGradeAndQuarter(userExperiences) {
    const initialSkills = {
      "Aprendizaje Continuo": 0.1,
      "Creatividad E Innovación": 0.1,
      "Global Mind": 0.1,
      "Impacto y Responsabilidad Social": 0.1,
      "Inteligencia Emocional": 0.1,
      "Liderazgo": 0.1,
      "Orientación a Resultados": 0.1,
      "Desempeño Global Competencias": 0.1,
      "Pensamiento Crítico": 0.1,
    };

    const quarters = ["FirstQuarter", "SecondQuarter", "ThirdQuarter", "FourthQuarter"];
    const skillsByGradeAndQuarter = {};

    // STEP 1: SORT EXPERIENCES BY GRADE AND END DATE (ascending order)
    userExperiences.sort((a, b) => {
      if (a.gradeOfExecution !== b.gradeOfExecution) {
        return parseInt(a.gradeOfExecution) - parseInt(b.gradeOfExecution);
      }
      return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
    });

    // STEP 2: PROCESS EXPERIENCES IN ORDER
    userExperiences.forEach(({ gradeOfExecution, endDate, principalSkill, secondarySkill, skillsScore, secondarySkillsScore,  status }) => {
      if (status !== "Aprobada") return;

      const grade = gradeOfExecution || this.calculateGradeOfExecution(this.userId, new Date(endDate).getFullYear());

      const quarter = this.getQuarter(new Date(endDate).getMonth(), this.schoolCalendarType);

      // Initialize grade if it doesn't exist
      if (!skillsByGradeAndQuarter[grade]) {
        skillsByGradeAndQuarter[grade] = quarters.reduce((acc, q) => {
          acc[q] = { ...initialSkills };
          return acc;
        }, {});
      }

      // STEP 3: UPDATE SKILL SCORES FOR THE SPECIFIC QUARTER
      const updateSkillScore = (skill, isPrincipal = false) => {
        if (skill) {
          const score = isPrincipal ? skillsScore : secondarySkillsScore / 2;
          skillsByGradeAndQuarter[grade][quarter][skill] =
            (skillsByGradeAndQuarter[grade][quarter][skill] || 0) + score;
        }
      };

      updateSkillScore(principalSkill, true);
      updateSkillScore(secondarySkill);
    });

    console.log(JSON.stringify(skillsByGradeAndQuarter, null, 2));


    const grades = Object.keys(skillsByGradeAndQuarter).sort((a, b) => parseInt(a) - parseInt(b));

    grades.forEach((grade, gradeIndex) => {
      quarters.forEach((quarter, index) => {
        if (index > 0) {
          // Ensure each quarter inherits the previous one if necessary
          Object.keys(initialSkills).forEach(skill => {
            let prevQuarter = quarters[index - 1];
            let prevValue = skillsByGradeAndQuarter[grade][prevQuarter][skill];
            let currValue = skillsByGradeAndQuarter[grade][quarter][skill];

            // If current value is 0.1, inherit the previous quarter
            if (currValue === 0.1) {
              skillsByGradeAndQuarter[grade][quarter][skill] = prevValue;
            } else {
              skillsByGradeAndQuarter[grade][quarter][skill] = parseFloat((prevValue + currValue).toFixed(2));
            }
          });
        }
      });

      // If this is NOT the last grade, carry over the last quarter (Q4) to the next grade's Q1
      if (gradeIndex < grades.length - 1) {
        const nextGrade = grades[gradeIndex + 1];
        const lastQuarterValues = skillsByGradeAndQuarter[grade]["FourthQuarter"];

        Object.keys(initialSkills).forEach(skill => {
          let inheritedValue = lastQuarterValues[skill];

          // Set Q1 of the next grade as the final value of Q4 from the previous grade
          skillsByGradeAndQuarter[nextGrade]["FirstQuarter"][skill] = parseFloat(
            (skillsByGradeAndQuarter[nextGrade]["FirstQuarter"][skill] + inheritedValue).toFixed(2)
          );
        });
      }
    });

    // STEP 6: CALCULATE "Desempeño Global Competencias" (Average of Other Skills)
    Object.values(skillsByGradeAndQuarter).forEach(gradeData => {
      Object.values(gradeData).forEach(quarterData => {
        const totalScore = Object.keys(initialSkills)
          .filter(skill => skill !== "Desempeño Global Competencias")
          .reduce((sum, skill) => sum + quarterData[skill], 0);

        const numberOfSkills = Object.keys(initialSkills).length - 1;
        quarterData["Desempeño Global Competencias"] = Math.round((totalScore / numberOfSkills) * 100) / 100;
      });
    });

    // STEP 7: ENSURE NO 0 VALUES (Replace 0 with 0.1)
    Object.values(skillsByGradeAndQuarter).forEach(gradeData =>
      Object.values(gradeData).forEach(quarterData =>
        Object.keys(quarterData).forEach(skill => {
          if (quarterData[skill] === 0) {
            quarterData[skill] = 0.1;
          }
        })
      )
    );

    return skillsByGradeAndQuarter;
  }

  getUserExperienceSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: `${this.plannedExperiences}?status=Aprobada` }).subscribe(
        (response) => {
          Swal.close();
          if (response.length !== 0) {
            const userExperiences = response;

            if (userExperiences.length > 0) {
              this.skillsScoreByQuarter = this.calculateSkillsByGradeAndQuarter(userExperiences);
              this.onQuarterSelection(this.userId.grade);
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Para ver resultados, Por favor, agrega experiencias.',
              });
            }
          }
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  getSkills() {
    this.apiService.get({ api: this.getSkill }).subscribe((response) => {
      this.skills = response;
      this.skillsForFilter = response.map((skill) => skill.skillName);

      this.onSkillSelection('Desempeño Global Competencias');
    });
  }

  findLevel(levels, skillScore) {
    for (const level of levels) {
      if (skillScore >= level.range1 && skillScore <= level.range2) {
        return level;
      }
    }
    return {
      level: 'N/A',
      description: 'Proficiency level not available for this skill score range.',
    };
  }
  onQuarterSelection(event) {
    this.selectedQuarterMonths = this.skillsScoreByQuarter[event];
    this.updateChart();
  }
  onSkillSelection(selectedSkill) {
    this.selectedSkill = selectedSkill ? selectedSkill : this.selectedSkill;

    const selectedSkillObject = selectedSkill;

    const filteredSkill = this.skills.filter((skill) => skill.skillName === selectedSkillObject);

    this.levels = filteredSkill[0].levels;

    this.updateChart();
  }

  updateChart() {
    const selectedSkill = this.selectedQuarterMonths || this.emptySkills;

    if (selectedSkill) {
      const categories = Object.keys(selectedSkill);
      const data = categories.map((month) => {
        const skillValue = selectedSkill[month][this.selectedSkill];
        return skillValue ? parseFloat(skillValue) : null;
      });

      this.skillObjects = data.map((score) => {
        const roundedScore = Math.round(score);
        const levelObject = this.levels.find((level) => roundedScore >= level.range1 && roundedScore <= level.range2);

        return {
          name: this.selectedSkill,
          score: roundedScore != null ? roundedScore + '%' : null,
          level: levelObject ? levelObject.level : null,
          levelDescription: levelObject ? levelObject.description : null,
        };
      });

      this.series = [
        {
          name: this.selectedSkill,
          data: data,
        },
      ];
      this.categories = categories;
      this.initializeChart();
    }
  }

  getLevel(score) {
    const levelObj = this.levels.find((levelObj) => score >= levelObj.range1 && score <= levelObj.range2);
    return levelObj ? levelObj.level : null;
  }

  initializeChart() {
    const translatedCategories = this.categories.map((category) => {
      switch (category) {
        case 'FirstQuarter':
          return 'Primer Periodo';
        case 'SecondQuarter':
          return 'Segundo Periodo';
        case 'ThirdQuarter':
          return 'Tercer Periodo';
        case 'FourthQuarter':
          return 'Cuarto periodo';
        default:
          return category;
      }
    });

    const minSeriesValue = Math.min(...this.series.flatMap((serie) => serie.data));

    const levelColors = {
      Principiante: 'rgb(255, 99, 99)',
      Intermedio: 'rgb(255, 171, 118)',
      Avanzado: 'rgb(255, 253, 162)',
      Maestro: 'rgb(186, 255, 180)',
      Visionario: 'rgb(57, 153, 24)',
    };

    const yaxisAnnotations = this.levels.map((level) => ({
      y: level.range1,
      y2: level.range2,
      borderColor: 'transparent',
      fillColor: levelColors[level.level] || 'rgba(0, 0, 0, 0.1)',
      opacity: 0.2,
      label: {
        text: level.level,
        style: {
          color: '#000',
        },
      },
    }));

    this.chartOptions = {
      series: this.series.map((serie) => ({ ...serie, dataLabels: { enabled: true } })),
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      xaxis: {
        categories: translatedCategories,
      },
      title: {
        text: `Skill Progression Chart - ${this.getQuarterLabel(this.selectedQuarter.toString())}`,
        align: 'center',
      },
      tooltip: {
        y: {
          formatter: (value: number) => {
            return value.toFixed(2) + '%';
          },
        },
      },
      dataLabels: {
        style: {
          fontSize: '10px',
        },
        enabled: true,
        formatter: (value, { dataPointIndex }) => {
          const skillObject = this.skillObjects[dataPointIndex];
          if (skillObject) {
            return `${value}% (${skillObject.level.toString()})`;
          }
          return value.toString();
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 5,
        title: {
          text: 'Puntaje de habilidad',
          style: {
            fontSize: '14px',
          },
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(2) + '%';
          },
          style: {
            fontSize: '12px',
          },
        },
      },
      annotations: {
        yaxis: yaxisAnnotations,
      },
    };
  }

  getQuarterLabel(quarter: string): string {
    const quarterOption = this.quarterOptions.find((option) => option.value === quarter);
    return quarterOption ? quarterOption.viewValue : '';
  }


  private getQuarter(month: number, calendarType): string {
    if (calendarType === 'A') {
      if (month < 3) {
        return 'FirstQuarter';
      } else if (month < 6) {
        return 'SecondQuarter';
      } else if (month < 9) {
        return 'ThirdQuarter';
      } else {
        return 'FourthQuarter';
      }
    }

    // Calendar type B

    if (month < 3) {
      return 'ThirdQuarter';
    } else if (month < 6) {
      return 'FourthQuarter';
    } else if (month < 9) {
      return 'FirstQuarter';
    } else {
      return 'SecondQuarter';
    }
  }

  private calculateGradeOfExecution(user, experienceYear) {
    const currentYear = new Date().getFullYear();
    const gradeDifference = currentYear - experienceYear;
    let calculatedGrade = user.grade - gradeDifference;

    // Ensure the grade stays within valid bounds (1-12)
    if (calculatedGrade < 1) calculatedGrade = 1;
    if (calculatedGrade > 12) calculatedGrade = 12;

    return calculatedGrade;
  }

}
