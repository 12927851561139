import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-initial-form-checkbox',
  templateUrl: './initial-form-checkbox.component.html',
  styleUrls: ['./initial-form-checkbox.component.scss']
})
export class InitialFormCheckboxComponent implements OnInit {
  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() disablesControl: FormControl | null = null; // Related input to disable

  ngOnInit(): void {
    if (!this.control) {
      console.warn('⚠️ No FormControl was passed to InitialFormCheckboxComponent!', this.label);
    }

    // Check initial state and disable if necessary
    if (this.disablesControl && this.control.value) {
      this.disablesControl.disable();
    }

    // Listen to checkbox changes and disable/enable the related input
    this.control.valueChanges.subscribe(checked => {
      if (this.disablesControl) {
        checked ? this.disablesControl.disable() : this.disablesControl.enable();
      }
    });
  }

  getErrorMessage(): string {
    if(this.control.hasError('required')) {
      return 'Este campo es obligatorio.';
    }
    return '';
  }
}
