import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { POST_SCHOOL_PLANS } from './step-future-plans.constants';

@Component({
  selector: 'app-step-future-plans',
  templateUrl: './step-future-plans.component.html',
  styleUrls: ['./step-future-plans.component.scss']
})
export class StepFuturePlansComponent implements OnInit {
  @Input() form: FormGroup;

  postSchoolPlanOptions: { label: string, value: string }[] = POST_SCHOOL_PLANS;

  constructor() { }

  ngOnInit(): void {
  }

}
