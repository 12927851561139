export const ENGLISH_LEVELS = [
    { _id: 'A1', name: 'A1' },
    { _id: 'A2', name: 'A2' },
    { _id: 'B1', name: 'B1' },
    { _id: 'B2', name: 'B2' },
    { _id: 'C1', name: 'C1' },
    { _id: 'C2', name: 'C2' },
];


export const GRADE_RATES = [
    {
        _id: 'not_available',
        name: 'No disponible'
    },
    {
        _id: 'regular',
        name: 'Regular'
    },
    {
        _id: 'acceptable',
        name: 'Aceptable'
    },
    {
        _id: 'good',
        name: 'Bueno'
    },
    {
        _id: 'very_good',
        name: 'Muy bueno'
    },
    {
        _id: 'excellent',
        name: 'Excelente'
    }
]

export const UNIVERSITY_DEGREES = [

    {
        _id: 'Artes, Diseño y Arquitectura',
        name: 'Artes, Diseño y Arquitectura',
    },
    {
        _id: 'Negocios y Gestión',
        name: 'Negocios y Gestión',
    },
    {
        _id: 'Ciencias Computacionales y TI',
        name: 'Ciencias Computacionales y TI',
    },
    {
        _id: 'Ingeniería',
        name: 'Ingeniería',
    },
    {
        _id: 'Ciencias y Matemáticas',
        name: 'Ciencias y Matemáticas',
    },
    {
        _id: 'Ciencias Sociales y Humanidades',
        name: 'Ciencias Sociales y Humanidades',
    },
    {
        _id: 'Educación y Deporte',
        name: 'Educación y Deporte',
    },
    {
        _id: 'Servicios Comunitarios',
        name: 'Servicios Comunitarios',
    },
    {
        _id: 'Estudios Ambientales y Ciencias de la Tierra',
        name: 'Estudios Ambientales y Ciencias de la Tierra',
    },
    {
        _id: 'Salud',
        name: 'Salud',
    },
    {
        _id: 'Periodismo y Medios',
        name: 'Periodismo y Medios',
    },
    {
        _id: 'Derecho y Ciencias Políticas',
        name: 'Derecho y Ciencias Políticas',
    },
    {
        _id: 'Idiomas',
        name: 'Idiomas',
    },
    {
        _id:'Turismo y Hospitalidad',
        name: 'Turismo y Hospitalidad'
    },
    {
        _id: 'Otro',
        name: 'Otro',
    }
]

export const STUDY_PLACES = [
    {
        _id:'El exterior',
        name: 'En el exterior'
    },
    {
        _id:'Mi pais',
        name: 'En el país'
    },
    {
        _id:'Aun no lo se',
        name: 'Aún no lo sé'
    }
];

export const GENDERS = [
    {
        _id:'male',
        name: 'Masculino'
    },
    {
        _id:'female',
        name: 'Femenino'
    },
    {
        _id:'other',
        name: 'Otro'
    }
]