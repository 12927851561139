<app-inital-form-card-selection [control]="form.get('careerMotivations')" [items]="motivationOptions" [multiSelect]="true" [maxSelections]="3">

  <ng-template let-item let-selected="selected">

    <div class="motivation-card" [class.selected]="selected">
      <div class="motivation-card__icon">
        <img [src]="item.img" [alt]="item.label">
      </div>
      <span class="motivation-card__name">{{ item.label }}</span>
    </div>

  </ng-template>
</app-inital-form-card-selection>