import { StepConfig } from "./initialForm.models";

export const STEPS_CONFIG: StepConfig[] = [
    { key: 'welcome', title: '¡Bienvenido a U360!', description: 'Completa los siguientes pasos para comenzar tu proceso de orientación académica.' },
    { key: 'personalInformation', title: 'Cuéntanos sobre ti', description: 'Ingresa tu nombre, apellido y otros datos básicos. ¡Queremos conocerte mejor!' },
    { key: 'academicBackground', title: 'Información académica', description: 'Dinos en qué grado estás. Esto nos ayudará a ofrecerte información relevante para tu futuro.' },
    { key: 'contactInformation', title: 'Mantengámonos en contacto', description: 'Déjanos tu correo, teléfono y redes sociales para que podamos enviarte información valiosa sobre tu futuro académico.' },
    { key: 'studyPreferences', title: '¿Dónde te gustaría estudiar?', description: 'Cuéntanos en qué lugar del mundo te gustaría estudiar.' },
    { key: 'locationPreferences', title: 'Explorando oportunidades', requiresFullForm: true },
    { key: 'futurePlans', title: 'Tu próximo paso después del colegio', description: 'Selecciona tu próximo paso después de la secundaria.' },
    { key: 'universityPreference', title: 'Tu estilo de aprendizaje y educación ideal', description: 'Elige el tipo de educación con el que más te identificas y que mejor se alinea con tus intereses y objetivos.' },
    { key: 'careerPreferences', title: 'Tu futuro profesional', description: 'Cuéntanos sobre las carreras que más te interesan, la que tienes en mente como tu primera opción y cuándo planeas comenzar tu camino universitario.' },
    { key: 'academicPerformance', title: 'Tus notas académicas', description: 'Ingresa tus notas académicas para que podamos ofrecerte información más precisa sobre tus opciones de estudio.' },
    { key: 'languageProficiency', title: 'Nivel de Inglés', description: 'Cuéntanos sobre tu nivel de inglés.' },
    { key: 'careerMotivations', title: 'Motivación', description: 'Selecciona hasta tres motivaciones que te impulsan a escoger una carrera.' },
    { key: 'standardizedTests', title: 'Pruebas estándarizadas', description: 'Ingresa tus resultados de pruebas estandarizadas para que podamos ofrecerte información más precisa sobre tus opciones de estudio.' },
    { key: 'skills', title: 'Habilidades académicas y generales', description: 'Qué tan interesado estas de explorar las siguientes áreas del conocimiento ó habilidades.' },
    { key: 'financialInformation', title: 'Información financiera', description: 'Cuéntanos sobre tu situación financiera para que podamos ofrecerte información más precisa sobre tus opciones de estudio.' },
    { key: 'icfesReadiness', title: 'Preparación para el ICFES', description: 'Cuéntanos cómo te estás preparando para el ICFES.' },
    { key: 'guardianInformation', title: 'Información de tus acudientes', description: 'Ingresa la información de tus padres o acudientes.' }
];


export const STEPS_UPDATE_PERSONAL_INFORMATION = ['personalInformation', 'academicBackground', 'contactInformation', 'universityPreference'];
