<article class="career-card__container">
    <header class="career-card__header-container">
        <div class="career-card__header">
            <h2 class="career-card__title">{{ universityCareer.name }}</h2>
            <p class="career-card__faculty-name">{{ universityCareer.faculty.name }}</p>
        </div>

        <button class="career-card__favorite-btn" (click)="toggleFavorite()" [disabled]="isLoading" [class.disabled]="isLoading">
            <i class="career-card__icon" [class.fas]="universityCareer.isFavorite" [class.fa-heart]="true" [class.far]="!universityCareer.isFavorite"
                [class.career-card__icon--filled]="universityCareer.isFavorite"></i>
        </button>

    </header>
    <p class="career-card__description">{{ universityCareer.description }}</p>
</article>