<div class="loading-container">
    <mat-spinner *ngIf="isLoadingCities && isLoadingUniversities" diameter="40"></mat-spinner>
</div>

<div class="form-container" *ngIf="!isLoadingCities && !isLoadingUniversities">

    <app-initial-form-dropdown label="Elige tu ciudad preferida para estudiar en Colombia"
        [control]="form.get('primaryCity')" [options]="colombianCitiesOptions" [enableSearch]="true">
    </app-initial-form-dropdown>

    <app-initial-form-dropdown label="Elige hasta 2 ciudades más donde considerarías estudiar"
        [control]="form.get('secondaryCities')" [options]="colombianCitiesOptions" [enableSearch]="true"
        [multiSelect]="true">
    </app-initial-form-dropdown>

    <app-initial-form-dropdown label="Elige la universidad en Colombia donde quisieras estudiar"
        [control]="form.get('mainColombianUniversity')" [options]="colombianUniversitiesOptions" [enableSearch]="true">
    </app-initial-form-dropdown>

    <app-initial-form-dropdown label="Selecciona hasta dos universidades adicionales que te interesen"
        [control]="form.get('colombianUniversities')" [options]="colombianUniversitiesOptions" [multiSelect]="true"
        [enableSearch]="true">
    </app-initial-form-dropdown>

</div>