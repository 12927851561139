// progression-chart.component.ts
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';
import { ApexOptions } from 'ng-apexcharts';

@Component({
  selector: 'app-root',
  templateUrl: './progressionChart.component.html',
  styleUrls: ['./progressionChart.component.scss'],
})
export class ProgressionChartComponent implements OnInit {
  userId = JSON.parse(localStorage.getItem('user'));
  readonly getSkill = 'api/getSkill/';
  readonly skillsGeneralScore = 'api/generalSkillTestScore/';
  readonly schoolApi = 'api/colegio';
  readonly plannedExperiences = 'api/experiences/';
  skillsScoreByQuarter: any;
  selectedSkill: string = 'Promedio';
  selectedQuarterMonths: any;
  chartOptions: ApexOptions;
  selectedQuarter: string = this.userId.grade;
  categories: any;
  schoolCalendarType: any;
  series: any;
  skills: any;
  levels: any;
  skillObjects: any;
  skillsForFilter: any;
  grades: any;

  quarterOptions = [
    { value: 'FirstQuarter', viewValue: 'Primer Periodo' },
    { value: 'SecondQuarter', viewValue: 'Segundo Periodo' },
    { value: 'ThirdQuarter', viewValue: 'Tercer Periodo' },
  ];

  constructor(private apiService: ApiService) { }

  async ngOnInit(): Promise<void> {
    // this.getGeneralSkillsScore();
    await this.getColegio({ id: this.userId.school });
    this.getSkills();
    this.getUserExperienceSkills();
  }

  async getColegio({ id }) {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.schoolApi + '/' + id }).subscribe(
        (response) => {
          this.schoolCalendarType = response.calendarType;
          this.grades = response.selectedGrades;

          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  getGeneralSkillsScore() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.skillsGeneralScore + this.userId._id }).subscribe(
        (response) => {
          this.skillsScoreByQuarter = response;

          this.onQuarterSelection(this.userId.grade);
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  calculateSkillsByGradeAndQuarter(userExperiences) {
    const initialSkills = {
      "Aprendizaje Continuo": 0,
      "Creatividad E Innovación": 0,
      "Global Mind": 0,
      "Impacto y Responsabilidad Social": 0,
      "Inteligencia Emocional": 0,
      "Liderazgo": 0,
      "Orientación a Resultados": 0,
    };

    const quarters = ["FirstQuarter", "SecondQuarter", "ThirdQuarter", "FourthQuarter"];
    const skillsByGradeAndQuarter = {};

    const determineQuarter = (month, calendar) => {
      if (calendar === 'A') {
        return month < 3 ? "FirstQuarter" : month < 6 ? "SecondQuarter" : month < 9 ? "ThirdQuarter" : "FourthQuarter";
      } else {
        return month < 3 ? "ThirdQuarter" : month < 6 ? "FourthQuarter" : month < 9 ? "FirstQuarter" : "SecondQuarter";
      }
    };

    userExperiences.forEach(({ gradeOfExecution, endDate, principalSkill, secondarySkill, skillsScore }) => {
      const grade = gradeOfExecution;
      const quarter = determineQuarter(new Date(endDate).getMonth(), this.schoolCalendarType);

      if (!skillsByGradeAndQuarter[grade]) {
        skillsByGradeAndQuarter[grade] = quarters.reduce((acc, q) => ({ ...acc, [q]: { ...initialSkills } }), {});
      }

      const updateSkillScore = (skill) => {
        if (skill) {
          const currentScore = skillsByGradeAndQuarter[grade][quarter][skill];
          skillsByGradeAndQuarter[grade][quarter][skill] = Math.min(currentScore + skillsScore, 100);
        }
      };

      updateSkillScore(principalSkill);
      updateSkillScore(secondarySkill);
    });

    Object.keys(skillsByGradeAndQuarter).forEach(grade => {
      quarters.forEach((quarter, index) => {
        if (index > 0) {
          const previousQuarter = quarters[index - 1];
          Object.keys(initialSkills).forEach(skill => {
            const accumulatedScore = skillsByGradeAndQuarter[grade][previousQuarter][skill] + skillsByGradeAndQuarter[grade][quarter][skill];
            skillsByGradeAndQuarter[grade][quarter][skill] = Math.min(accumulatedScore, 100);
          });
        }
      });
    });

    Object.values(skillsByGradeAndQuarter).forEach(gradeData =>
      Object.values(gradeData).forEach(quarterData =>
        Object.keys(quarterData).forEach(skill => {
          if (quarterData[skill] === 0) {
            quarterData[skill] = 0.1;
          }
        })
      )
    );

    console.log(skillsByGradeAndQuarter);
    return skillsByGradeAndQuarter;
  }

  getUserExperienceSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.plannedExperiences }).subscribe(
        (response) => {
          Swal.close();
          if (response.length !== 0) {
            const userExperiences = response.filter((experience) => experience.user === this.userId._id);

            if (userExperiences.length > 0) {
              this.skillsScoreByQuarter = this.calculateSkillsByGradeAndQuarter(userExperiences);
              this.onQuarterSelection(this.userId.grade);
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Para ver resultados, Por favor, agrega experiencias.',
              });
            }
          }
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  getSkills() {
    this.apiService.get({ api: this.getSkill }).subscribe((response) => {
      this.skills = response;
      this.skillsForFilter = response.map((skill) => skill.skillName).filter(skill => skill !== "Desempeño Global Competencias");

      this.onSkillSelection('Liderazgo');
    });
  }

  findLevel(levels, skillScore) {
    for (const level of levels) {
      if (skillScore >= level.range1 && skillScore <= level.range2) {
        return level;
      }
    }
    return {
      level: 'N/A',
      description: 'Proficiency level not available for this skill score range.',
    };
  }
  onQuarterSelection(event) {
    this.selectedQuarterMonths = this.skillsScoreByQuarter[event];
    this.updateChart();
  }
  onSkillSelection(selectedSkill) {
    this.selectedSkill = selectedSkill ? selectedSkill : this.selectedSkill;

    const selectedSkillObject = selectedSkill;

    const filteredSkill = this.skills.filter((skill) => skill.skillName === selectedSkillObject);

    this.levels = filteredSkill[0].levels;

    this.updateChart();
  }

  updateChart() {
    if (this.selectedQuarterMonths) {
      const categories = Object.keys(this.selectedQuarterMonths);
      const data = categories.map((month) => {
        const skillValue = this.selectedQuarterMonths[month][this.selectedSkill];
        return skillValue ? parseFloat(skillValue) : null;
      });

      this.skillObjects = data.map((score) => {
        const roundedScore = Math.round(score);
        const levelObject = this.levels.find((level) => roundedScore >= level.range1 && roundedScore <= level.range2);

        return {
          name: this.selectedSkill,
          score: roundedScore != null ? roundedScore + '%' : null,
          level: levelObject ? levelObject.level : null,
          levelDescription: levelObject ? levelObject.description : null,
        };
      });

      this.series = [
        {
          name: this.selectedSkill,
          data: data,
        },
      ];
      this.categories = categories;
      this.initializeChart();
    }
  }

  getLevel(score) {
    const levelObj = this.levels.find((levelObj) => score >= levelObj.range1 && score <= levelObj.range2);
    return levelObj ? levelObj.level : null;
  }

  initializeChart() {
    const translatedCategories = this.categories.map((category) => {
      switch (category) {
        case 'FirstQuarter':
          return 'Primer Periodo';
        case 'SecondQuarter':
          return 'Segundo Periodo';
        case 'ThirdQuarter':
          return 'Tercer Periodo';
        case 'FourthQuarter':
          return 'Cuarto periodo';
        default:
          return category;
      }
    });

    const minSeriesValue = Math.min(...this.series.flatMap((serie) => serie.data));

    const levelColors = {
      Principiante: 'rgb(255, 99, 99)',
      Intermedio: 'rgb(255, 171, 118)',
      Avanzado: 'rgb(255, 253, 162)',
      Maestro: 'rgb(186, 255, 180)',
      Visionario: 'rgb(57, 153, 24)',
    };

    const yaxisAnnotations = this.levels.map((level) => ({
      y: level.range1,
      y2: level.range2,
      borderColor: 'transparent',
      fillColor: levelColors[level.level] || 'rgba(0, 0, 0, 0.1)',
      opacity: 0.2,
      label: {
        text: level.level,
        style: {
          color: '#000',
        },
      },
    }));

    this.chartOptions = {
      series: this.series.map((serie) => ({ ...serie, dataLabels: { enabled: true } })),
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      xaxis: {
        categories: translatedCategories,
      },
      title: {
        text: `Skill Progression Chart - ${this.getQuarterLabel(this.selectedQuarter)}`,
        align: 'center',
      },
      tooltip: {
        y: {
          formatter: (value: number) => {
            return value.toFixed(2) + '%';
          },
        },
      },
      dataLabels: {
        style: {
          fontSize: '10px',
        },
        enabled: true,
        formatter: (value, { dataPointIndex }) => {
          const skillObject = this.skillObjects[dataPointIndex];
          if (skillObject) {
            return `${value}% (${skillObject.level.toString()})`;
          }
          return value.toString();
        },
      },
      yaxis: {
        min: minSeriesValue,
        max: 100,
        tickAmount: 5,
        title: {
          text: 'Puntaje de habilidad',
          style: {
            fontSize: '14px',
          },
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(2) + '%';
          },
          style: {
            fontSize: '12px',
          },
        },
      },
      annotations: {
        yaxis: yaxisAnnotations,
      },
    };
  }

  getQuarterLabel(quarter: string): string {
    const quarterOption = this.quarterOptions.find((option) => option.value === quarter);
    return quarterOption ? quarterOption.viewValue : '';
  }
}
