<div class="page-container">
  <p class="pageTitle">Bienvenido a U360</p>
  <p>Por favor complete su perfil para ser contactado por las universidades de acuerdo a su perfil individual.</p>
  <hr class="titleSeparator" />
  <app-form-input [control]="form.get('school')" placeholder="Colegio" [disabled]="true"></app-form-input>
  <app-form-input
    [control]="form.get('schoolCity')"
    type="text"
    placeholder="Ciudad de tu colegio"
    [disabled]="true"></app-form-input>
  <div *ngIf="schoolGrades?.length > 0">
    <mat-form-field style="width: 100%">
      <mat-label>Selecciona tu grado escolar:</mat-label>
      <mat-select [formControl]="form.get('currentGrade')" (selectionChange)="onGradeChange($event.value)">
        <mat-option *ngFor="let grade of schoolGrades" [value]="grade.value">
          {{ grade.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="selectedGradeLevels?.length > 0">
    <mat-form-field style="width: 100%">
      <mat-label>Selecciona el nivel:</mat-label>
      <mat-select [formControl]="form.get('gradeLevel')">
        <mat-option *ngFor="let level of selectedGradeLevels" [value]="level">
          {{ level }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <app-form-input [control]="form.get('name')" type="text" placeholder="Escribe tu nombre"></app-form-input>

  <app-form-input
    [control]="form.get('secondName')"
    type="text"
    placeholder="Escribe tu segundo nombre"></app-form-input>

  <app-form-input [control]="form.get('lastName')" type="text" placeholder="Escribe tu apellido"></app-form-input>

  <app-form-input
    [control]="form.get('secondLastName')"
    type="text"
    placeholder="Escribe tu segundo apellido"></app-form-input>

  <app-date-input [control]="form.get('birthDate')" label="Fecha de nacimiento"></app-date-input>

  <app-form-dropdown
    [control]="form.get('gender')"
    label="Genero"
    [options]="[
      { value: 'male', label: 'Masculino' },

      { value: 'female', label: 'Femenino' },
      { value: 'male', label: 'Otro' }
    ]"></app-form-dropdown>
</div>
