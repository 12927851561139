import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';
import { NavigationService } from '../../../services/navigation.service';
import questionary from '../questions/questionary.js';

export interface QueryResponse {
  question: string;
  answer: string;
}
export interface SkillResponse {
  skill: string;
  totalPoints: number;
}
@Component({
  selector: 'app-psychometricTest',
  templateUrl: './psychometricTest.component.html',
  styleUrls: ['./psychometricTest.component.scss'],
})
export class PsychometricTest implements OnInit, OnDestroy {
  @ViewChild('questionsSection', { static: false }) questionsSection!: ElementRef;
  selectedTarget: string = '';
  personalData: any;
  selectedOptionUniversity: string = '';
  selectedBudget: { question: string; answer: boolean } = { question: '', answer: false };
  collectedQuestionData: QueryResponse[] = [];
  skillsPoints: SkillResponse[] = [];
  skillPointsObject: { [skill: string]: number } = {};
  submittedQuestionsAndAnswers: { question: string; answer: boolean }[] = [];
  public selectedSkillDescription: string = '';
  public activeStep: number = 1;
  public scrollBar: number = 5.88;
  public progress: any;
  selectedAnswer: number | null = null;
  showPaso2: boolean = true;
  showComponents = true;
  activeComponent = 0;
  showAdditionalContent = false;
  navStyle = 'width:25%;';
  questions = questionary;
  selectedAnswers: any[] = [];
  selectedData: QueryResponse[] = [];
  previousSelectedAnswers: number[] = [];
  currentQuestionIndex: number = 0;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private navigationService: NavigationService
  ) {}

  toggleContent() {
    this.showAdditionalContent = !this.showAdditionalContent;
    this.showPaso2 = !this.showAdditionalContent;
  }
  ngOnDestroy(): void {
    this.navigationService.setIsVisibleSidebar(true);
  }

  ngOnInit(): void {
    this.navigationService.setIsVisibleSidebar(false);
  }
  isLastStep(): boolean {
    return this.activeStep === 50;
  }
  selectAnswer(event: any) {
    const selectedAnswerIndex = event.value;
    const selectedAnswer = this.questions[this.activeStep - 1].answers[selectedAnswerIndex];
    this.selectedAnswers[this.activeStep - 1] = selectedAnswerIndex;
    this.previousSelectedAnswers[this.activeStep - 1] = this.selectedAnswer || -1;
    this.selectedAnswer = selectedAnswerIndex;

    const selectedDataEntry: QueryResponse = {
      question: this.questions[this.activeStep - 1].question,
      answer: selectedAnswer,
    };
    this.selectedData[this.activeStep - 1] = selectedDataEntry;
  }

  toggleComponents() {
    if (!this.showComponents) {
      this.activeComponent = 0;
    }
    this.showComponents = !this.showComponents;
    this.showAdditionalContent = false;
  }
  previousComponent() {
    if (this.activeComponent > 0) {
      this.activeComponent--;
    }
  }

  nextComponent() {
    if (this.activeComponent < 5) {
      this.activeComponent++;
    } else {
      this.scrollToQuestionsSection();
    }
  }

  scrollToQuestionsSection() {
    if (this.questionsSection && this.questionsSection.nativeElement) {
      this.questionsSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  onSelectedTarget(option: string) {
    this.selectedTarget = option;
  }

  onPersonalDataSubmitted(data: any) {
    this.personalData = data;
  }

  onSelectedUniversityType(data: string) {
    this.selectedOptionUniversity = data;
  }

  onSelectedBudget(data: { question: string; answer: boolean }) {
    this.submittedQuestionsAndAnswers.push(data);
  }

  handleQuestionsAndAnswers(data: QueryResponse[]) {
    this.collectedQuestionData = data;
  }

  handleSkillPointsData(skillPercentages: { [key: string]: number }): void {
    this.skillPointsObject = skillPercentages;
  }

  onSelectedSkill(option: string) {
    if (
      option === 'Global Mind' ||
      option === 'Competencia Académica' ||
      option === 'Impacto Social' ||
      option === 'Logro y Acción' ||
      option === 'Liderazgo'
    ) {
      this.selectedSkillDescription = option;
    }
  }

  nextStep() {
    this.showPaso2 = false;
    if (this.activeStep < this.questions.length) {
      this.scrollBar = this.scrollBar + 2;
      this.progress = 'width:' + this.scrollBar + '%';
      this.activeStep++;
      this.currentQuestionIndex++;
      this.selectedAnswer = null;
      if (this.activeStep <= this.questions.length) {
        const selectedAnswerIndex = this.selectedAnswers[this.activeStep - 1];
        if (selectedAnswerIndex !== undefined) {
          const selectedQuestion = this.questions[this.activeStep - 1].question;
          const selectedAnswer = this.questions[this.activeStep - 1].answers[selectedAnswerIndex];
        }
      }
    }
  }

  goBack() {
    if (this.activeStep > 1) {
      this.scrollBar = this.scrollBar - 2;
      this.progress = 'width:' + this.scrollBar + '%';
      this.activeStep--;
      this.selectedAnswer = this.previousSelectedAnswers[this.activeStep - 1];
    }
  }

  onSubmit() {
    Swal.fire({
      title: 'Finalizar test',
      text: '¿Quieres finalizar el test? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, finalizar',
      cancelButtonText: 'Regresar',
    }).then((result) => {
      if (result.isConfirmed) {
        const preguntaRespuestas: QueryResponse[] = [];

        for (const selectedDataEntry of this.selectedData) {
          if (selectedDataEntry && selectedDataEntry.question && selectedDataEntry.answer) {
            preguntaRespuestas.push(selectedDataEntry);
          }
        }

        const userId = JSON.parse(localStorage.getItem('user'));
        const postData = {
          userId: userId,
          selectedTarget: this.selectedTarget,
          personalData: this.personalData,
          selectedOptionUniversity: this.selectedOptionUniversity,
          selectedSkillDescription: this.selectedSkillDescription,
          collectedQuestionData: this.collectedQuestionData,
          skillPointsObject: this.skillPointsObject,
          selectedBudget: this.submittedQuestionsAndAnswers,
        };
        const data = {
          api: 'api/skillsTest',
          data: postData,
        };

        this.apiService.post(data).subscribe(
          (response) => {
            this.navigationService.navigateTo({ path: '/current-test' });
          },
          (error) => {
            console.error('Error submitting questions and answers:', error);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salio mal al mandar los datos!',
            });
          }
        );
      }
    });
  }
}
