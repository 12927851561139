import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { InitialFormComponent } from '../../initialForm/initialForm.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';
import { formatDate } from '@angular/common';
import { SimulationChartComponentViewModel } from '../../simulationChartViewModel/simulationChartViewModel.component';

@Component({
  selector: 'app-principal-menu',
  templateUrl: './principal-menu.component.html',
  styleUrls: ['./principal-menu.component.scss'],
})
export class PrincipalMenuComponent implements OnInit {
  router: any;
  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private dialog: MatDialog,
    private apiService: ApiService
  ) {}

  actived: string;
  isRecruitment: boolean;
  isPremiumUser: boolean;
  isActiveMenuMobile: boolean;
  isColegio: boolean;
  isStudent: boolean;
  showMisExperiencias: boolean = false;
  userId: any;
  isConoceteVisible: boolean = false;
  searchTerm: string = '';
  filteredExperiences: any[] = [];
  isopenSimulationChartVisible: boolean = false;
  showDashboard: boolean = false;
  showExplore: boolean = false;
  experiences: any;
  user = JSON.parse(localStorage.getItem('user'));
  showMatch: boolean = false;
  showTest: boolean = false;
  studentId: any;
  showProgression: boolean = false;
  readonly plannedExperiences = 'api/experiences/';
  selectedSkillsOption: any;
  showEnglishTest: boolean = false;
  selectedCategoryOption: any;
  filteredExperiencesByUser: any;
  isSimulationChartSkillsVisible: boolean = false;
  dataCompleted: boolean = false;
  languageLevel: any;
  isPublished: string = localStorage.getItem('isPublished');

  @Input() displayMenu = false;
  @Input() escapeMenu = false;
  @Output() newEventEmiter = new EventEmitter<boolean>();
  @Output() isActiveMenuMobileEmitter = new EventEmitter<boolean>();
  @ViewChild('boxSidebarMenu') boxSidebarMenu: ElementRef;

  menuOptions: any = {
    match: false,
    dashboard: false,
    cv: false,
    search: false,
    favourites: false,
    applications: false,
    documents: false,
    blog: false,
    marketplace: false,
    testIA: false,
    englishTest: false,
    institutionType: false,
    coverLetter: false,
    experiencesExample: false,
    exploreSkills: false,
    experiences: false,
    experiencesPlanner: false,
    initalResults: false,
    progressionChart: false,
    englishResults: false,
    SimulationChartSkills: false,
    formUconnections: false,
  };

  ngOnInit(): void {
    if (this.isPublished != 'true') {
      this.activeOption('formUconnections');
      this.isopenSimulationChartVisible = true;
      this.openSimulationChart();
    } else {
      this.activeOption('documents');
      this.isopenSimulationChartVisible = false;
    }
    this.languageLevel = localStorage.getItem('languageLevel');
  
    // Detectar cambios en los parámetros de consulta
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['skill']) {
        this.activeOption('experiencesExample');
        this.showExplore = true;
      }
    });

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['fromUpdate']) {
        this.toggleProgression();
        this.activeOption('dashboard');
      }
    });

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['fromRegister']) {
        this.toggleProgression();
        this.activeOption('experiences');
      }
    });
    

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['fromPlanner']) {
        this.toggleProgression();
        this.activeOption('experiencesPlanner');
      }
    });
  
    this.activatedRoute.paramMap.subscribe((params) => {
      this.studentId = params.get('id');
    });
  
    this.userService.currentUser$.subscribe((user) => {
      if (user) {
        this.checkProfileType(user);
      }
    });
  
    const user = JSON.parse(localStorage.getItem('user'));
    this.userId = user._id;
  
    this.checkUrl();
    this.getRecordedExperiences();
  
    this.authService.isPremiumUser$.subscribe(
      (result) => (this.isPremiumUser = result)
    );
  
    this.activatedRoute.url.subscribe((url) => this.checkUrl(url[0].path));
    this.toggleConocete();
  
    this.router = ['profile'];
  }

  activeOption(option: string): void {
    for (const key in this.menuOptions) {
      this.menuOptions[key] = key === option;
    }

    if (option === 'coverLetter') {
      this.menuOptions.cv = !this.showMisExperiencias;
    } else if (option === 'cv') {
      this.menuOptions.coverLetter = false;
    }

    if (
      option !== 'cv' &&
      this.showMisExperiencias &&
      option !== 'coverLetter' 
    ) {
      this.showMisExperiencias = false;
    }
    if (option !== 'documents' && option !== 'formUconnections') {
      this.isConoceteVisible = false;
    }
    if (option !== 'testIA' && option !== 'initalResults' ) {
      this.showTest = false;
    }
    if (option !== 'englishTest' && option !== 'englishResults' ) {
      this.showEnglishTest = false;
    }
    if (option !== 'progressionChart' && option !== 'dashboard' ) {
      this.showProgression = false;
    }
    if ( option !== 'testIA' && option !== 'initalResults'  && option !== 'englishTest'  && option !== 'exploreSkills'  && option !== 'englishResults' ) {
      this.showDashboard = false;
    }
    if (option !== 'institutionType' && option !== 'experiencesExample' && option !== 'experiencesPlanner'  && option !== 'experiences' && option !== 'progressionChart' && option !== 'dashboard' && option !== 'SimulationChartSkills') {
      this.showExplore = false; 
    }
    if (option !== 'search' && option !== 'favourites') {
      this.showMatch = false;
    }
    this.actived = this.menuOptions[option] ? option : '';
  }

  @HostListener('click')
  clickout(): void {
    this.displayMenu = false;
  }

  onGoToProfile(): void {
    this.navigationService.navigateTo({ path: 'profile' });
  }

  checkUrl(urlActived?: string): void {
    const url = urlActived || document.location.pathname;

    if (url === '/web/home' || url === '/web/program/search') {
      this.actived = 'search';
    } else if (url === '/web/preTest') {
      this.actived = 'match';
    } else if (url === '/web/wishlist') {
      this.actived = 'favourites';
    } else if (url === '/web/skill-dashboard') {
      this.actived = 'dashboard';
    } else if (url === '/web/profile' || this.isopenSimulationChartVisible == false) {
      this.actived = 'documents';
    } else if (url === '/web/my-applications') {
      this.actived = 'applications';
    } else if (url === '/web/blog') {
      this.actived = 'blog';
    } else if (url === '/web/curriculum' || url === '/web/letter') {
      this.actived = 'cv';
    } else if (url === '/web/dashboard-school') {
      this.actived = 'dashboard-school';
    } else if (url === '/web/students') {
      this.actived = 'students';
    } else if (url == '/experience-record/'+this.userId) {
      this.actived = 'experiences';
    } else if (url === '/web/leaderTest') {
      this.actived = 'testIA';
    } else if (url === 'web/english-test') {
      this.actived = 'englishTest';
    } else if (url === 'web/universityType') {
      this.actived = 'institutionType';
    } else if (url === 'web/explore-skills') {
      this.actived = 'exploreSkills';
    } else if (url === 'web/coverLetter') {
      this.actived = 'coverLetter';
    } else if (url === 'web/experience-example') {
      this.actived = 'experiencesExample';
    } else if (url === 'web/experience-planner') {
      this.actived = 'experiencesPlanner';
    } else if (url === 'web/current-test') {
      this.actived = 'initalResults';
    } else if (url === 'web/progression') {
      this.actived = 'progressionChart';
    }else if (url === 'vocational-test') {
      this.actived = 'vocationalTest';
    }else if (this.isopenSimulationChartVisible === true) {
      this.actived = 'formUconnections';
    }
    else if (this.isSimulationChartSkillsVisible === true) {
      this.actived = 'SimulationChartSkills';
    }
    else if (url === 'english-test/'+this.languageLevel) {
      this.actived = 'englishResults';
    }
  }

  checkProfileType(user): void {
    this.isRecruitment = user?.tipo === 'Recruitment';
    this.isColegio = user?.tipo === 'Colegio';
    this.isStudent = user?.tipo === 'Estudiante';
  }
  async openSimulationChart(): Promise<void> {
    this.activeOption('formUconnections');
    this.isopenSimulationChartVisible = true;
    const dialogRef = this.dialog.open(InitialFormComponent, {
      width: '50vw',
      height: '95vh',
      disableClose: true,
    });

    dialogRef.backdropClick().subscribe(() => {
      Swal.fire({
        title: 'Tienes cambios sin guardar',
        text: '¿Qué deseas hacer?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Salir y guardar',
        cancelButtonText: 'Salir sin guardar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dialogRef.componentInstance.saveChanges();
          this.activeOption('documents');
        }
        dialogRef.close();
      });
    });

    dialogRef.afterClosed().subscribe(() => {
      this.activeOption('documents');
      dialogRef.componentInstance.dataCompleted.subscribe((completed) => {
        this.dataCompleted = completed;
      });
    });
  }

  async openSimulationChartSkills(simulationChartData: any): Promise<void> {
    this.isSimulationChartSkillsVisible = true;
    const dialogRef = this.dialog.open(SimulationChartComponentViewModel, {
      width: '50vw',
      height: '95vh',
      data: { experienceData: simulationChartData },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.activeOption('experiences');
      this.getRecordedExperiences();
    });
  }

  onToogleMenuMobile(): void {
    this.isActiveMenuMobile = !this.isActiveMenuMobile;
    this.isActiveMenuMobileEmitter.emit(this.isActiveMenuMobile);
  }

  getRecordedExperiences() {
      Swal.fire({
        title: 'Cargando...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      try {
        this.apiService.get({ api: this.plannedExperiences }).subscribe(
          (response) => {
            const userId = this.user?._id || this.studentId;
            this.filteredExperiencesByUser = response.filter((experience) => experience.user === userId);
  
            if (this.filteredExperiencesByUser) {
              this.experiences = this.filteredExperiencesByUser.map((experience) => {
                return {
                  ...experience,
                  formattedStartDate: formatDate(experience.startDate, 'dd/MM/yyyy', 'en-US'),
                  formattedEndDate: formatDate(experience.endDate, 'dd/MM/yyyy', 'en-US'),
                };
              });
  
              this.filterExperiences();
              
              Swal.close();
            }
          },
          (error) => {
            Swal.close();
            this.activeOption('documents');
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrió un error cargandos las experiencias registradas ',
            });
  
            console.error('Error fetching experiences:', error);
          }
        );
      } catch (error) {
        Swal.close();
        this.activeOption('documents');
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An unexpected error occurred!',
        });
  
        console.error('Unexpected error:', error);
      }
    }

    sortExperiencesBySkillsScore() {
      this.filteredExperiences.sort((a, b) => {
        return b.skillsScore - a.skillsScore;
      });
    }

    filterExperiences() {
      const selectedCategoriesLength = this.selectedCategoryOption?.length ?? 0;
      const selectedSkillsLength = this.selectedSkillsOption?.length ?? 0;
      const searchTerm = this.searchTerm.toLowerCase();
  
      if (selectedCategoriesLength === 0 && selectedSkillsLength === 0 && !searchTerm) {
        this.filteredExperiences = this.experiences;
      } else {
        this.filteredExperiences = this.experiences.filter((experience) => {
          const categoryMatch =
            selectedCategoriesLength === 0 ||
            (experience.experienceCategory &&
              experience.experienceCategory.some((category) => this.selectedCategoryOption.includes(category)));
  
          const skillMatch =
            selectedSkillsLength === 0 ||
            this.selectedSkillsOption.some((skill) => {
              const skillsArray = [experience.principalSkill, experience.secondarySkill];
              return skillsArray.includes(skill);
            });
  
          const searchTermMatch = !searchTerm || experience.experienceName.toLowerCase().includes(searchTerm);
  
          return categoryMatch && skillMatch && searchTermMatch;
        });
      }
      this.sortExperiencesBySkillsScore();
    }

  toggleConocete(): void {
    this.isConoceteVisible = !this.isConoceteVisible;
  }
  toggleDashboard(): void {
    this.showDashboard = !this.showDashboard;
  }
  toggleInitialTest(): void {
    this.showTest = !this.showTest;
  }
  toggleExplore(): void {
    this.showExplore = !this.showExplore;
  }
  toggleMisExperiencias(): void {
    this.showMisExperiencias = !this.showMisExperiencias;
  }
  toggleMatch(): void {
    this.showMatch = !this.showMatch;
  }
  toggleProgression(): void {
    this.showProgression = !this.showProgression;
  }
  toggleEnglishTest(): void {
    this.showEnglishTest = !this.showEnglishTest;
  }
}
