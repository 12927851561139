<div class="skills-progress-chart">
  <div *ngFor="let skill of skills" class="skills-progress-chart__skill" 
       [hidden]="isGlobalPerformanceSkill(skill.skillName)">

    <!-- Skill Title -->
    <h3 class="skills-progress-chart__title">{{ skill.skillName }}</h3>

    <div class="skills-progress-chart__container">
      <!-- Progress Bar -->
      <div class="skills-progress-chart__bar">

        <!-- Level Dividers -->
        <ng-container *ngFor="let level of skill.levels; let i = index">
          <div *ngIf="getLevelEndPosition(skill, i) !== null" class="skills-progress-chart__divider"
            [ngStyle]="{'left': getLevelEndPosition(skill, i) + '%'}">
            <span class="skills-progress-chart__divider-label" [matTooltip]="level.description">{{ level.level }}</span>
          </div>
        </ng-container>

        <div class="skills-progress-chart__progress" [style.width.%]="animated ? skillsScores[skill.skillName] : 0">
        </div>

        <div class="skills-progress-chart__marker" [style.left.%]="animated ? skillsScores[skill.skillName] : 0">
        </div>

      </div>
    </div>
  </div>
</div>
