import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { MatDialogRef } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Router } from '@angular/router';
import { RouterConfig } from 'aws-sdk/clients/directconnect';

@Component({
  selector: 'app-experience-example-modal',
  templateUrl: './experiencePlannerModal.component.html',
  styleUrls: ['./experiencePlannerModal.component.scss'],
})
export class ExperiencePlannerModal implements OnInit {
  @Input() experienceData: any;
  readonly experiencesApi = 'api/getExperience/';
  experiences: any;
  skills: any;
  secondarySkills: any;
  plannedExperience: any;
  experiencesData: any;
  selectedSkillScore: any;
  experienceRequired: any;
  category: any;
  user = JSON.parse(localStorage.getItem('user'));
  readonly optionsOfMultiSelect: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };
  datepickerConfig: Partial<BsDatepickerConfig>;
  constructor(
    public dialogRef: MatDialogRef<ExperiencePlannerModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.datepickerConfig = {
      containerClass: 'theme-dark-blue custom-datepicker',
      dateInputFormat: 'DD/MM/YYYY',
    };
  }
  ngOnInit() {
    this.getExperiences();
    this.getSkills();

    this.plannedExperience = {};

    if (this.data && this.data.experienceData) {
      this.experienceData = this.data.experienceData;
      this.plannedExperience = this.experienceData;
    } else if (this.data && this.data.experienceData) {
      this.plannedExperience = this.data.experienceData;
    }
  }

  getExperiences() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.experiencesApi }).subscribe(
        (response) => {
          this.experiencesData = response;
          this.experiences = response.map((item) => item.experienceName);
          this.experienceRequired = this.experiencesData.filter(
            (item) => item.experienceName === this.plannedExperience.experience
          );
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las experiencias',
          });

          console.error('Error fetching experiences:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  onExperienceChange(selectedExperience: any) {
    const matchingExperience = this.experiencesData.find(
      (experience) => experience.experienceName === selectedExperience
    );
    this.category = matchingExperience.experienceCategory;

    this.skills = matchingExperience.requiredSkills;
    this.selectedSkillScore = matchingExperience.skillsScore;
  }

  getSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getSkill' }).subscribe(
        (response) => {
          this.skills = this.convertToArray(this.experienceData.requiredSkills);
          if (this.data && this.data.experienceData) {
            this.onDropdownChange(this.plannedExperience.principalSkill);
          }
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  convertToArray(skills: string): string[] {
    // Verificar si skills es una cadena de texto y luego convertirla en un array
    if (typeof skills === 'string') {
      return skills.split(',').map((skill) => skill.trim());
    }
    // Si skills ya es un array, devolverlo tal cual
    if (Array.isArray(skills)) {
      return skills;
    }
    // En otros casos, devolver un array vacío o manejar el caso según sea necesario
    return [];
  }

  onDropdownChange(selectedValue: any): void {
    this.secondarySkills = this.skills.filter((item) => item !== selectedValue);
  }

  onSubmit(): void {
    const obj = {
      userId: this.user._id,
      experience: this.plannedExperience.experienceName,
      experienceDescription: this.plannedExperience.experienceDescription,
      principalSkill: this.plannedExperience.principalSkill,
      secondarySkill: this.plannedExperience.secondarySkill,
      startDate: this.plannedExperience.startDate,
      endDate: this.plannedExperience.endDate,
      experienceCategory: this.plannedExperience.experienceCategory
        ? this.plannedExperience.experienceCategory
        : this.category,
      skillsScore: this.plannedExperience.skillsScore ? this.plannedExperience.skillsScore : this.selectedSkillScore,
    };
    this.apiService.post({ api: `api/createExperiencePlan`, data: obj }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.dialogRef.close();
          this.getExperiences();
          this.alertService.showSuccess({
            msg: 'Experiencia planeada correctamente',
          });
            this.router.navigate(['/experience-planner'], { queryParams: { fromPlanner: 'true' } });
        } else {
          this.alertService.showError({
            msg: 'Error al planear la experiencia',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema al planear la experiencia', err);
        this.alertService.showError({
          msg: 'Error al planear la experiencia',
        });
      }
    );
  }
  onDelete(): void {
    this.apiService.delete({ api: `api/deleteExperiencePlan/${this.plannedExperience._id}` }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.dialogRef.close();
          this.alertService.showSuccess({
            msg: 'Experiencia eliminada correctamente',
          });
          this.getExperiences();
        } else {
          this.alertService.showError({
            msg: 'Error al eliminar la experiencia',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema al eliminar la experiencia', err);
        this.alertService.showError({
          msg: 'Error al eliminar la experiencia',
        });
      }
    );
    this.dialogRef.close();
  }
}
