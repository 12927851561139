import { Component, Input } from '@angular/core';
import {  FormGroup } from '@angular/forms';
import { ENGLISH_LEVELS } from './step-language-proficiency.constants';

@Component({
  selector: 'app-step-language-proficiency',
  templateUrl: './step-language-proficiency.component.html',
  styleUrls: ['./step-language-proficiency.component.scss']
})
export class StepLanguageProficiencyComponent  {

  @Input() form: FormGroup;
  
  englishLevels = ENGLISH_LEVELS;
   
  constructor() {}

}
