<div class="step-three-container">
    <div class="form-grid">
      
      <app-initial-form-input 
        class="col-span-2"
        [control]="form.get('personalEmail')" 
        label="Correo personal *" 
        placeholder="Correo personal">
      </app-initial-form-input>

        <app-initial-form-input 
            class="col-span-2"
            [control]="form.get('instagramProfile')" 
            label="Perfil de Instagram" 
            placeholder="@usuario">
        </app-initial-form-input>
        
      <!-- Phone Number with Checkbox -->
      <div class="input-checkbox-group">
        <app-initial-form-input 
          [control]="form.get('verifiedPhone')" 
          label="Número de teléfono *" 
          type="tel"
          placeholder="Número de teléfono">
        </app-initial-form-input>
        <app-initial-form-checkbox 
          [control]="form.get('hasNotPhone')" 
          label="No tengo teléfono" 
          [disablesControl]="form.get('verifiedPhone')">
        </app-initial-form-checkbox>
      </div>

      <!-- WhatsApp with Checkbox -->
      <div class="input-checkbox-group">
        <app-initial-form-input 
          [control]="form.get('verifiedWhatsApp')" 
          label="WhatsApp *" 
          type="tel"
          placeholder="Número de WhatsApp">
        </app-initial-form-input>
        <app-initial-form-checkbox 
          [control]="form.get('hasNotWhatsApp')" 
          label="No tengo WhatsApp" 
          [disablesControl]="form.get('verifiedWhatsApp')">
        </app-initial-form-checkbox>
      </div>

      <!-- Consent Checkbox spans two columns -->
      <div class="consent-container col-span-2">
        <app-initial-form-checkbox 
          [control]="form.get('consent')" 
          label="Al marcar esta casilla verifico que el número telefónico y demás datos proporcionados son correctos. Doy mi consentimiento para ser contactado por universidades para recibir beneficios e información sobre mi carrera para tomar una mejor decisión.">
        </app-initial-form-checkbox>
      </div>

    </div>
</div>
