<div class="selection-card-container">
  <div *ngFor="let item of items"
       [class.selected]="isSelected(item.value)"
       (click)="selectItem(item.value)"
       (mouseover)="hoveredItem = item.value"
       (mouseleave)="hoveredItem = null">

    <!-- Use Custom Template -->
    <ng-container *ngIf="customTemplate; else defaultTemplate">
      <ng-container 
        *ngTemplateOutlet="customTemplate; 
          context: { $implicit: item, selected: isSelected(item.value), hovered: hoveredItem === item.value }">
      </ng-container>
    </ng-container>

    <!-- Default Template -->
    <ng-template #defaultTemplate>
      <img *ngIf="item.img" [src]="item.img" [alt]="item.name">
      <span class="selection-title">{{ item.name }}</span>
    </ng-template>

  </div>
</div>

<p class="error-message" *ngIf="control.invalid && control.touched">
  Debes seleccionar al menos una opción.
</p>
