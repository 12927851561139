<app-initial-form-input [control]="form.get('guardianName')" label="Nombre de tu acudiente *"
    placeholder="Nombre"></app-initial-form-input>
<app-initial-form-input [control]="form.get('guardianEmail')" label="Correo de tu acudiente *"
    placeholder="Correo"></app-initial-form-input>
<app-initial-form-input [control]="form.get('guardianPhone')" label="Número de teléfono de tu acudiente *" type="tel"
    placeholder="Número de teléfono"></app-initial-form-input>

<app-initial-form-checkbox 
    [control]="form.get('declaration')"
    label="Declaro que la información depositada en campo anterior es verídica y he hablado con mis padres para que firmen tratamiento de datos en mi nombre.">
</app-initial-form-checkbox>