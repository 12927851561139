import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/public/services/api.service';

@Component({
  selector: 'app-page-one',
  templateUrl: './pageOne.component.html',
  styleUrls: ['./pageOne.component.scss'],
})
export class PageOneComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() gradeLevels: any;
  @Input() schoolInfo: any;
  @Input() schoolGrades: any;
  @Input() schoolGradesLevels: any;
  age: any;
  selectedGradeLevels: string[] = [];

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    const birthDateForm = this.form.get('birthDate');
    const schoolName = this.form.get('school').value;

    this.age = this.calculateAge(birthDateForm.value);

    this.apiService.get({ api: `api/schoolByName?name=${schoolName}` }).subscribe(
      (response) => {
        if (response.school && response.school.length > 0) {
          this.schoolInfo = response.school[0] || [];

          if (this.schoolInfo) {
            this.apiService.get({ api: `api/getGradeLevelsById/${this.schoolInfo._id || ''}` }).subscribe(
              (secondResponse) => {
                this.schoolGradesLevels = secondResponse.gradesLevels;

                this.schoolGrades = this.schoolGradesLevels.map((item) => {
                  return {
                    value: `${item.grade[0] || ''}`,
                    label: item.grade[0] || '',
                  };
                });

                this.onGradeChange(this.form.get('currentGrade')?.value || '', true);
              },
              (error) => {
                console.error('Error fetching additional school information:', error);
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'No se pudo obtener los grados del colegio.',
                });
              }
            );
          }
        } else {
          this.schoolInfo = [];
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No se encontró información del colegio.',
          });
        }
      },
      (error) => {
        console.error('Error fetching school information:', error);
      }
    );
  }

  onGradeChange(gradeValue: string, firstChange: boolean = false): void {
    const selectedGrade = this.schoolGradesLevels.find(grade => grade.grade[0].toString() === gradeValue.toString());
    this.selectedGradeLevels = selectedGrade ? selectedGrade.levels.map(level => level[0]) : [];

    if (!firstChange) {
      this.form.get('gradeLevel')?.setValue('');
    }
  }

  calculateAge(dateString: string): number | null {
    if (!dateString) return null;

    const birthDate = new Date(dateString);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
