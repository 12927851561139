import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import Swal from 'sweetalert2';
import { Skill } from './simulationChart.models';

const DEFAULT_GRADES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const SCHOOL_URL_ENDPOINT = 'api/colegio';
const SIMULATION_URL_ENDPOINT = 'api/simulateExperienceProgress';
const SKILL_URL_ENDPOINT = 'api/getSkill';

@Component({
  selector: 'app-root',
  templateUrl: './simulationChartViewModel.component.html',
  styleUrls: ['./simulationChartViewModel.component.scss'],
})
export class SimulationChartComponentViewModel implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  grades = [];
  selectedGrade = parseInt(this.user.grade);
  simulationChartData: any;
  filteredSimulationData: any = null;
  skills: Skill[] = [];

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.loadSchoolGrades();
    this.loadSimulationData();
    this.loadSkills();
  }

  loadSchoolGrades() {
    this.showLoadingAlert();
    this.apiService.get({ api: `${SCHOOL_URL_ENDPOINT}/${this.user.school}` }).subscribe(
      (response: any) => {
        this.grades = response.selectedGrades || DEFAULT_GRADES;
      },
      (error: any) => {
        console.error('Error loading school grades', error);
        this.grades = DEFAULT_GRADES;
      },
      () => {
        Swal.close();
      }
    );
  }

  loadSimulationData() {
    this.showLoadingAlert();
    this.apiService.get({ api: `${SIMULATION_URL_ENDPOINT}/${this.user._id}` }).subscribe(
      (response: any) => {
        this.simulationChartData = response;
        this.filterSimulationData();
      },
      (error: any) => {
        console.error('Error loading simulation data', error);
      },
      () => {
        Swal.close();
      }
    );
  }


  loadSkills() {
    this.showLoadingAlert();
    this.apiService.get({ api: SKILL_URL_ENDPOINT }).subscribe(
      (response: Skill[]) => {
        this.skills = response;
        this.putGlobalSkillAtTop();
      },
      (error) => {
        console.error('Error loading skills', error);
      },
      () => {
        Swal.close();
      });

  }

  onSelectedGradeChange(value: any) {
    this.selectedGrade = value;
    this.filterSimulationData();

  }

  /**
   * Filters the simulation data for the selected grade.
   */
  filterSimulationData() {
    if (this.simulationChartData && this.simulationChartData[this.selectedGrade]) {
      this.filteredSimulationData = this.simulationChartData[this.selectedGrade];
    } else {
      this.filteredSimulationData = null;
    }
  }

  private showLoadingAlert() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  private putGlobalSkillAtTop(){
    const globalSkillIndex = this.skills.findIndex((skill) => skill.skillName === 'Desempeño Global Competencias');

    if (globalSkillIndex !== -1) {
      const globalSkill = this.skills.splice(globalSkillIndex, 1);
      this.skills.unshift(globalSkill[0]);
    }
  }
}
