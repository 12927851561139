import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country } from '../countries.models';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';

const FAVORITE_COUNTRIES_ENDPOINT = 'api/pais/user/favorites';
@Component({
  selector: 'app-countries-card',
  templateUrl: './countries-card.component.html',
  styleUrls: ['./countries-card.component.scss']
})
export class CountriesCardComponent implements OnInit {
  @Input() country: Country;
  @Output() favoriteToggled: EventEmitter<Country> = new EventEmitter();
  isLoading = false;

  constructor(private readonly apiService: ApiService, private readonly alertService: AlertService) { }

  ngOnInit(): void {
  }

  toggleFavorite() {

    if (this.country.isFavorite) {
      this.removeFromFavorites();
    }
    else {
      this.addToFavorites();
    }

  }

  addToFavorites() {
    this.apiService.post({ api: `${FAVORITE_COUNTRIES_ENDPOINT}/${this.country._id}`, data: {} }).subscribe(
      (response) => {
        this.country.isFavorite = true;
        this.alertService.showSuccess({ msg: 'País agregado a favoritos' });
        this.favoriteToggled.emit(this.country);
      },
      (error) => {
        console.error('Error adding country to favorites', error);
        this.alertService.showError({ msg: 'Error agregando país a favoritos' });
      }
    );
  }

  removeFromFavorites() {
    this.apiService.delete({ api: `${FAVORITE_COUNTRIES_ENDPOINT}/${this.country._id}` }).subscribe(
      (response) => {
        this.country.isFavorite = false;
        this.alertService.showSuccess({ msg: 'País eliminado de favoritos' });
        this.favoriteToggled.emit(this.country);
      },
      (error) => {
        console.error('Error removing country from favorites', error);
        this.alertService.showError({ msg: 'Error eliminando país de favoritos' });
      }
    );

  }
}

