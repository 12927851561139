<div class="table-container">
  <div class="filters">
    <!-- Filtros de búsqueda y botones -->
    <div class="search-bar">
      <div class="input-container">
        <input
          type="text"
          [(ngModel)]="searchText"
          (ngModelChange)="onSearchTextChanged($event)"
          placeholder="Buscar estudiante"
          class="input-with-icon" />
        <button class="btn btn-primary bulkStudents small-button" routerLink="/bulk-students">Agregar estudiantes</button>
        <button class="btn btn-primary bulkStudents small-button" routerLink="/create-student">Agregar estudiante</button>
      </div>
    </div>

    <!-- Menú de filtros -->
    <div class="filter-menu" (click)="closeFiltersOnOutsideClick($event)">
      <div class="buttonsFilter">
        <button (click)="toggleFilters()" class="filter-button">
          <img class="filterIcons" src="../../../assets/school-dashboard/sort.png" alt="filter icon" />
          <span class="button-text">Filtro</span>
          <img class="filterIcons" src="../../../assets/school-dashboard/expand.png" alt="filter icon" />
        </button>
        <span class="separationBar">|</span>

        <!-- Botón de columnas -->
        <button (click)="toggleColumnDropdown()" class="filter-button">
          <span class="button-text">Columnas</span>
          <img class="filterIcons" src="../../../assets/school-dashboard/expand.png" alt="filter icon" />
        </button>

        <span class="separationBar">|</span>
        <button (click)="toggleFiltersSort()" class="filter-button">
          <span class="button-icon"><i class="fas fa-sort"></i></span>
          <span class="button-text">Ordenar por</span>
          <img class="filterIcons" src="../../../assets/school-dashboard/expand.png" alt="filter icon" />
        </button>
        <span class="separationBar">|</span>
        <button (click)="resetFilters()" class="filter-button">
          <span class="button-icon"><i class="fas fa-sync-alt"></i></span>
          <span class="button-text">Limpiar Filtros</span>
        </button>
      </div>

      <!-- Dropdown de columnas -->
      <div class="filter-dropdown" [ngClass]="{ open: showColumnDropdown }">
        <div class="column-config">
          <h4>Seleccionar columnas</h4>
          <label *ngFor="let column of columnsConfig">
            <input type="checkbox" [(ngModel)]="column.visible" (change)="toggleColumnVisibility(column)">
            {{ column.name }}
          </label>
        </div>
      </div>

      <!-- Menú de filtros -->
      <div class="filter-dropdown" [ngClass]="{ open: showFilters }">
        <!-- Filtros por grado, nivel, etc. -->
        <ul class="custom-dropdown">
          <li>
            <span>Grado:</span>
            <ul class="checkbox-list">
              <li>
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="selectedGrades['9']"
                    (change)="onGradeFilterChange()" />
                  9
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="selectedGrades['10']"
                    (change)="onGradeFilterChange()" />
                  10
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="selectedGrades['11']"
                    (change)="onGradeFilterChange()" />
                  11
                </label>
              </li>
            </ul>
          </li>
          <li class="separator"></li>
          <li>
            <span>Nivel de grado:</span>
            <ul class="checkbox-list">
              <li *ngFor="let grade of gradesAndLevels">
                <label>
                  <input type="checkbox" (change)="onGradeLevelFilterChange(grade.value)" />
                  {{ grade.value }}
                </label>
              </li>
            </ul>
          </li>
          <li class="separator"></li>
          <li>
            <span>Presentó test:</span>
            <ul class="checkbox-list">
              <li>
                <label>
                  <input type="checkbox" [(ngModel)]="selectedTestPresented['Si']" (change)="onTestPresentedFilte()" />
                  Si
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" [(ngModel)]="selectedTestPresented['No']" (change)="onTestPresentedFilte()" />
                  No
                </label>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- Ordenar y otras opciones -->
      <div class="filter-dropdown" [ngClass]="{ open: showFilterSort }">
        <ul class="custom-dropdown">
          <ng-container *ngFor="let skill of skillOptions">
            <ul class="checkbox-list">
              <li>
                <label>
                  <input
                    type="checkbox"
                    [ngModel]="selectedSkill === skill.id"
                    (change)="onSkillFilterChange(skill.id)" />
                  {{ skill.name }}
                </label>
              </li>
            </ul>
          </ng-container>
          <li class="separator"></li>
          <div class="custom-dropdown">
            <ul class="checkbox-list">
              <span class="title-sort">Orden:</span>
              <li>
                <label>
                  <input type="checkbox" [(ngModel)]="selectedOrder['Ascendente']" (change)="onSortOrderChange()" />
                  Ascendente
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" [(ngModel)]="selectedOrder['Descendente']" (change)="onSortOrderChange()" />
                  Descendente
                </label>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  </div>

  <!-- Tabla de estudiantes -->
  <table class="table">
    <thead class="tableHeader">
      <tr>
        <th *ngIf="columnsConfig[0].visible">Nombre</th>
        <th *ngIf="columnsConfig[1].visible">Grado</th>
        <th *ngIf="columnsConfig[2].visible">Nivel de grado</th>
        <th *ngIf="columnsConfig[3].visible">Presentó Test</th>
        <th *ngIf="columnsConfig[4].visible">Promedio General</th>
        <th *ngIf="columnsConfig[5].visible">Liderazgo</th>
        <th *ngIf="columnsConfig[6].visible">Orientación a resultados</th>
        <th *ngIf="columnsConfig[7].visible">Aprendizaje continuo</th>
        <th *ngIf="columnsConfig[8].visible">Impacto</th>
        <th *ngIf="columnsConfig[9].visible">Global Mind</th>
        <th *ngIf="columnsConfig[10].visible">Creatividad e Innovación</th>
        <th *ngIf="columnsConfig[11].visible">Nivel Inglés</th>
        <th *ngIf="columnsConfig[12].visible">Link a HV</th>
      </tr>
    </thead>
    <tbody class="tableBody">
      <tr *ngFor="let student of filteredStudents">
        <td *ngIf="columnsConfig[0].visible">{{ addEllipsisToName(student.name, 20) }}</td>
        <td *ngIf="columnsConfig[1].visible">{{ student.formatedGrade }}°</td>
        <td *ngIf="columnsConfig[2].visible">{{ student.gradeLevel }}</td>
        <td *ngIf="columnsConfig[3].visible">
          <span class="tag" [ngClass]="{ green: student.presentTest === 'Si', red: student.presentTest === 'No' }">
            {{ student.presentTest }}
          </span>
        </td>
        <td *ngIf="columnsConfig[4].visible">{{ student.promedioGeneral}}</td>
        <td *ngIf="columnsConfig[5].visible">{{ student.Liderazgo }}</td>
        <td *ngIf="columnsConfig[6].visible">{{ student.OrientacionResultados }}</td>
        <td *ngIf="columnsConfig[7].visible">{{ student.AprendizajePermanente }}</td>
        <td *ngIf="columnsConfig[8].visible">{{ student.ImpactoResponsabilidad }}</td>
        <td *ngIf="columnsConfig[9].visible">{{ student.GlobalMind }}</td>
        <td *ngIf="columnsConfig[10].visible">{{ student.CreatividadInovacion }}</td>
        <td *ngIf="columnsConfig[11].visible">{{ student.englishLevel }}</td>
        <td *ngIf="columnsConfig[12].visible">
          <ng-container *ngIf="(student.linkName && student.linkName.length === 0) || student.linkHv === null">
            <i class="fa fa-exclamation-triangle text-warning icon-exclamation" (click)="showAlert()"></i>
          </ng-container>
          <ng-container *ngIf="student.linkName && student.linkName.length > 0 && student.linkHv != null">
            <a class="button" href="{{ student.linkHv }}" target="_blank">Ver HV</a>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Paginación -->
  <div class="horizontal-pagination">
    <span class="dubblearrow" (click)="previousPageHorizontal()" [class.disabled]="currentPageHorizontal === 0">
      <i class="fa fa-chevron-left"></i>
    </span>
    <ng-container *ngFor="let page of pageNumbers; let i = index">
      <ng-container *ngIf="i >= currentPageHorizontal - 5 && i <= currentPageHorizontal + 5">
        <span *ngIf="i === currentPageHorizontal" class="page-link active">{{ i + 1 }}</span>
        <span *ngIf="i !== currentPageHorizontal" class="page-link" (click)="goToPageHorizontal(i)">
          {{ i + 1 }}
        </span>
      </ng-container>
    </ng-container>
    <span class="dubblearrow" (click)="nextPageHorizontal()" [class.disabled]="currentPageHorizontal === pageNumbers.length - 1">
      <i class="fa fa-chevron-right"></i>
    </span>
  </div>
</div>