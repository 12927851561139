<!-- bar-chart.component.html -->
<div class="wrapper">
  <div class="row">
    <div class="col-12">
      <h4 class="titulo-wizard">Resultados Iniciales del Test</h4>
      <p>
        Conóce en esta sección cuáles son tus competencias individuales más altas e identifica cuales vas a desarrollar.
      </p>
      <hr class="hrLine" />
    </div>
  </div>
  <div>
    <div *ngIf="radarChart" class="radar-chart-container">

      <app-skills-progress-level-chart [skills]="skills" [skillsScores]="skillsScore"></app-skills-progress-level-chart>

    </div>

  </div>

  <div *ngIf="averageChartoptions">
    <h2 class="title-section">Promedio general</h2>
    <div class="average__container">
      <div>
        <apx-chart [series]="averageChartoptions.series" [chart]="averageChartoptions.chart"
          [plotOptions]="averageChartoptions.plotOptions" [fill]="averageChartoptions.fill"
          [stroke]="averageChartoptions.stroke" [labels]="averageChartoptions.labels"></apx-chart>
      </div>

      <div class="row container-tip">
        <div class="col-1">
          <img class="tip-img" src="/assets/dashboard-skill/tips-puntaje-global/cara-feliz.png" alt="cara feliz" />
        </div>

        <div class="col-10">
          <p class="desc-tip">
            {{ averageLevelDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="chartOptions" class="chart-container">
    <h2 class="title-section">Competencias principales</h2>
    <apx-chart [options]="chartOptions.options" [series]="chartOptions.series" [chart]="chartOptions.chart"
      [plotOptions]="chartOptions.plotOptions" [tooltip]="chartOptions.tooltip" [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend" [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis"
      [labels]="chartOptions.labels" [responsive]="chartOptions.responsive" [fill]="chartOptions.fill"
      [stroke]="chartOptions.stroke" [theme]="chartOptions.theme"></apx-chart>
  </div>
  <div *ngIf="chartOptions" class="chart-container">
    <h2 class="title-section">Competencias secundarias</h2>
    <apx-chart [options]="secondarySkillsChartOptions.options" [series]="secondarySkillsChartOptions.series"
      [chart]="secondarySkillsChartOptions.chart" [plotOptions]="secondarySkillsChartOptions.plotOptions"
      [tooltip]="secondarySkillsChartOptions.tooltip" [dataLabels]="secondarySkillsChartOptions.dataLabels"
      [legend]="secondarySkillsChartOptions.legend" [xaxis]="secondarySkillsChartOptions.xaxis"
      [yaxis]="secondarySkillsChartOptions.yaxis" [labels]="secondarySkillsChartOptions.labels"
      [responsive]="secondarySkillsChartOptions.responsive" [fill]="secondarySkillsChartOptions.fill"
      [stroke]="secondarySkillsChartOptions.stroke" [theme]="secondarySkillsChartOptions.theme"></apx-chart>
  </div>
</div>