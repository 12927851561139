import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-icfes-readiness',
  templateUrl: './step-icfes-readiness.component.html',
  styleUrls: ['./step-icfes-readiness.component.scss']
})
export class StepIcfesReadinessComponent implements OnInit {

  @Input() form: FormGroup;

  icfesQuestions = [
    { value: 'icfesImportance', label: '¿Qué tan importante consideras un buen puntaje en el ICFES para tu vida académica y futura? (Incluye ingreso a la universidad, becas y oportunidades laborales.)' },
    { value: 'icfesPreparedness', label: '¿Qué tan preparado/a te sientes actualmente para presentar el ICFES?' },
    { value: 'icfesEffortCommitment', label: '¿Qué tanto esfuerzo adicional estás dispuesto/a a dedicar a la preparación del ICFES? (Ej: estudiar en tu tiempo libre, tomar cursos adicionales, resolver simulacros, etc.)' },
  ];

  icfesColumns = [
    { value: 'low', label: 'Poco / Nada' },
    { value: 'moderate', label: 'Moderado' },
    { value: 'high', label: 'Alto / Mucho' },
    { value: 'no_response', label: 'No sabe / Prefiere no responder' },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
