import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MOTIVATIONS } from './step-career-motivations.constants';

@Component({
  selector: 'app-step-career-motivations',
  templateUrl: './step-career-motivations.component.html',
  styleUrls: ['./step-career-motivations.component.scss']
})
export class StepCareerMotivationsComponent  {
  @Input() form: FormGroup;
  motivationOptions = MOTIVATIONS;

  constructor() { }

}
