<div class="profile-container">
  <!-- Cabecera del perfil -->
  <div class="profile-header">
    <img class="profile-picture" [src]="fotoPerfil" alt="Profile Picture" />
    <div class="profile-info">
      <h1><b>HOLA </b> <span class="username">{{ nombre }}</span>!</h1>
      <button class="activate-profile" (click)="openSimulationChart()">ACTIVA TU PERFIL 🔥</button>
      <p>
        Nos encanta verte por acá! 🌟 <br />
        Acá podrás construir tu perfil 360. Descubre tus competencias, explora
        oportunidades y prepárate para tu próxima gran aventura.
      </p>
    </div>
  </div>

  <!-- Acciones del perfil -->
  <div class="profile-actions">
    <div class="action-item">
      <a routerLink="/leaderTest">
        <img src="../../../assets/principal-menu/TEST_COMPETENCIAS_icone.png" alt="Test Competencias" />
        <p>TEST COMPETENCIAS</p>
        <span class="level">¡Nivel 1: Conócete! 🎯</span>
      </a>
    </div>
    <div class="action-item">
      <a routerLink="/skill-dashboard">
        <img src="../../../assets/principal-menu/resultados_icone.png" alt="Tablero Resultados" />
        <p>TABLERO RESULTADOS</p>
      </a>
    </div>
    <div class="action-item">
      <a routerLink="/explore-skills">
        <img src="../../../assets/principal-menu/explore_skills_icone.png" alt="Explora Competencias" />
        <p>EXPLORA COMPETENCIAS</p>
        <span class="level">¡Nivel 2: Explora! 💡</span>
      </a>
    </div>
    <div class="action-item">
      <a routerLink="/experience-example">
        <img src="../../../assets/principal-menu/experience-example-icone.png" alt="Explora Experiencias" />
        <p>EXPLORA EXPERIENCIAS</p>
      </a>
    </div>
    <div class="action-item">
      <span class="level">¡Nivel 3: Acciona! 🚀</span>
      <a routerLink="/experience-planner">
        <img src="../../../assets/principal-menu/experience-planner.png" alt="Plan Experiencias" />
        <p>PLAN EXPERIENCIAS</p>
      </a>
    </div>
    <div class="action-item">
      <a routerLink="/curriculum">
        <img src="../../../assets/principal-menu/curriculum.png" alt="Portafolio Vida/CV" />
        <p>PORTAFOLIO VIDA / CV</p>
        <span class="level">¡Nivel 4: Expón! 📣</span>
      </a>
    </div>
  </div>

  <!-- Contenedor de siguientes pasos y progreso -->
  <div class="progress-next-container">
    <!-- Siguientes pasos -->
    <div class="next-steps">
      <div routerLink="/cover-letter" class="personal-statement">
        <div class="personal-statement-img">
            <img src="../../../assets/principal-menu/personal-statement.png" alt="Personal Statement" />
            <p>PERSONAL STATEMENT</p> 
        </div>
        <div class="personal-statement-text">
            <p>Cuenta tu historia! Qué has hecho, quién eres y quién aspiras convertirte.</p>
        </div>
    </div>
    </div>

        
    <div class="progress-container">
      <svg class="progress-circle" viewBox="0 0 120 120">
        <!-- Fondo del círculo -->
        <circle class="progress-background"
          cx="60" cy="60" r="50">
        </circle>
      
        <!-- Círculo de progreso (ahora correcto) -->
        <circle class="progress-bar"
          cx="60" cy="60" r="50"
          stroke-linecap="round"
          stroke-dasharray="314"
          [attr.stroke-dashoffset]="progressOffset"
          transform="rotate(-90 60 60)"> <!-- Iniciar en las 12 en punto -->
        </circle>
      
        <!-- Nivel en la parte superior -->
        <text x="60" y="50" text-anchor="middle" class="progress-text-level">{{ level }}</text>
      
        <!-- Porcentaje en la parte inferior -->
        <text x="60" y="75" text-anchor="middle" class="progress-text">{{ progress }}%</text>
      </svg>
    </div>
    
    <button class="need-help">💬 NEED HELP?</button>