<div class="modalContainer">
  <div class="modal-header customHeader">
    <h4 class="modalTitle">Planeador de experiencias</h4>
  </div>
  <div class="modal-body customBody">
    <!-- Experiencia Asociada -->
    <mat-form-field>
      <mat-label>Experiencia asociada</mat-label>
      <mat-select (ngModelChange)="onExperienceChange($event)" [disabled]="experienceData" [(ngModel)]="plannedExperience.experience">
        <mat-option *ngFor="let option of experiences" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Descripción -->
    <mat-form-field>
      <mat-label>Descripción de lo que quiero hacer:</mat-label>
      <textarea [(ngModel)]="plannedExperience.experienceDescription" matInput></textarea>
    </mat-form-field>

    <!-- Competencias -->
    <div class="d-flex justify-content-between">
      <div class="skillsContainer">
        <mat-form-field>
          <mat-label>Competencia principal</mat-label>
          <mat-select (ngModelChange)="onDropdownChange($event)" [(ngModel)]="plannedExperience.principalSkill">
            <mat-option *ngFor="let option of skills" [value]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="skillsContainer">
        <mat-form-field>
          <mat-label>Competencia secundaria</mat-label>
          <mat-select [(ngModel)]="plannedExperience.secondarySkill">
            <mat-option *ngFor="let option of secondarySkills" [value]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Rango de Realización -->
    <label>Rango de realización</label>
    <div class="d-flex justify-content-between">
      <div class="datesContainer">
        <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="Fecha de inicio" [(ngModel)]="plannedExperience.startDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="datesContainer">
        <mat-form-field>
          <input matInput [matDatepicker]="secondPicker" placeholder="Fecha final" [(ngModel)]="plannedExperience.endDate" />
          <mat-datepicker-toggle matSuffix [for]="secondPicker"></mat-datepicker-toggle>
          <mat-datepicker #secondPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <!-- Habilidades Socioemocionales -->
    <mat-form-field>
      <mat-label>Habilidades Socioemocionales a desarrollar</mat-label>
      <mat-select multiple [(ngModel)]="selectedSocioEmotionalSkills" [compareWith]="compareSkills">
        <mat-option *ngFor="let skill of socioEmotionalSkills" [value]="skill">{{ skill.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Documentos -->
    <div *ngIf="!plannedExperience.isApproval && (!plannedExperience.isEdit || (plannedExperience.isEdit && plannedExperience.status === 'Mejorar soportes'))" class="documentsContainer">
      <p><span class="sectionTitle">Documentos sugeridos:</span></p>
      <ul class="listOfUploadedFiles">
        <li *ngFor="let document of documentsWithData">{{ document.document }}: {{ document.documentType }}</li>
      </ul>
      <span class="documentsRequirementMessage">
        Debes cargar la documentación de la manera más completa posible, que permita a un tercero verificar la veracidad de la información. En caso de no tenerla, abstente de registrar la actividad como ejecutada.
      </span>
    </div>
  </div>

  <!-- Footer -->
  <div class="modal-footer customFooter d-flex justify-content-between">
    <button class="delete-button" *ngIf="plannedExperience.isEdit" mat-button color="warn" (click)="onDelete()">Eliminar</button>
    <button mat-button (click)="onSubmit()">{{ plannedExperience.isEdit ? 'Actualizar' : 'Planear' }}</button>
  </div>
</div>