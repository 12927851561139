<app-inital-form-card-selection [control]="form.get('studyInterest')" [items]="placesToStudy" [multiSelect]="false">
  <ng-template let-item let-selected="selected">  

    <div class="study-card" [class.selected]="selected">
      <img [src]="item.img" [alt]="item.name">
      <p>{{ item.name }}</p>
    </div>

  </ng-template>
</app-inital-form-card-selection>
