import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-experience-info-modal-component',
  templateUrl: './experience-info-modal-component.component.html',
  styleUrls: ['./experience-info-modal-component.component.scss']
})
export class ExperienceInfoModalComponentComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ExperienceInfoModalComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; description: string }
  ) {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  close(): void {
    this.dialogRef.close();
  }

}
