<div class="filters">
  <!-- Debounced Search Input -->
  <mat-form-field class="mat-autocomplete-custom school-dashboard-dropdown">
    <input 
      type="text" 
      matInput 
      [formControl]="searchControl" 
      [matAutocomplete]="auto"
      (focus)="openAutocomplete()"
      autocomplete="off"
      class="autocomplete-input"
      placeholder="Buscar Estudiante"
    />
  
    <mat-autocomplete #auto="matAutocomplete" (closed)="onAutocompleteClosed()">
      <mat-option 
        *ngFor="let option of filteredStudents" 
        [value]="option"
        (click)="setStudent(option)">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  
  

  <!-- Grades Dropdown -->
  <app-school-dashboard-dropdown 
    [idField]="'id'" 
    [textField]="'name'" 
    [staticData]="grades"
    placeholder="Seleccionar Grado" 
    [singleSelection]="true"
    (selectionChanged)="handleGradeSelectionChange($event)">
  </app-school-dashboard-dropdown>

  <!-- Grade Levels Dropdown (Depends on the selected Grade.) -->
  <app-school-dashboard-dropdown 
    *ngIf="isSelectLevelGradeVisible()"
    [idField]="'id'" 
    [textField]="'name'" 
    [staticData]="gradeLevels"
    placeholder="Seleccionar Nivel" 
    [singleSelection]="false" 
    (selectionChanged)="handleSelectionChange('nivel', $event, 'id')">
  </app-school-dashboard-dropdown>

  <!-- Gender Dropdown -->
  <app-school-dashboard-dropdown
    [idField]="'_id'"
    [textField]="'name'"
    [staticData]="genders"
    placeholder="Genero"
    [singleSelection]="false"
    [itemsShowLimit]="1"
    (selectionChanged)="handleSelectionChange('genero', $event, '_id')">
  </app-school-dashboard-dropdown>

  <!-- Study Places Dropdown -->
  <app-school-dashboard-dropdown
    [idField]="'_id'"
    [textField]="'name'"
    [staticData]="studyPlaces"
    placeholder="Lugar de Estudio"
    [singleSelection]="false"
    [itemsShowLimit]="1"
    (selectionChanged)="handleSelectionChange('lugarEstudio', $event, '_id')">
  </app-school-dashboard-dropdown>

  <!-- University Degrees Dropdown -->
  <app-school-dashboard-dropdown
    [staticData]="universityDegrees"
    [idField]="'_id'"
    [textField]="'name'"
    placeholder="Carreras"
    [singleSelection]="false"
    (selectionChanged)="handleSelectionChange('carrera', $event, '_id')">
  </app-school-dashboard-dropdown>

  <!-- Country Dropdown -->
  <app-school-dashboard-dropdown
    apiEndpoint="api/pais"
    [idField]="'_id'"
    [textField]="'nombre'"
    placeholder="Países"
    [singleSelection]="false"
    (selectionChanged)="handleCountrySelectionChange($event)">
  </app-school-dashboard-dropdown>

  <!-- City Dropdown -->
  <app-school-dashboard-dropdown
    apiEndpoint="api/ciudad"
    *ngIf="isSelectCityVisible()"
    [idField]="'_id'"
    [textField]="'nombre'"
    placeholder="Ciudades"
    [singleSelection]="false"
    (selectionChanged)="handleSelectionChange('ciudadColombia', $event, 'nombre')">
  </app-school-dashboard-dropdown>

  <!-- English Levels Dropdown -->
  <app-school-dashboard-dropdown
    [idField]="'_id'"
    [textField]="'name'"
    [staticData]="englishLevels"
    placeholder="Nivel de Inglés"
    [singleSelection]="false"
    [itemsShowLimit]="1"
    (selectionChanged)="handleSelectionChange('nivelIngles', $event, 'name')">
  </app-school-dashboard-dropdown>

  <!-- Grade Rates Dropdown -->
  <app-school-dashboard-dropdown
    [idField]="'_id'"
    [textField]="'name'"
    [staticData]="gradeRates"
    placeholder="Nivel de Notas"
    [singleSelection]="false"
    [itemsShowLimit]="1"
    (selectionChanged)="handleSelectionChange('nivelNotas', $event, '_id')">
  </app-school-dashboard-dropdown>
</div>
