<article class="country-card__container">
    <header class="country-card__header-container">
        <div class="country-card__header">
            <img class="country-card__image" [src]="country.icono" [alt]="country.nombre">
            <div class="country-card__info">
                <h2 class="country-card__title">{{ country.nombre }}</h2>
                <p class="country-card__population">Población: {{ country.poblacion | number }}</p>
            </div>
        </div>

        <button class="country-card__favorite-btn" (click)="toggleFavorite()" [disabled]="isLoading" [class.disabled]="isLoading">
            <i class="country-card__icon" [class.fas]="country.isFavorite" [class.fa-heart]="true" [class.far]="!country.isFavorite"
                [class.country-card__icon--filled]="country.isFavorite"></i>
        </button>
    </header>
    <p class="country-card__description">Moneda: {{ country.moneda }}</p>
    <p class="country-card__description">Costo de Vida Mensual: {{ country.costoVidaMensual.min | number }}  - {{ country.costoVidaMensual.max | number }}</p>
</article>
