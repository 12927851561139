import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-guardian-information',
  templateUrl: './step-guardian-information.component.html',
  styleUrls: ['./step-guardian-information.component.scss']
})
export class StepGuardianInformationComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
