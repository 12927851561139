<div class="step-one-container">
  
    <div class="form-grid">  
        
      <app-initial-form-input [control]="form.get('name')" label="Nombre *" placeholder="Nombre"></app-initial-form-input>
      <app-initial-form-input [control]="form.get('secondName')" label="Segundo Nombre" placeholder="Segundo Nombre"></app-initial-form-input>
      <app-initial-form-input [control]="form.get('lastName')" label="Apellido *" placeholder="Apellido"></app-initial-form-input>
      <app-initial-form-input [control]="form.get('secondLastName')" label="Segundo Apellido" placeholder="Segundo Apellido"></app-initial-form-input>
  
      <app-initial-form-dropdown
        label="Tipo de documento *"
        [control]="form.get('typeOfDocument')"
        [options]="[
          { value: 'registro_civil', label: 'Registro Civil' },
          { value: 'tarjeta_identidad', label: 'Tarjeta de Identidad' },
          { value: 'cedula_ciudadania', label: 'Cédula de Ciudadanía' }
        ]">
      </app-initial-form-dropdown>
  
      <app-initial-form-input [control]="form.get('documentNumber')" label="Numero de documento *" placeholder="Número de documento"></app-initial-form-input>
      <app-initial-form-input  [control]="form.get('birthDate')" type="date" label="Fecha de nacimiento *"></app-initial-form-input>
  
      <app-initial-form-dropdown
        label="Género *"
        [control]="form.get('gender')"
        [options]="[
          { value: 'male', label: 'Masculino' },
          { value: 'female', label: 'Femenino' },
          { value: 'other', label: 'Otro' }
        ]">
      </app-initial-form-dropdown>
    </div>
  </div>
  