import { Component, Input, OnInit } from '@angular/core';
import { EDUCATION_PREFERENCES } from './step-education-preferences.constants';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-education-preferences',
  templateUrl: './step-education-preferences.component.html',
  styleUrls: ['./step-education-preferences.component.scss']
})
export class StepEducationPreferencesComponent implements OnInit {
  @Input() form: FormGroup;
  educationPreferencesOptions: { img: string; value: string; name: string; description: string; }[] = EDUCATION_PREFERENCES;

  constructor() { }

  ngOnInit(): void {
  }

}
