import { Component, Input, OnChanges, SimpleChanges, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-school-dashboard-dropdown',
  templateUrl: './school-dashboard-dropdown.component.html',
  styleUrls: ['./school-dashboard-dropdown.component.scss']
})
export class SchoolDashboardDropdownComponent<T> implements OnInit, OnChanges {

  @Input() apiEndpoint?: string;
  @Input() staticData?: T[];
  @Input() placeholder: string = 'Seleccionar';
  @Input() idField!: string;
  @Input() textField!: string;
  @Input() additionalField?: keyof T;
  @Input() singleSelection: boolean = false;
  @Input() disabled: boolean = false;
  @Input() enableCheckAll: boolean = false;
  @Input() itemsShowLimit: number = 1;

  @Output() selectionChanged = new EventEmitter<T[]>();

  data: T[] = [];
  selectedItems: T[] = [];

  dropdownSettings: IDropdownSettings = {};

  constructor(private apiService: ApiService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.initSettings();
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['staticData']) {
      this.data = [...this.staticData!];
    }
  }

  private loadData(): void {
    if (this.apiEndpoint) {
      this.loadDataFromAPI();
    } else if (this.staticData) {
      this.loadStaticData();
    }
  }

  private loadDataFromAPI(): void {
    this.apiService.get({ api: this.apiEndpoint! }).subscribe(
      (response: T[]) => {
        this.data = this.addAdditionalField ? response.map(item => this.addAdditionalField(item)) : response;
      },
      (err) => {
        this.alertService.showError({ msg: `Error al cargar los datos desde ${this.apiEndpoint}` });
      }
    );
  }

  private loadStaticData(): void {
    setTimeout(() => {
      this.data = [...this.staticData!];
    }, 0);
  }

  onSelect(): void {
    if (!this.disabled) this.emitSelection();
  }

  onDeselect(): void {
    if (!this.disabled) this.emitSelection();
  }

  private emitSelection(): void {
    this.selectionChanged.emit(this.selectedItems);
  }

  private initSettings(): void {
    this.dropdownSettings = {
      singleSelection: this.singleSelection,
      idField: this.idField,
      textField: this.textField,
      selectAllText: 'Seleccionar Todos',
      unSelectAllText: 'Deseleccionar Todos',
      noDataAvailablePlaceholderText: 'No hay datos disponibles',
      noFilteredDataAvailablePlaceholderText: 'No hay datos disponibles',
      searchPlaceholderText: 'Buscar',
      tooltipField: this.textField,
      itemsShowLimit: this.itemsShowLimit,
      allowSearchFilter: true,
      enableCheckAll: this.enableCheckAll,
      showSelectedItemsAtTop: true
    };
  }

  private addAdditionalField(item: T): T {
    return {
      ...item,
      [this.textField]: item[this.additionalField] ? `${item[this.textField]} - ${item[this.additionalField]}` : item[this.textField],
    }
  }
}
