import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { formatDate, isPlatformBrowser, Location } from '@angular/common';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationService } from '../../services/navigation.service';
import { Router } from '@angular/router';
import { skillsArray } from '../../skillsObject/skills.js';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss'],
})
export class CurriculumComponent implements OnInit {
  public Experiential = {
    "Aprendizaje Continuo": 0,
    "Creatividad E Innovación": 0,
    "Global Mind": 0,
    "Impacto y Responsabilidad Social": 0,
    "Liderazgo": 0,
    "Orientación a Resultados": 0
  };

  api = 'api/hoja-vida/getById/';
  readonly apiCountries = 'api/pais';
  /** API path for student get test*/
  readonly GET_TEST = 'api/test_internalizacionUser/';
  readonly getSkill = 'api/getSkill/';
  readonly getData = 'api/skillsTest/';
  readonly userExperiencesAPI = 'api/experiences/without/weighted/';
  readonly plannedExperiences = 'api/experiences/without/weighted';
  readonly initialFormAPI = 'api/getInitialDataFormById/'
  readonly getInstitutionOptions = 'api/getInstitutionOptions/';
  readonly ponderationApi = 'api/weightedTest/67bbfed2d036c606e8c191b7';
  readonly GET_STUDENT = 'api/oneUser/';
  public userData = JSON.parse(localStorage.getItem('user'));


  user = JSON.parse(localStorage.getItem('user'));

  /** Variable that holds the personal information binded to the form */
  fotoBanner: any;
  fotoPerfil: any;
  nombre: any;
  colegio: any;
  proyecto: any;
  palabrasClave: any;
  sobreMi: any;
  individualProfile: any;
  achievements: any;
  academicRecord: any;
  employmentHistory: any;
  relevantSkills: any;
  aditionalFormation: any;
  idiomas = [];
  areas = [];
  paises = [];
  logros = [];
  referencias = [];
  universidadesInteres = [];
  userTest: any;
  userId: any;
  slug: any;
  skillsTest: any;
  skillsScore: any;
  skillsArray = skillsArray;
  generalScore: any;
  averageScore: any;
  endprofile: any;
  sourceBadge: any;
  chartOptions: any;
  chartData: any;
  skillsPointsAverage: any;
  skills: any;
  combinedData: any;
  filteredResponse: any;
  skillScoreMap: any;
  desiredInstitutionAverage: any;
  institutionLevel: any;
  radarChart: any;
  skillsAverage: any;
  averageChartoptions: any;
  options: any;
  secondarySkillsChartOptions: any;
  secondarySkills: any;
  skillsObjects: any[] = [];
  secondarySkillsObjects: any[] = [];
  studentId: any;
  userExperiences: any[] = [];
  userInitialForm: any = {};
  /** Variable that holds the id of the current user */
  id: string;
  actived: string;
  perfilFinal: any;
  sourceInsignia: string;
  isMyCv: boolean = true;
  countries: any;
  matchingCountries: any;
  selectedOptionUniversity: any;
  constructor(
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId,
    private alertService: AlertService,
    private translate: TranslateService,
    private ngxSpinnerService: NgxSpinnerService,
    private navigationService: NavigationService,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.studentId = this.user._id;
    this.getUserInitialForm();
    if (window.location.pathname.includes('viewProfile')) {
      let slug = window.location.pathname;
      slug = slug.slice(17);
      this.isMyCv = false;
      this.api = 'api/hoja-vida/getByLink/';
      this.getUserCv({ data: slug });
      this.getExperiences();
      this.getSkillsRadar();
    } else if (window.location.pathname.includes('studentProfile')) {
      let student = window.location.pathname;
      student = student.slice(20);
      this.isMyCv = false;
      this.getUserCv({ data: student });
      this.getExperiences();
      this.getSkillsRadar()
    } else {
      this.userId = this.user._id;
      this.actived = 'icon';
      this.getUserCv({ data: this.userId });
      this.getSkillsTest({ userId: this.userId });
      this.getExperiences();
      this.getSkillsRadar()
    }
  }

  async getUserInitialForm() {
    try {
      this.apiService.get({ api: this.initialFormAPI + this.studentId }).subscribe(
        (response) => {
          this.userInitialForm = response;
          const initialFormCountries = response.abroadCountries || [];

          this.apiService.get({ api: this.apiCountries }).subscribe(
            (countriesResponse) => {
              this.matchingCountries = countriesResponse.filter((country: any) =>
                initialFormCountries.includes(country.nombre)
              );
            },
            (error) => {
              console.error('Error fetching second API data:', error);
            }
          );
        },
        (error) => {
          console.error('Error fetching initial form:', error);
          this.userInitialForm = {};
        }
      );
    } catch (error) {
      console.error('Unexpected error:', error);
      this.userInitialForm = {};
    }
  }

  getExperiences() {
    try {
      this.apiService.get({ api: this.userExperiencesAPI }).subscribe(
        (response) => {
          Swal.close();
          if (response.length !== 0) {
            const experiences = response.filter((experience) =>
              experience.user === this.studentId && experience.status === "Aprobada"
            );

            if (experiences.length > 0) {
              const userExperiences = experiences.map((experience) => {
                return {
                  ...experience,
                  formattedStartDate: formatDate(experience.startDate, 'dd/MM/yyyy', 'en-US'),
                  formattedEndDate: formatDate(experience.endDate, 'dd/MM/yyyy', 'en-US'),
                };
              });

              this.userExperiences = userExperiences;
            } else {
              this.userExperiences = [];
            }
          }
        },
        (error) => {
          console.error('Error fetching skills:', error);
          this.userExperiences = [];
        }
      );
    } catch (error) {
      this.userExperiences = [];
    }
  }

  // getCountries() {
  //   this.apiService.get({ api: this.apiCountries }).subscribe((response) => {
  //     this.countries = response;
  //     const countryNames = this.paises.map((pais) => pais.nombre);

  //     this.matchingCountries = this.countries.filter((country) => {
  //       return countryNames.includes(country.nombre);
  //     });
  //   });
  // }
  hideIcon(): void {
    this.actived = 'txt';
  }

  hideTxt(): void {
    this.actived = 'icon';
  }

  getUserCv({ data }): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.api + data }).subscribe(
      (response) => {
        if (response) {
          let usuario = response.usuario;
          this.nombre = `${usuario.nombre ? usuario.nombre : usuario.primerNombre} ${usuario.apellido ? usuario.apellido : usuario.primerApellido
            }`;
          this.fotoPerfil = response.fotoPerfil ? response.fotoPerfil : 'assets/sidebar/profile.png';
          this.colegio = response.colegio ? response.colegio : 'Sin colegio';
          this.proyecto = response.proyecto ? response.proyecto : 'Sin proyecto';
          this.palabrasClave = response.palabrasClave ? response.palabrasClave : 'Sin palabras clave';
          this.sobreMi = response.sobreMi ? response.sobreMi : '¡Cuentanos sobre ti!';

          this.individualProfile = response.individualProfile ? response.individualProfile : 'Sin Perfil Individual';

          this.achievements = response.achievements ? response.achievements : 'Sin Perfil Logros';

          this.academicRecord = response.academicRecord ? response.academicRecord : 'Sin Perfil historial academico';

          this.employmentHistory = response.employmentHistory
            ? response.employmentHistory
            : 'Sin Perfil historial laboral';

          this.relevantSkills = response.relevantSkills ? response.relevantSkills : 'Sin Perfil habilidades relevantes';

          this.aditionalFormation = response.aditionalFormation
            ? response.aditionalFormation
            : 'Sin Perfil informacion adicional';

          this.slug = response.link ? response.link : null;

          if (response.idioma.length > 0) {
            for (let idioma of response.idioma) {
              this.idiomas.push(idioma);
            }
          }

          if (response.areaInteres.length > 0) {
            this.areas = response.areaInteres;
          }

          if (response.paisInteres.length > 0) {
            this.paises = response.paisInteres;
            // this.getCountries();
          }

          if (response.logros.length > 0) {
            for (let logro of response.logros) {
              this.logros.push(logro);
            }
          }

          if (response.referencias.length > 0) {
            for (let ref of response.referencias) {
              this.referencias.push(ref);
            }
          }

          if (response.universidadInteres.length > 0) {
            for (const uni of response.universidadInteres) {
              this.universidadesInteres.push(uni);
            }
          }
          if (response.fotoBaner) {
            this.fotoBanner = response.fotoBaner;
          } else {
            this.fotoBanner = '/assets/curriculum/background-photo.png';
          }

          if (response.fotoPerfil) {
            this.fotoPerfil = response.fotoPerfil;
          } else {
            this.fotoPerfil = '/assets/sidebar/profile.png';
          }

          if (!this.isMyCv) {
            this.userId = usuario._id;
            this.getSkillsTest({ userId: this.userId });
          }
        } else {
          this.fotoPerfil = 'assets/sidebar/profile.png';
          this.fotoBanner = '/assets/curriculum/background-photo.png';
          this.colegio = 'Sin colegio';
          this.proyecto = 'Sin proyecto';
          this.palabrasClave = 'Sin palabras clave';
          this.sobreMi = '¡Cuentanos sobre ti!';
          this.individualProfile = '¡Cuentanos sobre ti!';
          this.achievements = '¡Cuentanos sobre ti!';
          this.academicRecord = '¡Cuentanos sobre ti!';
          this.employmentHistory = '¡Cuentanos sobre ti!';
          this.relevantSkills = '¡Cuentanos sobre ti!';
          this.aditionalFormation = '¡Cuentanos sobre ti!';
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Personal-information.load-error'),
        });
        this.ngxSpinnerService.hide();
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  getSkillDescription(skillId, score) {
    switch (true) {
      case score >= 66.01:
        return skillsArray.find((item) => item.id === skillId).descriptions.alto;
      case score >= 33.01:
        return skillsArray.find((item) => item.id === skillId).descriptions.medio;
      default:
        return skillsArray.find((item) => item.id === skillId).descriptions.bajo;
    }
  }
  goToTest() {
    this.navigationService.navigateTo({ path: '/leaderTest' });
  }
  getSkillsTest({ userId }) {
    const getDta = {
      api: `api/skillsTest/${userId}`,
    };
    this.apiService.get(getDta).subscribe(
      (response) => {
        if (response) {
          this.skillsTest = response;
          this.skillsScore = response.skillPoints;
          const averageSkills = { ...this.skillsScore };

          this.generalScore = Object.keys(this.skillsScore)
            .filter((key) => key !== 'Inteligencia Emocional')
            .reduce((sum, key) => sum + this.skillsScore[key], 0);
          const primarySkills = Object.keys(this.skillsScore).filter((skill) => skill !== 'Inteligencia Emocional');

          delete averageSkills['Inteligencia Emocional'];

          this.skillsPointsAverage = (this.generalScore / primarySkills.length).toFixed(2);
          this.skillsScore.Promedio = +this.skillsPointsAverage;
          this.getSkills();
          this.AssignBadge();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Para ver resultados, por favor completa el test de Skills',
          });
          return false;
        }
      },
      (error) => {
        console.error('Error fetching skills test:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Para ver resultados, por favor completa el test de Skills',
        });
        return false;
      }
    );
  }

  AssignBadge() {
    switch (true) {
      case this.averageScore >= 66:
        this.endprofile = 'Nivel Alto';
        this.sourceBadge = '/assets/dashboard-skill/insignias/master.png';
        break;
      case this.averageScore > 33 && this.averageScore < 66:
        this.endprofile = 'Nivel Medio';
        this.sourceBadge = '/assets/dashboard-skill/insignias/avanzado.png';
        break;
      case this.averageScore >= 0 && this.averageScore <= 33:
        this.endprofile = 'Nivel Principiante';
        this.sourceBadge = '/assets/dashboard-skill/insignias/en-construccion.png';
        break;
      default:
        break;
    }
  }

  goToLetterReady() {
    this.navigationService.navigateTo({ path: 'letter-ready' });
  }

  LinkProfileCopy() {
    if (this.slug != null) {
      navigator.clipboard.writeText(window.location.origin + '/web/viewProfile/' + this.slug);
      this.hideIcon();
    } else {
      this.alertService.showError({ msg: 'Por favor agregue un identificador a su hoja de vida' });
    }
  }

  goToExperiences() {
    this.router.navigate([`/experience-record/${this.userId}`]);
  }

  /**
   * Handles the click on the back button
   */
  onBackButtonClick(): void {
    this.location.back();
    this.router.navigate(['/studentsList']);
  }

  async getUniversityType({ id }: { id: string }) {
    try {
      const response = await this.apiService.get({ api: this.GET_STUDENT + id }).toPromise();
      this.selectedOptionUniversity = response.institutionType;
      await this.getInstitutions();
    } catch (error) {
      console.error('Error al obtener el tipo de universidad:', error);
    }
  }

  async getInstitutions() {
    try {
      const response = await this.apiService.get({ api: this.getInstitutionOptions }).toPromise();
      this.filteredResponse = response.filter(
        (university) => university.institutionOptionName === this.selectedOptionUniversity
      );

      if (this.filteredResponse.length > 0) {
        this.desiredInstitutionAverage = this.filteredResponse[0].selectedSkills.filter(
          (institution) => institution.name === 'Desempeño Global Competencias'
        );

        if (this.desiredInstitutionAverage.length === 0) {
          console.error("No se encontraron promedios de institución para 'Desempeño Global Competencias'");
        }
      } else {
        console.error('No se encontraron instituciones que coincidan con la opción seleccionada');
      }
    } catch (error) {
      console.error('Error al obtener las instituciones:', error);
    }
  }

  async getSkills() {
    try {
      const response = await this.apiService.get({ api: this.userExperiencesAPI }).toPromise();

      if (response.length !== 0) {
        await this.getUniversityType({ id: this.studentId });

        const userExperience = response.filter(
          (experience) => experience.user === this.studentId && experience.status === 'Aprobada'
        );

        Object.keys(this.Experiential).forEach((skill) => (this.Experiential[skill] = 0));

        if (userExperience.length > 0) {
          userExperience.forEach((experience) => {
            // Procesar habilidad principal
            if (this.Experiential.hasOwnProperty(experience.principalSkill)) {
              this.Experiential[experience.principalSkill] = Math.min(
                100,
                this.Experiential[experience.principalSkill] + experience.skillsScore
              );
            }

            // Procesar habilidad secundaria
            if (this.Experiential.hasOwnProperty(experience.secondarySkill)) {
              this.Experiential[experience.secondarySkill] = Math.min(
                100,
                this.Experiential[experience.secondarySkill] + experience.skillsScore / 2
              );
            }
          });

          this.skillsScore = this.Experiential;
         
        } else {
          this.userExperiences = [];
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor vuelve a intentarlo',
      });
    }
  }

  getSkillsRadar() {
    this.apiService.get({ api: this.getData + this.userData._id }).subscribe((response) => {
      this.apiService.get({ api: this.ponderationApi }).subscribe((ponderadoResponse) => {
          const ponderado = ponderadoResponse?.result?.ponderado / 100 || 0.1;
          console.log("🔹 Ponderado:", ponderado);

          this.skillScoreMap = {
              "Impacto y Responsabilidad Social": "ImpactoResponsabilidad",
              "Aprendizaje Continuo": "AprendizajePermanente",
              "Creatividad E Innovación": "CreatividadInovacion",
              "Inteligencia Emocional": "InteligenciaEmocional",
              "Orientación a Resultados": "OrientacionResultados",
              "Liderazgo": "Liderazgo",
              "Global Mind": "GlobalMind",
              "Pensamiento Crítico": "PensamientoCritico"
          };

          // Mapeo inverso para recuperar los nombres originales
          const skillLevelMap = {
              "ImpactoResponsabilidad": "Impacto y Responsabilidad Social",
              "AprendizajePermanente": "Aprendizaje Continuo",
              "CreatividadInovacion": "Creatividad E Innovación",
              "InteligenciaEmocional": "Inteligencia Emocional",
              "OrientacionResultados": "Orientación a Resultados",
              "Liderazgo": "Liderazgo",
              "GlobalMind": "Global Mind",
              "PensamientoCritico": "Pensamiento Crítico"
          };

          const skillPoints = response?.skillPoints || {};

          let skillObjects = Object.entries(skillPoints).map(([skillName, score]) => {
              const mappedName = this.skillScoreMap[skillName] || skillName;
              return {
                  name: mappedName,
                  skillScore: parseFloat(((score as number) * ponderado).toFixed(2)),
                  proficiencyLevel: "-",
                  description: "Descripción no disponible",
                  desiredScore: null, // Inicializamos desiredScore
                  proficiencyDescription: '' // Inicializamos proficiencyDescription
              };
          });

          console.log("🔹 Skills base con ponderado:", skillObjects);

          this.apiService.get({ api: this.plannedExperiences }).subscribe((experienceResponse) => {
              this.getUniversityType({ id: this.userData._id });
              const userExperiences = experienceResponse.filter(exp => exp.user === this.userData._id && exp.status === "Aprobada");

              let skillMap = new Map(skillObjects.map(skill => [skill.name, skill]));

              if (userExperiences.length > 0) {
                  userExperiences.forEach((experience) => {
                      const primarySkillName = this.skillScoreMap[experience.principalSkill] || experience.principalSkill;
                      const secondarySkillName = this.skillScoreMap[experience.secondarySkill] || experience.secondarySkill;

                      if (!skillMap.has(primarySkillName)) {
                          skillMap.set(primarySkillName, { name: primarySkillName, skillScore: 0, proficiencyLevel: "-", description: "Descripción no disponible", desiredScore: null, proficiencyDescription: '' });
                      }
                      let primarySkill = skillMap.get(primarySkillName);
                      primarySkill.skillScore = Math.min(100, parseFloat((primarySkill.skillScore + experience.skillsScore).toFixed(2)));

                      if (!skillMap.has(secondarySkillName)) {
                          skillMap.set(secondarySkillName, { name: secondarySkillName, skillScore: 0, proficiencyLevel: "-", description: "Descripción no disponible", desiredScore: null, proficiencyDescription: '' });
                      }
                      let secondarySkill = skillMap.get(secondarySkillName);
                      secondarySkill.skillScore = Math.min(100, parseFloat((secondarySkill.skillScore + (experience.skillsScore / 2)).toFixed(2)));
                  });
              }

              skillObjects = Array.from(skillMap.values());

              console.log("🔹 Skills después del merge con experiencias:", skillObjects);

              this.apiService.get({ api: this.getSkill }).subscribe((skillLevelsResponse) => {
                  const skillLevels = skillLevelsResponse.reduce((acc, skill) => {
                      acc[skill.skillName] = {
                          levels: skill.levels,
                          description: skill.skillDescription || "Descripción no disponible"
                      };
                      return acc;
                  }, {});

                  skillObjects = skillObjects.map(skill => {
                      const originalName = skillLevelMap[skill.name] || skill.name;
                      const skillData = skillLevels[originalName] || { levels: [], description: "Descripción no disponible" };
                      const levels = skillData.levels;
                      let assignedLevel = "Desconocido";

                      for (const level of levels) {

                      
                          if (skill.skillScore >= level.range1 && skill.skillScore < level.range2) {
                            
                              assignedLevel = level.level;
                              console.log("🔹 Nivel de proficiencia:", assignedLevel)
                            console.log("🔹 Skill score:", skill.skillScore, skill.name
                            );
                              break;
                          }
                      }

                      return {
                          ...skill,
                          name: originalName,
                          proficiencyLevel: assignedLevel,
                          description: skillData.description
                      };
                  });

                  console.log("🔹 Skills con niveles y descripciones asignadas:", skillObjects);

                  // 🔹 Agregar desiredScore desde filteredResponse[0].selectedSkills
                  if (this.filteredResponse && this.filteredResponse.length > 0) {
                      this.filteredResponse[0].selectedSkills.forEach((selectedSkill) => {
                          const matchingSkill = skillObjects.find((skill) => skill.name === selectedSkill.name);
                          if (matchingSkill) {
                              matchingSkill.desiredScore = selectedSkill.score; // Agregamos el desiredScore

                          }
                      });
                  }

                  console.log("🔹 Skills con desiredScore agregado:", skillObjects);
                  this.combinedData = skillObjects;
                  this.displayChart(skillObjects);

                  const totalScore = this.combinedData.reduce((sum, skill) => sum + skill.skillScore, 0);
                  const averageScore = parseFloat((totalScore / this.combinedData.length || 0).toFixed(2));
                this.averageScore = averageScore;

                  if (this.desiredInstitutionAverage && this.desiredInstitutionAverage.length > 0) {
                    console.log("🔹 Datos actuales en combinedData:", this.combinedData);
                
                    // Calculamos el promedio de todos los skillScores en combinedData
                   
                    console.log("🔹 Nuevo promedio calculado:", averageScore);
                
                    // Obtener el nivel usando la misma lógica de asignación de skillObjects
                    let assignedLevel = "Desconocido";
                    let levelDescription = "Descripción no disponible";
                
                    const institutionSkill = {
                        name: "Desempeño Global Competencias",
                        skillScore: averageScore
                    };
                
                    const originalName = skillLevelMap[institutionSkill.name] || institutionSkill.name;
                    const skillData = skillLevels[originalName] || { levels: [], description: "Descripción no disponible" };
                    const levels = skillData.levels;
                
                    for (const level of levels) {
                        if (institutionSkill.skillScore >= level.range1 && institutionSkill.skillScore < level.range2) {
                            assignedLevel = level.level;
                            levelDescription = level.description;
                            console.log("🔹 description:", level.description);
                            break;
                        }
                    }
                    this.institutionLevel = {
                      name: 'Desempeño Global Competencias',
                      score: averageScore, // Promedio calculado
                      level: assignedLevel, // Nivel encontrado
                      description: levelDescription, // Descripción del nivel
                  };
                   
                }
              });
          });
      });
  });
  }

  findLevel(levels, skillScore) {
    for (const level of levels) {
      if (skillScore >= level.range1 && skillScore <= level.range2) {
        return level;
      }
    }
    return {
      level: 'N/A',
      description: 'Proficiency level not available for this skill score range.',
    };
  }

  displayChart(skillsObjects) {
    // const dataToDisplay = {
    //   categories: skillsObjects.map((skill) => skill.name),
    //   data: skillsObjects.map((skill) => skill.score),
    //   levels: skillsObjects.map((skill) => skill.level),
    //   levelsDescription: skillsObjects.map((skill) => skill.levelDescription),
    // };

    // const categories = skillsObjects.map((skill) => {
    //   const formattedSkillName = skill.name.split(' ').join('<br>');
    //   return `${formattedSkillName} ${skill.score}%-${skill.level}`;
    // });

    // this.chartOptions = {
    //   series: [
    //     {
    //       name: 'Porcentaje',
    //       data: dataToDisplay.data,
    //     },
    //   ],
    //   theme: {
    //     palette: 'palette1',
    //   },
    //   chart: {
    //     height: 500,
    //     type: 'bar',
    //   },

    //   fill: {
    //     opacity: 0.8,
    //   },
    //   plotOptions: {
    //     bar: {
    //       columnWidth: '25%',
    //       distributed: true,
    //     },
    //   },
    //   dataLabels: {
    //     enabled: true,
    //     formatter: function (y) {
    //       const index = dataToDisplay.data.indexOf(y);
    //       const level = dataToDisplay.levels[index];
    //       return `${y.toFixed(0)}% - ${level}`;
    //     },
    //     offsetY: -20,
    //     style: {
    //       fontSize: '15px',
    //       colors: ['#304758'],
    //     },
    //   },
    //   legend: {
    //     show: false,
    //   },
    //   grid: {
    //     show: false,
    //   },
    //   xaxis: {
    //     categories: dataToDisplay.categories,
    //     labels: {
    //       style: {
    //         fontSize: '10px',
    //       },
    //     },
    //   },
    //   yaxis: {
    //     labels: {
    //       formatter: function (y) {
    //         return y.toFixed(0) + '%';
    //       },
    //     },
    //   },
    //   tooltip: {
    //     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
    //       const score = series[seriesIndex][dataPointIndex];
    //       const index = dataToDisplay.data.indexOf(score);
    //       const level = dataToDisplay.levels[index];
    //       const levelDescription = dataToDisplay.levelsDescription[index];
    //       return (
    //         '<div class="arrow_box" style="overflow-wrap: break-word; display: flex; flex-direction: column; width: 60vw;">' +
    //         '<span style="text-align: center; font-weight: bold;">' +
    //         score.toFixed(0) +
    //         '%' +
    //         ' - ' +
    //         level +
    //         '</span>' +
    //         '<div style="overflow-wrap: break-word; word-wrap: break-word; text-align: center; white-space: pre-wrap;">' +
    //         levelDescription +
    //         '</div>' +
    //         '</div>'
    //       );
    //     },
    //     fixed: {
    //       enabled: true,
    //       position: 'topRight',
    //       offsetX: 0,
    //       offsetY: 0,
    //     },
    //   },

    //   colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
    //   labels: {
    //     style: {
    //       colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
    //       fontSize: '12px',
    //     },
    //   },
    // };
    const categories = skillsObjects.map((skill) => {
      const formattedSkillName = skill.name.split(' ').join('<br>');
      return `${formattedSkillName} ${skill.skillScore}%-${skill.proficiencyLevel}`;
    });

    const skillValues = skillsObjects.map((skill) => skill.skillScore);

    this.chartOptions = {
      chart: {
        height: 500,
        width: 1500,
        type: 'radar',
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      series: [
        {
          name: 'Skills',
          data: skillValues,
          color: 'rgba(255, 255, 255, 0.5)',
        },
      ],
      plotOptions: {
        radar: {
          size: 200,
          polygons: {
            strokeColors: '#969FFB',
            fill: {
              colors: ['#d7dbff'],
            },
            connectorColors:['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F', '#FFD700']
          },
        },
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            colors: ['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F', '#FFD700'],
            fontSize: '18px',
            fontFamily: '"Montserrat", sans-serif',
            fontWeight: 600,
          },
          formatter: function (value) {
            return value.replace(/<br>/g, '\n');
          },
        },
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    };
  }
  displayZeroWeightChart(zeroWeightSkillsObjects) {
    const dataToDisplay = {
      categories: zeroWeightSkillsObjects.map((skill) => skill.name),
      data: zeroWeightSkillsObjects.map((skill) => skill.score),
      levels: zeroWeightSkillsObjects.map((skill) => skill.level),
      levelsDescription: zeroWeightSkillsObjects.map((skill) => skill.levelDescription),
    };
    this.secondarySkillsChartOptions = {
      series: [
        {
          name: 'Porcentaje',
          data: dataToDisplay.data,
        },
      ],
      theme: {
        palette: 'palette1',
      },
      chart: {
        height: 500,
        type: 'bar',
      },

      fill: {
        opacity: 0.8,
      },
      plotOptions: {
        bar: {
          columnWidth: '30px',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (y) {
          const index = dataToDisplay.data.indexOf(y);
          const level = dataToDisplay.levels[index];
          return `${y.toFixed(0)}% - ${level}`;
        },
        offsetY: -20,
        style: {
          fontSize: '15px',
          colors: ['#304758'],
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: dataToDisplay.categories,
      },
      yaxis: {
        labels: {
          formatter: function (y) {
            return y.toFixed(0) + '%';
          },
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const score = series[seriesIndex][dataPointIndex];
          const index = dataToDisplay.data.indexOf(score);
          const level = dataToDisplay.levels[index];
          const levelDescription = dataToDisplay.levelsDescription[index];
          return (
            '<div class="arrow_box" style="overflow-wrap: break-word; display: flex; flex-direction: column; width: 60vw;">' +
            '<span style="text-align: center; font-weight: bold;">' +
            score.toFixed(0) +
            '%' +
            ' - ' +
            level +
            '</span>' +
            '<div style="overflow-wrap: break-word; word-wrap: break-word; text-align: center; white-space: pre-wrap;">' +
            levelDescription +
            '</div>' +
            '</div>'
          );
        },
        fixed: {
          enabled: true,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
      },

      colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
      labels: {
        style: {
          colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
          fontSize: '12px',
        },
      },
    };
  }

  trimHtmlContent(content: string): string {
    const maxLength = 270;
    if (content.length <= maxLength) {
      return content;
    } else {
      const trimmedContent = content.substring(0, maxLength);
      return trimmedContent + '...';
    }
  }

  decodeEntities(encodedString: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString('<!doctype html><body>' + encodedString, 'text/html');
    return dom.body.textContent;
  }
}
