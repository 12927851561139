import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApexOptions } from 'ng-apexcharts';
import { SchoolDashboardFilter } from './school-dashboard-filters/school-dashboard-grade-dropdowns.models';

@Component({
  selector: 'app-school-dashboard',
  templateUrl: './school-dashboard.component.html',
  styleUrls: ['./school-dashboard.component.scss']
})
export class SchoolDashboardComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user') || '{}');
  school_name: string = localStorage.getItem('school_name') || '';
  school_id: string = localStorage.getItem('schoolId') || '';
  GET_STUDENTS = `api/getInitialDataFormBySchoolName/${this.school_name}`;
  GET_SKILLS = 'api/skillTestByColegio/student-list';
  readonly GET_SKILLS_LEVELS = 'api/getSkill'; 

  students: any[] = [];
  studentsSkills: any[] = [];
  skillLevels: any[] = [];
  cardsData: any[] = [];
  skillScoreMap: any;
  loading: boolean = true;
  skillsPointsAverage: any;
  institutionLevel: any;
  desiredInstitutionAverage: any;
  averageChartoptions: any;
  error: string = '';
  popupVisible = false;  // Controla la visibilidad del popup
  popupData: { title: string; description: string } | null = null;  // Datos del popup
  // Formulario de filtros
  filterForm = new FormGroup({
   
  });

  // Datos del gráfico
  chartDataStudyLocation = {
    series: [],
    options: {
      chart: {
        type: "pie",
        width: 380
      },
      labels: ["En el exterior", "En Colombia", "Aún no sabe"],
      colors: ["#00E396", "#FEB019", "#D10CE8"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          }
        }
      }]
    }
  };

  chartDataUniversities = {
    series: [],
    options: {
      chart: {
        type: "bar",
        width: 380
      },
      xaxis: {
        categories: [],
      },
      colors: ["#FF4560"],
    }
  };

  chartDataCountries = {
    series: [],
    options: {
      chart: {
        type: "pie",
        width: 380
      },
      labels: [],
      colors: ["#00E396", "#FEB019", "#D10CE8", "#008FFB", "#FF4560"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          }
        }
      }]
    }
  };

  chartDataCities = {
    series: [],
    options: {
      chart: {
        type: "bar",
        width: 380
      },
      xaxis: {
        categories: [],
      },
      colors: ["#00E396"],
    }
  };

  chartDataEnglishLevel = {
    series: [{
      name: 'Niveles de Inglés',
      data: []  // Aquí se actualizarán los valores de los niveles
    }],
    options: {
      chart: {
        type: "bar",  // Cambiado de "pie" a "bar"
        width: 380
      },
      xaxis: {
        categories: ["Básico", "Intermedio", "Avanzado"],  // Las categorías en el eje X
      },
      colors: ["#D10CE8", "#008FFB", "#FF4560"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            }
          }
        }
      ]
    }
  };
  chartDataNotesLevel = {
    series: [],
    options: {
      chart: {
        type: "pie",
        width: 380
      },
      labels: ["Bajo", "Medio", "Alto"],
      colors: ["#FF4560", "#775DD0", "#00E396"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          }
        }
      }]
    }
  };

  chartDataCareers = {
    series: [],
    options: {
      chart: {
        type: "bar",
        width: 380
      },
      xaxis: {
        categories: [],
      },
      colors: ["#00E396"],
    }
  };

  chartDataCountry = {
    series: [],
    options: {
      chart: {
        type: "pie",
        width: 380
      },
      labels: [],
      colors: ["#FEB019", "#FF4560", "#00E396"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          }
        }
      }]
    }
  };

  // Agregar un nuevo gráfico de "Interés de Estudio" en "Mi país"
  chartDataStudyInterest = {
    series: [0, 0], // valores iniciales
    options: {
      chart: {
        type: "pie",
        width: 380
      },
      labels: ["Mi país", "Otro lugar"],
      colors: ["#00E396", "#FEB019"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          }
        }
      }]
    }
  };

  chartDataGradeLevel = {
    series: [],
    options: {
      chart: {
        type: "pie",  // Cambiado de "bar" a "pie"
        width: 380
      },
      labels: [],  // Las etiquetas corresponden a los niveles de grado (por ejemplo, "12A", "11B")
      colors: ["#775DD0", "#00E396", "#FEB019", "#FF4560"],  // Colores para las categorías
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          }
        }
      }]
    }
  };
  chartDataSkills = {
    series: [
      {
        name: "Competencias",
        data: [] // Se llenará dinámicamente con los porcentajes
      }
    ],
    options: {
      chart: {
        type: "bar", // Gráfico de barras
        height: 400
      },
      plotOptions: {
        bar: {
          horizontal: false, // Mantener las barras verticales
          columnWidth: "50%",
          distributed: true // Colores diferentes por categoría
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => `${val.toFixed(0)}%`, // Asegurar que muestra el porcentaje
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          colors: ["#333"]
        }
      },
      xaxis: {
        categories: [], // Se llenará dinámicamente con los nombres de habilidades
        labels: {
          rotate: -45, // Inclinación del texto en el eje X
          style: {
            fontSize: "12px",
            fontWeight: "bold"
          }
        }
      },
      yaxis: {
        title: {
          text: "Porcentaje (%)",
          style: {
            fontSize: "14px",
            fontWeight: "bold"
          }
        },
        labels: {
          formatter: (val: number) => `${val.toFixed(0)}%` // Asegurar que se muestre el porcentaje en el eje Y
        }
      },
      colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#D7263D", "#F77F00"], // Colores variados
      tooltip: {
        y: {
          formatter: (val: number) => `${val.toFixed(0)}%` // Mostrar en el tooltip el porcentaje correctamente
        }
      },
      legend: {
        show: false // Ocultar leyenda para una vista más clara
      }
    }
  };

  constructor(
    private apiService: ApiService,
    private ngxSpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    // Inicializar los filtros, si es necesario
    this.setupFilters();
    
    this.filterForm.patchValue({
      // Filtros originales
      grado: '',
      nivel: '',
      genero: '',
      lugarEstudio: '',
      universidad: '',
      pais: '',
      carrera: '',
      ciudadColombia: '',
      nivelIngles: '',
      nivelNotas: '',
    
      // Filtros duplicados para el nuevo endpoint
      nuevoGrado: '',
      nuevoNivel: '',
      nuevoGenero: '',
      nuevoLugarEstudio: '',
      nuevaUniversidad: '',
      nuevoPais: '',
      nuevaCarrera: '',
      nuevaCiudadColombia: '',
      nuevoNivelIngles: '',
      nuevoNivelNotas: '',
      colegio: this.school_id
    });
    
    // Cargar la lista de estudiantes con los filtros iniciales
    this.getStudentsList(this.filterForm.value); 
    
    this.getStudentsListSkills(this.filterForm.value)// Pasar los filtros iniciales
    
  }
  

  

  setupFilters(): void {
    Object.keys(this.filterForm.controls).forEach(key => {
      this.filterForm.controls[key].valueChanges.pipe(
        debounceTime(500)
      ).subscribe(() => this.applyFilters());
    });
  }
  // Verificación en applyFilters para confirmar que los filtros se están construyendo y enviando correctamente
applyFilters(): void {
  const filters = this.buildFilterParams(); // Construir los filtros
  console.log('Applying filters with:', filters); // Verifica los filtros

  if (Object.keys(filters).length === 0) {
    console.log('No filters applied');
  } else {
    // Llamar a `getStudentsList` y pasar los filtros como argumento
    this.getStudentsList(filters);
    this.getStudentsListSkills(filters); // Pasar los filtros a la nueva API
  }
}

findLevel(levels, skillScore) {
  for (const level of levels) {
    if (skillScore >= level.range1 && skillScore <= level.range2) {
      return level;
    }
  }
  return {
    level: 'N/A',
    description: 'Proficiency level not available for this skill score range.',
  };
}



displayAverageChart(averageScore) {
  const averageScore2 = averageScore.score;

  console.log('Average score:', averageScore2);
  console.log(averageScore);

  this.averageChartoptions = {
    series: [averageScore2],
    chart: {
      height: 350,
      width: 300,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
      },
    },
    labels: [`${averageScore.level}`],
  };
}

buildFilterParams(): { [key: string]: string } {
  const filters = {};
  Object.keys(this.filterForm.controls).forEach(key => {
    const controlValue = this.filterForm.get(key)?.value;
    if (controlValue) {  // Solo agrega los valores que no sean vacíos
      filters[key] = controlValue;
    }
  });
  console.log('Filters inside buildFilterParams:', filters); // Verifica los filtros
  return filters;
}

  onFiltersChanged(filters: SchoolDashboardFilter): void {
    const filterParams = this.buildFilterParamsFromDropdowns(filters);
    this.getStudentsList(filterParams);
    this.getStudentsListSkills(filterParams);
  }

  private buildFilterParamsFromDropdowns(filters: SchoolDashboardFilter): { [key: string]: string | string[] } {
    const filterParams: { [key: string]: string } = {};

    Object.keys(filters).forEach((key: string) => {
      const value = filters[key];
      if (value) {
        filterParams[key] = Array.isArray(value) ? value.join(',') : value;
      }
    });
    console.log('Filter Params:', filterParams); // Verifica los filtros
    return filterParams;
  }

  // Verifica que la respuesta de la API contenga datos correctos
  getStudentsList(filters: { [key: string]: string | string[] }): void {
    this.ngxSpinnerService.show();

    // Verifica que los filtros sean correctos
    console.log('Filters being sent:', filters);

    const httpParams = new HttpParams({ fromObject: filters });
    console.log('HTTP Params:', httpParams.toString());  // Verifica la URL con los parámetros

    this.apiService.getIntialData(this.GET_STUDENTS, { params: httpParams }, { api: this.GET_STUDENTS })
      .subscribe({
        next: (resultPaginate) => {
          console.log('API Response:', resultPaginate); // Verifica la respuesta de la API
          if (resultPaginate) {
            this.students = resultPaginate;
            this.cardsData = this.createCardsData(this.students);
            this.updateChartsData(this.students);
          } else {
            this.error = 'No se encontraron estudiantes';
          }
        },
        error: (error) => {
          console.error('Error cargando estudiantes', error);
          this.error = 'Error al cargar los estudiantes';
        },
        complete: () => {
          this.ngxSpinnerService.hide();
        }
      });
  }

// Verifica que la respuesta de la API contenga datos correctos
getStudentsListSkills(filters: { [key: string]: string | string[] }): void {
  this.ngxSpinnerService.show();

  // Combina los filtros originales y los nuevos
  const updatedFilters = {
    ...filters,
    nuevoGrado: filters.grado || '',
    nuevoNivel: filters.nivel || '',
    nuevoGenero: filters.genero || '',
    nuevoLugarEstudio: filters.lugarEstudio || '',
    nuevaUniversidad: filters.universidad || '',
    nuevoPais: filters.pais || '',
    nuevaCarrera: filters.carrera || '',
    nuevaCiudadColombia: filters.ciudadColombia || '',
    nuevoNivelIngles: filters.nivelIngles || '',
    nuevoNivelNotas: filters.nivelNotas || '',
    colegio: this.school_id
  };

  console.log('Filters being sent:', updatedFilters);

  this.apiService.post({ api: this.GET_SKILLS, data: updatedFilters })
    .subscribe({
      next: (resultPaginate) => {
        console.log('API Response:', resultPaginate); // Verifica la respuesta de la API
        if (resultPaginate) {
          this.studentsSkills = resultPaginate.docs;
          this.updateSkillsChart(this.studentsSkills);
          this.updateGlobalPerformance(this.studentsSkills);
        } else {
          this.error = 'No se encontraron estudiantes';
        }
      },
      error: (error) => {
        console.error('Error cargando estudiantes', error);
        this.error = 'Error al cargar los estudiantes';
      },
      complete: () => {
        this.ngxSpinnerService.hide();
      }
    });


}

// Verifica que la respuesta de l




  openPopup(card: any): void {
    this.popupData = { title: card.title, description: card.details };
    this.popupVisible = true;
  }

  // Método para cerrar el popup
  closePopup(): void {
    this.popupVisible = false;
    this.popupData = null; // Limpiar los datos del popup al cerrarlo
  }

  // Crear los datos de las tarjetas
  createCardsData(students: any[]): any[] {
    const countryCount = this.countAbroadCountriesCard(students);
    const totalStudents = students.length;

    // Filtrar los datos sin valores vacíos
    const countries = students.map(student => student.abroadCountries).reduce((acc, curr) => acc.concat(curr), []).filter(c => c.trim() !== '');
    const uniqueCountries = [...new Set(countries)];
    const universities = students.map(student => student.colombianUniversities).reduce((acc, curr) => acc.concat(curr), []).filter(u => u.trim() !== '').slice(0, 10);
    const cities = students.map(student => student.primaryCity).filter(city => city && city.trim() !== '');
    const careers = students.map(student => student.careerInterest).reduce((acc, curr) => acc.concat(curr), []).filter(career => career && career.trim() !== '');
    const englishLevels = students.map(student => student.englishLevel).filter(level => level && level.trim() !== '');
    const grades = students.map(student => student.academicRatings12).filter(grade => grade && grade.trim() !== '');

    const cards: any[] = [];

    // Crear tarjetas
    if (totalStudents > 0) {
      cards.push({
        title: 'Total Estudiantes',
        value: totalStudents,
        icon: 'school',
        details: `Total estudiantes: ${totalStudents}`  // Información para mostrar en el popup
      });
    }

    if (Object.keys(countryCount).length > 0) {
      cards.push({
        title: 'Estudiantes que quieren estudiar fuera',
        value: countryCount,
        icon: 'public',
        details: `Estudiantes que desean estudiar en el exterior ${countryCount}` // Países donde los estudiantes quieren estudiar
      });
    }

    if (uniqueCountries.length > 0) {
      cards.push({
        title: 'Países donde los estudiantes quieren estudiar',
        value: 'Click para ver la información',
        icon: 'map',
        details: uniqueCountries.join(', ')  // Mostrar países en el popup
      });
    }

    if (universities.length > 0) {
      cards.push({
        title: 'Universidades que los estudiantes quieren estudiar',
        value: 'Click para ver la información',
        icon: 'school',
        details: universities.join(', ')  // Universidades en las que los estudiantes están interesados
      });
    }

    if (cities.length > 0) {
      cards.push({
        title: 'Ciudades donde los estudiantes quieren estudiar (Colombia)',
        value: 'Click para ver la información',
        icon: 'location_city',
        details: cities.join(', ')  // Ciudades en Colombia donde los estudiantes quieren estudiar
      });
    }

    if (careers.length > 0) {
      cards.push({
        title: 'Carreras que los estudiantes quieren estudiar',
        value: 'Click para ver la información',
        icon: 'work',
        details: careers.join(', ')  // Carreras de interés
      });
    }

    if (englishLevels.length > 0) {
      cards.push({
        title: 'Nivel de Inglés',
        value: 'Click para ver la información',
        icon: 'language',
        details: englishLevels.join(', ')  // Niveles de inglés
      });
    }

    if (grades.length > 0) {
      cards.push({
        title: 'Calificaciones de último año',
        value: 'Click para ver la información',
        icon: 'grade',
        details: grades.join(', ')  // Calificaciones
      });
    }

    return cards;
  }

  // Contar los países donde los estudiantes quieren estudiar
  countAbroadCountriesCard(students: any[]) {
    const countryCount = {};
    students.forEach(student => {
      student.abroadCountries.forEach(country => {
        if (country.trim() !== '') {
          countryCount[country] = (countryCount[country] || 0) + 1;
        }
      });
    });
    return countryCount;
  }

  countAbroadCountries(students: any[]): any {
    const countryCount: { [key: string]: number } = {};

    students.forEach(student => {
      const countries = Array.isArray(student.abroadCountries)
        ? student.abroadCountries
        : student.abroadCountries ? Object.values(student.abroadCountries) : [];

      countries.forEach(country => {
        countryCount[country] = (countryCount[country] || 0) + 1;
      });
    });

    return countryCount;
  }

  updateChartsData(students: any[]): void {
    this.updateUniversityChartData(students);
    this.updateCountryChartData(students);
    this.updateCitiesChartData(students);
    this.updateEnglishLevelChartData(students);
    this.updateGradeLevelChartData(students);
    this.updateNotesLevelChartData(students);  // Add this line
    this.updateCareersChartData(students);     // Add this line
    this.updateCountryChartDataV2(students);
    this.updateStudyInterestChartData(students);  // Add this
  
  }


  updateUniversityChartData(students: any[]): void {
    console.log("Datos de estudiantes recibidos:", students);

    // Inicializamos un objeto para contar las universidades
    const universities: { [key: string]: number } = students.reduce((acc: { [key: string]: number }, student) => {
      const studentUniversities = student.colombianUniversities?.filter(univ => univ.trim() !== "") || [];

      studentUniversities.forEach((university) => {
        const cleanUniversity = university.trim();
        acc[cleanUniversity] = (acc[cleanUniversity] || 0) + 1;
      });

      return acc;
    }, {});

    // Ordenamos por cantidad de estudiantes y tomamos las 10 principales
    const sortedUniversities = Object.entries(universities)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);

    // Asignamos los datos procesados al objeto `chartDataUniversities`
    this.chartDataUniversities.series = sortedUniversities.length > 0
      ? [{ name: "Estudiantes", data: sortedUniversities.map(([_, count]) => count) }]
      : [{ name: "Estudiantes", data: [0] }];

    this.chartDataUniversities.options.xaxis.categories = sortedUniversities.length > 0
      ? sortedUniversities.map(([university]) => university)
      : ["Sin datos"];

    console.log("Datos procesados para el gráfico de universidades:", this.chartDataUniversities);
  }

  // Actualizar gráfico de "Países"
  updateCountryChartData(students: any[]): void {
    const countryCount = this.countAbroadCountries(students);
    this.chartDataCountries.series = Object.values(countryCount);
    this.chartDataCountries.options.labels = Object.keys(countryCount);
  }

  updateNotesLevelChartData(students: any[]): void {
    console.log("Datos de estudiantes recibidos:", students);

    const levels = { "Bajo": 0, "Medio": 0, "Alto": 0 };

    students.forEach(student => {
      const currentGrade = student.currentGrade;

      // Acceder a la calificación basada en el grado actual del estudiante
      const rating = student[`academicRatings${currentGrade}`];

      if (!rating || rating === "not_available") return; // Si no hay calificación, no lo contamos

      if (rating === "excellent" || rating === "very_good") {
        levels["Alto"]++;
      } else if (rating === "good") {
        levels["Medio"]++;
      } else {
        levels["Bajo"]++;
      }
    });

    // Asignamos los datos en el formato correcto para un gráfico tipo "pie"
    this.chartDataNotesLevel.series = Object.values(levels);

    // Actualizamos `labels` para asegurar que coincidan con los valores
    this.chartDataNotesLevel.options = {
      ...this.chartDataNotesLevel.options,
      labels: Object.keys(levels),
    };

    console.log("Datos procesados para el gráfico de niveles de notas:", this.chartDataNotesLevel);
  }

  updateCareersChartData(students: any[]): void {
    console.log("Datos de estudiantes recibidos:", students);

    const careers: { [key: string]: number } = {};

    students.forEach(student => {
      // Acceder al array careerInterest de cada estudiante
      const careerInterests = student.careerInterest || [];

      // Contar cada carrera de interés en el arreglo
      careerInterests.forEach(interest => {
        if (interest.trim() !== "") { // Evitar strings vacíos
          careers[interest] = (careers[interest] || 0) + 1;
        }
      });
    });

    // Ordenamos por cantidad y tomamos las 10 carreras más populares
    const sortedCareers = Object.entries(careers)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);

    // Verificamos si hay datos válidos
    if (sortedCareers.length === 0) {
      console.warn("No hay datos válidos para el gráfico de carreras");
      this.chartDataCareers.series = [];
      this.chartDataCareers.options.xaxis.categories = [];
      return;
    }

    // Asignar datos en formato correcto para ApexCharts
    this.chartDataCareers.series = [{
      name: "Interés por carrera",
      data: sortedCareers.map(([_, count]) => count) // Solo valores numéricos
    }];

    // Actualizar categorías del eje X con los nombres de las carreras
    this.chartDataCareers.options = {
      ...this.chartDataCareers.options,
      xaxis: {
        categories: sortedCareers.map(([career]) => career)
      }
    };

    console.log("Datos procesados para el gráfico de carreras:", this.chartDataCareers);
  }

  // Update "Países" (Countries) chart data - V2
  updateCountryChartDataV2(students: any[]): void {
    const countries = students.reduce((acc: { [key: string]: number }, student) => {
      const country = student.country || 'Desconocido';
      acc[country] = (acc[country] || 0) + 1;
      return acc;
    }, {});

    this.chartDataCountry.series = Object.values(countries);
    this.chartDataCountry.options.labels = Object.keys(countries);
  }

  // Método para actualizar los datos del gráfico de "Interés de Estudio"
  // Método para actualizar los datos del gráfico de "Interés de Estudio"
  // Método para actualizar los datos del gráfico de "Interés de Estudio"
  updateStudyInterestChartData(students: any[]): void {
    const interestCount = { "Mi país": 0, "Otro lugar": 0 };

    students.forEach(student => {
      const studyInterest = student.studyInterest;

      console.log("Estudio Interés de estudiante:", studyInterest);

      // Solo contamos si "studyInterest" tiene un valor
      if (studyInterest) {
        if (studyInterest === "Mi pais") {
          interestCount["Mi país"]++;
        } else {
          interestCount["Otro lugar"]++;
        }
      }
    });

    console.log("Conteo de interés:", interestCount);

    // Asegúrate de que siempre haya datos en 'series'
    this.chartDataStudyInterest.series = [
      interestCount["Mi país"],
      interestCount["Otro lugar"]
    ];

    console.log("Datos de interés de estudio actualizados:", this.chartDataStudyInterest.series);
  }

  updateCitiesChartData(students: any[]): void {
    console.log("Datos de estudiantes recibidos:", students);

    const cities: { [key: string]: number } = {};

    students.forEach(student => {
      const studyInterest = student.studyInterest;
      let primaryCity = student.primaryCity?.trim();
      let secondaryCity = student.secondaryCity?.trim();

      // Omitir estudiantes con ciudades vacías
      if (!primaryCity && !secondaryCity) {
        return;  // Si ambas ciudades son vacías, no contamos este estudiante
      }

      if (studyInterest === "Mi pais") {
        if (primaryCity) {
          cities[primaryCity] = (cities[primaryCity] || 0) + 1;
        }
        if (secondaryCity && secondaryCity !== primaryCity) {
          cities[secondaryCity] = (cities[secondaryCity] || 0) + 0.5; // Menor peso
        }
      } else {
        if (primaryCity) {
          cities[primaryCity] = (cities[primaryCity] || 0) + 1;
        }
        if (secondaryCity && secondaryCity !== primaryCity) {
          cities[secondaryCity] = (cities[secondaryCity] || 0) + 1;
        }
      }
    });

    // Ordenar ciudades por cantidad de estudiantes
    const sortedCities = Object.entries(cities)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);

    if (sortedCities.length === 0) {
      console.warn("No hay datos válidos para el gráfico de ciudades");
      this.chartDataCities.series = [];
      this.chartDataCities.options = { ...this.chartDataCities.options, xaxis: { categories: [] } };
      return;
    }

    // Asignar datos en el formato correcto para ApexCharts
    this.chartDataCities.series = [{
      name: "Ciudades",
      data: sortedCities.map(([_, count]) => count)
    }];

    // Actualizar categorías del eje X
    this.chartDataCities.options = {
      ...this.chartDataCities.options,
      xaxis: { categories: sortedCities.map(([city]) => city) }
    };

    console.log("Datos procesados para el gráfico de ciudades:", this.chartDataCities);
  }

  // Método para actualizar los datos del gráfico de nivel de inglés
  updateEnglishLevelChartData(students: any[]): void {
    const levels = { "Básico": 0, "Intermedio": 0, "Avanzado": 0 };

    students.forEach(student => {
      const englishLevel = student.englishLevel;

      // Asignamos los niveles correspondientes
      if (['A1', 'A2'].includes(englishLevel)) {
        levels["Básico"]++;
      } else if (['B1', 'B2'].includes(englishLevel)) {
        levels["Intermedio"]++;
      } else if (['C1', 'C2'].includes(englishLevel)) {
        levels["Avanzado"]++;
      }
    });

    // Actualizamos los datos del gráfico
    this.chartDataEnglishLevel.series[0].data = Object.values(levels);  // Se asignan los valores de las categorías
  }

  updateGradeLevelChartData(students: any[]): void {
    const gradeLevels: { [key: string]: number } = {};

    students.forEach(student => {
      const gradeLevel = student.gradeLevel;

      if (gradeLevel) {
        // Si el grado ya existe en el objeto, lo incrementamos
        gradeLevels[gradeLevel] = (gradeLevels[gradeLevel] || 0) + 1;
      }
    });

  // Actualizar los datos del gráfico
  this.chartDataGradeLevel.series = Object.values(gradeLevels);  // Los conteos por cada grado
  this.chartDataGradeLevel.options.labels = Object.keys(gradeLevels);  // Los grados (por ejemplo, "12A", "11B", etc.)
}


getSkillLevels(): Promise<void> {
  return new Promise((resolve, reject) => {
    this.apiService.get({ api: this.GET_SKILLS_LEVELS }).subscribe(
      (response) => {
        if (!response || !Array.isArray(response)) {
          console.warn('No se recibieron datos de habilidades.');
          this.skillLevels = [];
          return resolve();
        }

        this.skillLevels = response.map((skill) => ({
          skillName: skill.skillName,
          levels: skill.levels.map((level) => ({
            level: level.level,
            description: level.description,
            range1: level.range1,
            range2: level.range2,
          })),
        }));

        console.log('Niveles de habilidades cargados:', this.skillLevels);
        resolve();
      },
      (error) => {
        console.error('Error al obtener los niveles de habilidades:', error);
        this.skillLevels = [];
        reject(error);
      }
    );
  });
}

async updateSkillsChart(students: any[]): Promise<void> {
  await this.getSkillLevels(); // Cargar los niveles de habilidades antes de procesar los datos

  const skillGroups: { [key: string]: string[] } = {
    "Liderazgo": ["Liderazgo"],
    "Inteligencia Emocional": ["InteligenciaEmocional"],
    "Global Mind": ["GlobalMind"],
    "Impacto y Responsabilidad Social": ["ImpactoResponsabilidad"],
    "Aprendizaje Continuo": ["AprendizajePermanente"],
    "Creatividad E Innovación": ["CreatividadInovacion"],
    "Orientación a Resultados": ["OrientacionResultados"]
  };

  const skillAverages: { [key: string]: number[] } = {};

  // Calcular promedios por competencia
  students.forEach(student => {
    if (!student?.skillsScore || !Array.isArray(student.skillsScore)) {
      return;
    }

    student.skillsScore.forEach(skillObj => {
      Object.entries(skillGroups).forEach(([category, skillKeys]) => {
        skillKeys.forEach(skillKey => {
          if (skillObj[skillKey] !== undefined) {
            if (!skillAverages[category]) {
              skillAverages[category] = [];
            }
            skillAverages[category].push(skillObj[skillKey]);
          }
        });
      });
    });
  });

  // Crear el array final con los niveles correspondientes
  const finalSkillData = Object.entries(skillAverages).map(([category, values]) => {
    const average = values.reduce((acc, val) => acc + val, 0) / values.length;
    const roundedAverage = Math.round(average);

    // Buscar el nivel correspondiente en los rangos de habilidades usando getSkillLevels()
    let skillLevel = "Sin nivel";
    const skill = this.skillLevels.find(s => s.skillName === category);
    if (skill) {
      const matchedLevel = skill.levels.find(level => roundedAverage >= level.range1 && roundedAverage < level.range2);
      skillLevel = matchedLevel ? matchedLevel.level : "Sin nivel";
    }

    return { category, percentage: roundedAverage, level: skillLevel };
  });

  // Ordenar de mayor a menor porcentaje
  finalSkillData.sort((a, b) => b.percentage - a.percentage);

  // Configurar los datos del gráfico con etiquetas personalizadas
  this.chartDataSkills.series = [
    {
      name: "Competencias",
      data: finalSkillData.map(skill => ({
        x: skill.category,
        y: skill.percentage,
        level: skill.level // Guardamos el nivel para usarlo en el tooltip
      }))
    }
  ];

  this.chartDataSkills.options.xaxis.categories = finalSkillData.map(skill => skill.category);

  this.chartDataSkills.options.tooltip = {
    y: {
      formatter: (val: number) => {
        
        const matchedSkill = this.chartDataSkills.series[0].data.find(skill => skill.y === val);
        return matchedSkill ? `Nivel: ${matchedSkill.level}` : "";
      }
    }
  };
}

async updateGlobalPerformance(students: any[]): Promise<void> {
  await this.getSkillLevels(); // Asegurar que los niveles de habilidades están cargados

  if (!Array.isArray(students) || students.length === 0) {
    return;
  }

  const skillKeys = [
    "Liderazgo",
    "InteligenciaEmocional",
    "GlobalMind",
    "ImpactoResponsabilidad",
    "AprendizajePermanente",
    "CreatividadInovacion",
    "OrientacionResultados"
  ];

  let totalScore = 0;
  let totalCount = 0;

  students.forEach(student => {
    if (!student || typeof student !== "object") return;
    if (!Array.isArray(student.skillsScore) || student.skillsScore.length === 0) return;

    student.skillsScore.forEach(skillObj => {
      if (!skillObj || typeof skillObj !== "object") return;

      skillKeys.forEach(key => {
        if (typeof skillObj[key] === "number") {
          totalScore += skillObj[key];
          totalCount++;
        }
      });
    });
  });

  // Calcular promedio y redondear a un solo decimal
  this.skillsPointsAverage = totalCount > 0 ? Number((totalScore / totalCount).toFixed(1)) : 0;

  const roundedAverage = Math.round(this.skillsPointsAverage);

  // Buscar el nivel correspondiente
  let matchedLevel = "Sin nivel";
  let levelDescription = "";

  if (Array.isArray(this.skillLevels)) {
    for (const skill of this.skillLevels) {
      const foundLevel = skill.levels?.find(level => roundedAverage >= level.range1 && roundedAverage < level.range2);
      if (foundLevel) {
        matchedLevel = foundLevel.level;
        levelDescription = foundLevel.description;
        break;
      }
    }
  }

  this.institutionLevel = { level: matchedLevel, description: levelDescription };

  // Construir el objeto con los datos del gráfico
  const averageObject = {
    name: "Desempeño Global Competencias",
    score: this.skillsPointsAverage, // Ahora con un solo decimal
    level: this.institutionLevel.level,
    levelDescription: this.institutionLevel.description,
  };

  this.displayAverageChart(averageObject);
}
}