<div class="input-container" [class.disabled]="disabled">

  <div class="input-wrapper">
    <label *ngIf="label">{{ label }}</label>
    
    <input 
      class="input"
      [type]="type" 
      [formControl]="control" 
      [placeholder]="placeholder"
      [disabled]="disabled"
      [class.error]="control.invalid && control.touched">
  </div>

  <p class="error-message" [class.active]="control.invalid && control.touched">
    {{ getErrorMessage() }}
  </p>

  </div>
  