export const MOTIVATIONS = [
    { value: 'salary', label: 'Salario', img: 'assets/initial-form/money.png' },
    { value: 'passion', label: 'Pasión', img: 'assets/initial-form/passion.png' },
    { value: 'impact', label: 'Impacto', img: 'assets/initial-form/impact.png' },
    { value: 'business', label: 'Negocios o Emprendimiento', img: 'assets/initial-form/entrepreneurship.png' },
    { value: 'growth', label: 'Crecimiento', img: 'assets/initial-form/growth.png' },
    { value: 'prestige', label: 'Prestigio', img: 'assets/initial-form/prestige.png' },
    { value: 'innovation', label: 'Innovación', img: 'assets/initial-form/innovation.png' },
    { value: 'family_legcy', label: 'Legado Familiar', img: 'assets/initial-form/family-legacy.png' },
    { value: 'balance', label: 'Equilibrio', img: 'assets/initial-form/balance.png' },
    { value: 'international', label: 'Internacionalización', img: 'assets/initial-form/internationalization.png' },
  ]
