<div class="wrapper">
  <div class="row">
    <div class="col-12">
      <h4 class="titulo-wizard">Conoce los tipos de competencias</h4>
      <p>Explora entre los tipos de competencias</p>
      <hr class="hrLine" />
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="cards-grid">
        <div class="card-item" *ngFor="let item of skills; index as i">
          <div class="news-card">
            <div class="card-body pad0">
              <div class="headerSection">
                <label class="universityTitle" title="{{ item.skillName }}">
                  {{ item.skillName }}
                </label>
                <label class="universityDescription">
                  ¿Qué es {{ item.skillName }}?
                </label>
                <span class="showMessage" (click)="openShowMoreComponent(item)">
                  Ver más <em class="fas fa-solid fa-plus"></em>
                </span>
              </div>

              <!-- ✅ Prevent reload, use key tracking -->
              <iframe
                *ngIf="item.safeVideoUrl"
                [src]="item.safeVideoUrl"
                frameborder="0"
                allowfullscreen
                loading="lazy"
                (load)="onVideoPlay(item.skillName)"
                (fullscreenchange)="onVideoPause()"
              ></iframe>
            </div>

            <div class="news-card-footer">
              <button class="addAnotherButton" (click)="selectSkill(item.skillName)">
                Experiencias Asociadas
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
