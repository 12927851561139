<div class="filters">

    <!-- Grades Dropdown -->
    <app-school-dashboard-dropdown 
    [idField]="'id'" 
    [textField]="'name'" 
    [staticData]="grades"
    placeholder="Seleccionar Grado" [singleSelection]="true"
    (selectionChanged)="handleGradeSelectionChange($event)">
    </app-school-dashboard-dropdown>

    <!-- Dropdown de Niveles (Depende del Grado seleccionado) -->
  <app-school-dashboard-dropdown 
    [idField]="'id'" 
    [textField]="'name'" 
    [staticData]="gradeLevels"
    placeholder="Seleccionar Nivel" [singleSelection]="false" (selectionChanged)="handleSelectionChange('nivel', $event, 'id')">
  </app-school-dashboard-dropdown>  
  <!-- Gender Dropdwon -->
    <app-school-dashboard-dropdown
    [idField]="'_id'"
    [textField]="'name'"
    [staticData]="genders"
    placeholder="Genero"
    [singleSelection]="false"
    [itemsShowLimit]="1"
    (selectionChanged)="handleSelectionChange('genero', $event, '_id')">
  </app-school-dashboard-dropdown>

  <!-- Study Places Dropdown -->
  <app-school-dashboard-dropdown
    [idField]="'_id'"
    [textField]="'name'"
    [staticData]="studyPlaces"
    placeholder="Lugar de Estudio"
    [singleSelection]="false"
    [itemsShowLimit]="1"
    (selectionChanged)="handleSelectionChange('lugarEstudio', $event, '_id')">
  </app-school-dashboard-dropdown>

  <!-- University Dropdown -->
  <app-school-dashboard-dropdown
    apiEndpoint="api/universidades/dropdown"
    [idField]="'_id'"
    [textField]="'nombre'"
    [additionalField]="'campus'"
    placeholder="Universidades"
    [singleSelection]="false"
    [itemsShowLimit]="1"
    (selectionChanged)="handleSelectionChange('universidad', $event, '_id')">
  </app-school-dashboard-dropdown>

  <!-- University Degrees Dropdown -->
  <app-school-dashboard-dropdown
    [staticData]="universityDegrees"
    [idField]="'_id'"
    [textField]="'name'"
    placeholder="Carreras"
    [singleSelection]="false"
    (selectionChanged)="handleSelectionChange('carrera', $event, '_id')">
  </app-school-dashboard-dropdown>

  <!-- Country Dropdown -->
  <app-school-dashboard-dropdown
    apiEndpoint="api/pais"
    [idField]="'_id'"
    [textField]="'nombre'"
    placeholder="Países"
    [singleSelection]="false"
    (selectionChanged)="handleSelectionChange('pais', $event, 'nombre')">
  </app-school-dashboard-dropdown>

  <!-- City Dropdown -->
  <app-school-dashboard-dropdown
    apiEndpoint="api/ciudad"
    [idField]="'_id'"
    [textField]="'nombre'"
    placeholder="Ciudades"
    [singleSelection]="false"
    (selectionChanged)="handleSelectionChange('ciudadColombia', $event, 'nombre')">
  </app-school-dashboard-dropdown>

  <!-- English Levels Dropdown -->
  <app-school-dashboard-dropdown
    [idField]="'_id'"
    [textField]="'name'"
    [staticData]="englishLevels"
    placeholder="Nivel de Inglés"
    [singleSelection]="false"
    [itemsShowLimit]="1"
    (selectionChanged)="handleSelectionChange('nivelIngles', $event, 'name')">
  </app-school-dashboard-dropdown>

  <!-- Grade Rates Dropdown -->
  <app-school-dashboard-dropdown
  [idField]="'_id'"
  [textField]="'name'"
  [staticData]="gradeRates"
  placeholder="Nivel de Notas"
  [singleSelection]="false"
  [itemsShowLimit]="1"
  (selectionChanged)="handleSelectionChange('nivelNotas', $event, 'name')">
</app-school-dashboard-dropdown>
</div>