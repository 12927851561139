<div class="change">
  <h3 class="title">
    {{ 'Password-recovery.recover-password' | translate }}
  </h3>
  <div class="description">
    {{ 'Password-recovery.type-your-data' | translate }}
  </div>
  <form [formGroup]="form" (ngSubmit)="onRecuperar()">
    <div class="container-email">
      <div class="email-title">{{ 'Password-recovery.email' | translate }}</div>
      <input placeholder="Escribe aquí tu correo" type="email" formControlName="email" />

      <p class="container-email__invalid" [class.hidden]="!(form.get('email').touched && form.get('email').invalid)">
        {{ 'Login.valid-email' | translate }}
      </p>
    </div>

    <button class="change__button">
      {{ 'Password-recovery.recover' | translate }}
    </button>
  </form>
</div>