<div class="academic-interests">
  <div class="academic-interests-form">
    <div class="academic-interests-header">
      <img routerLink="../" class="back-button" src="assets/utils/back.png" alt="go back" />
      <h3 class="academic-interests-title">{{ 'Academic-interests.academic-interests' | translate }}</h3>
    </div>
    <form class="form" [formGroup]="form" (ngSubmit)="submitData('submitForm')">
      <div class="academic-interest">
        <div class="form-container" *ngIf="universitiesOfColombia && abroadUniversities && citiesOfColombia">
          <app-page-three
            [form]="form"
            [universitiesOfColombia]="universitiesOfColombia"
            [abroadUniversities]="abroadUniversities"
            [citiesOfColombia]="citiesOfColombia"></app-page-three>
          <app-page-four [form]="form"></app-page-four>
        </div>
        <div class="separator"></div>
      </div>

      <button type="button" (click)="submitData('submitForm')" id="save-changes">
        {{ 'Academic-interests.save-changes' | translate }}
      </button>
    </form>
  </div>
</div>
