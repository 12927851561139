import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /** Constant that holds the base url for making backend requests */
  public baseUrl = environment.BASE_URL;
  public pasoActivoUser: any;
  /** Variable that holds the http headers */
  private headers: HttpHeaders;
  semestreActivo: any;

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId,
  private cookieService: CookieService
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
  }
  SetLocal(data){
    this.cookieService.set('formulario_test', JSON.stringify(data));
    const loc = this.cookieService.get('formulario_test');

  }
  getLocal(){
    const loc = this.cookieService.get('formulario_test');

    return loc
  }
  /**
   * Executes get instruction from the backend on the given API.
   * @param api api path
   */
  get({ api }: { api: string }): Observable<any> {

    return this.http.get(`${this.baseUrl}${api}`, { headers: this.headers });
  }
  setSemestre(id){
    localStorage.setItem('semestreActivo', id)
  }
  getSemestre(){
    return localStorage.getItem('semestreActivo');
  }
  getIntialData(p0: string, p1: { params: HttpParams }, { api }: { api: string }): Observable<any> {
    const params = p1.params;  // Aquí se toman los parámetros
    return this.http.get(`${this.baseUrl}${api}`, { headers: this.headers, params: params });
  }
  getCountries() {
    return new Promise((resolve, reject) => {
      try {
        this.http.get('https://restcountries.com/v3.1/all').subscribe((res: any) => {
          let response = (res as any[]).map((element) => {
            return { flags: element.flags, idd: element.idd, name: element.name };
          });
          response = response.sort(function (a, b) {
            return a.name.common === b.name.common ? 0 : a.name.common < b.name.common ? -1 : 1;
          });
          resolve(response);
        });
      } catch (error) {
        reject(error.toString());
      }
    });
  }

  /**
   * Executes the post instruction in the backend, on the given API
   * @param api api path
   * @param data data used for the post instruction
   */
  post({ api, data }: { api: string; data: any }): Observable<any> {

    return this.http.post(`${this.baseUrl}${api}`, data, {
      headers: this.headers,
    });
  }

  /**
   * Executes the post instruction in the backend, on the given API
   * @param api api path
   * @param data data used for the post instruction
   */
  async postPromise({ api, data }: { api: string; data: any }) {
    return new Promise((success, reject) => {
      this.http
        .post(`${this.baseUrl}${api}`, data, {
          headers: this.headers,
        })
        .subscribe(
          (data) => {
            success(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
   * Executes the put instruction in the backend, on the given API
   * @param api api path
   * @param data data used for the put operation
   */
  put({ api, data }: { api: string; data: any }): Observable<any> {
    return this.http.put(`${this.baseUrl}${api}`, data, {
      headers: this.headers,
    });
  }

  /**
   * Executes the delete instruction in the backend, on the given API
   * @param api api path
   */
  delete({ api }: { api: string }): Observable<any> {
    return this.http.delete(`${this.baseUrl}${api}`, {
      headers: this.headers,
    });
  }

  /**
   * Executes the get instruction in the backend but returns a promise instead of an observable
   * @param api api path
   */
  getAsPromise({ api }: { api: string }): Promise<any> {

    return this.http.get(`${this.baseUrl}${api}`, { headers: this.headers }).toPromise();
  }


}
