<div class="modal-container">
  <div class="modal-content">
    <h3 class="skill-title">{{ showMoreData.name }}</h3>

    <p class="skill-description" [innerHTML]="showMoreData.description | breakLines">
    
    </p>

    <!-- Display requirements if available -->
    <div *ngIf="showMoreData.requirements" class="requirements">
      <h4>Requisitos generales de admisión:</h4>
      <ul>
        <li *ngFor="let item of showMoreData.requirements">
          {{ item }}
        </li>
      </ul>
    </div>

    <!-- Video at the bottom -->
    <div *ngIf="showMoreData.video" class="video-container">
      <iframe
        class="responsive-video"
        [src]="getSafeVideoUrl(showMoreData.video)"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>
