<div class="question-container">
    <label class="question-title">{{ question }}</label>
  
    <!-- Single Selection (Radio Buttons) -->
    <ng-container *ngIf="!multiSelect">
      <div *ngFor="let option of options" class="option">
        <input 
          type="radio" 
          [id]="option.value" 
          [name]="question" 
          [value]="option.value" 
          [checked]="control.value === option.value" 
          (change)="selectOption(option.value)"
        />
        <label [for]="option.value" [matTooltip]="option.tooltip">{{ option.label }}</label>
      </div>
    </ng-container>
  
    <!-- Multi Selection (Checkboxes) -->
    <ng-container *ngIf="multiSelect">
      <div *ngFor="let option of options" class="option">
        <input 
          type="checkbox" 
          [id]="option.value" 
          [checked]="isSelected(option.value)" 
          (change)="toggleSelection(option.value)"
        />
        <label [for]="option.value">{{ option.label }}</label>
      </div>
    </ng-container>
  
    <!-- Error Message -->
    <p class="error-message" *ngIf="control.invalid && control.touched">
      {{ getErrorMessage() }}
    </p>
  </div>
  