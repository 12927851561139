<ng-multiselect-dropdown
  [data]="data"
  [placeholder]="placeholder"
  class="school-dashboard-dropdown"
  [settings]="dropdownSettings"
  [(ngModel)]="selectedItems"
  (onSelect)="onSelect()"
  (onDeSelect)="onDeselect()"
  >
  
</ng-multiselect-dropdown>