export const TABLE_ROWS = [
    { value: '7', label: 'Grado 7°' },
    { value: '8', label: 'Grado 8°' },
    { value: '9', label: 'Grado 9°' },
    { value: '10', label: 'Grado 10°' },
    { value: '11', label: 'Grado 11°' },
    { value: '12', label: 'Grado 12°' }
];
export const TABLE_COLUMNS = [
    {
        value: 'not_available',
        label: 'No Disponible',
        toolTip: {
            title: 'Sin información',
            text: ['No aplica para este caso']
        }
    },
    {
        value: 'insufficient',
        label: 'Insuficiente',
        toolTip: {
            title: 'Rangos de notas',
            text: ['Escala 0–5 (0.0 – 1.9)', 'Escala 0-10 (0 – 3.8)', 'Escala GPA 4.0 (0.0 – 1.59)']
        }
    },
    {
        value: 'regular',
        label: 'Regular',
        toolTip: {
            title: 'Rangos de notas',
            text: ['Escala 0–5 (2.0 – 2.9)', 'Escala 0-10 (4.0 – 5.8)', 'Escala GPA 4.0 (1.6 – 2.39)']
        }
    },
    {
        value: 'acceptable',
        label: 'Aceptable',
        toolTip: {
            title: 'Rangos de notas',
            text: ['Escala 0–5 (3.0 – 3.4)', 'Escala 0-10 (6.0 – 6.8)', 'Escala GPA 4.0 (2.4 – 2.79)']
        }
    },
    {
        value: 'good',
        label: 'Bueno',
        toolTip: {
            title: 'Rangos de notas',
            text: ['Escala 0–5 (3.5 – 3.9)', 'Escala 0-10 (7.0 – 7.8)', 'Escala GPA 4.0 (2.8 – 3.19)']
        }
    },
    {
        value: 'very_good',
        label: 'Muy Bueno',
        toolTip: {
            title: 'Rangos de notas',
            text: ['Escala 0–5 (4.0 – 4.4)', 'Escala 0-10 (8.0 – 8.9)', 'Escala GPA 4.0 (3.2 – 3.59)']
        }
    },
    {
        value: 'excellent',
        label: 'Excelente',
        toolTip: {
            title: 'Rangos de notas',
            text: ['Escala 0–5 (4.5 – 5.0)', 'Escala 0-10 (9.0 – 10.0)', 'Escala GPA 4.0 (3.6 – 4.0)']
        }
    }
];
