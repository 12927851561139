import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';
import { formatDate } from '@angular/common';
import { SimulationChartComponentViewModel } from '../../simulationChartViewModel/simulationChartViewModel.component';
import { InitialFormComponent } from '../../initialForm/components/initial-form/initial-form.component';

@Component({
  selector: 'app-principal-menu',
  templateUrl: './principal-menu.component.html',
  styleUrls: ['./principal-menu.component.scss'],
})
export class PrincipalMenuComponent implements OnInit {
  router: any;
  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private dialog: MatDialog,
    private apiService: ApiService
  ) {}

  actived: string;
  isRecruitment: boolean;
  isPremiumUser: boolean;
  isActiveMenuMobile: boolean;
  isColegio: boolean;
  isStudent: boolean;
  showMisExperiencias: boolean = false;
  userId: any;
  isConoceteVisible: boolean = false;
  searchTerm: string = '';
  filteredExperiences: any[] = [];
  isopenSimulationChartVisible: boolean = false;
  showDashboard: boolean = false;
  showSimulate: boolean  = false;
  showExplore: boolean = false;
  experiences: any;
  user = JSON.parse(localStorage.getItem('user'));
  showMatch: boolean = false;
  showTest: boolean = false;
  studentId: any;
  showProgression: boolean = false;
  readonly plannedExperiences = 'api/experiences/user/';
  selectedSkillsOption: any;
  showEnglishTest: boolean = false;
  selectedCategoryOption: any;
  filteredExperiencesByUser: any;
  isSimulationChartSkillsVisible: boolean = false;
  dataCompleted: boolean = false;
  languageLevel: any;
  isPublished: string = localStorage.getItem('isPublished');

  @Input() displayMenu = false;
  @Input() escapeMenu = false;
  @Output() newEventEmiter = new EventEmitter<boolean>();
  @Output() isActiveMenuMobileEmitter = new EventEmitter<boolean>();
  @ViewChild('boxSidebarMenu') boxSidebarMenu: ElementRef;

  menuOptions: any = {
    match: false,
    dashboard: false,
    cv: false,
    search: false,
    favourites: false,
    applications: false,
    documents: false,
    blog: false,
    marketplace: false,
    testIA: false,
    englishTest: false,
    institutionType: false,
    coverLetter: false,
    experiencesExample: false,
    exploreSkills: false,
    experiences: false,
    experiencesPlanner: false,
    initialResults: false,
    progressionChart: false,
    home: false,
    englishResults: false,
    SimulationChartSkills: false,
    formUconnections: false,
    Contries: false,
    UniversityCarrers: false,
  };
  
  ngOnInit(): void {
    this.initializeOptions();
    this.subscribeToQueryParams();
    this.subscribeToRouteParams();
    this.subscribeToUserChanges();
    this.setupInitialChecks();
   
  }
  
  initializeOptions(): void {
    //if (this.isPublished !== 'true') {
      //this.activeOption('formUconnections');
      //this.isopenSimulationChartVisible = true;
      //this.openSimulationChart();
    //} else {
      //this.activeOption('documents');
      //this.isopenSimulationChartVisible = false;
    //}
    this.activeOption('home');
    this.languageLevel = localStorage.getItem('languageLevel');
  }
  
  subscribeToQueryParams(): void {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['skill']) {
        this.activeOption('experiencesExample');
        this.showExplore = true;
      }
      if (queryParams['fromUpdate']) {
        this.toggleProgression();
        this.activeOption('dashboard');
      }
      if (queryParams['fromRegister']) {
        this.toggleProgression();
        this.activeOption('experiences');
      }
      if (queryParams['fromPlanner']) {
        this.toggleProgression();
        this.activeOption('experiencesPlanner');
      }
    });
  }
  
  subscribeToRouteParams(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.studentId = params.get('id');
    });
  }
  
  subscribeToUserChanges(): void {
    this.userService.currentUser$.subscribe((user) => {
      if (user) {
        this.checkProfileType(user);
      }
    });
  }
  
  setupInitialChecks(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    this.userId = user?._id;
  
    this.checkUrl();
    this.getRecordedExperiences();
    
    this.authService.isPremiumUser$.subscribe(
      (result) => (this.isPremiumUser = result)
    );
    
    this.activatedRoute.url.subscribe((url) => this.checkUrl(url[0]?.path));
    this.toggleConocete();
    
    this.router = ['student-home'];
  }
  
  activeOption(option: string): void {
    Object.keys(this.menuOptions).forEach((key) => {
      this.menuOptions[key] = key === option;
    });

  
    this.updateVisibility(option);
    this.actived = this.menuOptions[option] ? option : '';
  }
  
  updateVisibility(option: string): void {
    if (!['experiencesPlanner', 'experiences', 'SimulationChartSkills', 'experiencesExample'].includes(option)) {
      this.showMisExperiencias = false;
    }
    if (!['formUconnections'].includes(option)) {
      this.isConoceteVisible = false;
    }
    if (!['testIA', 'initialResults'].includes(option)) {
      this.showTest = false;
    }
    if (!['englishTest', 'englishResults'].includes(option)) {
      this.showEnglishTest = false;
    }
    if (!['progressionChart', 'dashboard'].includes(option)) {
      this.showProgression = false;
    }
    if (!['testIA', 'initialResults', 'englishTest', 'englishResults'].includes(option)) {
      this.showDashboard = false;
    }
    if (!['experiencesPlanner', 'SimulationChartSkills' ].includes(option)) {
      this.showSimulate = false;
    }
    if (!['institutionType',  'exploreSkills',  'Contries', 'UniversityCarrers'].includes(option)) {
      this.showExplore = false;
    }
    if (!['coverLetter', 'documents', 'cv'].includes(option)) {
      this.showMatch = false;
    }
  }
  
  @HostListener('click')
  clickout(): void {
    this.displayMenu = false;
  }
  
  onGoToProfile(): void {
    this.navigationService.navigateTo({ path: 'profile' });
  }
  
  checkUrl(urlActived?: string): void {
    const url = urlActived || document.location.pathname;
    const urlMappings: any = {
      '/web/home': 'search',
      '/web/program/search': 'search',
      '/web/preTest': 'match',
      '/web/wishlist': 'favourites',
      '/web/skill-dashboard': 'dashboard',
      '/web/profile': 'documents',
      '/web/my-applications': 'applications',
      '/web/blog': 'blog',
      '/web/curriculum': 'cv',
      'web/student-home': 'home',
      '/web/letter': 'cv',
      '/web/dashboard-school': 'dashboard-school',
      '/web/students': 'students',
      [`/web/experience-record/${this.userId}`]: 'experiences',
      '/web/leaderTest': 'testIA',
      'web/english-test': 'englishTest',
      'web/universityType': 'institutionType',
      'web/explore-skills': 'exploreSkills',
      'web/coverLetter': 'coverLetter',
      'web/experience-example': 'experiencesExample',
      'web/experience-planner': 'experiencesPlanner',
      'web/current-test': 'initialResults',
      'web/progression': 'progressionChart',
      'web/vocational-test': 'vocationalTest',
      'web/university-careers': 'UniversityCarrers',
      'web/countries': 'Contries',
      [`english-test/${this.languageLevel}`]: 'englishResults',
    };
  
    this.actived = urlMappings[url] || (this.isopenSimulationChartVisible ? 'formUconnections' : this.isSimulationChartSkillsVisible ? 'SimulationChartSkills' : this.actived);
  }

  checkProfileType(user): void {
    this.isRecruitment = user?.tipo === 'Recruitment';
    this.isColegio = user?.tipo === 'Colegio';
    this.isStudent = user?.tipo === 'Estudiante';
  }
  async openSimulationChart(): Promise<void> {
    this.activeOption('formUconnections');
    this.isopenSimulationChartVisible = true;
    const dialogRef = this.dialog.open(InitialFormComponent, {
      width: '50vw',
      height: '95vh',
      disableClose: true,
    });

    dialogRef.backdropClick().subscribe(() => {
        dialogRef.close();  
    });
  }

  async openSimulationChartSkills(simulationChartData: any): Promise<void> {
    this.isSimulationChartSkillsVisible = true;
    const dialogRef = this.dialog.open(SimulationChartComponentViewModel, {
      width: '50vw',
      height: '95vh',
      data: { experienceData: simulationChartData },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.activeOption('experiencesPlanner');
      this.getRecordedExperiences();
    });
  }


  onToogleMenuMobile(): void {
    this.isActiveMenuMobile = !this.isActiveMenuMobile;
    this.isActiveMenuMobileEmitter.emit(this.isActiveMenuMobile);
  }

  getRecordedExperiences() {
      Swal.fire({
        title: 'Cargando...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      try {
        this.apiService.get({ api: `${this.plannedExperiences}${this.user?._id || this.studentId}` }).subscribe(
          (response) => {
            this.filteredExperiencesByUser = response;
  
            if (this.filteredExperiencesByUser) {
              this.experiences = this.filteredExperiencesByUser.map((experience) => {
                return {
                  ...experience,
                  formattedStartDate: formatDate(experience.startDate, 'dd/MM/yyyy', 'en-US'),
                  formattedEndDate: formatDate(experience.endDate, 'dd/MM/yyyy', 'en-US'),
                };
              });
  
              console.log('Experiences:', this.experiences);
              this.filterExperiences();
              
              Swal.close();
            }
          },
          (error) => {
            Swal.close();
            this.activeOption('documents');
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrió un error cargandos las experiencias registradas ',
            });
  
            console.error('Error fetching experiences:', error);
          }
        );
      } catch (error) {
        Swal.close();
        this.activeOption('documents');
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An unexpected error occurred!',
        });
  
        console.error('Unexpected error:', error);
      }
    }

    sortExperiencesBySkillsScore() {
      this.filteredExperiences.sort((a, b) => {
        return b.skillsScore - a.skillsScore;
      });
    }

    filterExperiences() {
      const selectedCategoriesLength = this.selectedCategoryOption?.length ?? 0;
      const selectedSkillsLength = this.selectedSkillsOption?.length ?? 0;
      const searchTerm = this.searchTerm.toLowerCase();
  
      if (selectedCategoriesLength === 0 && selectedSkillsLength === 0 && !searchTerm) {
        this.filteredExperiences = this.experiences;
      } else {
        this.filteredExperiences = this.experiences.filter((experience) => {
          const categoryMatch =
            selectedCategoriesLength === 0 ||
            (experience.experienceCategory &&
              experience.experienceCategory.some((category) => this.selectedCategoryOption.includes(category)));
  
          const skillMatch =
            selectedSkillsLength === 0 ||
            this.selectedSkillsOption.some((skill) => {
              const skillsArray = [experience.principalSkill, experience.secondarySkill];
              return skillsArray.includes(skill);
            });
  
          const searchTermMatch = !searchTerm || experience.experienceName.toLowerCase().includes(searchTerm);
  
          return categoryMatch && skillMatch && searchTermMatch;
        });
      }
      this.sortExperiencesBySkillsScore();
    }

  toggleConocete(): void {
    this.isConoceteVisible = !this.isConoceteVisible;
  }
  toggleDashboard(): void {
    this.showDashboard = !this.showDashboard;
  }
  toggleExperiencePlanner(): void {
    this.showSimulate = !this.showSimulate;
  }
  toggleInitialTest(): void {
    this.showTest = !this.showTest;
  }
  toggleExplore(): void {
    this.showExplore = !this.showExplore;
  }
  toggleMisExperiencias(): void {
    this.showMisExperiencias = !this.showMisExperiencias;
  }
  toggleMatch(): void {
    this.showMatch = !this.showMatch;
  }
  toggleProgression(): void {
    this.showProgression = !this.showProgression;
  }
  toggleEnglishTest(): void {
    this.showEnglishTest = !this.showEnglishTest;
  }
}
