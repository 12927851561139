import { Component, Input, OnInit } from '@angular/core';
import { StepperService } from '../../../services/stepper.service';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { GradeLevel, GradeLevelsResponse } from '../../initial-form/initialForm.models';

@Component({
  selector: 'app-step-academic-background',
  templateUrl: './step-academic-background.component.html',
  styleUrls: ['./step-academic-background.component.scss']
})
export class StepAcademicBackgroundComponent implements OnInit {

  @Input() form: FormGroup;
  schoolGrades: any[] = [];
  selectedGradeLevels: string[] = [];
  isLoading = false;

  constructor(private stepperService: StepperService) { }

  ngOnInit(): void {
    console.log('StepAcademicBackgroundComponent', this.form);
    this.loadSchoolData();
  }

  private loadSchoolData(): void {
    const schoolName = this.form.get('school')?.value;
    if (!schoolName) return;

    this.isLoading = true;
    this.stepperService.loadSchoolGrades(schoolName).subscribe(
      (res: GradeLevelsResponse) => {
        if (res.gradesLevels) {
          this.schoolGrades = res.gradesLevels.map((item: GradeLevel) => ({
            value: item.grade[0].toString(), // Example: 10, 11, 12
            label: `Grado ${item.grade[0]}`,
            levels: item.levels.map(levelArray => levelArray[0]), // Example: ["A", "B"]
          }));
          console.log('schoolGrades', this.schoolGrades);

          this.onGradeChange(this.form.get('currentGrade')?.value || '', true);
        }
      },
      (error) => {
        console.error('Error fetching school grades:', error);
        Swal.fire('Error', 'No se encontraron los grados del colegio.', 'error');
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  onGradeChange(gradeValue: string, firstChange: boolean = false): void {
    const selectedGrade = this.schoolGrades.find(grade => grade.value.toString() == gradeValue);
    this.selectedGradeLevels = selectedGrade ? selectedGrade.levels.map(level => ({
      label: level,
      value: level
    })) : [];
  
    if (!firstChange) {
      const gradeLevelControl = this.form.get('gradeLevel');
      gradeLevelControl?.setValue('');
      
      // ✅ Mark gradeLevel as touched to trigger validation messages
      gradeLevelControl?.markAsTouched();
      gradeLevelControl?.updateValueAndValidity();
    }
  }
  

}
