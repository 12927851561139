<app-inital-form-card-selection [control]="form.get('universityTypePreference')" [items]="universityTypesOptions">

  <ng-template let-item let-selected="selected">

    <div class="card" [class.selected]="selected">
      <span class="card__title">{{ item.name }}</span>
      <p class="card__description">{{ descriptions[item.name] }}</p>
    </div>

  </ng-template>
  
</app-inital-form-card-selection>