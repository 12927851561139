import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { NavigationService } from '../../services/navigation.service';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-legal-representative',
  templateUrl: './legal-representative.component.html',
  styleUrls: ['./legal-representative.component.scss'],
})
export class LegalRepresentativeComponent implements OnInit {
  /** CONSTANTS */
  /** Variable that holds the api for the legal guardian */
  readonly USER_API = 'api/acudiente/';
  readonly REMOVE_ACUDIENTE_BY_STUDENT = 'api/acudiente-remove-student/';
  /** Variable that holds the api for the legal guardian by student */
  readonly USER_BY_STUDENT_API = 'api/acudiente/estudiante/';
  readonly getInitialForm = 'api/getInitialDataFormById/';
  readonly updateInitialForm = 'api/updateInitialDataForm/';
  /** Form array that will contain the list of legal representatives */
  legalRepresentatives: FormArray;
  /** variable that holds the current user id */
  id: string;
  /** Variable that shows if the information is being edited or created */
  editing = false;
  /** variable that holds the id of the data that is being deleted If the component is currently editing */
  dataId: string;
  /** Variable that signals if the component is currently loading */
  loading = false;
  initialForm: any;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private navigationService: NavigationService,
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService,
    private ngxSpinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.legalRepresentatives = this.fb.array([]);
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      this.getLegalrepresentative();
    }
  }
  getDataForm() {
    const url = `${this.getInitialForm}${this.id}`;

    this.apiService.get({ api: url }).subscribe(
      (response) => {},
      (error) => {
        console.error('Error fetching initial data:', error);
      }
    );
  }
  /**
   * Adds a new contact to the legal representatives form array
   */
  onAddContact(): void {
    const contact = this.fb.group({
      nombre: '',
      apellido: '',
      parentesco: '',
      telefono: '',
      correo: '',
    });
    this.legalRepresentatives.push(contact);
  }

  /**
   * Removes an entry from the legal representative array
   * @param param0
   */
  onRemoveEntry({ index }: { index: number }) {
    this.legalRepresentatives.removeAt(index);
  }

  /**
   * Retrieves the information of the legal represantatives from the beckend
   */
  getLegalrepresentative(): void {
    const url = `${this.getInitialForm}${this.id}`;
    this.ngxSpinner.show();
    this.apiService.get({ api: url }).subscribe(
      (response) => {
        this.initialForm = response;

        if (response === null) {
          this.editing = false;
          this.onAddContact();
        } else {
          // Setup the form
          this.editing = true;
          this.dataId = response._id;
          this.legalRepresentatives.push(
            this.fb.group({
              nombre: response.guardianName,
              parentesco: response.relationship,
              telefono: response.guardianPhone,
              correo: response.guardianEmail,
            })
          );
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Legal-representative.load-error'),
        });
        this.ngxSpinner.hide();
      },
      (complete?) => {
        this.ngxSpinner.hide();
      }
    );
  }

  /** Handles the form submission */
  onSubmitData(): void {
    this.loading = true;

    // Validar que haya datos en legalRepresentatives y en el primer elemento.
    if (this.legalRepresentatives.value && this.legalRepresentatives.value.length > 0) {
      const { nombre, correo, telefono, parentesco } = this.legalRepresentatives.value[0];

      // Asignar los valores correspondientes a initialForm
      this.initialForm.guardianName = nombre;
      this.initialForm.guardianEmail = correo;
      this.initialForm.guardianPhone = telefono;
      this.initialForm.relationship = parentesco;

      const url = `${this.updateInitialForm}${this.initialForm._id}`;

      // Enviar los datos actualizados al backend
      this.apiService.put({ api: url, data: this.initialForm }).subscribe(
        (response) => {
          if (response.success) {
            // Mostrar mensaje de éxito
            this.alertService.showSuccess({
              msg: 'Se han actualizado los datos correctamente',
            });
            // Navegar al perfil después de la actualización exitosa
            this.navigationService.navigateTo({ path: 'profile' });
          } else {
            // Manejar respuesta sin éxito
            this.alertService.showError({
              msg: 'Hubo error actualizando los datos',
            });
          }
        },
        (err) => {
          // Manejar errores de la llamada API
          console.error('Error al actualizar los datos:', err);
          this.alertService.showError({
            msg: 'Hubo error actualizando los datos',
          });
        },
        () => {
          // Desactivar el estado de carga después de la respuesta
          this.loading = false;
        }
      );
    } else {
      // Manejar el caso en que no haya datos en legalRepresentatives
      this.loading = false;
      this.alertService.showError({
        msg: 'No se encontraron datos de representantes legales',
      });
    }
  }
}
