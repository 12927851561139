import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Country } from '../countries.models';

const COUNTRIES_STUDENT_ENDPOINT = 'api/pais/student/favorites';
@Component({
  selector: 'app-countries-list',
  templateUrl: './countries-list.component.html',
  styleUrls: ['./countries-list.component.scss']
})
export class CountriesListComponent implements OnInit {
  countries: Country[] = [];
  isLoading = false;

  constructor(private readonly apiService: ApiService) { }

  ngOnInit(): void {
    this.loadCountries();
  }

  loadCountries() {
    this.isLoading = true;
    this.apiService.get({ api: COUNTRIES_STUDENT_ENDPOINT }).subscribe(
      (response: Country[]) => {
        this.countries = response;
      },
      (error) => {
        console.error('Error fetching countries', error);
      },
      () => {
        this.isLoading = false
      }
    );
  }

  onFavoriteToggled(country: Country) {
    const index = this.countries.findIndex(({ _id }) => _id === country._id);
    this.countries[index] = country;

    this.sortCountriesByFavorite();
  }

  sortCountriesByFavorite() {
    this.countries.sort((a, b) => Number(b.isFavorite) - Number(a.isFavorite));
  }

}
