import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/public/services/alert.service';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/public/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/public/services/auth.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personalData.html',
  styleUrls: ['./personalData.scss'],
})
export class PersonalData {
  @Output() nextButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() previousButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() personalDataSubmitted: EventEmitter<any> = new EventEmitter<any>();
  public discipline: string = '';
  public optionalDiscipline: string = '';
  public academicLevel: string = '';
  public age: string = '';
  public school: string = '';
  public gender: string = '';
  public disciplinesList: any[] = [];
  private disciplineMap: { [key: string]: string } = {
    'Arts, Design and Architecture': 'Artes, Diseño y Arquitectura',
    'Business and Management': 'Negocios y Gestión',
    'Computer Sciences and IT': 'Ciencias Computacionales y TI',
    'Engineering': 'Ingeniería',
    'Sciences and Mathematics': 'Ciencias y Matemáticas',
    'Social Sciences and Humanities': 'Ciencias Sociales y Humanidades',
    'Education and Sport': 'Educación y Deporte',
    'Community Services': 'Servicios Comunitarios',
    'Environmental Studies and Earth Sciences': 'Estudios Ambientales y Ciencias de la Tierra',
    'Health': 'Salud',
    'Journalism and Media': 'Periodismo y Medios',
    'Law and Politic Sciences': 'Derecho y Ciencias Políticas',
    'Languages': 'Idiomas',
    'Tourism and Hospitality': 'Turismo y Hospitalidad',
    'Other': 'Otros',
  };
  cookieValue: string;
  userID: any;
  optionSelected: boolean = false;
  constructor(private alertService: AlertService, private apiService: ApiService, private authService: AuthService) {
    this.disciplinesList = [
      {
        value: 'Artes, Diseño y Arquitectura',
        name: 'Home.arts-architecture',
        text: '',
      },
      {
        value: 'Negocios y Gestión',
        name: 'Home.business',
        text: '',
      },
      {
        value: 'Ciencias Computacionales y TI',
        name: 'Home.computer-science',
        text: '',
      },
      {
        value: 'Ingeniería',
        name: 'Home.engineering',
        text: '',
      },
      {
        value: 'Ciencias y Matemáticas',
        name: 'Home.sciences-math',
        text: '',
      },
      {
        value: 'Ciencias Sociales y Humanidades',
        name: 'Home.social-sciences',
        text: '',
      },
      {
        value: 'Educación y Deporte',
        name: 'Home.education',
        text: '',
      },
      {
        value: 'Servicios Comunitarios',
        name: 'Home.community',
        text: '',
      },
      {
        value: 'Estudios Ambientales y Ciencias de la Tierra',
        name: 'Home.env-studies',
        text: '',
      },
      {
        value: 'Salud',
        name: 'Home.health',
        text: '',
      },
      {
        value: 'Periodismo y Medios',
        name: 'Home.journalism',
        text: '',
      },
      {
        value: 'Derecho y Ciencias Políticas',
        name: 'Home.law-politics',
        text: '',
      },
      {
        value: 'Idiomas',
        name: 'Home.languages',
        text: '',
      },
      {
        value: 'Turismo y Hospitalidad',
        name: 'Home.tourism-hospitality',
        text: '',
      },
      {
        value: 'Otros',
        name: 'Home.other',
        text: '',
      },
    ];
  }

  ngOnInit() {
    try {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      this.discipline = user.facultadInteres || '';

      if (this.disciplineMap[this.discipline]) {
        this.discipline = this.disciplineMap[this.discipline];
      }
    } catch (error) {
      console.error('Error al obtener la información del usuario:', error);
      this.discipline = '';
    }
  }

  submitPersonalData() {
    if (this.discipline) {
      const personalDataObject = {
        gender: this.gender,
        school: this.school,
        age: this.age,
        academicLevel: this.academicLevel,
        discipline: this.discipline,
        optionalDiscipline: this.optionalDiscipline,
      };
      this.personalDataSubmitted.emit(personalDataObject);
      this.optionSelected = true;
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Debes llenar todos los campos obligatorios antes de continuar.',
        icon: 'error',
      });
    }
  }

  emitNextButtonClick() {
    this.submitPersonalData();
    if (this.discipline) {
      this.nextButtonClick.emit();
    }
  }

  emitPreviousButtonClick() {
    this.previousButtonClick.emit();
  }
}
