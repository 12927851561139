import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationService } from '../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-school-comparison-dashboard',
  templateUrl: './school-comparison-dashboard.component.html',
  styleUrls: ['./school-comparison-dashboard.component.scss'],
})
export class SchoolComparisonDashboard implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  loggedSchool: any;
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';
  readonly GET_STUDENTS = 'api/skillTestByColegio/student-list';
  readonly GET_SCHOOLS = 'api/colegio';
  readonly GET_CLUSTERED_SCHOOLS = 'api/clusteredSchools';
  public chartOptions: any;
  public averageChartoptions: any;
  public chartProgrammsOptions: any;
  public testChart: any;
  public chartLanguagesOptions: any;
  public skillsLevelChart: any;
  languagesChart: any;
  studentsPerPage = 25;
  searchText: string = '';
  testPresentedFilter: string = '';
  nombreColegio: '';
  dataSource: any;
  studentData: any;
  studentPerPage: any;
  pageLimit: any;
  students: any[] = [];
  queryStudents: any = {};
  aprendizajePermanenteLevel: any;
  globalMindLevel: any;
  inteligenciaEmocionalLevel: any;
  creatividadInnovacionLevel: any;
  impactoResponsabilidadLevel: any;
  liderazgoLevel: any;
  orientacionResultadosLevel: any;
  averageLevel: any;
  selectedGrade: string = '';
  schoolData: any;
  studentsData: any;
  schoolName = '';
  sortBy: string = '';
  sortOrder: string = '';
  totalStudents: any;
  generalScore: any;
  sizePagination = [];
  skills: any;
  studentFilter: any;
  listTable: any = [];
  seriesLevel: any;
  skillAverages: any;
  selectedChartData: any;
  currentPageHorizontal: any;
  skillsChartOptions: any;
  englishlLevelChartOptions: any;
  skillsLevelsChartOptions: any;
  selectedSkill: any;
  selectedLevelsSkill: any;
  skillsChartsOptions: any;
  schoolGroups: any;
  filteredSchoolGroups: any;
  selectedOption: any;
  groupedSchool: any[] = [];
  selectedFilterValue: any;
  skillsOptions = [
    { value: 'OverallAverage', label: 'Promedio General' },
    { value: 'AprendizajePermanente', label: 'Aprendizaje Permanente' },
    { value: 'CreatividadInovacion', label: 'Creatividad e Innovación' },
    { value: 'GlobalMind', label: 'Global Mind' },
    { value: 'ImpactoResponsabilidad', label: 'Impacto y Responsabilidad' },
    { value: 'Liderazgo', label: 'Liderazgo' },
    { value: 'OrientacionResultados', label: 'Orientación a Resultados' },
  ];
  options = [
    { value: 'Todos', label: 'Todos' },
    { value: 'Colegios IB', label: 'Bilingue' },
    { value: 'Colegios NoIB', label: 'No bilingue' },
    { value: 'Quindío', label: 'Region' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Colegios IB Quindío', label: 'Bilingues de la misma region' },
    { value: 'Colegios calendario B', label: 'Calendario b' },
  ];
  schoolsData: any;
  allStudentData: any;
  themePalette = ['#4ccdb2', '#fab902', '#775dd0', '#3dc8e1', '#ea447c', '#2b96f3', '#Ee8c2c', '#Bbda86'];
  resultPaginate: any;
  isBilingual: boolean = true;
  querySchools: any;
  selectedTypeOfSchool: any;
  selectedCalendarType: any;
  selectedClassificationByGender: any;
  selectedIsBilingual: any;
  filteredSchools: any;
  studentTest: any;
  selectedSkillToFilter: string = '';
  averagesSkillsBySchool: any;
  clusteredSchools: any;
  groupedSkillCounts: any;
  selectedGroup: any;
  filteredSchoolGroupsNames: any;
  schoolGroups2: any;
  selectedSchools: any;
  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
   // this.loggedSchool = this.user.nombre;
   // this.currentPageHorizontal = 0;
   // this.selectedOption = 'Todos';
   // this.getColegio(this.currentPageHorizontal);
   // this.getClusteredSchools();
   // this.selectedSkill = 'OverallAverage';
   // this.selectedLevelsSkill = 'Liderazgo';
   // this.applyFilter();
   // this.languageSkill();
   // this.filterBySkill();
   // this.filterCategoriesSkill();
  }

  ngOnDestroy(): void {}

}
