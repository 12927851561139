import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UniversityCareer } from '../university-careers.models';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';

const UNIVERSITY_CAREERS_ADD_FAVORITE_ENDPOINT = 'api/universityCareers/user/favorites';
const UNIVERSITY_CAREERS_REMOVE_FAVORITE_ENDPOINT = 'api/universityCareers/user/favorites';
@Component({
  selector: 'app-university-careers-card',
  templateUrl: './university-careers-card.component.html',
  styleUrls: ['./university-careers-card.component.scss']
})
export class UniversityCareersCardComponent implements OnInit {
  @Input() universityCareer: UniversityCareer;
  @Output() favoriteToggled: EventEmitter<UniversityCareer> = new EventEmitter();
  isLoading = false;

  constructor(private readonly apiService: ApiService, private readonly alertService: AlertService) { }

  ngOnInit(): void {
  }

  toggleFavorite() {
    if (this.universityCareer.isFavorite) {
      this.deleteFavorite();
    } else {
      this.addFavorite()
    }
  }

  addFavorite() {
    this.isLoading = true;
    this.apiService.post({ api: `${UNIVERSITY_CAREERS_ADD_FAVORITE_ENDPOINT}/${this.universityCareer._id}`, data: {} })
      .subscribe(
        (response) => {
          this.alertService.showSuccess({ msg: `La carrera ${this.universityCareer.name} ha sido agregada a tus favoritas!` });

          this.universityCareer.isFavorite = true;
          this.favoriteToggled.emit(this.universityCareer);
        },
        (error) => {
          this.alertService.showError({ msg: 'Error al agregar la carrera a favoritos' });
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  deleteFavorite() {
    this.isLoading = true;
    this.apiService.delete({ api: `${UNIVERSITY_CAREERS_REMOVE_FAVORITE_ENDPOINT}/${this.universityCareer._id}` })
      .subscribe(
        (response) => {
          this.alertService.showSuccess({ msg: `La carrera ${this.universityCareer.name} ha sido eliminada de tus favoritas!` });
          this.universityCareer.isFavorite = false;
          this.favoriteToggled.emit(this.universityCareer);
        },
        (error) => {
          this.alertService.showError({ msg: 'Error al eliminar la carrera de favoritos' });
        },
        () => {
          this.isLoading = false;
        }
      );
  }
}
