import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-initial-form-question',
  templateUrl: './initial-form-question.component.html',
  styleUrls: ['./initial-form-question.component.scss']
})
export class InitialFormQuestionComponent {
  @Input() control: FormControl;
  @Input() question: string = '';
  @Input() options: { value: string; label: string; tooltip?:string}[] = [];
  @Input() multiSelect: boolean = false;
  @Input() maxSelections: number = 3;
  @Output() selectionChange: EventEmitter<string | string[]> = new EventEmitter();

  selectOption(value: string): void {
    this.control.setValue(value);
    this.control.markAsTouched();
    this.selectionChange.emit(this.control.value);
  }

  toggleSelection(value: string): void {
    let selectedValues: string[] = Array.isArray(this.control.value) ? this.control.value : [];
    selectedValues = selectedValues.filter(val => val !== '');

    if (selectedValues.includes(value)) {
      selectedValues = selectedValues.filter(item => item !== value);
    } else if (selectedValues.length < this.maxSelections) {
      selectedValues.push(value);
    }
    console.log('selectedValues', selectedValues);

    this.control.setValue(selectedValues.length > 0 ? selectedValues : []);
    this.control.markAsTouched();
    this.selectionChange.emit(this.control.value);
  }

  isSelected(value: string): boolean {
    return Array.isArray(this.control.value) ? this.control.value.includes(value) : false;
  }

  getErrorMessage(): string {
    return this.control.hasError('required') ? 'Este campo es obligatorio.' : '';
  }
}
