import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { S3Service } from '../services/s3.service';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { tap } from 'rxjs/operators';
import { InitialFormComponent } from '../initialForm/components/initial-form/initial-form.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  /** CONSTANTS */
  readonly TIPO_RECRUITMENT = 'Recruitment';
  /** API for the user data */
  readonly USER_API = 'api/oneUser/';
  /** Variable that holds the api for getting  the information of the legal representative by student*/
  readonly LEGAL_REPRESENTATIVE_API = 'api/acudiente/estudiante/';
  /** Variable that holds the api for getting  the information of the legal representative by student*/
  readonly USERS_BY_RECRUITMENT = 'api/user/recruitment/';
  /** Variable that holds the api for getting the academic history by student*/
  readonly ACADEMIC_HISTORY_API = 'api/academic-history/estudiante/';
  /** List of items of the menu */
  readonly menuItems = [
    {
      image: 'assets/profile/personal-information.png',
      title: 'Profile.personal-info-title',
      buttonText: 'Editar',
      link: './personal-info',
      alt: 'personal information',
    },
    {
      image: 'assets/profile/legal.png',
      title: 'Profile.legal-guardian-title',
      buttonText: 'Iniciar',
      link: './legal-representative',
      alt: 'legal',
    },
    // {
    //   image: 'assets/profile/travel.png',
    //   title: 'Profile.travel-history-title',
    //   buttonText: 'Iniciar',
    //   link: './travel-history',
    //   alt: 'travel',
    // },
    {
      image: 'assets/profile/interests.png',
      title: 'Profile.academic-interests-title',
      buttonText: 'Iniciar',
      link: './academic-interests',
      alt: 'interests',
    },
    // {
    //   image: 'assets/profile/budget.png',
    //   title: 'Profile.budget-title',
    //   buttonText: 'Iniciar',
    //   link: './my-budget',
    //   alt: 'budget',
    // },
    {
      image: 'assets/profile/languages.png',
      title: 'My-languages.language-information',
      buttonText: 'Iniciar',
      link: './my-languages',
      alt: 'languages',
    },
    // {
    //   image: 'assets/profile/academic.png',
    //   title: 'Profile.academic-history-title',
    //   buttonText: 'Iniciar',
    //   link: './academic-history/',
    //   alt: 'academic history',
    // },
    {
      image: 'assets/profile/academic.png',
      title: 'Profile.work-experience-title',
      buttonText: 'Iniciar',
      link: './work-experience/',
      alt: 'work experience',
    },
  ];

  /** Variable that holds the user data */
  userData = {
    name: '',
    image: '',
    lastName: '',
  };

  imageProfile: any;

  /** Selected student */
  student = '0';

  /** Array with current recruiter students */
  recruitmentStudents;

  /** boolean if is recruitment */
  isRecruitment = false;

  /** Variable that holds the current user id */
  id: string;

  /** Variable that holds the current user */
  loggeduser: any;

  /** Variable that holds the documents user */
  userDocuments: any;

  documentId: any;
  documentPassport: any;
  documentQualifications: any;
  documentLanguages: any;
  documentLetter: any;
  documentVisaLetter: any;
  documentPortfolio: any;
  documentOther: any;

  listOtherDocuments = [];
  lastIndexOtherDocuments: any;
  readonly getInitialForm = 'api/getInitialDataFormById/';
  user = JSON.parse(localStorage.getItem('user'));
  dataCompleted: boolean = false;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private ngxSpinner: NgxSpinnerService,
    private s3Service: S3Service,
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      localStorage.setItem('languageLevel', user.nivelIngles);
      this.listOtherDocuments = [];
      this.userDocuments = user.documentosAdicionales;
      for (const document of this.userDocuments) {
        switch (document.nombre) {
          case 'photo':
            this.imageProfile = document.archivo;

          case 'identification':
            this.documentId = document;
            break;

          case 'passport':
            this.documentPassport = document;
            break;

          case 'qualifications':
            this.documentQualifications = document;
            break;

          case 'languages':
            this.documentLanguages = document;
            break;

          case 'letter':
            this.documentLetter = document;
            break;

          case 'visaLetter':
            this.documentVisaLetter = document;
            break;

          case 'portfolio':
            this.documentPortfolio = document;
            break;

          default:
            if (document.nombre.includes('other')) {
              this.listOtherDocuments.push(document);
            }
            break;
        }
      }
      this.lastIndexOtherDocuments = this.listOtherDocuments.length;

      this.loggeduser = user;
      this.id = user._id;

      if (user.tipo === this.TIPO_RECRUITMENT) {
        this.isRecruitment = true;
        this.userData.name = user.nombre;
        this.userData.lastName = user.apellido;
        this.getRecruitmentStudents().subscribe((response) => {
          this.recruitmentStudents = response as any[];
        });
        const student = localStorage.getItem('student_profile');
        if (student) {
          this.student = student;
          this.onStudentChange({ id: student });
        }
      } else {
        const userRequest = this.getUserInfo({ id: this.id }).pipe(
          tap((response) => {
            localStorage.setItem('languageLevel', response.nivelIngles);
            console.log('response', response.nivelIngles);
          })
        );
        const legalRequest = this.getLegalrepresentative({ id: this.id });
        const academicHistoryRequest = this.getAcademicHistory({ id: this.id });
        this.ngxSpinner.show();
        forkJoin([userRequest, legalRequest, academicHistoryRequest]).subscribe(
          (response) => {
            this.ngxSpinner.hide();
            this.setUserInfo({ user: response[0] });
            this.setStepStatus({ user: response[0], legalRepresentative: response[1], academicHistory: response[2] });
          },
          (err) => {
            this.alertService.showError({
              msg: this.translate.instant('Profile.load-error'),
            });
            this.ngxSpinner.hide();
          }
        );
      }
    }
    const url = `${this.getInitialForm}${this.user._id}`;
    this.apiService.get({ api: url }).subscribe(
      (response) => {
        this.dataCompleted = response.isPublished;
        if (!this.dataCompleted) {
          this.openSimulationChart();
        }
      },
      (error) => {
        console.error('Error fetching initial data:', error);
      }
    );
  }

  /**
   * Retrieves recruitment partner students
   * @returns Observable with response from backend
   */
  getRecruitmentStudents(): Observable<any> {
    return this.apiService.get({ api: this.USERS_BY_RECRUITMENT + this.id });
  }

  /**
   * Retrieves user information from the backend
   * @param object with student id property
   */
  getUserInfo({ id }): Observable<any> {
    return this.apiService.get({ api: this.USER_API + id });
  }

  /**
   * Retrieves the legal representative information from the backend
   * @param object with student id property
   */
  getLegalrepresentative({ id }): Observable<any> {
    return this.apiService.get({ api: this.LEGAL_REPRESENTATIVE_API + id });
  }

  /**
   * Retrieves the academic history information from the backend
   * @param object with student id property
   */
  getAcademicHistory({ id }): Observable<any> {
    return this.apiService.get({ api: this.ACADEMIC_HISTORY_API + id });
  }
  /**
   * Sets up the user information to display it in the GUI
   * @param user
   */
  setUserInfo({ user }: { user: any }): void {
    this.userData.name = user.nombre;
    this.userData.lastName = user.apellido;
    let documentos = user.documentosAdicionales;
    if (documentos.length > 0) {
      for (const documento of documentos) {
        if (documento.nombre == 'photo') {
          this.userData.image = documento.archivo;
        } else {
          this.userData.image = 'assets/sidebar/profile.png';
        }
      }
    } else {
      this.userData.image = 'assets/sidebar/profile.png';
    }
  }

  /**
   * Handles on student change when is a recruiter
   * @param object with student id property
   */
  onStudentChange({ id }): void {
    const userRequest = this.getUserInfo({ id: this.student });
    const legalRequest = this.getLegalrepresentative({ id: this.student });
    const academicHistoryRequest = this.getAcademicHistory({ id: this.student });
    this.ngxSpinner.show();
    forkJoin([userRequest, legalRequest, academicHistoryRequest]).subscribe(
      (response) => {
        this.ngxSpinner.hide();
        this.setUserInfo({ user: response[0] });
        this.setStepStatus({ user: response[0], legalRepresentative: response[1], academicHistory: response[2] });
        localStorage.setItem('student_profile', this.student);
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Profile.load-error'),
        });
        this.ngxSpinner.hide();
      }
    );
  }

  /**
   * Uploads an image into S3
   * @param event image upload event
   */
  onChangeProfileImage({ event }: { event: any }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant('Profile.you-must-upload-1-file'));
    } else {
      this.ngxSpinner.show();
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      const file = event.target.files[0];
      const fileType = file.type;
      if (!validImageTypes.includes(fileType)) {
        alert(this.translate.instant('Profile.you-must-upload-1-file'));
        this.ngxSpinner.hide();
      } else {
        const key = `user/${this.id}/profile-image/`;
        // Callback that handles S3 response
        const callback = (err, data) => {
          if (err) {
            this.ngxSpinner.hide();
            this.alertService.showError({
              msg: this.translate.instant('Profile.upload-image-error'),
            });
          } else {
            let updateData;
            let documents = this.loggeduser.documentosAdicionales;
            if (documents.length > 0) {
              let existe = false;
              for (const document of documents) {
                if (document.nombre == 'photo') {
                  document.archivo = data.Location;
                  existe = true;
                }
              }
              if (existe == true) {
                updateData = {
                  documentosAdicionales: this.loggeduser.documentosAdicionales,
                };
              } else {
                this.loggeduser.documentosAdicionales.push({
                  nombre: 'photo',
                  archivo: data.Location,
                });
                updateData = {
                  documentosAdicionales: this.loggeduser.documentosAdicionales,
                };
              }
            } else {
              updateData = {
                documentosAdicionales: [
                  {
                    nombre: 'photo',
                    archivo: data.Location,
                  },
                ],
              };
              this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;
            }

            this.updateUserInfo({ data: updateData });
            this.ngxSpinner.hide();
          }
        };
        this.s3Service.uploadFile({ file, key, callback });
      }
    }
  }
  async openSimulationChart(): Promise<void> {
    const dialogRef = this.dialog.open(InitialFormComponent, {
      width: '65vw',
      height: '85vh',
      disableClose: true,
    });

    dialogRef.backdropClick().subscribe(() => {
        dialogRef.close();
      });
    
  }

  uploadDocumentFile(type, event) {
    if (event.target.files.length > 1) {
      alert(this.translate.instant('Profile.you-must-upload-1-file'));
    } else {
      this.ngxSpinner.show();
      const validImageTypes = ['application/pdf'];
      const file = event.target.files[0];

      if (!file) {
        return this.ngxSpinner.hide();
      }
      const fileType = file.type;
      if (!validImageTypes.includes(fileType)) {
        // invalid file type code goes here.
        alert('Porfavor cargue un documento pdf');
        this.ngxSpinner.hide();
      } else {
        const key = `user/${this.id}/${type}/`;
        // Callback that handles S3 response
        const callback = (err, data) => {
          if (err) {
            this.ngxSpinner.hide();
            this.alertService.showError({
              msg: this.translate.instant('Error al cargar el archivo'),
            });
          } else {
            // Assign the value to the corresponding component
            let updateData: any;
            if (type == 'passport') {
              if (this.loggeduser.documentosAdicionales.length > 0) {
                let existe = false;
                for (let document of this.loggeduser.documentosAdicionales) {
                  if (document.nombre == type) {
                    document.archivo = data.Location;
                    document.text = file.name;
                    existe = true;
                  }
                }

                if (existe == true) {
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                } else {
                  this.loggeduser.documentosAdicionales.push({
                    nombre: 'passport',
                    archivo: data.Location,
                    text: file.name,
                  });
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                }
              } else {
                updateData = {
                  documentosAdicionales: [
                    {
                      nombre: 'passport',
                      archivo: data.Location,
                      text: file.name,
                    },
                  ],
                };
                this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;
              }
            } else if (type == 'identification') {
              if (this.loggeduser.documentosAdicionales.length > 0) {
                let existe = false;
                for (let document of this.loggeduser.documentosAdicionales) {
                  if (document.nombre == type) {
                    document.archivo = data.Location;
                    document.text = file.name;
                    existe = true;
                  }
                }

                if (existe == true) {
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                } else {
                  this.loggeduser.documentosAdicionales.push({
                    nombre: 'identification',
                    archivo: data.Location,
                    text: file.name,
                  });
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                }
              } else {
                updateData = {
                  documentosAdicionales: [
                    {
                      nombre: 'identification',
                      archivo: data.Location,
                      text: file.name,
                    },
                  ],
                };
                this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;
              }
            } else if (type == 'qualifications') {
              if (this.loggeduser.documentosAdicionales.length > 0) {
                let existe = false;
                for (const document of this.loggeduser.documentosAdicionales) {
                  if (document.nombre == type) {
                    document.archivo = data.Location;
                    document.text = file.name;
                    existe = true;
                  }
                }

                if (existe == true) {
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                } else {
                  this.loggeduser.documentosAdicionales.push({
                    nombre: 'qualifications',
                    archivo: data.Location,
                    text: file.name,
                  });
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                }
              } else {
                updateData = {
                  documentosAdicionales: [
                    {
                      nombre: 'qualifications',
                      archivo: data.Location,
                      text: file.name,
                    },
                  ],
                };
                this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;
              }
            } else if (type == 'languages') {
              if (this.loggeduser.documentosAdicionales.length > 0) {
                let existe = false;
                for (const document of this.loggeduser.documentosAdicionales) {
                  if (document.nombre == type) {
                    document.archivo = data.Location;
                    document.text = file.name;
                    existe = true;
                  }
                }

                if (existe == true) {
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                } else {
                  this.loggeduser.documentosAdicionales.push({
                    nombre: 'languages',
                    archivo: data.Location,
                    text: file.name,
                  });
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                }
              } else {
                updateData = {
                  documentosAdicionales: [
                    {
                      nombre: 'languages',
                      archivo: data.Location,
                      text: file.name,
                    },
                  ],
                };
                this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;
              }
            } else if (type == 'letter') {
              if (this.loggeduser.documentosAdicionales.length > 0) {
                let existe = false;
                for (const document of this.loggeduser.documentosAdicionales) {
                  if (document.nombre == type) {
                    document.archivo = data.Location;
                    document.text = file.name;
                    existe = true;
                  }
                }

                if (existe == true) {
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                } else {
                  this.loggeduser.documentosAdicionales.push({
                    nombre: 'letter',
                    archivo: data.Location,
                    text: file.name,
                  });
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                }
              } else {
                updateData = {
                  documentosAdicionales: [
                    {
                      nombre: 'letter',
                      archivo: data.Location,
                      text: file.name,
                    },
                  ],
                };
                this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;
              }
            } else if (type == 'visaLetter') {
              if (this.loggeduser.documentosAdicionales.length > 0) {
                let existe = false;
                for (const document of this.loggeduser.documentosAdicionales) {
                  if (document.nombre == type) {
                    document.archivo = data.Location;
                    document.text = file.name;
                    existe = true;
                  }
                }

                if (existe == true) {
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                } else {
                  this.loggeduser.documentosAdicionales.push({
                    nombre: 'visaLetter',
                    archivo: data.Location,
                    text: file.name,
                  });
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                }
              } else {
                updateData = {
                  documentosAdicionales: [
                    {
                      nombre: 'visaLetter',
                      archivo: data.Location,
                      text: file.name,
                    },
                  ],
                };
                this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;
              }
            } else if (type == 'portfolio') {
              if (this.loggeduser.documentosAdicionales.length > 0) {
                let existe = false;
                for (const document of this.loggeduser.documentosAdicionales) {
                  if (document.nombre == type) {
                    document.archivo = data.Location;
                    document.text = file.name;
                    existe = true;
                  }
                }

                if (existe == true) {
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                } else {
                  this.loggeduser.documentosAdicionales.push({
                    nombre: 'portfolio',
                    archivo: data.Location,
                    text: file.name,
                  });
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                }
              } else {
                updateData = {
                  documentosAdicionales: [
                    {
                      nombre: 'portfolio',
                      archivo: data.Location,
                      text: file.name,
                    },
                  ],
                };
                this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;
              }
            } else if (type.includes('other')) {
              if (this.loggeduser.documentosAdicionales.length > 0) {
                let existe = false;
                for (const document of this.loggeduser.documentosAdicionales) {
                  if (document.nombre == type) {
                    document.archivo = data.Location;
                    document.text = file.name;
                    existe = true;
                  }
                }

                if (existe == true) {
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                } else {
                  this.loggeduser.documentosAdicionales.push({
                    nombre: type,
                    archivo: data.Location,
                    text: file.name,
                  });
                  updateData = {
                    documentosAdicionales: this.loggeduser.documentosAdicionales,
                  };
                }
              } else {
                updateData = {
                  documentosAdicionales: [
                    {
                      nombre: type,
                      archivo: data.Location,
                      text: file.name,
                    },
                  ],
                };
                this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;
              }
            }
            this.updateUserInfo({ data: updateData });
            this.ngxSpinner.hide();
          }
        };
        this.s3Service.uploadFile({ file, key, callback });
      }
    }
  }

  deleteDocumentFile(type) {
    let fileToDelete: any;
    this.ngxSpinner.show();
    switch (type) {
      case 'identification':
        fileToDelete = this.documentId;
        this.documentId = undefined;
        break;

      case 'passport':
        fileToDelete = this.documentPassport;
        this.documentPassport = undefined;
        break;

      case 'qualifications':
        fileToDelete = this.documentQualifications;
        this.documentQualifications = undefined;
        break;

      case 'languages':
        fileToDelete = this.documentLanguages;
        this.documentLanguages = undefined;
        break;

      case 'letter':
        fileToDelete = this.documentLetter;
        this.documentLetter = undefined;
        break;

      case 'visaLetter':
        fileToDelete = this.documentVisaLetter;
        this.documentVisaLetter = undefined;
        break;

      case 'portfolio':
        fileToDelete = this.documentPortfolio;
        this.documentPortfolio = undefined;
        break;

      default:
        if (type.includes('other')) {
          for (const document of this.listOtherDocuments) {
            if (document.nombre == type) fileToDelete = document;
          }
        }
        break;
    }
    this.ngxSpinner.hide();

    this.ngxSpinner.show();
    const key = `user/${this.id}/${type}/`;
    const callback = (err, data) => {
      if (err) {
        // If there is an error alert the user
        this.ngxSpinner.hide();
        this.alertService.showError({
          msg: this.translate.instant('Error al eliminar el archivo'),
        });
      } else {
        let updateData = { documentosAdicionales: [] };
        for (const document of this.loggeduser.documentosAdicionales) {
          if (document.nombre != fileToDelete.nombre) {
            updateData.documentosAdicionales.push(document);
          }
        }
        this.loggeduser.documentosAdicionales = updateData.documentosAdicionales;

        this.updateUserInfo({ data: updateData });
        this.ngxSpinner.hide();
      }
    };
    this.s3Service.deleteFileS3({ key, callback });
  }

  /**
   * Updates the user information in the backend
   */
  updateUserInfo({ data }: { data: any }): void {
    this.ngxSpinner.show();
    this.apiService.put({ api: this.USER_API + this.id, data }).subscribe(
      (response) => {
        if (response.success === false) {
          this.alertService.showError({
            msg: this.translate.instant('Profile.upload-image-error'),
          });
        } else {
          localStorage.setItem('user', JSON.stringify(this.loggeduser));

          this.ngOnInit();
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Profile.upload-image-error'),
        });
      },
      (complete?) => {
        this.ngxSpinner.hide();
      }
    );
  }

  /** Configures the status of the steps based on the user information  */
  setStepStatus({
    user,
    legalRepresentative,
    academicHistory,
  }: {
    user: any;
    legalRepresentative: any;
    academicHistory: any;
  }): void {
    if (
      user.primerNombre !== null &&
      user.primerNombre !== undefined &&
      user.primerNombre !== '' &&
      user.primerApellido !== null &&
      user.primerApellido !== undefined &&
      user.primerApellido !== '' &&
      user.email !== null &&
      user.email !== undefined &&
      user.email !== '' &&
      user.telefono !== null &&
      user.telefono !== undefined &&
      user.telefono !== '' &&
      user.ciudad !== null &&
      user.ciudad !== undefined &&
      user.ciudad !== '' &&
      user.pais !== null &&
      user.pais !== undefined &&
      user.pais !== ''
    ) {
      this.menuItems[0].buttonText = 'Realizado';
    } else {
      this.menuItems[0].buttonText = 'En progreso';
    }
    // setup the status of step 2
    if (legalRepresentative !== null) {
      if (legalRepresentative.acudientes.length > 0) {
        if (
          legalRepresentative.acudientes[0].nombre !== null &&
          legalRepresentative.acudientes[0].nombre !== undefined &&
          legalRepresentative.acudientes[0].nombre !== '' &&
          legalRepresentative.acudientes[0].apellido !== null &&
          legalRepresentative.acudientes[0].apellido !== undefined &&
          legalRepresentative.acudientes[0].apellido !== '' &&
          legalRepresentative.acudientes[0].parentesco !== null &&
          legalRepresentative.acudientes[0].parentesco !== undefined &&
          legalRepresentative.acudientes[0].parentesco !== '' &&
          legalRepresentative.acudientes[0].telefono !== null &&
          legalRepresentative.acudientes[0].telefono !== undefined &&
          legalRepresentative.acudientes[0].telefono !== '' &&
          legalRepresentative.acudientes[0].correo !== null &&
          legalRepresentative.acudientes[0].correo !== undefined &&
          legalRepresentative.acudientes[0].correo !== ''
        ) {
          this.menuItems[1].buttonText = 'Realizado';
        } else if (
          (legalRepresentative.acudientes[0].nombre === null ||
            legalRepresentative.acudientes[0].nombre === undefined ||
            legalRepresentative.acudientes[0].nombre === '') &&
          (legalRepresentative.acudientes[0].apellido === null ||
            legalRepresentative.acudientes[0].apellido === undefined ||
            legalRepresentative.acudientes[0].apellido === '') &&
          (legalRepresentative.acudientes[0].parentesco === null ||
            legalRepresentative.acudientes[0].parentesco === undefined ||
            legalRepresentative.acudientes[0].parentesco === '') &&
          (legalRepresentative.acudientes[0].telefono === null ||
            legalRepresentative.acudientes[0].telefono === undefined ||
            legalRepresentative.acudientes[0].telefono === '') &&
          (legalRepresentative.acudientes[0].correo === null ||
            legalRepresentative.acudientes[0].correo === undefined ||
            legalRepresentative.acudientes[0].correo === '')
        ) {
          this.menuItems[1].buttonText = 'Iniciar';
        } else {
          this.menuItems[1].buttonText = 'En progreso';
        }
      } else {
        this.menuItems[1].buttonText = 'Iniciar';
      }
    }

    // Setup status of step 3
    if (
      user.pasaporte !== null &&
      user.pasaporte !== undefined &&
      user.pasaporte !== '' &&
      user.paisPasaporte !== null &&
      user.paisPasaporte !== undefined &&
      user.paisPasaporte !== '' &&
      user.numeroPasaporte !== null &&
      user.numeroPasaporte !== undefined &&
      user.numeroPasaporte !== '' &&
      user.viajesUltimos3Anios &&
      user.viajesUltimos3Anios !== '' &&
      user.viajes3A5Anios &&
      user.viajes3A5Anios !== ''
    ) {
      this.menuItems[2].buttonText = 'Realizado';
    } else if (
      (user.pasaporte === null || user.pasaporte === undefined || user.pasaporte === '') &&
      (user.paisPasaporte === null || user.paisPasaporte === undefined || user.paisPasaporte === '') &&
      (user.numeroPasaporte === null || user.numeroPasaporte === undefined || user.numeroPasaporte === '') &&
      (user.viajesUltimos3Anios === null ||
        user.viajesUltimos3Anios === undefined ||
        user.viajesUltimos3Anios === '') &&
      (user.viajes3A5Anios === null || user.viajes3A5Anios === undefined || user.viajes3A5Anios === '')
    ) {
      this.menuItems[2].buttonText = 'Iniciar';
    } else {
      this.menuItems[2].buttonText = 'En progreso';
    }

    // setup the status of step 4
    if (user.interesesAcademicos.length > 0) {
      if (
        user.interesesAcademicos[0].disciplina !== null &&
        user.interesesAcademicos[0].disciplina !== '' &&
        user.interesesAcademicos[0].disciplina !== undefined &&
        user.interesesAcademicos[0].pais !== null &&
        user.interesesAcademicos[0].pais !== '' &&
        user.interesesAcademicos[0].pais !== undefined &&
        user.interesesAcademicos[0].nivelDeEstudios !== null &&
        user.interesesAcademicos[0].nivelDeEstudios !== '' &&
        user.interesesAcademicos[0].nivelDeEstudios !== undefined
      ) {
        this.menuItems[3].buttonText = 'Realizado';
      } else {
        this.menuItems[3].buttonText = 'En progreso';
      }
    } else {
      this.menuItems[3].buttonText = 'Iniciar';
    }

    // setup the status of step 5
    // if (
    //   user.presupuestoMaximo !== null &&
    //   user.presupuestoMaximo !== undefined &&
    //   user.presupuestoMinimo !== null &&
    //   user.presupuestoMinimo !== undefined &&
    //   user.tipoFinanciacion.length > 0
    // ) {
    //   this.menuItems[4].buttonText = 'Realizado';
    // } else if (
    //   (user.presupuestoMaximo === null || user.presupuestoMaximo === undefined) &&
    //   (user.presupuestoMinimo === null || user.presupuestoMinimo === undefined) &&
    //   user.tipoFinanciacion.length === 0
    // ) {
    //   this.menuItems[4].buttonText = 'Iniciar';
    // } else {
    //   this.menuItems[4].buttonText = 'En progreso';
    // }

    // setup the status of step 6
    if (user.idiomas.length > 0) {
      if (
        user.idiomas[0].nivel !== '' &&
        user.idiomas[0].nivel !== undefined &&
        user.idiomas[0].nivel !== null &&
        user.idiomas[0].nombre !== '' &&
        user.idiomas[0].nombre !== undefined &&
        user.idiomas[0].nombre !== null
      ) {
        this.menuItems[5].buttonText = 'Realizado';
      } else {
        this.menuItems[5].buttonText = 'En progreso';
      }
    } else {
      this.menuItems[5].buttonText = 'Iniciar';
    }

    // setup status of step 7
    // if (academicHistory !== null) {
    //   if (academicHistory.historial.length > 0) {
    //     if (
    //       academicHistory.historial[0].estudiosRealizados !== null &&
    //       academicHistory.historial[0].estudiosRealizados !== '' &&
    //       academicHistory.historial[0].estudiosRealizados !== undefined &&
    //       academicHistory.historial[0].tituloAlcanzado !== null &&
    //       academicHistory.historial[0].tituloAlcanzado !== '' &&
    //       academicHistory.historial[0].tituloAlcanzado !== undefined &&
    //       academicHistory.historial[0].fechaDeInicio !== null &&
    //       academicHistory.historial[0].fechaDeInicio !== '' &&
    //       academicHistory.historial[0].fechaDeInicio !== undefined &&
    //       academicHistory.historial[0].fechaDeFinalizacion !== null &&
    //       academicHistory.historial[0].fechaDeFinalizacion !== '' &&
    //       academicHistory.historial[0].fechaDeFinalizacion !== undefined &&
    //       academicHistory.historial[0].notaPromedio !== null &&
    //       academicHistory.historial[0].notaPromedio !== '' &&
    //       academicHistory.historial[0].notaPromedio !== undefined &&
    //       academicHistory.historial[0].certificadoDeNotas !== null &&
    //       academicHistory.historial[0].certificadoDeNotas !== '' &&
    //       academicHistory.historial[0].certificadoDeNotas !== undefined &&
    //       academicHistory.historial[0].diploma !== null &&
    //       academicHistory.historial[0].diploma !== '' &&
    //       academicHistory.historial[0].diploma !== undefined
    //     ) {
    //       this.menuItems[6].buttonText = 'Realizado';
    //     } else if (
    //       (academicHistory.historial[0].estudiosRealizados === null ||
    //         academicHistory.historial[0].estudiosRealizados === '' ||
    //         academicHistory.historial[0].estudiosRealizados === undefined) &&
    //       (academicHistory.historial[0].tituloAlcanzado === null ||
    //         academicHistory.historial[0].tituloAlcanzado === '' ||
    //         academicHistory.historial[0].tituloAlcanzado === undefined) &&
    //       (academicHistory.historial[0].fechaDeInicio === null ||
    //         academicHistory.historial[0].fechaDeInicio === '' ||
    //         academicHistory.historial[0].fechaDeInicio === undefined) &&
    //       (academicHistory.historial[0].fechaDeFinalizacion === null ||
    //         academicHistory.historial[0].fechaDeFinalizacion === '' ||
    //         academicHistory.historial[0].fechaDeFinalizacion === undefined) &&
    //       (academicHistory.historial[0].notaPromedio === null ||
    //         academicHistory.historial[0].notaPromedio === '' ||
    //         academicHistory.historial[0].notaPromedio === undefined) &&
    //       (academicHistory.historial[0].certificadoDeNotas === null ||
    //         academicHistory.historial[0].certificadoDeNotas === '' ||
    //         academicHistory.historial[0].certificadoDeNotas === undefined) &&
    //       (academicHistory.historial[0].diploma === null ||
    //         academicHistory.historial[0].diploma === '' ||
    //         academicHistory.historial[0].diploma === undefined)
    //     ) {
    //       this.menuItems[6].buttonText = 'Iniciar';
    //     } else {
    //       this.menuItems[6].buttonText = 'En progreso';
    //     }
    //   } else {
    //     this.menuItems[6].buttonText = 'Iniciar';
    //   }
    // }

    if (user.tieneExperiencia === false) {
      this.menuItems[7].buttonText = 'Realizado';
    } else if (user.tieneExperiencia === true && user.experiencias.length > 0) {
      if (
        user.experiencias[0].posicion &&
        user.experiencias[0].posicion !== '' &&
        user.experiencias[0].nombreEmpresa &&
        user.experiencias[0].nombreEmpresa !== '' &&
        user.experiencias[0].anoInicio &&
        user.experiencias[0].anoFin
      ) {
        this.menuItems[7].buttonText = 'Realizado';
      } else {
        this.menuItems[7].buttonText = 'En progreso';
      }
    } else if (user.tieneExperiencia === true && user.experiencias.length === 0) {
      this.menuItems[7].buttonText = 'En progreso';
    } else {
      this.menuItems[7].buttonText = 'Iniciar';
    }
  }
}
