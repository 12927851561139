import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ENGLISH_LEVELS, GENDERS, GRADE_RATES, STUDY_PLACES, UNIVERSITY_DEGREES } from './school-dashboard-filters.constants';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { FilterItem, Grade, GradeLevel, GradeLevelResponse, Level, SchoolDashboardFilter } from './school-dashboard-grade-dropdowns.models';

const GRADE_LEVELS_ENDPOINT = 'api/getGradeLevelsById/';
@Component({
  selector: 'app-school-dashboard-filters',
  templateUrl: './school-dashboard-filters.component.html',
  styleUrls: ['./school-dashboard-filters.component.scss']
})
export class SchoolDashboardFiltersComponent implements OnInit {

  schoolId: string = localStorage.getItem('schoolId');
  schoolName: string = localStorage.getItem('school_name');

  englishLevels = []
  gradeRates = []
  universityDegrees = []
  studyPlaces = []
  genders = []
  grades = [];
  allGradesLevels = [];
  gradeLevels = [];

  currentFilters: SchoolDashboardFilter = {
    schoolName: this.schoolName
  }

  @Output() filtersChanged = new EventEmitter<SchoolDashboardFilter>();

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.englishLevels = ENGLISH_LEVELS;
    this.gradeRates = GRADE_RATES;
    this.universityDegrees = UNIVERSITY_DEGREES;
    this.studyPlaces = STUDY_PLACES;
    this.genders = GENDERS;
  }


  ngOnInit(): void {
    this.loadGradeLevels();
  }

  handleSelectionChange(property: keyof SchoolDashboardFilter, items: FilterItem[], itemProperty: string): void {
    this.currentFilters = {
      ...this.currentFilters,
      [property]: items.map(item => item[itemProperty])
    };

    this.filtersChanged.emit(this.currentFilters);
  }

  handleGradeSelectionChange(selected: Grade[]): void {
    console.log(selected);
    if (selected.length > 0) {
      const selectedGrade = selected[0];

      const selectedGradeData = this.allGradesLevels.find(g => g.grade[0] === Number(selectedGrade.id));
      console.log('selectedGradeData', selectedGradeData);

      this.gradeLevels = selectedGradeData
        ? selectedGradeData.levels.reduce((acc: Level[], levelArray: string[]) => {
          return acc.concat(levelArray.map(level => ({ id: `${selectedGradeData.grade[0]}${level}`, name: level })));
        }, [])
        : [];

      console.log('gradeLevels', this.gradeLevels);

    } else {
      this.gradeLevels = [];
    }
    this.handleSelectionChange('grado', selected, 'id');
  }


  private loadGradeLevels(): void {
    this.apiService.get({ api: GRADE_LEVELS_ENDPOINT + this.schoolId }).subscribe(
      (response: GradeLevelResponse) => {
        this.formatGradeLevels(response);
      },
      () => {
        this.alertService.showError({ msg: 'Error al cargar niveles de los grados' });
      }
    );
  }

  private formatGradeLevels(gradeLevelsResponse: GradeLevelResponse): void {
    this.allGradesLevels = gradeLevelsResponse.gradesLevels;

    const uniqueGrades = new Set(gradeLevelsResponse.gradesLevels.map((g: GradeLevel) => g.grade[0]));
    this.grades = Array.from(uniqueGrades).map(grade => ({
      id: grade.toString(),
      name: `Grado ${grade}`
    }));
  }
}
