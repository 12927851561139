import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-skills',
  templateUrl: './step-skills.component.html',
  styleUrls: ['./step-skills.component.scss']
})
export class StepSkillsComponent {
  @Input() form: FormGroup;

  skillsRows = [
    { value: 'Razonamiento Matemático y Científico', label: 'Razonamiento Matemático y Científico', tooltip: 'Analizar, calcular, investigar y resolver problemas.' },
    { value: 'Comunicación y Pensamiento Crítico', label: 'Comunicación y Pensamiento Crítico', tooltip: 'Argumentar, evaluar información, expresar ideas con claridad y tomar decisiones informadas.' },
    { value: 'Creatividad, Innovación y Resolución de Problemas', label: 'Creatividad, Innovación y Resolución de Problemas', tooltip: 'Generar ideas nuevas, diseñar soluciones y enfrentar desafíos de manera creativa.' },
    { value: 'Tecnología y Cultura Digital', label: 'Tecnología y Cultura Digital', tooltip: 'Programar, analizar datos, gestionar herramientas digitales y adaptarse al entorno tecnológico.' },
    { value: 'Ciencias Sociales y Humanas', label: 'Ciencias Sociales y Humanas', tooltip: 'Comprender el comportamiento humano, analizar sociedades, historia, filosofía y políticas.' },
    { value: 'Expresión Artística y Comunicación Visual', label: 'Expresión Artística y Comunicación Visual', tooltip: 'Crear, diseñar, interpretar y comunicar mensajes a través del arte y los medios visuales.' },
    { value: 'Liderazgo, Trabajo en Equipo y Gestión Personal', label: 'Liderazgo, Trabajo en Equipo y Gestión Personal', tooltip: 'Guiar, motivar, organizar y colaborar para alcanzar metas personales y colectivas.' },
    { value: 'Emprendimiento, Finanzas y Sostenibilidad', label: 'Emprendimiento, Finanzas y Sostenibilidad', tooltip: 'Desarrollar proyectos, administrar recursos y generar impacto económico y social.' },
  ];

  interestLevelsColumns = [
    { value: 'no_interest', label: 'Para nada interesado' },
    { value: 'low_interest', label: 'Poco interesado' },
    { value: 'neutral', label: 'Neutral / Moderadamente interesado' },
    { value: 'high_interest', label: 'Muy interesado' },
    { value: 'deep_interest', label: 'Extremadamente interesado' }
  ];

  constructor() { }

  handleSkillSelection(rowValue: string, columnValue: string) {
    const formArray = this.form.get('skills') as FormArray;
    const existingIndex = formArray.controls.findIndex(
      control => control.value.skill === rowValue
    );

    if (existingIndex !== -1) {
      formArray.removeAt(existingIndex); // Remove existing selection
    }

    formArray.push(
      new FormControl({
        skill: rowValue,
        interestLevel: columnValue
      })
    );
  }

  isSelected(rowValue: string, columnValue: string): boolean {
    const formArray = this.form.get('skills') as FormArray;
    const selectedSkill = formArray.controls.find(
      control => control.value.skill === rowValue
    );

    return selectedSkill?.value.interestLevel === columnValue;
  }

}
