
export const EDUCATION_PREFERENCES = [
    {
        img: 'assets/initial-form/practical-university.png',
        value: 'modern_and_industry_focused',
        name: 'Moderna y orientada a la industria',
        description: 'Universidades con un enfoque práctico, alianzas con empresas y programas diseñados para el mundo laboral. (Ejemplo: educación aplicada, programas con pasantías y conexiones empresariales).'
    },

    {
        img: 'assets/initial-form/classical-university.png',
        value: 'traditional_and_academic',
        name: 'Tradicional y académica',
        description: 'Instituciones con una larga trayectoria, un fuerte enfoque en la investigación y un ambiente académico riguroso. (Ejemplo: universidades clásicas con formación teórica y estructurada).'
    },
    {
        img: 'assets/initial-form/technological-university.png',
        value: 'technological_or_specialized',
        name: 'Tecnológica o especializada',
        description: 'Instituciones con énfasis en tecnología, innovación y metodologías digitales avanzadas, o centros de formación en áreas específicas como arte, música, gastronomía, cine, salud y oficios especializados. (Ejemplo: universidades STEM, bootcamps tecnológicos, conservatorios de música, escuelas de arte y programas técnicos especializados).'
    }
]