<div class="table-container">
  <table class="selection-table">
    <thead>
      <tr>
        <th></th> <!-- First column for row labels -->
        <th *ngFor="let column of columns">
          <div class="column-header">
            <p>{{ column.label }}</p>

            <!-- Tooltip: Only show if the column has tooltip data -->
            <div *ngIf="hasTooltip(column.value)" class="tooltip-container">
              <span class="tooltip-icon">ℹ️</span>
              <div class="tooltip-content">
                <p>{{ column.toolTip?.title }}</p>
                <ul>
                  <li *ngFor="let text of getTooltipText(column.value)">
                    {{ text }}
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let row of rows">
        <td class="row-label" *ngIf="!row.tooltip">{{ row.label }}</td>
        <td class="row-label" [matTooltip]="row.tooltip" *ngIf="row.tooltip">{{ row.label }}</td>

        <td *ngFor="let column of columns" (click)="selectValue(row.value, column.value)"
          [class.selected]="isSelected(row.value, column.value)">

          <span class="radio-indicator"></span>

        </td>
      </tr>
    </tbody>
  </table>
</div>