import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { StepperService } from '../../../../services/stepper.service';
import { CountryResponse } from '../../../initial-form/initialForm.models';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit {
  @Input() form: FormArray;

  countries: CountryResponse[] = [];
  filteredCountries: CountryResponse[] = [];
  selectedCountries: string[] = [];

  searchControl = this.fb.control('');
  MAX_COUNTRIES = 3;

  isLoading = true;

  constructor(private stepperService: StepperService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.setSelectedCountries();
    this.loadCountries();
    this.setupSearch();
  }

  updateSelectedCountries(selectedCountries: string[]): void {
    const abroadControl = this.form.get('abroadCountries') as FormArray;
    abroadControl.clear();

    selectedCountries.forEach((country) => {
      abroadControl.push(new FormControl(country));
    });
  }

  setSelectedCountries(): void {
    const abroadCountriesArray = this.form.get('abroadCountries') as FormArray;

    abroadCountriesArray.controls.forEach((control, index) => {
      if(!control.value){
        abroadCountriesArray.removeAt(index);
      }
    });
    
    this.selectedCountries = abroadCountriesArray.controls.map(control => control.value);
  }

  /** Fetch countries from API */
  loadCountries(): void {
    this.stepperService.getCountries().subscribe(
      (response) => {
        this.countries = response.sort((a, b) => a.nombre.localeCompare(b.nombre));
        this.updateFilteredCountries();
      },
      (error) => console.error('Error fetching countries:', error),
      () => this.isLoading = false
    );

  }

  /** Setup search functionality */
  setupSearch(): void {
    this.searchControl.valueChanges.subscribe((searchTerm) => {
      this.updateFilteredCountries(searchTerm);
    });
  }

  /** Update the displayed countries based on search & selection */
  updateFilteredCountries(searchTerm: string = ''): void {
    const selectedSet = new Set(this.selectedCountries);

    this.filteredCountries = this.countries
      .filter((country) => country.nombre.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => {
        const isSelectedA = selectedSet.has(a.nombre);
        const isSelectedB = selectedSet.has(b.nombre);
        return Number(isSelectedB) - Number(isSelectedA) || a.nombre.localeCompare(b.nombre);
      });
  }

  /** Select or remove a country */
  toggleCountrySelection(country: CountryResponse): void {
    if (this.selectedCountries.includes(country.nombre)) {
      this.selectedCountries = this.selectedCountries.filter(nombre => nombre !== country.nombre);
    } else if (this.selectedCountries.length < this.MAX_COUNTRIES) {
      this.selectedCountries.push(country.nombre);
    }

    this.updateFilteredCountries();
    this.updateSelectedCountries(this.selectedCountries);
  }

  /** Check if a country is selected */
  isSelected(countryName: string): boolean {
    return this.selectedCountries.includes(countryName);
  }
}
