<div class="step-twelve-container">

  <div *ngFor="let test of testFormArray.controls; let i = index" class="test-row">
    <label class="test-label">{{ test.get('testName').value || 'Otra' }}</label>

    <!-- Score Input -->
    <input type="number" class="score-input" min="0" [formControl]="test.get('score')" [placeholder]="'Puntaje...'">

    <!-- Error message for missing score -->
    <div *ngIf="test.get('score').invalid && test.get('score').touched" class="error-message">
      ¡Debes ingresar un puntaje si has presentado la prueba!
    </div>


    <!-- Checkbox for "Not Taken" -->
    <label class="checkbox-label">
      <input type="checkbox" [formControl]="test.get('notTaken')" (change)="toggleTest(i)">
      No la he presentado
    </label>
  </div>
</div>