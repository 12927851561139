import { Component, Input, Output, EventEmitter, OnInit, HostListener, ElementRef } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-initial-form-dropdown',
  templateUrl: './initial-form-dropdown.component.html',
  styleUrls: ['./initial-form-dropdown.component.scss']
})
export class InitialFormDropdownComponent implements OnInit {
  @Input() control: FormControl | FormArray;
  @Input() label: string = '';
  @Input() options: { value: string; label: string }[] = [];
  @Input() multiSelect: boolean = false;
  @Input() maxSelections: number = 2;
  @Input() enableSearch: boolean = false; // Enables the search bar
  @Output() selectionChange: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();

  dropdownOpen = false;
  searchQuery: string = ''; // Search query input
  filteredOptions: { value: string; label: string }[] = [];

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    // Initialize filtered options
    this.filteredOptions = [...this.options];
  }

  toggleDropdown(): void {
    if (!this.control.disabled) {
      this.dropdownOpen = !this.dropdownOpen;
      this.searchQuery = ''; // Reset search when opening
      this.filteredOptions = [...this.options]; // Reset filtered options when opening dropdown
    }
  }

  filterOptions(): void {
    this.filteredOptions = this.options
      ? this.options.filter(option =>
          option.label.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      : [];
  }

  selectOption(value: string): void {
    if (this.multiSelect) {
      if (this.control instanceof FormArray) {
        this.removeEmptyControls();
        const values = this.control.controls.map(control => control.value);

        if (values.includes(value)) {
          const indexToRemove = values.indexOf(value);
          if (indexToRemove !== -1) {
            this.control.removeAt(indexToRemove);
          }
        } else if (values.length < this.maxSelections) {
          this.control.push(new FormControl(value));
        }
      } else if (this.control instanceof FormControl) {
        let selectedValues: string[] = Array.isArray(this.control.value) ? this.control.value : [];
        selectedValues = selectedValues.filter(val => val !== '');

        if (selectedValues.includes(value)) {
          selectedValues = selectedValues.filter(item => item !== value);
        } else if (selectedValues.length < this.maxSelections) {
          selectedValues.push(value);
        }

        this.control.setValue(selectedValues.length > 0 ? selectedValues : []);
      }
    } else {
      if (this.control instanceof FormArray) {
        this.control.clear();
        this.control.push(new FormControl(value));
      } else if (this.control instanceof FormControl) {
        this.control.setValue(value);
      }
      this.dropdownOpen = false;
    }

    this.control.markAsTouched();
    this.selectionChange.emit(this.control.value);
  }

  removeEmptyControls(): void {
    if (!(this.control instanceof FormArray)) {
      return;
    }

    for (let i = this.control.length - 1; i >= 0; i--) {
      if (!this.control.at(i).value) {
        this.control.removeAt(i);
      }
    }
  }

  getSelectedText(): string {
    if (this.multiSelect) {
      return (this.control.value || [])
        .filter(value => value != '')
        .map(value => this.options.find(option => option.value === value)?.label)
        .join(', ');
    } else {
      return this.options.find(option => option.value == this.control.value)?.label || '';
    }
  }

  isSelected(value: string): boolean {
    return this.multiSelect ? this.control.value?.includes(value) : this.control.value === value;
  }

  getErrorMessage(): string {
    if (this.control.hasError('required')) {
      return 'Este campo es obligatorio.';
    }
    return '';
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (this.dropdownOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }

  deselectOption(event: Event): void {
    event.stopPropagation(); 
  
    if (this.multiSelect) {
      this.control.setValue([]);
    } else {
      this.control.setValue('');
    }
  
    this.selectionChange.emit(this.control.value);
  }
}
