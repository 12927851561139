import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SchoolDashboardFilter } from './school-dashboard-filters/school-dashboard-grade-dropdowns.models';
import { DashboardData } from './school-dashboard.model';

@Component({
  selector: 'app-school-dashboard',
  templateUrl: './school-dashboard.component.html',
  styleUrls: ['./school-dashboard.component.scss']
})
export class SchoolDashboardComponent implements OnInit {
  school_name: string = localStorage.getItem('school_name') || '';
  GET_DASHBOARD_DATA = `api/schoolDashboard/${this.school_name}`;

  institutionLevel: any;
  error: string = '';

  skillsPointsAverage: any;
  averageChartoptions: any;
  topTwoSkillsChartOptions: any;
// Chart for Universities
chartDataUniversities = {
  series: [],
  options: {
    chart: {
      type: "bar",
      width: 400,
      fontFamily: "'Montserrat', sans-serif"
    },
    xaxis: {
      categories: [],
    },
    colors: ["#FF4560"],
  },
  hasData: false
};

// Main Universities Chart
chartDataMainUniversities = {
  series: [],
  options: {
    chart: {
      type: "bar",
      width: 400,
      fontFamily: "'Montserrat', sans-serif"
    },
    plotOptions: {
      bar: {
        horizontal: false, // Keep bars vertical
        columnWidth: "50%",
        distributed: true // Different colors per category
      }
    },
    xaxis: {
      categories: [],
    },
    colors: ["#4B98EB"],
  },
  hasData: false
};

// Countries Chart
chartDataCountries = {
  series: [],
  options: {
    chart: {
      type: "pie",
      width: 380,
      fontFamily: "'Montserrat', sans-serif"
    },
    legend: {
      position: "bottom",
      fontSize: "14px"
    },
    labels: [],
    colors: ["#00E396", "#FEB019", "#D10CE8", "#008FFB", "#FF4560"],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
          fontFamily: "'Montserrat', sans-serif"
        },
        legend: {
          position: "bottom",
          fontSize: "10px"
        }
      }
    }]
  },
  hasData: false
};

// Cities Chart
chartDataCities = {
  series: [],
  options: {
    chart: {
      type: "bar",
      width: 380,
      fontFamily: "'Montserrat', sans-serif"
    },
    plotOptions: {
      bar: {
        horizontal: false, // Keep bars vertical
        columnWidth: "50%",
        distributed: true
      }
    },
    legend: {
      position: "bottom",
      fontSize: "14px"
    },
    xaxis: {
      categories: [],
    },
    colors: ["#00E396"],
  },
  hasData: false
};

// English Level Chart
chartDataEnglishLevel = {
  series: [{
    name: 'Niveles de Inglés',
    data: []
  }],
  options: {
    chart: {
      type: "bar",
      width: 380,
      fontFamily: "'Montserrat', sans-serif"
    },
    plotOptions: {
      bar: {
        columnWidth: '60%', // Adjusts bar width
      }
    },
    xaxis: {
      categories: [],
    },
    colors: ["#D10CE8", "#008FFB", "#FF4560"],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
          fontFamily: "'Montserrat', sans-serif"
        }
      }
    }]
  },
  hasData: false
};

// Notes Level Chart
chartDataNotesLevel = {
  series: [],
  options: {
    chart: {
      type: "pie",
      width: 380,
      fontFamily: "'Montserrat', sans-serif"
    },
    legend: {
      position: "bottom",
      fontSize: "14px"
    },
    colors: [
      "#FF4560", // Red
      "#775DD0", // Purple
      "#00E396", // Green
      "#008FFB", // Blue
      "#FEB019", // Yellow
      "#D10CE8", // Pink
      "#546E7A", // Gray
      "#26A69A"  // Teal
    ],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
          fontFamily: "'Montserrat', sans-serif"
        }
      }
    }]
  },
  hasData: false
};

// Careers Chart
chartDataCareers = {
  series: [],
  options: {
    chart: {
      type: "bar",
      width: 380,
      fontFamily: "'Montserrat', sans-serif"
    },
    xaxis: {
      categories: [],
    },
    colors: ["#00E396"],
  }
};

// Study Interest Chart
chartDataStudyInterest = {
  series: [],
  options: {
    chart: {
      type: "pie",
      width: 380,
      fontFamily: "'Montserrat', sans-serif"
    },
    legend: {
      position: "bottom",
      fontSize: "14px"
    },
    labels: ["Mi país", "Otro lugar"],
    colors: ["#00E396", "#FEB019"],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
          fontFamily: "'Montserrat', sans-serif"
        }
      }
    }]
  }
};

// Grade Level Chart
chartDataGradeLevel = {
  series: [],
  options: {
    chart: {
      type: "pie",
      width: 380,
      fontFamily: "'Montserrat', sans-serif"
    },
    legend: {
      position: "bottom",
      fontSize: "14px"
    },
    labels: [], // Labels corresponding to grade levels (e.g., "12A", "11B")
    colors: ["#775DD0", "#00E396", "#FEB019", "#FF4560"],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
          fontFamily: "'Montserrat', sans-serif"
        }
      }
    }]
  }
};

// Skills Chart
chartDataSkills = {
  series: [
    {
      name: "Competencias",
      data: [] // Will be filled dynamically with percentages
    }
  ],
  options: {
    chart: {
      type: "bar",
      height: 400,
      fontFamily: "'Montserrat', sans-serif"
    },
    plotOptions: {
      bar: {
        horizontal: false, // Keep bars vertical
        columnWidth: "50%",
        distributed: true
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(0)}%`,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        colors: ["#FFFFFF"],
        fontFamily: "'Montserrat', sans-serif"
      }
    },
    xaxis: {
      categories: [], // Filled dynamically with skill names
      labels: {
        rotate: -45, // Rotate x-axis labels
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          fontFamily: "'Montserrat', sans-serif"
        }
      }
    },
    yaxis: {
      title: {
        text: "Porcentaje (%)",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "'Montserrat', sans-serif"
        }
      },
      labels: {
        formatter: (val) => `${val.toFixed(0)}%`,
        style: {
          fontFamily: "'Montserrat', sans-serif"
        }
      }
    },
    colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#D7263D", "#F77F00"],
    tooltip: {
      y: {
        formatter: (val) => `${val.toFixed(0)}%`
      }
    },
    legend: {
      show: false,
      labels: {
        fontFamily: "'Montserrat', sans-serif"
      }
    }
  }
};


  constructor(
    private apiService: ApiService,
    private ngxSpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getShoolDashboardData({} as SchoolDashboardFilter);
  }


  onFiltersChanged(filters: SchoolDashboardFilter): void {
    this.getShoolDashboardData(filters);
  }

  getShoolDashboardData(dashboardFilters: SchoolDashboardFilter): void {
    const filterParams = this.buildFilterParamsFromDropdowns(dashboardFilters);
    const httpParams = new HttpParams({ fromObject: filterParams });

    this.ngxSpinnerService.show();

    this.apiService.getIntialData(this.GET_DASHBOARD_DATA, { params: httpParams }, { api: this.GET_DASHBOARD_DATA })
      .subscribe({
        next: (result: DashboardData) => {
          this.updateCharts(result);
        },
        error: (error) => {
          this.error = 'Error cargando los datos del Dashboard';
        },
        complete: () => {
          this.ngxSpinnerService.hide();
        }
      });
  }

  private updateCharts(dashboardData: DashboardData): void {
    // For charts that simply need series and options updated:
    this.updateGenericChart(
      this.chartDataStudyInterest,
      dashboardData.charts.studyInterest.data,
      { optionType: 'labels' }
    );
    this.updateGenericChart(
      this.chartDataMainUniversities,
      dashboardData.charts.mainUniversities.data,
      { seriesFormat: 'object', seriesName: 'Estudiantes', optionType: 'xaxis' }
    );
    this.updateGenericChart(
      this.chartDataUniversities,
      dashboardData.charts.universities.data,
      { seriesFormat: 'object', seriesName: 'Estudiantes', optionType: 'xaxis' }
    );
    this.updateGenericChart(
      this.chartDataCountries,
      dashboardData.charts.countries.data,
      { optionType: 'labels' }
    );
    this.updateGenericChart(
      this.chartDataCities,
      dashboardData.charts.cities.data,
      { seriesFormat: 'object', seriesName: 'Estudiantes', optionType: 'xaxis' }
    );
    this.updateGenericChart(
      this.chartDataEnglishLevel,
      dashboardData.charts.englishLevel.data,
      { seriesFormat: 'object', seriesName: 'Niveles de Inglés', optionType: 'labels' }
    );
    this.updateGenericChart(
      this.chartDataNotesLevel,
      dashboardData.charts.notesLevel.data,
      {
        optionType: 'labels',
        customHasData: (values) => values.find((value: number) => value > 0) !== undefined
      }
    );
    this.updateGenericChart(
      this.chartDataGradeLevel,
      dashboardData.charts.gradeLevel.data,
      { optionType: 'labels' }
    );

    // The skills chart has more custom logic so we keep them separate.
    this.updateSkillsChart(dashboardData);
    this.updateGlobalPerformance(dashboardData);
    this.updateTopTwoSkillsChart(dashboardData);
  }
  private buildFilterParamsFromDropdowns(filters: SchoolDashboardFilter): { [key: string]: string | string[] } {
    const filterParams: { [key: string]: string } = {};

    Object.keys(filters).forEach((key: string) => {
      const value = filters[key];
      if (value) {
        filterParams[key] = Array.isArray(value) ? value.join(',') : value;
      }
    });
    return filterParams;
  }

  private updateGenericChart(
    chart: any,
    data: any,
    config: {
      seriesFormat?: 'direct' | 'object',
      seriesName?: string,
      optionType?: 'xaxis' | 'labels',
      customHasData?: (values: any[]) => boolean
    } = {}
  ): void {
    // Provide safe defaults for arrays if they are undefined.
    const values: any[] = Array.isArray(data.values) ? data.values : [];
    const categories: any[] = Array.isArray(data.categories) ? data.categories : [];
    const labels: any[] = Array.isArray(data.labels) ? data.labels : [];

    // Update series based on format.
    const seriesFormat = config.seriesFormat || 'direct';
    if (seriesFormat === 'object') {
      chart.series = [{ name: config.seriesName, data: values }];
    } else {
      chart.series = values;
    }

    // Update options (either xaxis or labels).
    if (config.optionType === 'xaxis') {
      chart.options = { ...chart.options, xaxis: { categories: categories } };
    } else {
      chart.options = { ...chart.options, labels: labels };
    }

    // Set hasData based on either a custom function or the default check.
    if (config.customHasData) {
      chart.hasData = config.customHasData(values);
    } else {
      chart.hasData = values.length > 0;
    }
  }



  updateSkillsChart(dashboardData: DashboardData): void {
    const skillsData = dashboardData.charts.skills.data;

    if (!skillsData || !skillsData.categories || !skillsData.values || !skillsData.levels) {
      console.warn("No hay datos disponibles para el gráfico de competencias.");
      return;
    }

    this.chartDataSkills.series = [
      {
        name: "Competencias",
        data: skillsData.categories.map((category, index) => ({
          x: category,
          y: skillsData.values[index],
          level: skillsData.levels[index]
        }))
      }
    ];

    this.chartDataSkills.options = {
      ...this.chartDataSkills.options,
      xaxis: {
        categories: skillsData.categories,
        labels: {
          rotate: -45,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: "'Montserrat', sans-serif"
          }
        }
      },
      tooltip: {
        y: {
          formatter: (val: number) => {
            const matchedSkill = this.chartDataSkills.series[0].data.find(skill => skill.y === val);
            return matchedSkill ? `Nivel: ${matchedSkill.level}` : "";
          }
        }
      }
    };

  }

  updateGlobalPerformance(dashboardData: DashboardData): void {
    const performanceData = dashboardData.globalPerformance;

    this.skillsPointsAverage = Number(performanceData.score.toFixed(1));
    this.institutionLevel = {
      level: performanceData.level,
      description: performanceData.levelDescription || ""
    };

    this.averageChartoptions = this.displayRadialChart(
      "Desempeño Global Competencias",
      this.skillsPointsAverage,
      this.institutionLevel.level
    );
  }

  updateTopTwoSkillsChart(dashboardData: DashboardData): void {
    const topTwoSkillsData = dashboardData.topTwoSkills;

    this.topTwoSkillsChartOptions = this.displayRadialChart(
      "Promedio Competencias Destacadas",
      topTwoSkillsData.score,
      topTwoSkillsData.level
    );

    this.topTwoSkillsChartOptions = {
      ...this.topTwoSkillsChartOptions,
      skills: topTwoSkillsData?.skills?.join(", ") || "",
      description: topTwoSkillsData.levelDescription || ""
    }
  }


  displayRadialChart(name: string, score: number, level: string): any {
    return {
      series: [score],
      chart: {
        height: 350,
        width: 300,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
        },
      },
      labels: [level],
    };
  }
}