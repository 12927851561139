import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-english-test',
  templateUrl: './english-test.component.html',
  styleUrls: ['./english-test.component.scss'],
})
export class EnglishTestComponent implements OnInit {

  nivelIngles = parseInt(localStorage.getItem('languageLevel') || '0', 10);
  
  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.saveCambridgeTest();
  }

  saveCambridgeTest() {
    const element = this.elementRef.nativeElement.querySelector(`[href="https://www.u360education.com/english-test/${this.nivelIngles}"]`);
    if (element) {
      this.renderer.selectRootElement(element).click();
    } else {
      setTimeout(() => this.saveCambridgeTest(), 1000);
    }
  }
}
