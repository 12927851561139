<app-inital-form-card-selection [control]="form.get('englishLevel')" [items]="englishLevels">

  <ng-template let-item let-selected="selected">

    <div class="level-card" [class.selected]="selected">
      <span class="level-title">{{ item.label }}</span>
      <p class="level-description">{{ item.description }}</p>
    </div>

  </ng-template>

</app-inital-form-card-selection>