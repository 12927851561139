import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ApexOptions } from 'ng-apexcharts';
import { Skill } from '../simulationChart.models';

@Component({
  selector: 'app-simulation-chart',
  templateUrl: './simulationChart.component.html',
  styleUrls: ['./simulationChart.component.scss'],
})
export class SimulationChartComponent implements OnInit, OnChanges {
  @Input() passedSkill: Skill;
  @Input() data: any; // Already filtered data from parent

  chartOptions: ApexOptions;
  series: any;
  categories: any;
  skillObjects: any;

  levelColors = {
    'Principiante': 'rgb(255, 99, 99)',
    'Intermedio': 'rgb(255, 171, 118)',
    'Avanzado': 'rgb(255, 253, 162)',
    'Maestro': 'rgb(186, 255, 180)',
    'Visionario': 'rgb(57, 153, 24)',
  };

  ngOnInit(): void {
    console.log('Passed skill', this.passedSkill);
    this.updateChart();
  }

  ngOnChanges(): void {
    this.updateChart();
  }

  updateChart() {
    if (!this.data) return;

    const quarters = Object.keys(this.data);
    const data = quarters.map((quarter) => {
      const skillValue = this.data[quarter][this.passedSkill.skillName];
      return skillValue ? parseFloat(skillValue) : null;
    });

    this.series = [{ name: this.passedSkill.skillName, data }];
    this.categories = quarters.map(this.getQuarterLabel);

    // Map skill levels
    this.skillObjects = data.map((score) => {
      const roundedScore = Math.round(score);
      const levelObject = this.passedSkill.levels.find(
        (level) => roundedScore >= level.range1 && roundedScore <= level.range2
      );

      return {
        name: this.passedSkill.skillName,
        score: roundedScore != null ? roundedScore + '%' : null,
        level: levelObject ? levelObject.level : null,
        levelDescription: levelObject ? levelObject.description : null,
      };
    });

    this.initializeChart();
  }

  initializeChart() {
    const translatedCategories = this.categories.map((category) => {
      switch (category) {
        case 'FirstQuarter':
          return 'Primer Periodo';
        case 'SecondQuarter':
          return 'Segundo Periodo';
        case 'ThirdQuarter':
          return 'Tercer Periodo';
        case 'FourthQuarter':
          return 'Cuarto Periodo';
        default:
          return category;
      }
    });

    // Define Y-axis annotations for skill levels
    const yaxisAnnotations = this.passedSkill.levels.map((level) => ({
      y: level.range1,
      y2: level.range2,
      borderColor: 'transparent',
      fillColor: this.levelColors[level.level] || 'rgba(0, 0, 0, 0.1)',
      opacity: 0.2,
      label: {
        text: level.level,
        style: {
          color: '#000',
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    }));

    this.chartOptions = {
      series: this.series,
      chart: {
        height: 350,
        type: 'line',
        toolbar: { show: false },
        animations: { enabled: true, speed: 800 },
      },
      xaxis: { categories: translatedCategories },
      title: { text: `Evolución de ${this.passedSkill.skillName}`, align: 'center' },
      tooltip: {
        y: {
          formatter: (value: number) => `${value.toFixed(2)}%`,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value, { dataPointIndex }) => {
          const skillObject = this.skillObjects[dataPointIndex];
          return skillObject ? `${value}% (${skillObject.level})` : `${value}%`;
        },
        style: {
          fontSize: '12px',
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: 'bold',
      },
      },
      yaxis: {
        min: 0,
        title: { text: 'Puntaje de habilidad' },
        labels: {
          formatter: (value) => `${value.toFixed(2)}%`,
        },
      },
      annotations: {
        yaxis: yaxisAnnotations,
      },
      colors: Object.values(this.levelColors),
      fill: {
        type:'solid',
        colors:['#f3f3f3'],
      }
    };
  }

  getQuarterLabel(quarter: string): string {
    const labels = {
      FirstQuarter: 'Primer Periodo',
      SecondQuarter: 'Segundo Periodo',
      ThirdQuarter: 'Tercer Periodo',
      FourthQuarter: 'Cuarto Periodo',
    };
    return labels[quarter] || quarter;
  }

  private DEFAULT_VALUE = 0.1;

  showChart() {
    return this.series && this.series[0].data.some((value: number) => value > this.DEFAULT_VALUE);
  }
}
