<div class="step-two">

  <div class="step-two__loading">
    <mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>
  </div>


    <div *ngIf="!isLoading">
        <app-initial-form-dropdown
          label="Selecciona tu grado"
          [control]="form.get('currentGrade')"
          [options]="schoolGrades"
          (selectionChange)="onGradeChange($event)">
        </app-initial-form-dropdown>
      </div>
  
      <div *ngIf="selectedGradeLevels.length > 0">
        <app-initial-form-dropdown
          label="Selecciona el nivel"
          [control]="form.get('gradeLevel')"
          [options]="selectedGradeLevels">
        </app-initial-form-dropdown>
      </div>
</div>
