import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StepperService } from '../../../../services/stepper.service';
import { CityResponse, UniversityResponse } from '../../../initial-form/initialForm.models';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-colombia-selector',
  templateUrl: './colombia-selector.component.html',
  styleUrls: ['./colombia-selector.component.scss']
})
export class ColombiaSelectorComponent implements OnInit {
  @Input() form: FormGroup;
  colombianCitiesOptions: { value: string, label: string }[] = [];
  colombianUniversitiesOptions: { value: string, label: string }[] = [];
  isLoadingCities = true;
  isLoadingUniversities = true;

  constructor(private stepperService: StepperService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    forkJoin({
      cities: this.stepperService.loadColombianCities(),
      universities: this.stepperService.laodColombianUniversities()
    }).subscribe(
      ({ cities, universities }) => {
        this.processCities(cities);
        this.processUniversities(universities);
      },
      (error) => console.error('Error fetching data:', error),
      () => {
        this.isLoadingCities = false;
        this.isLoadingUniversities = false;
      }
    );
  }

  processCities(response: CityResponse[]): void {
    const uniqueCities = new Map<string, CityResponse>();

    response.forEach((city: CityResponse) => {
      if (!uniqueCities.has(city.nombre)) {
        uniqueCities.set(city.nombre, city);
      }
    });

    this.colombianCitiesOptions = Array.from(uniqueCities.values()).map((city: CityResponse) => ({
      value: city.nombre,
      label: city.nombre
    }));
  }

  processUniversities(response: UniversityResponse[]): void {
    const uniqueUniversities = new Map<string, UniversityResponse>();

    response.forEach((university: UniversityResponse) => {
      if (!uniqueUniversities.has(university.name)) {
        uniqueUniversities.set(university.name, university);
      }
    });

    this.colombianUniversitiesOptions = Array.from(uniqueUniversities.values()).map((university: UniversityResponse) => ({
      value: university._id,
      label: `${university.name} - ${university.city ? university.city.nombre : ''}`
    }));
  }
}