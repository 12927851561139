<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [formControl]="control"
    (dateChange)="onDateChange($event)"
    [readonly]="monthYearOnly" />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    [startView]="monthYearOnly ? 'multi-year' : 'month'"
    (monthSelected)="monthYearOnly ? onDateChange($event) : null"
    [panelClass]="monthYearOnly ? 'month-year-picker' : null"></mat-datepicker>
</mat-form-field>
