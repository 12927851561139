<div class="school-dashboard">
  <h1 class="title">Filtros Dashboard de Colegio</h1>

  <app-school-dashboard-filters (filtersChanged)="onFiltersChanged($event)"></app-school-dashboard-filters>

  <div class="charts-container">
    <div class="chart large" *ngIf="chartDataSkills?.series?.length > 0">
      <h3>Competencias</h3>
      <apx-chart 
        [series]="chartDataSkills.series" 
        [chart]="chartDataSkills.options.chart" 
        [xaxis]="chartDataSkills.options.xaxis"
        [labels]="chartDataSkills.options.labels"
        [colors]="chartDataSkills.options.colors"
        [legend]="chartDataSkills.options.legend"
        [responsive]="chartDataSkills.options.responsive"
        [tooltip]="chartDataSkills.options.tooltip"
        [dataLabels]="chartDataSkills.options.dataLabels"
        [plotOptions]="chartDataSkills.options.plotOptions">
      </apx-chart>
    </div>


    <div class="chart large" *ngIf="averageChartoptions?.series?.length > 0">
        <apx-chart
          [series]="averageChartoptions.series"
          [chart]="averageChartoptions.chart"
          [plotOptions]="averageChartoptions.plotOptions"
          [fill]="averageChartoptions.fill"
          [stroke]="averageChartoptions.stroke"
          [labels]="averageChartoptions.labels"></apx-chart>
        <div class="row container-tip">
          <div *ngIf="institutionLevel.description" class="col-10">
            <p class="desc-tip">
              {{ institutionLevel.description }}
            </p>
        </div>
      </div>
    </div>
      
    <div class="chart-grid">

      <div class="chart half" *ngIf="chartDataStudyInterest?.series?.length > 0">
        <h3>¿Dónde Quiere Estudiar?</h3>
        <apx-chart 
          [series]="chartDataStudyInterest.series" 
          [chart]="chartDataStudyInterest.options.chart" 
          [xaxis]="chartDataStudyInterest.options.xaxis"
          [labels]="chartDataStudyInterest.options.labels"
          [colors]="chartDataStudyInterest.options.colors"
          [legend]="chartDataStudyInterest.options.legend"
          [responsive]="chartDataStudyInterest.options.responsive">
        </apx-chart>
      </div>

      <div class="chart half" *ngIf="chartDataUniversities?.series?.length > 0">
        <h3>Universidades donde los estudiantes quieren estudiar</h3>
        <apx-chart 
          [series]="chartDataUniversities.series" 
          [chart]="chartDataUniversities.options.chart" 
          [xaxis]="chartDataUniversities.options.xaxis"
          [labels]="chartDataUniversities.options.labels"
          [colors]="chartDataUniversities.options.colors"
          [legend]="chartDataUniversities.options.legend"
          [responsive]="chartDataUniversities.options.responsive">
        </apx-chart>
      </div>

      <div class="chart half" *ngIf="chartDataCountries?.series?.length > 0">
        <h3>¿En qué países quieren estudiar?</h3>
        <apx-chart 
          [series]="chartDataCountries.series" 
          [chart]="chartDataCountries.options.chart" 
          [xaxis]="chartDataCountries.options.xaxis"
          [labels]="chartDataCountries.options.labels"
          [colors]="chartDataCountries.options.colors"
          [legend]="chartDataCountries.options.legend"
          [responsive]="chartDataCountries.options.responsive">
        </apx-chart>
      </div>

      <div class="chart half" *ngIf="chartDataCities?.series?.length > 0">
        <h3>¿En qué ciudades quieren estudiar? (Colombia)</h3>
        <apx-chart 
          [series]="chartDataCities.series" 
          [chart]="chartDataCities.options.chart" 
          [xaxis]="chartDataCities.options.xaxis"
          [labels]="chartDataCities.options.labels"
          [colors]="chartDataCities.options.colors"
          [legend]="chartDataCities.options.legend"
          [responsive]="chartDataCities.options.responsive">
        </apx-chart>
      </div>

      <div class="chart half" *ngIf="chartDataEnglishLevel?.series?.length > 0">
        <h3>Nivel de Inglés</h3>
        <apx-chart 
          [series]="chartDataEnglishLevel.series" 
          [chart]="chartDataEnglishLevel.options.chart" 
          [xaxis]="chartDataEnglishLevel.options.xaxis"
          [labels]="chartDataEnglishLevel.options.labels"
          [colors]="chartDataEnglishLevel.options.colors"
          [legend]="chartDataEnglishLevel.options.legend"
          [responsive]="chartDataEnglishLevel.options.responsive">
        </apx-chart>
      </div>

      <div class="chart half" *ngIf="chartDataNotesLevel?.series?.length > 0">
        <h3>Nivel de Notas de Último Año</h3>
        <apx-chart 
          [series]="chartDataNotesLevel.series" 
          [chart]="chartDataNotesLevel.options.chart" 
          [xaxis]="chartDataNotesLevel.options.xaxis"
          [labels]="chartDataNotesLevel.options.labels"
          [colors]="chartDataNotesLevel.options.colors"
          [legend]="chartDataNotesLevel.options.legend"
          [responsive]="chartDataNotesLevel.options.responsive">
        </apx-chart>
      </div>

      <div class="chart half" *ngIf="chartDataGradeLevel?.series?.length > 0">
        <h3>Grado de Estudio</h3>
        <apx-chart 
          [series]="chartDataGradeLevel.series" 
          [chart]="chartDataGradeLevel.options.chart" 
          [xaxis]="chartDataGradeLevel.options.xaxis"
          [labels]="chartDataGradeLevel.options.labels"
          [colors]="chartDataGradeLevel.options.colors"
          [legend]="chartDataGradeLevel.options.legend"
          [responsive]="chartDataGradeLevel.options.responsive">
        </apx-chart>
      </div>
    </div>
  </div>

</div>