import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TABLE_COLUMNS, TABLE_ROWS } from './step-academic-performance.constants';

@Component({
  selector: 'app-step-academic-performance',
  templateUrl: './step-academic-performance.component.html',
  styleUrls: ['./step-academic-performance.component.scss']
})
export class StepAcademicPerformanceComponent{

  @Input() form: FormGroup;

  rows = TABLE_ROWS;
  columns = TABLE_COLUMNS;

  constructor() { }

}
