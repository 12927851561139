<div class="row introductionContainer">
  <div class="col-12">
    <h4 class="titulo-wizard">Conoce las experiencias para mejorar tu perfil</h4>
    <p>
      Explora aquí las experiencias que se ajusten con tus habilidades individuales. Estas experiencias te permitirán
      fortalecer tus competencias y enriquecer tu hoja de vida, encontrando el camino para un ingreso exitoso a la
      universidad y fortaleciendote para un futuro profesional 🌟.
    </p>
    <p>
      Agregalas a planeadas para que empieces a ejecutarlas cuanto antes 🚀.
    </p>
    <hr class="hrLine" />
  </div>
</div>

<div class="filters-container">
  <mat-form-field>
    <mat-label>Buscar experiencias por nombre</mat-label>
    <input
      matInput
      [(ngModel)]="searchTerm"
      (ngModelChange)="filterExperiences()"
      placeholder="Buscar experiencias por nombre" />
  </mat-form-field>

  <mat-form-field>
    <mat-label for="filterByCategories">Categoría de experiencia</mat-label>
    <mat-select
      id="filterByCategories"
      name="filterByCategories"
      (ngModelChange)="toggleCategorySelection($event)"
      multiple
      [(ngModel)]="selectedCategoryOption">
      <mat-option *ngFor="let option of categories" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label for="filterBySkills">Competencia principal</mat-label>
    <mat-select
      id="filterBySkills"
      name="filterBySkills"
      (ngModelChange)="toggleSkillSelection($event)"
      multiple
      [(ngModel)]="selectedSkillsOption">
      <mat-option *ngFor="let option of skills" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="experienceCardContainer">
  <div class="experienceCard">
    <div class="cardComponent">
      <div class="card" *ngFor="let formData of filteredExperiences; let i = index">
        <div class="cardTitle">
          <h5>{{ formData.experienceName }}</h5>
        </div>
        <div class="cardBody">
          <!-- <div [innerHTML]="decodeEntities(trimHtmlContent(formData.description))"></div> -->
          <div class="typeOfCard">
            <img src="../../../assets/experiences/experience.png" alt="Competencias" />
            <p>{{ formData.requiredSkills.join(", ") }}</p>
          </div>
          <p class="scoreText" *ngIf="formData.skillsScore">
            Puntaje porcentual otorgado:
            <strong>{{ formData.skillsScore }}</strong>
          </p>
          <span
            [ngClass]="{
              'low-impact': formData.skillsScore <= 15,
              'intermediate-impact': formData.skillsScore > 15 && formData.skillsScore < 25,
              'high-impact': formData.skillsScore >= 25
            }">
            {{
              formData.skillsScore >= 25
                ? 'Impacto Alto'
                : formData.skillsScore > 15 && formData.skillsScore < 25
                ? 'Impacto Significativo'
                : 'Impacto Moderado'
            }}
          </span>
          <div class="bottomSide">
            <p class="date">
              <img src="../../../assets/experiences/hourglass.png" alt="" />
              Horas requeridas: {{ formData.totalHours.range1 }} - {{ formData.totalHours.range2 }}
            </p>
            <div class="footer__buttons">
              <button type="button" class="editButton" (click)="openModal(formData)">Requisitos</button>

              <button type="button" class="editButton" (click)="openExperienceInfo(formData)">Ver más</button>
  
              <button type="button"class="experienceExample" (click)="openExperiencePlanner(formData)">Agregar a planeadas</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
