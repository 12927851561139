<div class="step-financial">
  
  <app-initial-form-question [control]="form.get('financialSupport')"
    question="¿Tienes apoyo financiero para tus estudios universitarios?" [options]="financialSupportOptions"
    [multiSelect]="false">
  </app-initial-form-question>

  <app-initial-form-question [control]="form.get('tuitionRange')"
    question="¿Cuál es el rango aproximado que consideras para tu matrícula anual de pregrado?"
    [options]="tuitionOptions" [multiSelect]="false">
  </app-initial-form-question>

</div>