import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-completed-english-test',
  templateUrl: './completed-english-test.component.html',
  styleUrls: ['./completed-english-test.component.scss'],
})
export class CompletedEnglishTestComponent implements OnInit {
  private readonly USER_API = 'api/user/';
  private readonly API_FORM = 'api/formulario';
  private readonly API_TEST = 'api/updateInternalizacionUser/';
  levelNumber: number = 0;
  englishLevel: any;
  private user: any;

  /* Child References */
  createForm: FormGroup = this.fb.group({
    anioInteres: ['', [Validators.required]],
    edad: ['', [Validators.required]],
    programa: ['', [Validators.required]],
    pregunta: ['', [Validators.required]],
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    let url = this.router.url.split('/');
    this.levelNumber = parseInt(url[url.length - 1]);
    this.levelNumber = this.levelNumber > 9 ? 9 : this.levelNumber < 1 ? 1 : this.levelNumber;

    switch (true) {
      case this.levelNumber >= 0 && this.levelNumber < 2:
        this.englishLevel = 'A1';
        break;
      case this.levelNumber >= 2 && this.levelNumber < 4:
        this.englishLevel = 'A2';
        break;
      case this.levelNumber >= 4 && this.levelNumber < 6:
        this.englishLevel = 'B1';
        break;
      case this.levelNumber >= 6 && this.levelNumber < 7:
        this.englishLevel = 'B2';
        break;
      case this.levelNumber >= 7 && this.levelNumber < 8:
        this.englishLevel = 'C1';
        break;
      case this.levelNumber >= 8 && this.levelNumber <= 9:
        this.englishLevel = 'C2';
        break;
      default:
        this.englishLevel = 'Unknown';
        break;
    }

    this.user = JSON.parse(this.authService.getCurrentLoggedUser());
    let languageLevel = {
      languageLevel: this.englishLevel,
      nivelIngles: this.levelNumber.toString(),
    };

    // Actualiza localStorage y la info del usuario
    this.apiService.put({ api: this.USER_API + this.user._id, data: languageLevel }).subscribe(
      (response) => {
        localStorage.setItem('languageLevel', this.levelNumber.toString());
        this.alertService.showSuccess({
          msg: 'Se guardó el nivel de inglés',
        });
      },
      (err) => {
        this.alertService.showError({
          msg: 'Error guardando el nivel de inglés',
        });
      }
    );

    // Guarda nivel en internazio
    this.apiService.put({ api: this.API_TEST + this.user._id, data: languageLevel }).subscribe(
      (response) => {
        this.alertService.showSuccess({
          msg: 'Se guardó el nivel de inglés en internazio',
        });
      },
      (err) => {
        this.alertService.showError({
          msg: 'Error guardando el nivel de inglés en internazio',
        });
      }
    );
  }

  /**
   * Valida que los campos sean correctos
   * @param field campo a validar
   */
  public validField({ field }): boolean {
    return this.createForm.controls[field].errors && this.createForm.controls[field].touched;
  }

  onSubmit({ event }): void {
    event.preventDefault(); // Evita el envío por defecto del formulario
    if (this.createForm.valid) {
      const form = {
        nombre: this.user.nombre,
        apellido: this.user.apellido,
        correo: this.user.email,
        anioInteres: this.createForm.controls.anioInteres.value,
        edad: this.createForm.controls.edad.value,
        telefono: this.user.telefono,
        programa: this.createForm.controls.programa.value,
        pregunta: this.createForm.controls.pregunta.value,
        fuente: 'Test Inglés, nivel: ' + this.levelNumber,
      };
      this.createFormByContact({ form });
    } else {
      this.createForm.markAllAsTouched();
    }
  }

  /**
   * Crea un formulario a través del API y actualiza el localStorage si se guarda
   * @param form Datos del formulario
   */
  public async createFormByContact({ form }): Promise<void> {
    try {
      const resp: any = await this.apiService.post({ api: this.API_FORM, data: form }).toPromise();
      if (resp && resp.success) {
        // Actualiza el localStorage indicando que se completó el test de inglés
        localStorage.setItem('englishTestCompleted', 'true');
        this.createForm.reset();
        this.alertService.showSuccess({
          msg: 'Formulario creado y test guardado correctamente',
        });
      } else {
        this.alertService.showError({
          msg: 'No se pudo guardar el test de inglés',
        });
      }
    } catch (error) {
      console.error('Error guardando test de inglés:', error);
      this.alertService.showError({
        msg: 'Error al guardar el test de inglés',
      });
    }
  }
}
