import { FormGroup } from "@angular/forms";
import { InitialFormDataResponse } from "../components/initial-form/initialForm.models";


export function flattenFormData(form: FormGroup): Partial<InitialFormDataResponse> {
    const formValue = form.value;
    const flattenedData: Partial<InitialFormDataResponse> = {};

    Object.keys(formValue).forEach(section => {
        Object.keys(formValue[section]).forEach(field => {
            const value = formValue[section][field];
            if (value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0)) {
                flattenedData[field] = value;
            }
        });
    });

    return flattenedData;
}

export function flattenFormDataForUpdatePersonalInformation(form: FormGroup) {
    const formValue = form.value;

    return {
        primerNombre: formValue.personalInformation.name,
        segundoNombre: formValue.personalInformation.secondName,
        primerApellido: formValue.personalInformation.lastName,
        segundoApellido: formValue.personalInformation.secondLastName,
        birthDay: formValue.personalInformation.birthDate,
        grade: formValue.academicBackground.currentGrade.toString(),
        gradeLevel: formValue.academicBackground.gradeLevel,
        sexo: formValue.personalInformation.gender,
        telefono: formValue.contactInformation.verifiedPhone,
        institutionType: formValue.universityPreference.universityTypePreference,
    };

}
