import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ENGLISH_LEVELS, GENDERS, GRADE_RATES, STUDY_PLACES, UNIVERSITY_DEGREES } from './school-dashboard-filters.constants';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { FilterItem, Grade, GradeLevel, GradeLevelResponse, Level, SchoolDashboardFilter } from './school-dashboard-grade-dropdowns.models';

const GRADE_LEVELS_ENDPOINT = 'api/getGradeLevelsById/';
const SCHOOL_DASHBOARD_BASE_URL = 'api/schoolDashboard';
const STUDENT_NAME_SUGGESTIONS_ENDPOINT = 'studentsNamesSuggestions';

@Component({
  selector: 'app-school-dashboard-filters',
  templateUrl: './school-dashboard-filters.component.html',
  styleUrls: ['./school-dashboard-filters.component.scss']
})
export class SchoolDashboardFiltersComponent implements OnInit {

  schoolId: string = localStorage.getItem('schoolId');
  schoolName: string = localStorage.getItem('school_name');

  englishLevels = [];
  gradeRates = [];
  universityDegrees = [];
  studyPlaces = [];
  genders = [];
  grades = [];
  allGradesLevels = [];
  gradeLevels = [];
  filteredStudents: string[] = [];


  // Reactive search control for filtering by name
  searchControl = new FormControl('');

  // Current filters now include the school name and (optionally) the search term
  currentFilters: SchoolDashboardFilter = {
    schoolName: this.schoolName
  };

  @Output() filtersChanged = new EventEmitter<SchoolDashboardFilter>();

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.englishLevels = ENGLISH_LEVELS;
    this.gradeRates = GRADE_RATES;
    this.universityDegrees = UNIVERSITY_DEGREES;
    this.studyPlaces = STUDY_PLACES;
    this.genders = GENDERS;
  }

  ngOnInit(): void {
    this.loadGradeLevels();
    this.setupNameAutocomplete();
  }

  setupNameAutocomplete(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(300), // Prevents excessive API calls
      distinctUntilChanged(),
      filter(value => value.length >= 3 || value.length === 0), // Only trigger if 3+ characters or empty
      switchMap(searchTerm => {
        this.currentFilters = {
          ...this.currentFilters,
          name: searchTerm
        };
        this.filtersChanged.emit(this.currentFilters);
        return this.fetchStudentSuggestions(searchTerm);
      })
    ).subscribe(results => {
      this.filteredStudents = results || [];
    });
  }
  

  fetchStudentSuggestions(searchTerm: string) {
 
    const apiUrl = `${SCHOOL_DASHBOARD_BASE_URL}/${this.schoolName}/${STUDENT_NAME_SUGGESTIONS_ENDPOINT}?name=${searchTerm}`;
    return this.apiService.get({ api: apiUrl });
  }

  handleSelectionChange(property: keyof SchoolDashboardFilter, items: FilterItem[], itemProperty: string): void {
    this.currentFilters = {
      ...this.currentFilters,
      [property]: items.map(item => item[itemProperty])
    };

    this.filtersChanged.emit(this.currentFilters);
  }

  handleGradeSelectionChange(selected: Grade[]): void {
    if (selected.length > 0) {
      const selectedGrade = selected[0];
      const selectedGradeData = this.allGradesLevels.find(g => g.grade[0] === Number(selectedGrade.id));

      this.gradeLevels = selectedGradeData
        ? selectedGradeData.levels.reduce((acc: Level[], levelArray: string[]) => {
            return acc.concat(levelArray.map(level => ({ id: level, name: level })));
          }, [])
        : [];
    } else {
      this.gradeLevels = [];
    }
    this.handleSelectionChange('grado', selected, 'id');
  }

  handleCountrySelectionChange(selected: FilterItem[]): void {
    if (selected.length > 0) {
      this.handleSelectionChange('pais', selected, 'nombre');
      return;
    }
    this.currentFilters = {
      ...this.currentFilters,
      pais: [],
      ciudadColombia: []
    };
    this.filtersChanged.emit(this.currentFilters);
  }

  isSelectLevelGradeVisible(): boolean {
    return this.currentFilters.grado && this.currentFilters.grado.length > 0;
  }

  isSelectCityVisible(): boolean {
    return this.currentFilters.pais && this.currentFilters.pais.includes('Colombia');
  }

  private loadGradeLevels(): void {
    this.apiService.get({ api: GRADE_LEVELS_ENDPOINT + this.schoolId }).subscribe(
      (response: GradeLevelResponse) => {
        this.formatGradeLevels(response);
      },
      () => {
        this.alertService.showError({ msg: 'Error al cargar niveles de los grados' });
      }
    );
  }

  private formatGradeLevels(gradeLevelsResponse: GradeLevelResponse): void {
    this.allGradesLevels = gradeLevelsResponse.gradesLevels;

    const uniqueGrades = new Set(gradeLevelsResponse.gradesLevels.map((g: GradeLevel) => g.grade[0]));
    this.grades = Array.from(uniqueGrades).map(grade => ({
      id: grade.toString(),
      name: `Grado ${grade}`
    }));
  }

  setStudent(student: string) {
    this.searchControl.setValue(student); 
    this.filteredStudents = []; 
    this.filtersChanged.emit(
      {
        ...this.currentFilters,
        name: student
      }
    ); 
  }

  openAutocomplete() {
    if (this.filteredStudents.length === 0 && this.searchControl.value.length >= 3) {
      this.fetchStudentSuggestions(this.searchControl.value).subscribe(results => {
        this.filteredStudents = results || [];
      });
    }
  }

  onAutocompleteClosed() {
    this.filteredStudents = []; // Hide suggestions when autocomplete closes
  }
}
