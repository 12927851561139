import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NavigationService } from '../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { skillsArray } from '../skillsObject/skills.js';
import { ModalService } from './bulkStudents/modal.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-school-students-list',
  templateUrl: './school-students-list.component.html',
  styleUrls: ['./school-students-list.component.scss'],
})
export class SchoolStudentsListComponent implements OnInit {
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';
  readonly GET_STUDENTS = 'api/skillTestByColegio/student-list';
  readonly GET_SKILLS_LEVELS = 'api/getSkill'; 
  gradeLevels = 'api/getGradeLevelsById/';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  visiblePageRange: number[] = [];
  skillOptions = skillsArray;
  studentsPerPage = 25;
  highlightCurrentPage = false;
  ascendingSortOrder: boolean = false;
  descendingSortOrder: boolean = false;
  pageNumbers: number[] = [];
  totalPages: any;
  allData = []; 
  filteredData = []; 
  skillLevels: any[] = [];

  totalStudents = 0;
  filter = '';
  activePage: number | null = null;
  searchText: string = '';
  selectedGrade: string = '';
  testPresentedFilter: string = '';
  grades: { [key: string]: boolean } = {};
  sortBy: string = '';
  sortOrder: string = '';
  maxPageHorizontal: any;
  schoolData: any;
  studentsData: any;
  nombreColegio = '';
  selectedLink: boolean = false;
  dataSource: any;
  studentData: any;
  studentPerPage: any;
  pageLimit: any;
  pageResult: any;
  hasPrevPage: any;
  hasNextPage: any;
  schoolId: any;
  selectedPage: number = 0;
  prevPage: any;
  showSummaryPages = true;
  nextPage: any;
  link: any;
  linkName: any;
  showAlertLink: boolean = false;
  visibleStudents: any;
  students: any;
  gradesAndLevels: any;
  currentPageHorizontal: number;
  generalScore;
  filteredStudents: any[];
  allStudentsData: any[] = [];
  sizePagination = [];
  user = JSON.parse(localStorage.getItem('user'));
  displayedColumns: string[] = ['nombre', 'grado', 'insignia', 'promedio', 'aplicaciones', 'verperfil'];
  studentFilter: any;
  listTable: any = [];
  allStudent: any;
  selectedGradesLevels: any;
  queryStudents: any = {};
  showFilters: boolean = false;
  showFilterSort: boolean = false;
  showFilterSortUp: boolean = false;
  selectedSkill: string | null = null;
  selectedGradeLevels: string[] = [];
  selectedTestPresented = {
    Si: false,
    No: false,
  };
  selectedGrades = {
    '': false,
    '9': false,
    '10': false,
    '11': false,
  };
  selectedOrder = {
    Ascendente: false,
    Descendente: false,
  };

  columnVisibility = {
    nombre: true,
    grado: true,
    nivelDeGrado: true,
    presentoTest: true,
    promedioGeneral: true,
    liderazgo: true,
    orientacionResultados: true,
    aprendizajeContinuo: true,
    impacto: true,
    globalMind: true,
    creatividadInnovacion: true,
    nivelIngles: true,
    linkHv: true
  };
  
  columnsConfig = [
    { name: 'Nombre', visible: true },
    { name: 'Grado', visible: true },
    { name: 'Nivel de grado', visible: true },
    { name: 'Presentó Test', visible: true },
    { name: 'Promedio General', visible: true },
    { name: 'Liderazgo', visible: true },
    { name: 'Orientación a resultados', visible: true },
    { name: 'Aprendizaje continuo', visible: true },
    { name: 'Impacto', visible: true },
    { name: 'Global Mind', visible: true },
    { name: 'Creatividad e Innovación', visible: true },
    { name: 'Nivel Inglés', visible: true },
    { name: 'Link a HV', visible: true }
  ];
  showColumnDropdown = false;

  

  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService,
    private modalService: ModalService
  ) {}

    // Método para cambiar la visibilidad de una columna
    toggleColumnVisibility(column: string) {
      this.columnVisibility[column] = !this.columnVisibility[column];
    }

    toggleColumnDropdown() {
      this.showColumnDropdown = !this.showColumnDropdown;
    }
    

  ngOnInit(): void {
    document.addEventListener('click', (event) => this.closeFiltersOnOutsideClick(event));
    this.currentPageHorizontal = 0;
    this.getColegio(this.currentPageHorizontal);
    this.getSchoolId();
    this.getGradeLevelsBySchoolId(this.schoolId);
  }
  getSchoolId() {
    this.apiService.get({ api: this.GET_DATA_SCHOOL + this.user._id }).subscribe((response: any) => {
      this.schoolId = response._id;
      this.getGradeLevelsBySchoolId(this.schoolId);
    });
  }
  getGradeLevelsBySchoolId(schoolId: string) {
    this.apiService.get({ api: this.gradeLevels + schoolId }).subscribe(
      (response: any) => {
        const formattedGrades = this.formatGrades(response);
        this.gradesAndLevels = formattedGrades;
        if (response) {
        } else {
          console.error('Invalid response structure:', response);
        }
      },
      (error) => {},
      () => {}
    );
  }

  formatGrades(gradesData): { value: string; display: string }[] {
    let formattedGrades = [];
    const gradeLevels = gradesData.gradesLevels;

    gradeLevels.forEach((item) => {
      const grade = item.grade[0];
      const levels = item.levels;

      levels.forEach((levelPosition) => {
        levelPosition.forEach((level) => {
          const formattedGrade = `${grade}${level}`;
          formattedGrades.push({
            value: formattedGrade,
            display: formattedGrade,
          });
        });
      });
    });

    return formattedGrades;
  }
  nextPageHorizontal() {
    if (this.currentPageHorizontal >= 0 && this.currentPageHorizontal < this.totalPages) {
      this.currentPageHorizontal++;
      this.getColegio(this.currentPageHorizontal);
    }
  }

  previousPageHorizontal() {
    if (this.currentPageHorizontal > 0) {
      this.currentPageHorizontal--;
      this.getColegio(this.currentPageHorizontal);
    }
  }
  goToPageHorizontal(page: number) {
    if (this.activePage === page) {
      this.activePage = null;
    } else {
      this.activePage = page;
      this.currentPageHorizontal = page;
      this.getColegio(page);
    }
  }
  openModal() {
    this.modalService.openModal();
  }
  toggleSummaryPages() {
    this.showSummaryPages = !this.showSummaryPages;
  }
  

onGradeFilterChange() {
  const selectedGradeValues = Object.keys(this.selectedGrades).filter((key) => this.selectedGrades[key]);

  if (selectedGradeValues.length === 0) {
    this.selectedGrade = '';
  } else {
    this.selectedGrade = selectedGradeValues.join(',');
  }

  // Filtrar los datos localmente
  this.applyFilters();
}

onGradeLevelFilterChange(gradeLevel: string, event: Event) {
  const checkbox = event.target as HTMLInputElement;

  if (checkbox.checked) {
    // ✅ Add selected grade level if not already present
    if (!this.selectedGradeLevels.includes(gradeLevel)) {
      this.selectedGradeLevels.push(gradeLevel);
    }
  } else {
    // ✅ Remove grade level when unchecked
    this.selectedGradeLevels = this.selectedGradeLevels.filter(level => level !== gradeLevel);
  }

  // ✅ Apply updated filters
  this.applyFilters();
}

applyFilters() {
  const selectedGrades = Object.keys(this.selectedGrades)
    .filter(key => this.selectedGrades[key])
    .join(',');

  const selectedOrder = this.selectedOrder['Ascendente'] ? 'asc' : this.selectedOrder['Descendente'] ? 'desc' : 'asc';
  const selectedGradeLevelsString = this.selectedGradeLevels.length > 0 
    ? this.selectedGradeLevels.join(',') 
    : "";

  const filters = {
    colegio: this.schoolData._id || '',
    page: 0,
    limit: 25,
    searchText: this.searchText || "",
    selectedGrade: selectedGrades,
    selectedGradeLevel: selectedGradeLevelsString,
    testPresentedFilter: this.selectedTestPresented['Si'] ? 'Si' : this.selectedTestPresented['No'] ? 'No' : '',
    sortBy: this.selectedSkill || "name",
    sortOrder: selectedOrder
  };

  // Llamar a la API con los filtros aplicados
  this.getStudentsList(filters);
}

// Método para actualizar la vista
updateView(data) {
  // Actualiza la tabla, lista, o el componente de la vista
  console.log('Updated View with Data:', data);
}

  onTestPresentedFilte() {
    this.testPresentedFilter = '';
    if (this.selectedTestPresented['Si']) {
      this.testPresentedFilter = 'Si';
    }
    if (this.selectedTestPresented['No']) {
      this.testPresentedFilter = 'No';
    }
    this.getColegio(this.currentPageHorizontal);
  }
  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getColegio(this.currentPageHorizontal);
  }
  onSortOrderChange() {
    this.sortOrder = '';
    if (this.selectedOrder['Ascendente']) {
      this.sortOrder = 'asc';
    }
    if (this.selectedOrder['Descendente']) {
      this.sortOrder = 'desc';
    }
    this.getColegio(this.currentPageHorizontal);
  }

  onSkillFilterChange(skillId: string) {
    if (this.selectedSkill !== skillId) {
      this.selectedSkill = skillId;
      this.sortBy = this.selectedSkill;
      this.getColegio(this.currentPageHorizontal);
    } else {
      this.selectedSkill = null;
    }
  }

  resetFilters() {
    this.selectedSkill = null;
    this.selectedGrades = {
      '': false,
      '9': false,
      '10': false,
      '11': false,
    };
    this.selectedTestPresented = {
      Si: false,
      No: false,
    };
    this.selectedOrder = {
      Ascendente: false,
      Descendente: false,
    };
    this.selectedGrade = '';
    this.testPresentedFilter = '';
    this.sortBy = '';
    this.sortOrder = '';

    this.getColegio(this.currentPageHorizontal);
  }

  addEllipsisToName(name, maxLength) {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    }
    return name;
  }

  isLoading = false;

  async getColegio(page: number) {
    console.log('Fetching data for page:', page);
  
    // Evitar múltiples solicitudes simultáneas
    if (this.isLoading) {
      console.warn('Ya se está procesando una solicitud, por favor espera.');
      return;
    }
  
    this.isLoading = true;
  
    try {
      // Mostrar un spinner mientras se carga la solicitud
      this.ngxSpinnerService.show();
  
      // Realiza la solicitud para obtener datos del colegio
      const response = await this.apiService
        .get({ api: `${this.GET_DATA_SCHOOL}${this.user?._id}` })
        .toPromise();
  
      if (response && response._id) {
        // Asignar datos del colegio
        this.schoolData = response;
        this.nombreColegio = this.schoolData.primerNombre;
  
        // Construir el objeto de consulta
        this.queryStudents = {
          colegio: this.schoolData._id || '',
          page: page || 0,
          limit: this.studentsPerPage || 10,
          searchText: this.searchText || '',
          selectedGrade: this.selectedGrade || '',
          selectedGradeLevel: this.selectedGradeLevels.join(",") || '',
          testPresentedFilter: this.testPresentedFilter || '',
          sortBy: this.sortBy || 'name',
          sortOrder: this.sortOrder || 'asc',
        };
  
        // Obtener la lista de estudiantes
        await this.getStudentsList(this.queryStudents);
      } else {
        console.warn('La respuesta no contiene un ID válido de colegio:', response);
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No se encontraron datos del colegio. Por favor verifica.',
        });
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Algo salió mal en la solicitud, intenta de nuevo más tarde.',
      });
    } finally {
      // Asegurarse de que la bandera y el spinner se liberen al final
      this.isLoading = false;
      this.ngxSpinnerService.hide();
    }
  }
  async getStudentsList(queryStudents: object) {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    // Obtener niveles de habilidades antes de procesar estudiantes
    await this.getSkillLevels();
  
    this.apiService.post({ api: this.GET_STUDENTS, data: queryStudents }).subscribe(
      (resultPaginate) => {
        this.totalStudents = resultPaginate?.totalDocs ?? 0;
        this.totalPages = resultPaginate?.totalPages ?? 0;
        this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i);
  
        this.allStudent = Array.isArray(resultPaginate?.docs) ? resultPaginate.docs : [];
        this.students = this.allStudent;
  
        const formatData = [];
  
        // Diccionario para normalizar nombres de habilidades
        const skillNameMap: Record<string, string> = {
          "InteligenciaEmocional": "Inteligencia Emocional",
          "GlobalMind": "Global Mind",
          "ImpactoResponsabilidad": "Impacto y Responsabilidad Social",
          "AprendizajePermanente": "Aprendizaje Continuo",
          "CreatividadInovacion": "Creatividad e Innovación",
          "OrientacionResultados": "Orientación a Resultados",
          "Liderazgo": "Liderazgo",
          "DesempenoGlobalCompetencias": "Desempeño Global Competencias",
          "Creatividad E Innovación": "Creatividad e Innovación",
        };
  
        for (const student of this.students) {
          this.linkName = Array.isArray(student?.linkHv) ? student.linkHv : [];
          let link = this.linkName.length > 0 ? `${window.location.origin}/web/viewProfile/${this.linkName}` : '';
  
          const englishLanguage = student?.englishRange ?? 'Sin nivel';
          const profilePhoto = student?.documents?.[0]?.filter((document) => document?.nombre === 'photo') ?? [];
  
          // Extraer puntajes de habilidades
          const skillsPoints: Record<string, number> = student?.skillsScore?.[0] ?? {};

           const totalScore = Object.keys(skillsPoints).reduce((acc, key) => {
            if (key !== 'Inteligencia Emocional' && key !== 'InteligenciaEmocional') {
              return acc + (skillsPoints[key] || 0);
            }
            return acc;
          }, 0);

          const averageScore = totalScore / (Object.keys(skillsPoints).length - 1);
          const skillsAverage =  parseFloat(averageScore.toFixed(2));

          if(skillsAverage > 0){
            skillsPoints['Desempeño Global Competencias'] = skillsAverage;

          }

          // Asignar niveles basados en rangos de getSkillLevels()
          const mappedSkills: { [key: string]: string } = {};
          for (const [key, score] of Object.entries(skillsPoints)) {
            const normalizedKey = skillNameMap[key] || key; // Normalizar nombre
    
            const skillLevel = this.skillLevels.find(
              (level) => level.skillName.toLowerCase().trim() === normalizedKey.toLowerCase().trim()
            );
  
            if (skillLevel) {
              const matchedLevel = skillLevel.levels.find((lvl) => score >= lvl.range1 && score < lvl.range2);
              mappedSkills[normalizedKey] = matchedLevel ? matchedLevel.level : 'Sin nivel';
            } else {
              mappedSkills[normalizedKey] = 'Sin nivel';
            }
          }

          const data = {
            userId: student?.userId?.[0] ?? '',
            email: student?.email ?? '',
            name: `${student?.userName?.[0] ?? 'Sin nombre'} ${student?.lastnameUser?.[0] ?? ''}`.trim(),
            grade: student?.schoolGrade ?? '',
            formatedGrade: student?.schoolGrade ?? 'Grado no definido',
            gradeLevel: student?.schoolGradeLevel ?? 'Nivel de grado no definido',
            promedioGeneral: mappedSkills['Desempeño Global Competencias'],
            Liderazgo: mappedSkills['Liderazgo'],
            OrientacionResultados: mappedSkills['Orientación a Resultados'],
            AprendizajePermanente: mappedSkills['Aprendizaje Continuo'],
            ImpactoResponsabilidad: mappedSkills['Impacto y Responsabilidad Social'], 
            GlobalMind: mappedSkills['Global Mind'],
            CreatividadInovacion: mappedSkills['Creatividad e Innovación'],
            InteligenciaEmocional: mappedSkills['Inteligencia Emocional'],
            englishLevel: englishLanguage,
            fulltest: student?.fullTest ?? false,
            linkHv: link,
            linkName: this.linkName,
            presentTest: student.test ? 'Si' : 'No',
            profilePicture: profilePhoto.length > 0 ? profilePhoto[0].archivo : '/assets/sidebar/profile.png',
          };
  
          formatData.push(data);
        }
  
        this.listTable = formatData;
        this.studentsData = new MatTableDataSource(formatData);
        this.studentData = formatData;
        this.filteredStudents = this.studentData.slice(0, resultPaginate?.limit ?? 10);
  
        this.ngxSpinnerService.hide();
        Swal.close();
      },
      (error) => {
        console.error('Error en la solicitud:', error);
        this.ngxSpinnerService.hide();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Algo salió mal en la solicitud, intenta de nuevo más tarde.',
        });
      }
    );
  }

  getSkillLevels(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.get({ api: this.GET_SKILLS_LEVELS }).subscribe(
        (response) => {
          if (!response || !Array.isArray(response)) {
            console.warn('No se recibieron datos de habilidades.');
            this.skillLevels = [];
            return resolve();
          }
  
          this.skillLevels = response.map((skill) => ({
            skillName: skill.skillName,
            levels: skill.levels.map((level) => ({
              level: level.level,
              description: level.description,
              range1: level.range1,
              range2: level.range2,
            })),
          }));
  
          resolve();
        },
        (error) => {
          console.error('Error al obtener los niveles de habilidades:', error);
          this.skillLevels = [];
          reject(error);
        }
      );
    });
  }

  isNextButtonDisabled() {
    return this.currentPageHorizontal >= this.maxPageHorizontal;
  }

  calculateStartResult(): number {
    if (this.totalStudents === 0) {
      return 0;
    }
    const start = this.currentPageHorizontal * this.studentsPerPage + 1;
    return start;
  }

  calculateEndResult(): number {
    if (this.totalStudents === 0) {
      return 0;
    }
    const start = this.currentPageHorizontal * this.studentsPerPage + 1;
    let end = start + this.studentsPerPage - 1;
    if (end > this.totalStudents) {
      end = this.totalStudents;
    }

    return end;
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', (event) => this.closeFiltersOnOutsideClick(event));
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
    if (this.showFilters) {
      this.showFilterSort = false;
      this.showFilterSortUp = false;
    }
  }

  closeFiltersOnOutsideClick(event: MouseEvent): void {
    const filterMenu = document.querySelector('.filter-menu');

    if (filterMenu && !filterMenu.contains(event.target as Node)) {
      this.showFilters = false;
      this.showFilterSort = false;
      this.showFilterSortUp = false;
    }
  }

  toggleFiltersSort() {
    this.showFilterSort = !this.showFilterSort;
    if (this.showFilterSort) {
      this.showFilters = false;
      this.showFilterSortUp = false;
    }
  }

  getByPagination(event) {
    const itemsPerPage = event.pageSize;
    const pageIndex = event.pageIndex;
    this.currentPageHorizontal = pageIndex * (this.studentsPerPage / itemsPerPage);
  }
  navigateToResume(event) {
    this.navigationService.navigateTo({
      path: 'student-resume/' + event,
      params: event,
    });
  }
}
