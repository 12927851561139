import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { UniversityCareer, UniversityCareerFilter } from '../university-careers.models';

const UNIVERSITY_CAREERS_ENDPOINT = 'api/universityCareers/student';
@Component({
  selector: 'app-university-careers-list',
  templateUrl: './university-careers-list.component.html',
  styleUrls: ['./university-careers-list.component.scss']
})
export class UniversityCareersListComponent implements OnInit {
  universityCareers: UniversityCareer[] = [];
  universityCareersFiltered: UniversityCareer[] = [];
  isLoading = false;

  constructor(private readonly apiService: ApiService) { }

  ngOnInit(): void {
    this.getUniversityCareers();
  }

  getUniversityCareers() {
    this.isLoading = true;
    this.apiService.get({ api: UNIVERSITY_CAREERS_ENDPOINT }).subscribe(
      (response: UniversityCareer[]) => {
        this.universityCareers = response;
        this.universityCareersFiltered = response;
      },
      (error) => {
        console.error('Error fetching university careers', error);
      },
      () => {
        this.isLoading = false
      }
    );
  }

  onFilterChange(filter: UniversityCareerFilter) {
    
    const { facultyIds = [], name = '' } = filter;  
    
    this.universityCareersFiltered = this.universityCareers.filter((universityCareer) => {
      const matchesFaculty = facultyIds.length === 0 || facultyIds.some(id => id === universityCareer.faculty._id);
      const matchesName = name.trim() === '' || universityCareer.name.toLowerCase().includes(name.toLowerCase().trim());
  
      return matchesFaculty && matchesName;
    });
  }

  onFavoriteToggled(universityCareer: UniversityCareer) {
    const index = this.universityCareers.findIndex(({ _id }) => _id === universityCareer._id);
    const filteredIndex = this.universityCareersFiltered.findIndex(({ _id }) => _id === universityCareer._id);

    this.universityCareers[index] = universityCareer;
    this.universityCareersFiltered[filteredIndex] = universityCareer;

    //Sort the universityCareersFiltered array by favorite status

    this.universityCareersFiltered.sort((a, b) => Number(b.isFavorite) - Number(a.isFavorite));
    this.universityCareers.sort((a, b) => Number(b.isFavorite) - Number(a.isFavorite));
  }
  

}
