import { NgxSpinnerService } from 'ngx-spinner';
import { PasswordRecoveryComponent } from './../../auth/password-recovery/password-recovery.component';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import csc from 'country-state-city';
import { NavigationService } from '../../services/navigation.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit {
  /** Variable that holds the user api */
  readonly USER_API = 'api/oneUser/';
  user;
  readonly PUT_USER_API = 'api/user/';

  personalInfo: any;
  disciplinesList = [
    {
      value: 'Arts, Design and Architecture',
      name: 'Home.arts-architecture',
      text: 'Artes, Diseño y Arquitectura',
    },
    {
      value: 'Business and Management',
      name: 'Home.business',
      text: 'Negocios y Gestión',
    },
    {
      value: 'Computer Sciences and IT',
      name: 'Home.computer-science',
      text: 'Ciencias Computacionales y TI',
    },
    {
      value: 'Engineering',
      name: 'Home.engineering',
      text: 'Ingeniería',
    },
    {
      value: 'Sciences and Mathematics',
      name: 'Home.sciences-math',
      text: 'Ciencias y Matemáticas',
    },
    {
      value: 'Social Sciences and Humanities',
      name: 'Home.social-sciences',
      text: 'Ciencias Sociales y Humanidades',
    },
    {
      value: 'Education and Sport',
      name: 'Home.education',
      text: 'Educación y Deporte',
    },
    {
      value: 'Community Services',
      name: 'Home.community',
      text: 'Servicios Comunitarios',
    },
    {
      value: 'Environmental Studies and Earth Sciences',
      name: 'Home.env-studies',
      text: 'Estudios Ambientales y Ciencias de la Tierra',
    },
    {
      value: 'Health',
      name: 'Home.health',
      text: 'Salud',
    },
    {
      value: 'Journalism and Media',
      name: 'Home.journalism',
      text: 'Periodismo y Medios',
    },
    {
      value: 'Law and Politic Sciences',
      name: 'Home.law-politics',
      text: 'Derecho y Ciencias Políticas',
    },
    {
      value: 'Languages',
      name: 'Home.languages',
      text: 'Idiomas',
    },
    {
      value: 'Tourism and Hospitality',
      name: 'Home.tourism-hospitality',
      text: 'Turismo y Hospitalidad',
    },
    {
      value: 'Other',
      name: 'Home.other',
      text: 'Otros',
    },
  ];
  /** Variable that holds the id of the current user */
  id: string;

  /** Variable that holds the list of countries */
  countries = [];

  /** Variable that holds the list of cities */
  cities = [];

  /** Variable that shows if the component is loading */
  loading = false;

  originalBirthDate = '';

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private plaformId,
    private translate: TranslateService,
    private ngxSpinnerService: NgxSpinnerService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.plaformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      this.getUserinfo();
      this.countries = csc.getAllCountries();
    }
  }

  getUserinfo(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.USER_API + this.id }).subscribe(
      (response) => {
        this.setStudentInfo(response);

        if (response.pais !== undefined) {
          const states = csc.getStatesOfCountry(response.pais);

          let cities = [];
          for (const state of states) {
            cities = cities.concat(csc.getCitiesOfState(state.id));
          }

          cities.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            } else {
              return 1;
            }
          });
          this.cities = cities;
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Personal-information.load-error'),
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }
  setStudentInfo(student) {
    this.personalInfo = student;
    this.originalBirthDate = this.personalInfo.birthDay;
    this.personalInfo.birthDay = this.datePipe.transform(this.personalInfo.birthDay, 'dd/MM/yyyy');
  }
  /**
   * Saves changes in the profile
   */
  onSaveChanges(): void {
    this.loading = true;
    let data = this.personalInfo;

    if (this.personalInfo.correoPapa && !this.isValidEmail(this.personalInfo.correoPapa)) {
      this.alertService.showError({
        msg: 'El correo electrónico del papá no es válido',
      });
      this.loading = false;
      return;
    }

    if (this.personalInfo.correoMama && !this.isValidEmail(this.personalInfo.correoMama)) {
      this.alertService.showError({
        msg: 'El correo electrónico de la mamá no es válido',
      });
      this.loading = false;
      return;
    }

    data.birthDay = this.originalBirthDate;
    this.apiService.put({ api: this.PUT_USER_API + this.id, data }).subscribe(
      (response) => {
        this.alertService.showSuccess({
          msg: 'Se han actualizado los datos correctamente',
        });

        if (isPlatformBrowser(this.plaformId)) {
          const storedUser = JSON.parse(localStorage.getItem('user'));
          const updatedUser = { ...storedUser, ...this.personalInfo };
          localStorage.setItem('user', JSON.stringify(updatedUser));
        }

        this.navigationService.navigateTo({ path: 'profile' });
      },
      (err) => {
        this.alertService.showError({
          msg: 'Hubo error actualizando los datos',
        });
      }
    );
  }

  /**
   * Handles the selection of a country in the country select
   * @param id country id in the city-state-country library
   */
  onSelectCountry({ id }: { id: string }): void {
    const states = csc.getStatesOfCountry(id);

    let cities = [];
    for (const state of states) {
      cities = cities.concat(csc.getCitiesOfState(state.id));
    }

    cities.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    this.cities = cities;
  }

  /**
   * Opens the modal to change the password
   */
  onChangePasswordClick(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    const email = user.email;
    this.dialog.open(PasswordRecoveryComponent, {
      data: { userId: this.id, userEmail: email },
      height: '400px',
      width: '600px',
    });
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isValidEmail(email: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }
}
