<div class="header">
  <div class="header-container">
    <div class="d-flex">
      <div *ngIf="!isEstudiante && !isUniversity && loggedIn" class="dropdown source display-mobile" dropdown>
        <button class="singin-button-menu btn btn-outline-light" dropdownToggle aria-controls="dropdown-basic">
          <img id="bars-menu" alt="menu" src="assets/navbar/menu-2.png" />
        </button>
        <ul
          id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu drop-menu"
          role="menu"
          aria-labelledby="button-basic">
          <li role="menuitem" routerLink="/myStudents" class="dropdown-item options-item mb-2" *ngIf="!isEstudiante && !isUniversity">
            <img alt="buscador" src="assets/navbar/buscador-icon.png" class="mr-3" />
            Mis Estudiantes
          </li>
          <li role="menuitem" routerLink="/myStudents" class="dropdown-item options-item mb-2" *ngIf="!isEstudiante && !isUniversity">
            <img alt="buscador" src="assets/navbar/buscador-icon.png" class="mr-3" />
            Mis Estudiantes
          </li>
          <li role="menuitem" routerLink="/studentsList" class="dropdown-item options-item mb-2" *ngIf="!isEstudiante && !isUniversity">
            <img alt="buscador" src="assets/navbar/buscador-icon.png" class="mr-3" />
            Dashboard school-comparison-dashboard
          </li>
          <li
            role="menuitem"
            routerLink="/ school-comparison-dashboard"
            class="dropdown-item options-item mb-2"
            *ngIf="!isEstudiante">
            <img alt="buscador" src="assets/navbar/buscador-icon.png" class="mr-3" />
            Comparison Dashboard
          </li>
          <!-- <li
            *ngIf="!loggedIn"
            role="menuitem"
            routerLink="/services"
            target="_blank"
            class="dropdown-item options-item mt-2">
            <img alt="servicios" src="assets/navbar/servicios-icon.png" class="mr-3" />
            Servicios
          </li> -->

          <li
            *ngIf="!isEstudiante"
            role="menuitem"
            routerLink="/program/search"
            class="dropdown-item options-item my-4">
            <img alt="programas" src="assets/navbar/programas-icon.png" class="mr-3" />
            Programas
          </li>
          *ngIf="!loggedIn"
          <li role="menuitem" routerLink="/home" class="dropdown-item options-item mb-2">
            <img alt="buscador" src="assets/navbar/buscador-icon.png" class="mr-3" />
            Buscador
          </li>
        </ul>
      </div>
      <div class="logo-container">
        <img alt="U360 logo" id="home-logo" src="assets/navbar/logo.png" [ngClass]="{ 'mx-4': loggedIn }" />
      </div>
    </div>

    <label
      *ngIf="!isEstudiante && loggedIn && !isUniversity"
      [routerLink]="'/school-profile/' + schoolId"
      routerLinkActive="activo"
      class="nav-options">
      Mi perfil
    </label>

    <div class="row header-options">
      <div
        *ngIf="!isEstudiante && loggedIn && !isUniversity"
        class="dropdown source"
        (mouseenter)="showDropdown = true"
        (mouseleave)="showDropdown = false">
        <div>
          <label class="nav-options">Dashboard</label>
          <div class="dropdown-content" *ngIf="showDropdown">
            <div routerLink="/school-dashboard" class="dropdown-option">Dashboard Mi Colegio</div>
            <div routerLink="/school-comparison-dashboard" routerLinkActive="activo" class="dropdown-option">
              Dashboard Comparativo
            </div>
          </div>
        </div>
      </div>

      <label *ngIf="!isEstudiante && loggedIn && !isUniversity" class="nav-options" routerLink="/myStudents" routerLinkActive="activo">
        Mis Estudiantes
      </label>
      <label
        *ngIf="!isEstudiante && loggedIn && !isUniversity"
        class="nav-options"
        routerLink="/program/search"
        routerLinkActive="activo">
        Programas
      </label>
      <!-- <label *ngIf="!loggedIn" class="nav-options" routerLink="/home/buscador" routerLinkActive="activo">
        Buscador
      </label>
      <a *ngIf="!loggedIn" class="nav-options" target="_blank" routerLink="/services" routerLinkActive="activo">
        <label class="nav-options">Servicios</label>
      </a> -->
    </div>

    <div class="header-singin" *ngIf="!loggedIn">
      <div class="dropdown source" (mouseenter)="showDropdown = true" (mouseleave)="showDropdown = false">
        <button class="singin-button">
          <label class="log-in">Iniciar sesión</label>
        </button>
        <div class="dropdown-content" *ngIf="showDropdown">
          <div
            class="dropdown-option"
            (click)="$event.stopPropagation(); onLoginLogoutClick(); selectedUserType = 'Colegio'">
            Colegio
          </div>
          <div
            class="dropdown-option"
            (click)="$event.stopPropagation(); onLoginLogoutClick(); selectedUserType = 'Estudiante'">
            Estudiante
          </div>
          <div
          class="dropdown-option"
          (click)="$event.stopPropagation(); onLoginLogoutClick(); selectedUserType = 'Universidad'">
          Universidad
        </div>
        </div>
      </div>
    </div>
    <div class="user-container">
      <div *ngIf="loggedIn" class="dropdown">
        <div class="user-info">
          <button (click)="$event.stopPropagation()" id="profile-menu-button">
            <span class="material-icons mx-2" id="icon">person</span>
            <div class="user-name mr-2" *ngIf="loggedIn">{{ username }}</div>
          </button>
        </div>
        <div class="dropdown-content">
          <a class="sign-item" (click)="$event.stopPropagation(); logOut()">
            {{ 'Header.logout' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="menu-responsive" [ngClass]="showMenu ? 'show-container' : 'noShow-container'">
    <div class="menu-container-img">
      <img
        alt="U360 logo"
        id="home-logo"
        src="assets/navbar/logo.png"
        (click)="$event.stopPropagation(); onLogoClick()" />
      <img
        id="close-img"
        alt="close menu"
        src="assets/utils/close-outline.svg"
        (click)="$event.stopPropagation(); showMenuAction()" />
    </div>
    <label id="program-design-two" (click)="showMenuAction()" routerLink="/program/search" routerLinkActive="activo">
      Programas
    </label>
    <label (click)="showMenuAction()" routerLink="/student">Especialistas Hec</label>
    <!-- <label
      *ngIf="!loggedIn"
      (click)="showMenuAction()"
      routerLink="/services"
      target="_blank"
      routerLinkActive="activo">
      Servicios
    </label> -->
    <label (click)="showMenuAction()" routerLink="/favourites" routerLinkActive="activo">Favoritos</label>
    <div class="dropdown source">
      <label>Blog</label>
      <div class="dropdown-content source-content">
        <a (click)="showMenuAction()" routerLink="/blog">Blog</a>
        <a (click)="showMenuAction()" routerLink="/cultural-item">Match Destino</a>
        <a (click)="showMenuAction()" routerLink="/visa-thermometer">Probabilidades Visa</a>
        <a (click)="showMenuAction()" routerLink="/english-test">Test Inglés Gratuito</a>
      </div>
    </div>
  </div>
</div>
