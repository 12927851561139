import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { MatDialogRef } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { S3Service } from '../../services/s3.service';
import { stat } from 'fs';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-experience-example-modal',
  templateUrl: './experiencesRecordModal.component.html',
  styleUrls: ['./experiencesRecordModal.component.scss'],
})
export class ExperienceRecordModalComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  user_id = localStorage.getItem('user_id');
  token = localStorage.getItem('U360token');
  @Input() experienceData: any;
  @Input() needsRedirectToRegisteredExperiences: boolean;
  readonly experiencesApi = 'api/getExperience/';
  readonly schoolApi = 'api/colegio';
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';
  experienceControl = new FormControl('');
  experiences: any;
  skills: any;
  secondarySkills: any;
  plannedExperience: any;
  activityType: any;
  allowAutocomplete = false;
  location: any;
  executionMethod: any;
  currentPage = 1;
  getSupports: any;
  supportsArray = [];
  incomingSupports: any;
  selectedFileTypes: any[] = [];
  files: File[] = [];
  experienceSupports: any[];
  supports: {};
  linksData: any;
  typeSupport: any[] = [];
  documents: any;
  documentsWithData: any[];
  experienceRequired: any;
  experienceResponse: any;
  supervisorEmail: any;
  status: any;
  category: any;
  filteredExperiences: string[] = [];
  searchText: string = ''; // 
  readonly optionsOfMultiSelect: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };
  datepickerConfig: Partial<BsDatepickerConfig>;
  grades = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  statusOptions = [
    { value: 'Approve', name: 'Aprobada' },
    { value: 'Denied', name: 'Rechazada' },

    { value: 'Improve', name: 'Mejorar soportes' },
  ];
  typesOfFile = [
    { id: 'Fotos', name: 'Fotos' },
    { id: 'Certificados', name: 'Certificados' },
    { id: 'Escritos', name: 'Escritos' },
    { id: 'Videos', name: 'Videos' },
    { id: 'Audios', name: 'Audios' },
    { id: 'Portafolio', name: 'Portafolio' },
    { id: 'Articulo', name: 'Articulo' },
    { id: 'Investigación', name: 'Investigación' },
    { id: 'Pagina WEB', name: 'Pagina WEB' },
    { id: 'Plan de Negocio', name: 'Plan de Negocio' },
    { id: 'Notas', name: 'Notas' },
    { id: 'Codigo', name: 'Codigo' },
    { id: 'Otros', name: 'Otros' },
  ];
  socioEmotionalSkills: any[] = [];
  selectedSocioEmotionalSkills: any[] = [];
  socioEmotionalSkillsScores: { socioEmotionalSkill: string; score: number, name: string }[] = [];
  socioEmotionalReflection: string = '';

  constructor(
    public dialogRef: MatDialogRef<ExperienceRecordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private alertService: AlertService,
    private s3Service: S3Service,
    private router: Router
  ) {
    this.datepickerConfig = {
      containerClass: 'theme-dark-blue custom-datepicker',
      dateInputFormat: 'DD/MM/YYYY',
    };
    if (this.data && this.data.needRedirectToRegisteredExperiences) {
      console.log('this.data.needRedirectToRegisteredExperiences', this.data.needRedirectToRegisteredExperiences);
      this.needsRedirectToRegisteredExperiences = this.data.needRedirectToRegisteredExperiences;
    }
  }

  ngOnInit() {
    
  
    this.getExperiences();
    this.experienceControl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      if (typeof value === 'string' && value.trim() !== '') {
        this.filterExperiences(value);
      } else {
        this.filteredExperiences = [...this.experiences];
      }
    });
    this.plannedExperience = {};
    if (this.data && this.data.experienceName) {
      this.experienceData = this.data.experienceName;
      this.plannedExperience.experience = this.experienceData;
    } else if (this.data && this.data.experienceData) {
      this.plannedExperience = this.data.experienceData;
    }

    if (this.plannedExperience.experience) {
      this.experienceControl.setValue(this.plannedExperience.experience);
      this.experienceControl.disable();
    }

    if (this.plannedExperience.socioEmotionalSkills) {
      this.selectedSocioEmotionalSkills = [...this.plannedExperience.socioEmotionalSkills];
    }

    this.getSkills();
    this.getExecutionMode();
    
    this.getExecutionMethod();
    this.getExecutionType();
    this.loadAllSocioEmotionalSkills();
    this.getColegio({ id: this.user.school });

  }

  setSocioEmotionalSkillsFromPlan() {
    if (this.plannedExperience && this.plannedExperience.socioEmotionalSkills) {
      this.socioEmotionalSkillsScores = this.selectedSocioEmotionalSkills.map(skill => ({
        socioEmotionalSkill: skill._id,
        name: skill.name,
        score: 1 // Valor por defecto
      }));
    }


    if (this.plannedExperience.socioEmotionalSkillsScores) {
      this.socioEmotionalSkillsScores = this.plannedExperience.socioEmotionalSkillsScores.map(skill => (
        {
          socioEmotionalSkill: skill.socioEmotionalSkill,
          score: skill.score,
          name: this.socioEmotionalSkills.find(s => s._id === skill.socioEmotionalSkill)?.name
        }
      ));
    }

    if (this.plannedExperience.socioEmotionalReflection) {
      this.socioEmotionalReflection = this.plannedExperience.socioEmotionalReflection;
    }
  }

  getExecutionType() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getExecutionType' }).subscribe((response) => {
        this.activityType = response.map((item) => item.executionType);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  getExecutionMode() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getExecutionMode' }).subscribe((response) => {
        this.location = response.map((item) => item.executionMode);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  getExecutionMethod() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getExecutionMethod' }).subscribe((response) => {
        this.executionMethod = response.map((item) => item.executionMethod);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  getSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getSkill' }).subscribe(
        (response) => {
          this.skills = response.map((item) => item.skillName);
          if (this.data && this.data.experienceData) {
            this.onDropdownChange(this.plannedExperience.principalSkill);
          }

          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  getExperiences() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    this.apiService.get({ api: this.experiencesApi }).subscribe({
      next: (response) => {
        this.experienceResponse = Array.isArray(response) ? response : [];
        this.experienceRequired = this.experienceResponse.filter(
          (item) => item.experienceName === this.plannedExperience.experience
        );
        this.experiences = this.experienceResponse.map((item) => item.experienceName);
        this.getDocumentType();
        this.filteredExperiences = [...this.experiences];
        Swal.close();
      },
      error: (error) => {
        console.error('Error fetching experiences:', error);
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrió un error cargando las experiencias',
        });
      }
    });
  }

  filterExperiences(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredExperiences = this.experiences.filter(exp =>
      exp.toLowerCase().includes(filterValue)
    );
  }

  setExperience(selectedExperience: string) {
    this.plannedExperience.experience = selectedExperience;
    this.experienceControl.setValue(selectedExperience, { emitEvent: false });

    // Update the dropdown skills
    const matchingExperience = this.experienceResponse.find(
      (experience) => experience.experienceName === selectedExperience
    );
    if (matchingExperience) {
      this.skills = matchingExperience.requiredSkills;
      this.plannedExperience.skillsScore = parseInt(matchingExperience.skillsScore);
    }
  }

  openAutocomplete() {
    if (!this.plannedExperience.experience) {
      this.experienceControl.enable();
      this.allowAutocomplete = true;
    }
  }
  onAutocompleteClosed() {
    this.allowAutocomplete = false; // Lo deshabilita cuando se cierra
  }
  getDocumentType() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getDocumentConfig' }).subscribe(
        (response) => {

          
          console.log('response', response);

          const experienceDocuments = this.experienceRequired[0].documentsRequired;
         
          if (Array.isArray(experienceDocuments)) {
            this.documents = response.filter((doc) => experienceDocuments.some((expDoc) => expDoc === doc.document));
          } else {
            this.documents = response.filter((doc) => experienceDocuments === doc.document);
          }
          this.documentsWithData = this.documents.map((doc) => {
            
            return { document: doc.document, documentType: doc.documentType };
          });

          

          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  async getColegio({ id }) {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.schoolApi + '/' + id }).subscribe(
        (response) => {
          this.supervisorEmail = response.aproveEmail;
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  updateTheDocumentsRequire() {
    this.experienceRequired = this.experienceResponse.filter(
      (item) => item.experienceName === this.plannedExperience.experience
    );
  }
  onDropdownChange(selectedValue: any): void {
    this.secondarySkills = this.skills.filter((item) => item !== selectedValue);
  }

  nextPage() {
    if (this.currentPage < 3) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  getFileNameFromURL(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  maxCaractersContent(content: string): string {
    const maxLength = 40;
    if (content.length <= maxLength) {
      return content;
    } else {
      const trimmedContent = content.substring(0, maxLength);
      return trimmedContent + '.....';
    }
  }

  onSelectFileType(selectedValue: any) {
    if (!this.selectedFileTypes.includes(selectedValue)) {
      this.selectedFileTypes.push(selectedValue);
    }
  }

  async uploadFileToS3(file, experienceId, name) {
    return new Promise((resolve, reject) => {
      const key = `experience/${experienceId}/${name}`;

      this.s3Service.uploadFile({
        file: file,
        key: key,
        callback: (err, data) => {
          if (err) {
            console.error('Error uploading file to S3:', err);
            reject(err);
          } else {
            const link = `https://${this.s3Service.S3_BUCKET}.s3.amazonaws.com/${key}`;
            resolve(link);
          }
        },
      });
    });
  }

  async deleteFileFromS3(key, index) {
    if (this.plannedExperience.supportsLinks.linksData) {
      if (index >= 0 && index < this.plannedExperience.supportsLinks.linksData.length) {
        this.plannedExperience.supportsLinks.linksData.splice(index, 1);
      }
    }

    this.apiService.delete({ api: `api/deleteExperienceSupports/${this.plannedExperience._id}` }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.alertService.showSuccess({
            msg: 'Experiencia eliminada correctamente',
          });
        } else {
          this.alertService.showError({
            msg: 'Error al eliminar la experiencia',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema al eliminar la experiencia', err);
        this.alertService.showError({
          msg: 'Error al eliminar la experiencia',
        });
      }
    );

    return new Promise((resolve, reject) => {
      this.s3Service.deleteFileS3({
        key: key,
        callback: (err, data) => {
          if (err) {
            console.error('Error deleting file from S3:', err);
            reject(err);
          }

          resolve(key);
        },
      });
    });
  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(index: number) {
    if (this.files) {
      if (index >= 0 && index < this.files.length) {
        this.files.splice(index, 1);
      }
    }
  }

  handlePostRequest(apiService: ApiService, formData) {
    formData.requestApproval = true;
    formData.isEmailSent = false;

    return new Promise<string>((resolve, reject) => {
      const postData = {
        api: 'api/experiences',
        data: formData,
      };

      apiService.post(postData).subscribe(
        (response) => {
          resolve(response.experienceId);
        },
        (error) => {
          console.error('Error en el POST:', error);
          reject(error);
        }
      );
    });
  }

  handlePutRequest(apiService: ApiService, experienceID: string, formData) {
    if (this.plannedExperience.status === 'Aprobada' || this.plannedExperience.status === 'Rechazada') {
      formData.requestApproval = false;
    }
    if (this.plannedExperience.isApproval) {
      formData.isEmailSent = true;
    }

    const putData = {
      api: `api/experiences/${experienceID}`,
      data: formData,
    };

    apiService.put(putData).subscribe(
      (response) => { },
      (error) => {
        console.error('Error en el PUT:', error);
      }
    );
  }

  async onSubmit() {
    try {
      // Validation: Check required fields and files
      if (
        !this.plannedExperience.experienceName ||
        !this.plannedExperience.experience ||
        !this.plannedExperience.experienceDescription ||
        !this.plannedExperience.principalSkill ||
        !this.plannedExperience.secondarySkill ||
        !this.plannedExperience.activityType ||
        !this.plannedExperience.location ||
        !this.plannedExperience.executionMethod ||
        !this.plannedExperience.startDate ||
        !this.plannedExperience.endDate ||
        !this.plannedExperience.gradeOfExecution ||
        this.files.length === 0
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'Campos incompletos',
          text: 'Todos los campos y soportes son obligatorios.',
          confirmButtonText: 'Aceptar',
        });
        return;
      }
      let experienceId: any;
      const obj = this.buildExperienceObject();

      await this.updateExperienceSupports(experienceId);
      obj.supports = this.supportsArray;

      if (!this.plannedExperience.isEdit) {
        experienceId = await this.handlePostRequest(this.apiService, obj);
        if (this.needsRedirectToRegisteredExperiences) {
          this.router.navigate([`/experience-record/${this.user._id}`]);
        }

        Swal.fire({
          title: 'Experiencia creada!',
          html: 'Tu experiencia será revisada lo más pronto posible, muchas gracias!',
          allowOutsideClick: true,
          showCancelButton: true,
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.dialogRef.close();
          }
        });
        this.apiService.delete({ api: `api/deleteExperiencePlan/${this.plannedExperience.experiencePlannedId}` }).subscribe(
          (response) => {
            console.log('response', response);
          },
          (error) => {
            console.error('Error en el DELETE:', error);
          }
        );
        this.supportsArray = [];
        return;
      }

      this.handlePutRequest(this.apiService, this.plannedExperience._id ?? experienceId, obj);
      this.supportsArray = [];

      Swal.fire({
        title: 'Experiencia editada exitosamente!',
        allowOutsideClick: true,
        showCancelButton: true,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
          this.router.navigate(['/experience-planner' + this.user_id], { queryParams: { fromRegister: 'true' } });
        }
      });

    } catch (error) {
      console.error('An error occurred:', error);
      Swal.fire({
        text: 'Ha ocurrido un error al guardar la experiencia',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  onDelete(): void {
    this.apiService.delete({ api: `api/experiences/${this.plannedExperience._id}` }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.dialogRef.close();
          this.alertService.showSuccess({
            msg: 'Experiencia eliminada correctamente',
          });
          this.getExperiences();
        } else {
          this.alertService.showError({
            msg: 'Error al eliminar la experiencia',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema al eliminar la experiencia', err);
        this.alertService.showError({
          msg: 'Error al eliminar la experiencia',
        });
      }
    );
    this.dialogRef.close();
  }

  private async updateExperienceSupports(experienceId: string | undefined) {


    if (this.files.length == 0) return;
    console.log('this.files', this.files);

    const id = experienceId ?? uuidv4();


    const uploadPromises = this.files.map((file, index) =>
      this.uploadFileToS3(file, id, file.name)
        .then((link) => {
          return link;
        })
        .catch((error) => {
          console.error(`Error uploading ${file.name}:`, error);
          throw error;
        })
    );
    this.experienceSupports = await Promise.all(uploadPromises);

    for (let i = 0; i < this.experienceSupports.length; i++) {
      this.supportsArray.push({
        links: this.experienceSupports[i],
      });
    }
  }


  private buildExperienceObject() {
    return {
      user: this.user,
      experienceName: this.plannedExperience.experienceName,
      experience: this.plannedExperience.experience,
      experienceDescription: this.plannedExperience.experienceDescription,
      principalSkill: this.plannedExperience.principalSkill,
      secondarySkill: this.plannedExperience.secondarySkill,
      activityType: this.plannedExperience.activityType,
      location: this.plannedExperience.location,
      executionMethod: this.plannedExperience.executionMethod,
      startDate: this.plannedExperience.startDate,
      endDate: this.plannedExperience.endDate,
      gradeOfExecution: this.plannedExperience.gradeOfExecution,
      experienceCategory: this.plannedExperience.experienceCategory
        ? this.plannedExperience.experienceCategory
        : this.category,
      skillsScore: this.plannedExperience.skillsScore
        ? this.plannedExperience.skillsScore
        : Number(this.experienceRequired[0].skillsScore),
      supports: this.supports,
      recommendation: this.plannedExperience.improveMessage,
      supervisorEmail: this.supervisorEmail,
      status: this.plannedExperience.status ? this.plannedExperience.status : 'En revisión',
      socioEmotionalSkillsScores: this.socioEmotionalSkillsScores.map(skill => ({
        socioEmotionalSkill: skill.socioEmotionalSkill,
        score: skill.score
      })),
      socioEmotionalReflection: this.socioEmotionalReflection,
    }
  }

  private loadAllSocioEmotionalSkills() {
    this.apiService.get({ api: 'api/socioEmotionalSkills' })
      .subscribe((skills: any[]) => {
        this.socioEmotionalSkills = skills;
      }, err => {
        this.alertService.showError({ msg: 'Error cargando habilidades socioemocionales.' });
      },
        () => {
          this.setSocioEmotionalSkillsFromPlan();
        }

      );
  }

  compareSkills(skill1: any, skill2: any): boolean {
    return skill1?._id === skill2?._id;
  }

  setStarScore(skillIndex: number, score: number): void {
    this.socioEmotionalSkillsScores[skillIndex].score = score;
  }
  updateSocioEmotionalSkillsScores() {
    // Obtener solo los IDs de las habilidades seleccionadas
    const selectedIds = this.selectedSocioEmotionalSkills.map(skill => skill._id);

    // Filtrar habilidades no seleccionadas de socioEmotionalSkillsScores
    this.socioEmotionalSkillsScores = this.socioEmotionalSkillsScores.filter(skill => selectedIds.includes(skill.socioEmotionalSkill));

    // Agregar nuevas habilidades seleccionadas que no estén en socioEmotionalSkillsScores
    this.selectedSocioEmotionalSkills.forEach(skill => {
      if (!this.socioEmotionalSkillsScores.some(s => s.socioEmotionalSkill === skill._id)) {
        this.socioEmotionalSkillsScores.push({
          socioEmotionalSkill: skill._id,
          name: skill.name,
          score: 1, // Default: 0 estrellas
        });
      }
    });
  }
}
