import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-initial-form-table',
  templateUrl: './initial-form-table.component.html',
  styleUrls: ['./initial-form-table.component.scss']
})
export class InitialFormTableComponent {
  @Input() form: FormGroup;
  @Input() controlName: string = '';
  @Input() rows: { value: string; label: string, tooltip?: string }[] = [];
  @Input() columns: { value: string; label: string; toolTip?: { title: string, text: string[] } }[] = [];

  @Input() selectionHandler?: (rowValue: string, columnValue: string) => void;

  @Input() isSelectedHandler?: (rowValue: string, columnValue: string) => boolean;


  selectValue(rowValue: string, columnValue: string): void {
    if (this.selectionHandler) {
      this.selectionHandler(rowValue, columnValue);
      return;
    }

    const controlPath = `${this.controlName}${rowValue}`;
    if (this.form.get(controlPath)) {
      this.form.get(controlPath).setValue(columnValue);
    }
  }

  isSelected(rowValue: string, columnValue: string): boolean {
    if (this.isSelectedHandler) {
      return this.isSelectedHandler(rowValue, columnValue);
    }

    const controlPath = `${this.controlName}${rowValue}`;
    return this.form.get(controlPath)?.value === columnValue;
  }

  getTooltipText(columnValue: string): string[] {
    const column = this.columns.find(col => col.value === columnValue);
    return column?.toolTip?.text || [];
  }


  hasTooltip(columnValue: string): boolean {
    return this.columns.some(col => col.value === columnValue && col.toolTip);
  }
}
