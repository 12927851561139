<section>

    <div *ngIf="isLoading" class="loading-container">
        <mat-spinner diameter="40" ></mat-spinner>
    </div>

    <ng-container *ngIf="!isLoading">
        <app-university-careers-filter (filterChange)="onFilterChange($event)"></app-university-careers-filter>
        <div class="careers-list">
            <app-university-careers-card (favoriteToggled)="onFavoriteToggled($event)" *ngFor="let universityCareer of universityCareersFiltered" [universityCareer]="universityCareer"></app-university-careers-card>
        </div>
    </ng-container>


</section>