<div class="container pt-4">
  <div class="row">
    <div class="col-12">
      <h4 class="titulo-wizard">Simulación de Progreso Total y por Competencia:</h4>
      <p>
        En esta sección verás reflejado tu avance general y por competencias teniendo en cuenta experiencias planeadas y
        aprobadas!
      </p>
      <hr class="hrLine" />
    </div>
  </div>

  <!-- Grade Selector -->
  <div class="filter-container" *ngIf="skills.length > 0">
    <mat-form-field class="mr-3">
      <mat-label>Año escolar</mat-label>
      <mat-select [(value)]="selectedGrade" (selectionChange)="onSelectedGradeChange($event.value)">
        <mat-option *ngFor="let grade of grades" [value]="grade">{{ grade }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="loading" *ngIf="skills.length === 0 && !filteredSimulationData">
    <p>Cargando...</p>
  </div>

  <!-- Display message if no data exists -->
  <div class="loading" *ngIf="skills.length > 0 && (!filteredSimulationData && simulationChartData)">
    <p>No hay datos disponibles para mostrar.</p>
  </div>
  
  <!-- Display simulation chart only if data exists -->
  <div *ngIf="filteredSimulationData && skills">
    <h4 class="titulo-wizard">Competencias Específicas</h4>
    <ng-container *ngFor="let skill of skills">
      <app-simulation-chart [passedSkill]="skill" [data]="filteredSimulationData">
      </app-simulation-chart>
    </ng-container>
  </div>

</div>