import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Validador personalizado para correos electrónicos
function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    const valid = email && email.includes('@');
    return valid ? null : { invalidEmail: true };
  };
}

@Component({
  selector: 'app-page-two',
  templateUrl: './pageTwo.component.html',
  styleUrls: ['./pageTwo.component.scss'],
})
export class PageTwoComponent implements OnInit {
  @Input() form: FormGroup;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form.get('schoolEmail')?.setValidators([Validators.required, emailValidator()]);
    this.form.get('personalEmail')?.setValidators([Validators.required, emailValidator()]);
    this.form.get('schoolEmail')?.updateValueAndValidity();
    this.form.get('personalEmail')?.updateValueAndValidity();

    this.setupDynamicValidation();
  }

  onPhoneCheckboxChange(): void {
    this.toggleFieldValidationAndClearValue('verifiedPhone', 'hasNotPhone');
    this.form.get('verifiedPhone')?.reset(); // Limpia el campo de texto
    this.form.get('verifiedPhone')?.disable(); // Inhabilita el campo de texto
  }

  onWhatsAppCheckboxChange(): void {
    this.toggleFieldValidationAndClearValue('verifiedWhatsApp', 'hasNotWhatsApp');
    this.form.get('verifiedWhatsApp')?.reset(); // Limpia el campo de texto
    this.form.get('verifiedWhatsApp')?.disable(); // Inhabilita el campo de texto
  }

  private setupDynamicValidation(): void {
    this.toggleFieldValidationAndClearValue('verifiedPhone', 'hasNotPhone');
    this.toggleFieldValidationAndClearValue('verifiedWhatsApp', 'hasNotWhatsApp');
  }

  private toggleFieldValidationAndClearValue(fieldName: string, checkboxName: string): void {
    const checkboxControl = this.form.get(checkboxName);
    const fieldControl = this.form.get(fieldName);

    if (checkboxControl?.value) {
      fieldControl?.reset(); // Limpia el campo y errores
      fieldControl?.disable({ emitEvent: false }); // Deshabilita el campo
    } else {
      fieldControl?.enable({ emitEvent: false }); // Habilita el campo
      fieldControl?.setValidators([Validators.required]);
    }

    fieldControl?.updateValueAndValidity();
    this.cdr.detectChanges(); // Forzar sincronización de la vista
  }
}