<form class="form" [formGroup]="form" (ngSubmit)="submitData('submitForm')">
  <div class="mat-card-header header">
    <p class="currentPage">Paso {{ currentPage }}/5</p>
    <button type="button" (click)="closeWithoutSaving()" class="close-button"><mat-icon>close</mat-icon></button>
  </div>
  <div class="form-container">
    <app-page-one *ngIf="currentPage === 1" [form]="form"></app-page-one>
    <app-page-two *ngIf="currentPage === 2" [form]="form"></app-page-two>
    <app-page-three
      *ngIf="currentPage === 3"
      [form]="form"
      [universitiesOfColombia]="universitiesOfColombia"
      [abroadUniversities]="abroadUniversities"
      [citiesOfColombia]="citiesOfColombia"></app-page-three>
    <app-page-four *ngIf="currentPage === 4" [form]="form"></app-page-four>
    <app-page-five *ngIf="currentPage === 5" [form]="form" [age]="age"></app-page-five>
  </div>

  <div class="navigation-buttons">
    <button class="navigationButton" *ngIf="currentPage > 1" type="button" (click)="previousPage()">Atras</button>
    <button
      class="principalButton"
      *ngIf="currentPage < totalPages"
      type="button"
      (click)="nextPage(); submitData('nextPage')">
      Siguiente
    </button>
    <button class="principalButton" *ngIf="currentPage === totalPages" type="button" (click)="submitData('submitForm')">
      Guardar
    </button>
    <button
      class="principalButton"
      *ngIf="currentPage === totalPages"
      type="button"
      (click)="submitDataAndDoOtherThings()">
      Publicar
    </button>
  </div>
</form>
