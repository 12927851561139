<main class="dashboard">
  <div *ngIf="dashboardData && dashboardData.length !== 0 && selectedOptionUniversity; else noTestData">
    <div class="col-xl-12" *ngIf="!isReadOnly">
      <img class="back-img" src="/assets/wizard-images/back.png" alt="back" (click)="onBackButtonClick()" />
    </div>
    <div class="row mx-0" [class.component-container]="isReadOnly">
      <div class="row col-xl-12 container-profile" *ngIf="filteredResponse && filteredResponse[0]">
        <div class="col-xl-8 col-12 column-info-profile">
          <p>
            <strong class="evaluated-profile">Ajuste de perfil vs</strong>
            <span class="type-university">{{ selectedOptionUniversity }}</span>
          </p>

          <p class="profile-description display-mobile-none" [innerHTML]="filteredResponse[0].description | breakLines">
          </p>
        </div>

        <div class="col-xl-3 container-college-img">
          <img src="/assets/wizard-images/college.png" alt="universidad-img" class="college-img" />
        </div>

        <div class="col-xl-12 display-mobile-none" style="margin: 2%">
          <h3 class="title-adjustment-profile">Ajuste del estudiante al perfil</h3>
          <div
            style="margin-bottom: 1%"
            [ngClass]="{ 'config-progress': averageScore < desiredInstitutionAverage[0].score }">
            <span class="reached">Alcanzado {{ averageScore }}%</span>
            <progress
              class="progressBar"
              [value]="averageScore"
              [max]="desiredInstitutionAverage[0].score"
              style="vertical-align: revert !important"></progress>
            <span class="desired">{{ desiredInstitutionAverage[0].score }}% Deseable</span>
          </div>
        </div>
      </div>

      <div class="row col-12 border-bottom">
        <h2 class="title-section">Tu puntaje global</h2>
      </div>
      <div class="globalScoreSection">
        <div class="radar-chart-container">
          <apx-chart
            [tooltip]="radarChart.tooltip"
            [yaxis]="radarChart.yaxis"
            [xaxis]="radarChart.xaxis"
            [colors]="radarChart.colors"
            [series]="radarChart.series"
            [chart]="radarChart.chart"
            [plotOptions]="radarChart.plotOptions"
            [markers]="radarChart.markers"
            [grid]="radarChart.grid"
            [labels]="radarChart.labels"
            [legend]="radarChart.legend"
            [dataLabels]="radarChart.dataLabels"></apx-chart>
        </div>
      </div>

      <div class="charts-container">
        <div class="chart-wrapper">
          <div class="row col-xl-12 skills-section border-bottom">
            <h2 class="title-section">Promedio general</h2>
          </div>
          <div *ngIf="institutionLevel" class="container-result">
            <apx-chart
              [series]="averageChartoptions.series"
              [chart]="averageChartoptions.chart"
              [plotOptions]="averageChartoptions.plotOptions"
              [fill]="averageChartoptions.fill"
              [stroke]="averageChartoptions.stroke"
              [labels]="averageChartoptions.labels">
            </apx-chart>
            <div class="row container-tip">
              <div class="col-1">
                <img class="tip-img" src="/assets/dashboard-skill/tips-puntaje-global/cara-feliz.png" alt="cara feliz" />
              </div>

              <div *ngIf="institutionLevel.description" class="col-10">
                <p class="desc-tip" [innerHTML]="institutionLevel.description | breakLines">">
                </p>
              </div>
            </div>

            <div class="row container-tip-alert">
              <div class="col-1">
                <img class="tip-img" src="/assets/dashboard-skill/tips-puntaje-global/alerta.png" alt="cara feliz" />
              </div>

              <div class="col-10 container-tip-alert">
                <p class="desc-tip">
                  No olvides asesorarte para revisar requisitos específicos de admisión cómo exámenes
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="chart-wrapper">
          <div class="row col-xl-12 skills-section border-bottom">
            <h2 class="title-section">Promedio habilidades destacadas</h2>
          </div>
          <div *ngIf="bestSkillsChartOptions" class="container-result">
            <p class="best-skills-text">
              <strong>Mejores habilidades:</strong>
            </p>
            <ul class="best-skills-list">
              <li *ngFor="let skill of bestSkillsNames">{{ skill }}</li>
            </ul>
            <apx-chart
              [series]="bestSkillsChartOptions.series"
              [chart]="bestSkillsChartOptions.chart"
              [plotOptions]="bestSkillsChartOptions.plotOptions"
              [labels]="bestSkillsChartOptions.labels">
            </apx-chart>
          </div>
        </div>
      </div>

      <div class="row col-xl-12 skills-section border-bottom">
        <h2 class="title-section">Recomendaciones por competencias</h2>
      </div>

      <div class="row col-xl-12 pr-0">
        <div class="col-xl-12 card style-card" *ngFor="let skill of combinedData">
          <div class="card-body px-0">
            <div class="col-xl-12">
              <h3 class="title-skill">{{ skill.name }} ({{ skill.proficiencyLevel }})</h3>
            </div>
            <div class="col-xl-12">
              <p class="desc-skill" [innerHTML]="skill.description | breakLines"></p>
              <p class="desc-skill"><strong>Nivel</strong> {{ skill.level }}</p>
            </div>
            <div
              class="col-xl-12 bottomSection"
              style="margin-bottom: 1%"
              [ngClass]="{ 'config-progress': skill.skillScore < skill.desiredScore }">
              <div class="skillScore">
                <span class="reached">Alcanzado {{ skill.skillScore }}%</span>
                <progress
                  class="progressBar"
                  [value]="skill.skillScore"
                  [max]="skill.desiredScore"
                  style="vertical-align: revert !important"></progress>
                <span class="desired">{{ skill.desiredScore }}% Deseable</span>
              </div>
              <div style="text-align: end">
                <label (click)="displayScreen(skill)"><span class="view-more-link">Ver mas</span></label>
                <label (click)="handleHowToImproveClick(skill.name)">
                  <span class="how-to-improve">| Cómo mejorar</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row col-xl-12 checklist-section">
        <h2 class="title-section">Nivel de inglés</h2>
      </div>
      <div class="view-more-link">
        <div class="col-xl-12 bottomSection" style="margin-bottom: 1%">
          <div class="skillScore englishLevelCard" *ngFor="let level of englishLevels">
            <div class="col-xl-12 card style-card" [ngClass]="{ 'green-background': level === englishLevel }">
              <div class="card-body px-0">
                <span class="view-more-link">{{ level }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noTestData>
    <div *ngIf="isReadOnly && !userData">
      <div class="col-xl-12">
        <img class="back-img" src="/assets/wizard-images/back.png" alt="back" (click)="onBackButtonClick()" />
      </div>

      <div *ngIf="isReadOnly" class="col-xl-12">
        <p class="px-5 pt-4">No hay resultados...</p>
      </div>
    </div>
    <div *ngIf="!dashboardData || dashboardData.length === 0">
      <p class="titleAnalysis">
        ¡Aún no has hecho el test de competencias!
      </p>
      <button class="btn-experiences" (click)="goToTest()">Realizar</button>
      <!-- Gráfico en cero -->
      <div class="globalScoreSection">
        <div class="radar-chart-container">
          <apx-chart
            [tooltip]="radarChart.tooltip"
            [yaxis]="radarChart.yaxis"
            [xaxis]="radarChart.xaxis"
            [colors]="radarChart.colors"
            [series]="[{
              name: 'Puntaje',
              data: [0, 0, 0, 0, 0, 0]
            }]"
            [chart]="radarChart.chart"
            [plotOptions]="radarChart.plotOptions"
            [markers]="radarChart.markers"
            [grid]="radarChart.grid"
            [labels]="radarChart.labels"
            [legend]="radarChart.legend"
            [dataLabels]="radarChart.dataLabels"></apx-chart>
        </div>
      </div>
    </div>
    <div *ngIf="!selectedOptionUniversity">
      <p class="titleAnalysis">
        ¡Aún no has seleccionado ningún tipo de universidad! Elige tu tipo de universidad para conocer tu nivel para la
        universidad de tus sueños!
      </p>
      <button class="btn-experiences" (click)="goToUniversityType()">Elegir</button>
    </div>
  </ng-template>
</main>
