<mat-form-field appearance="fill" style="width: 100%">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    (blur)="validateOption()"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>