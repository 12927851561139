<div class="card menu-container" 
     [ngClass]="{ 
       'menu-collapsed-mobile': !isActiveMenuMobile, 
       'college-menu': isColegio, 
       'full-menu': isStudent 
     }" 
     #boxSidebarMenu 
     *ngIf="isStudent || isRecruitment">

  <div class="card-body menu-options-container">
    <!-- Menu Option: Perfil Inicial -->
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!(menuOptions.documents || isConoceteVisible)"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/documentos-2-gris.png" alt="documents" />
        <p 
          routerLink="/profile" 
          [ngClass]="{
            'option-active': menuOptions.formUconnections || isConoceteVisible,
            'display-mobile-none': !isActiveMenuMobile
          }" 
        (click)="activeOption('formUconnections'); toggleConocete(); isPublished !== 'false' && openSimulationChart()">
          Perfil Inicial
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="isConoceteVisible">
        <div class="optionWrapper">
          <p 
            [ngClass]="{ 'option-active': menuOptions.formUconnections }" 
            routerLink="/profile" 
            (click)="openSimulationChart(); activeOption('formUconnections');">
            Formulario Conexión U
          </p>
        </div>
      </div>
    </div>
    <div class="mis-experiencias" *ngIf="isConoceteVisible">
      <div class="optionWrapper">
        <p [ngClass]="{ 'option-active': menuOptions.documents }" routerLink="/profile" (click)="activeOption('documents')">Perfil de inicio</p>
      </div>
    </div>

    <hr *ngIf="isStudent || isRecruitment" />

    <!-- Menu Option: Conocete y Explora -->
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
        <div class="barra" [hidden]="!menuOptions.exploreSkills || showDashboard"></div>
        <div class="menu-options">
          <img src="/assets/principal-menu/cv.png" alt="cv" />
          <p 
            [ngClass]="{
              'option-active': showDashboard || menuOptions.exploreSkills, 
              'display-mobile-none': !isActiveMenuMobile
            }" 
            routerLink="explore-skills" 
            (click)="activeOption('exploreSkills'); toggleDashboard();">
            Conocete y Explora
          </p>
        </div>
        <div class="mis-experiencias" *ngIf="showDashboard">
          <div class="optionWrapper">
            <p [ngClass]="{ 'option-active': menuOptions.exploreSkills }" routerLink="explore-skills" (click)="activeOption('exploreSkills')">Explorar competencias</p>
          </div>
          <div class="optionWrapper">
            <p [ngClass]="{ 'option-active': showTest || menuOptions.testIA }" routerLink="leaderTest" (click)="activeOption('testIA'); toggleInitialTest()">Test Competencias</p>
          </div>
          <div class="tests-iniciales" *ngIf="showTest">
            <div class="optionWrapper">
              <p [ngClass]="{ 'option-active': menuOptions.testIA }" routerLink="/leaderTest" (click)="activeOption('testIA')">Iniciar</p>
            </div>
            <div class="optionWrapper">
              <p [ngClass]="{ 'option-active': menuOptions.initalResults }" routerLink="current-test" (click)="activeOption('initalResults')">Resultados</p>
            </div>
          </div>
          <div class="optionWrapper">
            <p [ngClass]="{ 'option-active': showEnglishTest || menuOptions.englishTest }" routerLink="english-test" (click)="activeOption('englishTest'); toggleEnglishTest()">Test Inglés</p>
          </div>
          <div class="tests-ingles" *ngIf="showEnglishTest">
            <div class="optionWrapper">
              <p [ngClass]="{ 'option-active': menuOptions.englishTest }" routerLink="/english-test" (click)="activeOption('englishTest')">Iniciar</p>
            </div>
            <div class="optionWrapper">
              <p [ngClass]="{ 'option-active': menuOptions.englishResults }" [routerLink]="'english-test/' + languageLevel" (click)="activeOption('englishResults')">Resultados</p>
            </div>
          </div>
        <div class="optionWrapper">
          <p [ngClass]="{ 'option-active': menuOptions.vocationalTest }" routerLink="/vocational-test" (click)="activeOption('vocationalTest')">Test Vocacional</p>
        </div>
      </div>
    </div>
    <hr />

    <!-- Menu Option: Explora y planea -->
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!(menuOptions.institutionType || showExplore)"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/cv.png" alt="cv" />
        <p 
          routerLink="/experience-example" 
          [ngClass]="{
            'option-active': menuOptions.experiencesExample || showExplore,
            'display-mobile-none': !isActiveMenuMobile
          }" 
          (click)="activeOption('experiencesExample'); toggleExplore()">
          Contruye Tú Perfil
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="showExplore">
          <div class="optionWrapper">
            <p [ngClass]="{ 'option-active': menuOptions.experiencesExample }" routerLink="experience-example" (click)="activeOption('experiencesExample')">Explora Experiencias.</p>
          </div>
          <div class="optionWrapper" >
            <p [ngClass]="{ 'option-active': menuOptions.institutionType }" routerLink="universityType" (click)="activeOption('institutionType')">Tipo de instituciones</p>
          </div>
          <div class="optionWrapper">
            <p [ngClass]="{ 'option-active': menuOptions.experiencesPlanner }" [routerLink]="['/experience-planner']" (click)="activeOption('experiencesPlanner')">Planea Tus Experiencias</p>
          </div>
          <div class="optionWrapper">
            <p [ngClass]="{ 'option-active': menuOptions.experiences }" [routerLink]="['/experience-record', userId]" (click)="activeOption('experiences')">Experiencias Registradas</p>
          </div>
          <!-- <div class="optionWrapper">
            <p
              [ngClass]="{ 'option-active': menuOptions.SimulationChartSkills }"
              [routerLink]="['/experience-record', userId]"
              (click)="activeOption('SimulationChartSkills'); openSimulationChartSkills(filteredExperiences[0])"
            >
              Simulación de Progreso Total
            </p>
          </div>-->
          <div class="optionWrapper">
            <p [ngClass]="{ 'option-active': showProgression || menuOptions.progressionChart }" routerLink="progression" (click)="activeOption('progressionChart'); toggleProgression()">Progreso</p>
          </div>
          <div class="progression-chart" *ngIf="showProgression">
            <div class="optionWrapper">
              <p [ngClass]="{ 'option-active': menuOptions.progressionChart }" routerLink="progression" (click)="activeOption('progressionChart')">Progreso Real</p>
            </div>
            <div class="optionWrapper">
              <p [ngClass]="{ 'option-active': menuOptions.dashboard }" routerLink="/skill-dashboard" (click)="activeOption('dashboard')">Progreso Vs Universidades</p>
            </div>
          </div>
      </div>
    </div>
    <hr *ngIf="isStudent || isRecruitment" />

    <!-- Menu Option: Desarrolla y Expón -->
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!(menuOptions.cv || showMisExperiencias)"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/cv.png" alt="cv" />
        <p 
          [ngClass]="{
            'option-active': menuOptions.cv || showMisExperiencias,
            'display-mobile-none': !isActiveMenuMobile
          }" 
          routerLink="/curriculum" 
          (click)="activeOption('cv'); toggleMisExperiencias()">
          Alista Tú Perfil
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="showMisExperiencias">
        <div class="optionWrapper">
          <p [ngClass]="{ 'option-active': menuOptions.cv }" routerLink="/curriculum" (click)="activeOption('cv')">Hoja de vida</p>
        </div>
        <div class="optionWrapper">
          <p [ngClass]="{ 'option-active': menuOptions.coverLetter }" routerLink="/cover-letter" (click)="activeOption('coverLetter')">Ensayo De Admisión</p>
        </div>
      </div>
    </div>

    <hr *ngIf="isStudent || isRecruitment" />

    <!-- Menu Option: Conéctate y aplica -->
    <div id="myProfileContainer" class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!(menuOptions.search || showMatch)"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/loupe.png" alt="search" />
        <p 
          [ngClass]="{
            'option-active': menuOptions.search || showMatch,
            'display-mobile-none': !isActiveMenuMobile
          }" 
          routerLink="/home" 
          (click)="activeOption('search'); toggleMatch()">
          Conéctate
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="showMatch">
        <div class="optionWrapper">
          <p [ngClass]="{ 'option-active': menuOptions.search }" routerLink="/home" (click)="activeOption('search')">Explorador Programas y Universidades</p>
        </div>
        <div class="optionWrapper">
          <p [ngClass]="{ 'option-active': menuOptions.favourites }" routerLink="/wishlist" (click)="activeOption('favourites')">Wishlist</p>
        </div>
      </div>
    </div>
  </div>
</div>