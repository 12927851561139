import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-step-personal-information',
  templateUrl: './step-personal-information.component.html',
  styleUrls: ['./step-personal-information.component.scss']
})
export class StepPersonalInformationComponent {
  @Input() form: FormGroup;

  constructor() { }
}
