import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-academic-interests',
  templateUrl: './academic-interests.component.html',
  styleUrls: ['./academic-interests.component.scss'],
})
export class AcademicInterestsComponent implements OnInit {
  data: any;
  form: FormGroup;
  currentPage: number = 1;
  totalPages: number = 5;
  universitiesOfColombia: any;
  abroadUniversities: any;
  citiesOfColombia: any;
  readonly getColombiaUniversities = 'api/universidad';
  readonly countries = 'api/pais/';
  readonly cities = 'api/ciudad/pais/';
  readonly getInitialForm = 'api/getInitialDataFormById/';
  user = JSON.parse(localStorage.getItem('user'));
  constructor(private fb: FormBuilder, private apiService: ApiService) {}
  userId = JSON.parse(localStorage.getItem('user'));

  ngOnInit(): void {
    const initialFormUrl = `${this.getInitialForm}${this.user._id}`;
    this.apiService.get({ api: initialFormUrl }).subscribe(
      (response) => {
        this.data = response;
        this.createForm();
      },
      (error) => {
        console.error('Error fetching initial data:', error);
      }
    );

    const citiesURL = `${this.cities}63f7c7981eb43b0012ea3d84`;
    const url = `${this.getColombiaUniversities}`;
    const countriesURL = `${this.countries}`;

    this.apiService.get({ api: url }).subscribe(
      (response) => {
        this.universitiesOfColombia = response.docs
          .filter((university) => university.ciudad.pais.nombre === 'Colombia')
          .map((university) => university.nombre);
      },
      (error) => {
        console.error('Error fetching universities:', error);
      }
    );

    this.apiService.get({ api: countriesURL }).subscribe(
      (response) => {
        this.abroadUniversities = response.map((university) => university.nombre);
      },
      (error) => {
        console.error('Error fetching universities:', error);
      }
    );

    this.apiService.get({ api: citiesURL }).subscribe(
      (response) => {
        this.citiesOfColombia = response.map((city) => city.nombre);
      },
      (error) => {
        console.error('Error fetching universities:', error);
      }
    );
  }

  createForm() {
    this.form = this.fb.group({
      userId: [this.userId._id],
      school: [this.data.school || ''],
      schoolCity: [this.data.schoolCity || ''],
      currentGrade: [this.data.currentGrade || ''],
      gradeLevel: [this.data.gradeLevel || ''],
      name: [this.data.name || ''],
      secondName: [this.data.secondName || ''],
      lastName: [this.data.lastName || ''],
      secondLastName: [this.data.secondLastName || ''],
      schoolEmail: [this.data.schoolEmail || ''],
      personalEmail: [this.data.personalEmail || ''],
      verifiedPhone: [this.data.verifiedPhone || ''],
      verifiedWhatsApp: [this.data.verifiedWhatsApp || ''],
      linkedin: [this.data.linkedin || ''],
      xProfile: [this.data.xProfile || ''],
      tiktokProfile: [this.data.tiktokProfile || ''],
      instagramProfile: [this.data.instagramProfile || ''],
      facebookProfile: [this.data.facebookProfile || ''],
      consent: [this.data.consent || false],
      birthDate: [this.data.birthDate || null],
      gender: [this.data.gender || ''],
      subscribe: [this.data.subscribe || false],
      studyInterest: [this.data.studyInterest || ''],
      univertiesSelected: [this.data.univertiesSelected || []],
      englishLevel: [this.data.englishLevel || ''],
      colombianUniversities: this.fb.array(this.data.colombianUniversities || []),

      abroadCountries: this.fb.array(this.data.abroadCountries || []),
      primaryCity: [this.data.primaryCity || ''],
      secondaryCity: [this.data.secondaryCity || ''],
      careerInterest: [this.data.careerInterest || []],
      estimatedStartDate: [this.data.estimatedStartDate || null],
      academicRatings7: [this.data.academicRatings7 || 'Aun no curso'],
      academicRatings8: [this.data.academicRatings8 || 'Aun no curso'],
      academicRatings9: [this.data.academicRatings9 || 'Aun no curso'],
      academicRatings10: [this.data.academicRatings10 || 'Aun no curso'],
      academicRatings11: [this.data.academicRatings11 || 'Aun no curso'],
      academicRatings12: [this.data.academicRatings12 || 'Aun no curso'],
      guardianName: [this.data.guardianName || ''],
      guardianEmail: [this.data.guardianEmail || ''],
      guardianPhone: [this.data.guardianPhone || ''],
      declaration: [this.data.declaration || false],
      isCreatedAlready: [this.data.isCreatedAlready || false],
    });
  }

  submitData(type: string) {
    const postData = {
      api: 'api/createInitialDataForm',
      data: this.form.value,
    };
    const updateData = {
      api: `api/updateInitialDataForm/${this.data._id}`,
      data: this.form.value,
    };

    if (!this.form.value.isCreatedAlready) {
      this.apiService.post(postData).subscribe(
        (response) => {
          if (type === 'submitForm') {
            Swal.fire({
              title: 'Éxito',
              text: 'Se han guardado los cambios correctamente.',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              // this.dialogRef.close(); // Cierra el diálogo después de que el usuario confirme
            });
          }
        },
        (error) => {
          if (type === 'submitForm') {
            Swal.fire({
              title: 'Error',
              text: 'No se pudieron guardar los cambios. Inténtalo de nuevo.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
          console.error('Error submitting questions and answers:', error);
        }
      );
    } else {
      this.apiService.put(updateData).subscribe(
        (response) => {
          if (type === 'submitForm') {
            Swal.fire({
              title: 'Éxito',
              text: 'Se ha actualizado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              // this.dialogRef.close(); // Cierra el diálogo después de que el usuario confirme
            });
          }
        },
        (error) => {
          if (type === 'submitForm') {
            Swal.fire({
              title: 'Error',
              text: 'No se pudieron actualizar los cambios. Inténtalo de nuevo.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
          console.error('Error submitting questions and answers:', error);
        }
      );
    }
  }
}
