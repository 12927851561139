import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Función de validador personalizada para el correo electrónico
function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    const valid = email.includes('@');
    return valid ? null : { invalidEmail: true };
  };
}

@Component({
  selector: 'app-page-two',
  templateUrl: './pageTwo.component.html',
  styleUrls: ['./pageTwo.component.scss'],
})
export class PageTwoComponent implements OnInit {
  @Input() form: FormGroup;

  ngOnInit(): void {
    this.form.get('schoolEmail')?.setValidators([Validators.required, emailValidator()]);
    this.form.get('personalEmail')?.setValidators([Validators.required, emailValidator()]);
    this.form.get('schoolEmail')?.updateValueAndValidity();
    this.form.get('personalEmail')?.updateValueAndValidity();
  }

  onPhoneCheckboxChange(event: Event): void {
    this.toggleFieldControl(event, 'verifiedPhone');
  }

  onWhatsAppCheckboxChange(event: Event): void {
    this.toggleFieldControl(event, 'verifiedWhatsApp');
  }

  private toggleFieldControl(event: Event, controlName: string): void {
    const checkbox = event.target as HTMLInputElement;
    const control = this.form.get(controlName);

    if (checkbox.checked) {
      control?.setValue('');
      control?.disable({ emitEvent: false });
    } else {
      control?.enable({ emitEvent: false });
    }
  }
}
