import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Función de validador personalizada para el correo electrónico
function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    const valid = email.includes('@');
    return valid ? null : { invalidEmail: true };
  };
}

@Component({
  selector: 'app-page-five',
  templateUrl: './pageFive.component.html',
  styleUrls: ['./pageFive.component.scss'],
})
export class PageFiveComponent implements OnInit {
  @Input() form: FormGroup;

  academicRating = [
    { value: 'excellent', label: 'Superior ó Excelente' },
    { value: 'very_good', label: 'Alto ó Muy Bueno' },
    { value: 'good', label: 'Básico ó Bueno' },
    { value: 'acceptable', label: 'Aceptable o Satisfactorio' },
    { value: 'regular', label: 'Bajo ó Regular' },
    { value: 'insufficient', label: 'Muy Bajo ó Insuficiente' },
    { value: 'not_available', label: 'No las tengo disponibles' },
  ];

  englishLevels = [
    { label: 'Principiante (A1),', value: 'A1' },
    { label: 'Básico ó Elemental (A2)', value: 'A2' },
    { label: ' Intermedio (B1)', value: 'B1' },
    { label: 'ntermedio Alto (B2)', value: 'B2' },
    { label: 'Alto', value: 'C1' },
    { label: 'Maestría (C2),', value: 'C2' },
  ];

  ngOnInit(): void {
    this.form.get('guardianEmail')?.setValidators([Validators.required, emailValidator()]);
    this.form.get('guardianEmail')?.updateValueAndValidity();
  }
}
