<div class="country-selector-container">
    <h3 class="title">Selecciona hasta 3 países donde deseas estudiar</h3>


    <mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>

    <ng-container *ngIf="!isLoading">
        <div class="search-container">
            <app-initial-form-input [control]="searchControl" placeholder="Escribe un país...">
            </app-initial-form-input>
        </div>

        <div class="countries-list">
            <div *ngFor="let country of filteredCountries" class="country-card"
                [class.selected]="isSelected(country.nombre)" (click)="toggleCountrySelection(country)">

                <img [src]="country.icono" [alt]="country.nombre" class="country-flag">
                <span class="country-name">{{ country.nombre }}</span>
            </div>
        </div>
    </ng-container>
</div>