<app-inital-form-card-selection [control]="form.get('educationTypePreference')" [items]="educationPreferencesOptions"
  [multiSelect]="false">
  <ng-template let-item let-selected="selected" let-hovered="hovered">

    <div class="card" [class.selected]="selected">
      <img [src]="item.img" [alt]="item.name">
      <h3>{{ item.name }}</h3>
      <p>{{ item.description }}</p>
    </div>

  </ng-template>
</app-inital-form-card-selection>