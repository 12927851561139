import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StepperService } from '../../../services/stepper.service';
import { UniversityTypesResponse } from '../../initial-form/initialForm.models';

@Component({
  selector: 'app-step-university-preferences',
  templateUrl: './step-university-preferences.component.html',
  styleUrls: ['./step-university-preferences.component.scss']
})
export class StepUniversityPreferencesComponent implements OnInit {

  @Input() form: FormGroup;
  universityTypesOptions: { value: string; name: string }[] = [];

  constructor(private stepperService: StepperService) { }

  ngOnInit(): void {
    this.loadUniversityTypes();
  }

  private loadUniversityTypes() {
    this.stepperService.loadUniversityTypes().subscribe(
      (universityTypes) => {
        this.universityTypesOptions = universityTypes.map((universityType: UniversityTypesResponse) => ({
          value: universityType.institutionOptionName,
          name: universityType.institutionOptionName
        }));
      },
      (error) => {
        console.error(error);
      },

    );
  }


  descriptions = {
    'Universidades Experienciales': ' Educación combinada entre academia, investigación y proyectos 🔬📖 (Instituciones que equilibran teoría, investigación y aprendizaje basado en proyectos aplicados).',
    'Aprendizaje Práctico': 'Educación práctica y orientada al trabajo 👷‍♂️💼 (Formación centrada en el desarrollo de habilidades aplicables directamente en el ámbito laboral). ',
    'Academias e Instituciones Especializadas ': ' Educación especializada en academias y formación técnica 🎭🎨 (Instituciones enfocadas en habilidades específicas, como artes, tecnología o formación vocacional).',
    'Top Tier Universidades': 'Educación de élite académica y de investigación profunda 🎓📚 (Universidades altamente selectivas con un enfoque en investigación y excelencia académica).',
    'Experiential TOP': 'Educación basada en proyectos 🛠️🚀 (Modelo de aprendizaje enfocado en resolver problemas y desarrollar habilidades a través de proyectos prácticos).'
  }

}
