import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-financial-information',
  templateUrl: './step-financial-information.component.html',
  styleUrls: ['./step-financial-information.component.scss']
})
export class StepFinancialInformationComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() { }

  tuitionOptions = [
    { value: 'under_2_min_wage', label: 'Menos de $3.000.000 COP al año', tooltip: '(Universidades públicas colombianas altamente subsidiadas, instituciones técnicas públicas económicas o universidades públicas internacionales altamente subsidiadas estudiantes TOP).' },
    { value: 'between_3_5_min_wage', label: 'Entre $3.000.000 y $6.000.000 COP al año', tooltip: '(Universidades públicas colombianas o instituciones técnicas con matrícula parcialmente subsidiada; universidades públicas internacionales de bajo costo altamente subsidiadas estudiantes TOP).' },
    { value: 'between_6_8_min_wage', label: 'Entre $6.000.000 y $9.000.000 COP al año', tooltip: '(Universidades privadas colombianas accesibles, universidades regionales públicas o privadas, incluyendo instituciones privadas en grandes ciudades.).' },
    { value: 'between_9_15_min_wage', label: 'Entre $9.000.000 y $15.000.000 COP al año', tooltip: '(Universidades privadas colombianas reconocidas con una amplia oferta educativa).' },
    { value: 'over_16_min_wage', label: 'Más de $15.000.000 COP al año', tooltip: 'Universidades privadas colombianas de alto prestigio nacional e internacional; universidades internacionales altamente reconocidas, públicas o privadas, especialmente en países como EE.UU., Canadá, Reino Unido o Australia).' },
    { value: 'no_response', label: 'Prefiero no responder / No tengo la información' },
  ];

  financialSupportOptions = [
    { value: 'family_support', label: 'Sí, mi familia puede cubrir mis estudios.' },
    { value: 'partial_support', label: 'Sí, pero necesitaré apoyo parcial (becas, créditos, subsidios).' },
    { value: 'full_support_needed', label: 'No, necesito acceder a becas o créditos para estudiar.' },
    { value: 'unsure', label: 'No estoy seguro/a en este momento como lo financiaré.' },
  ];
  ngOnInit(): void {
  }

}
