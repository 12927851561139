import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'app-date-input',
  templateUrl: './dateInput.component.html',
  styleUrls: ['./dateInput.component.scss'],
})
export class DateInputComponent implements OnChanges {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() monthYearOnly: boolean = false; // Nueva propiedad para controlar el formato

  constructor(private dateAdapter: DateAdapter<Date>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['control']) {
      this.updateInputValue();
    }
    if (changes['monthYearOnly']) {
      this.updateDatePickerMode();
    }
  }

  private updateInputValue(): void {
    if (this.control) {
      const value = this.control.value;
      if (value) {
        const date = new Date(value);
        this.control.setValue(date, { emitEvent: false });
      }
    }
  }

  private updateDatePickerMode(): void {
    // Si el valor de monthYearOnly es true, el DateAdapter cambia a modo solo mes y año
    if (this.monthYearOnly) {
      this.dateAdapter.setLocale('en-GB'); // Ajusta el formato si es necesario
    }
  }

  onDateChange(event: any): void {
    const date = event.value;
    if (date instanceof Date) {
      const isoString = this.monthYearOnly
        ? new Date(date.getFullYear(), date.getMonth(), 1).toISOString()
        : date.toISOString();
      this.control.setValue(isoString);
    }
  }
}
