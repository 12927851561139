import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationService } from '../services/navigation.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { InitialFormComponent } from '../initialForm/components/initial-form/initial-form.component';

@Component({
  selector: 'app-home-student',
  templateUrl: './home-student.component.html',
  styleUrls: ['./home-student.component.scss']
})
export class HomeStudentComponent implements OnInit {

  public Experiential = {
    "Aprendizaje Continuo": 0,
    "Creatividad E Innovación": 0,
    "Global Mind": 0,
    "Impacto y Responsabilidad Social": 0,
    "Liderazgo": 0,
    "Orientación a Resultados": 0
  };

  api = 'api/hoja-vida/getById/';
  readonly apiCountries = 'api/pais';
  /** API path for student get test*/
  readonly GET_TEST = 'api/test_internalizacionUser/';
  readonly getSkill = 'api/getSkill/';
  readonly userExperiencesAPI = 'api/experiences/';
  readonly checkInitialFormApi = 'api/checkInitialDataFormPublished/'
  readonly getData = 'api/skillsTest/';
  readonly getInstitutionOptions = 'api/getInstitutionOptions/';
  readonly GET_STUDENT = 'api/oneUser/';
  readonly plannedExperiences = 'api/experiences/without/weighted';
  readonly ponderationApi = 'api/weightedTest/67bbfed2d036c606e8c191b7';



  user = JSON.parse(localStorage.getItem('user'));


  /** Variable that holds the personal information binded to the form */
  fotoBanner: any;
  fotoPerfil: any;
  nombre: any;
  colegio: any;
  proyecto: any;
  level: any;
  palabrasClave: any;
  sobreMi: any;
  individualProfile: any;
  achievements: any;
  academicRecord: any;
  employmentHistory: any;
  relevantSkills: any;
  aditionalFormation: any;
  progress: number = 0; // Valor quemado por ahora
  idiomas = [];
  areas = [];
  paises = [];
  logros = [];
  referencias = [];
  universidadesInteres = [];
  userTest: any;
  userId: any;
  slug: any;
  skillsTest: any;
  skillsScore: any;
  generalScore: any;
  averageScore: any;
  endprofile: any;
  sourceBadge: any;
  chartOptions: any;
  chartData: any;
  skillsPointsAverage: any;
  skills: any;
  combinedData: any;
  filteredResponse: any;
  skillScoreMap: any;
  desiredInstitutionAverage: any;
  institutionLevel: any;
  radarChart: any;
  skillsAverage: any;
  averageChartoptions: any;
  options: any;
  secondarySkillsChartOptions: any;
  secondarySkills: any;
  skillsObjects: any[] = [];
  secondarySkillsObjects: any[] = [];
  studentId: any;
  userExperiences: any[] = [];
  userInitialForm: any = {};
  /** Variable that holds the id of the current user */
  id: string;
  actived: string;
  perfilFinal: any;
  sourceInsignia: string;
  isMyCv: boolean = true;
  countries: any;
  matchingCountries: any;
  public userData = JSON.parse(localStorage.getItem('user'));
  selectedOptionUniversity: any;
  constructor(
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId,
    private alertService: AlertService,
    private translate: TranslateService,
    private ngxSpinnerService: NgxSpinnerService,
    private navigationService: NavigationService,
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.studentId = this.user._id;
    console.log(this.user);
    console.log(this.studentId);
    this.getUserCv({ data: this.studentId  });
    this.getSkillsTest({ userId: this.studentId});
    this.hasAlreadyCompletedInitialForm();
  }


  openSimulationChart(){
    const dialogRef = this.dialog.open(InitialFormComponent, {
      width: '65vw',
      height: '85vh',
      disableClose: true,
    });

    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });
  }


  getUserCv({ data }): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.api + data }).subscribe(
      (response) => {
        if (response) {
          let usuario = response.usuario;
          this.nombre = `${usuario.nombre ? usuario.nombre : usuario.primerNombre} ${usuario.apellido ? usuario.apellido : usuario.primerApellido
            }`;
          this.fotoPerfil = response.fotoPerfil ? response.fotoPerfil : 'assets/sidebar/profile.png';
          this.colegio = response.colegio ? response.colegio : 'Sin colegio';
          this.proyecto = response.proyecto ? response.proyecto : 'Sin proyecto';
          this.palabrasClave = response.palabrasClave ? response.palabrasClave : 'Sin palabras clave';
          this.sobreMi = response.sobreMi ? response.sobreMi : '¡Cuentanos sobre ti!';

          this.individualProfile = response.individualProfile ? response.individualProfile : 'Sin Perfil Individual';

          this.achievements = response.achievements ? response.achievements : 'Sin Perfil Logros';

          this.academicRecord = response.academicRecord ? response.academicRecord : 'Sin Perfil historial academico';

          this.employmentHistory = response.employmentHistory
            ? response.employmentHistory
            : 'Sin Perfil historial laboral';

          this.relevantSkills = response.relevantSkills ? response.relevantSkills : 'Sin Perfil habilidades relevantes';

          this.aditionalFormation = response.aditionalFormation
            ? response.aditionalFormation
            : 'Sin Perfil informacion adicional';

          this.slug = response.link ? response.link : null;

          if (response.idioma.length > 0) {
            for (let idioma of response.idioma) {
              this.idiomas.push(idioma);
            }
          }

          if (response.areaInteres.length > 0) {
            this.areas = response.areaInteres;
          }

          if (response.paisInteres.length > 0) {
            this.paises = response.paisInteres;
            // this.getCountries();
          }

          if (response.logros.length > 0) {
            for (let logro of response.logros) {
              this.logros.push(logro);
            }
          }

          if (response.referencias.length > 0) {
            for (let ref of response.referencias) {
              this.referencias.push(ref);
            }
          }

          if (response.universidadInteres.length > 0) {
            for (const uni of response.universidadInteres) {
              this.universidadesInteres.push(uni);
            }
          }
          if (response.fotoBaner) {
            this.fotoBanner = response.fotoBaner;
          } else {
            this.fotoBanner = '/assets/curriculum/background-photo.png';
          }

          if (response.fotoPerfil) {
            this.fotoPerfil = response.fotoPerfil;
          } else {
            this.fotoPerfil = '/assets/sidebar/profile.png';
          }

          if (!this.isMyCv) {
            this.userId = usuario._id;
            this.getSkillsTest({ userId: this.userId });
          }
        } else {
          this.fotoPerfil = 'assets/sidebar/profile.png';
          this.fotoBanner = '/assets/curriculum/background-photo.png';
          this.colegio = 'Sin colegio';
          this.proyecto = 'Sin proyecto';
          this.palabrasClave = 'Sin palabras clave';
          this.sobreMi = '¡Cuentanos sobre ti!';
          this.individualProfile = '¡Cuentanos sobre ti!';
          this.achievements = '¡Cuentanos sobre ti!';
          this.academicRecord = '¡Cuentanos sobre ti!';
          this.employmentHistory = '¡Cuentanos sobre ti!';
          this.relevantSkills = '¡Cuentanos sobre ti!';
          this.aditionalFormation = '¡Cuentanos sobre ti!';
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Personal-information.load-error'),
        });
        this.ngxSpinnerService.hide();
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

   getSkillsTest({ userId }) {
      const getDta = {
        api: `api/skillsTest/${userId}`,
      };
      this.apiService.get(getDta).subscribe(
        (response) => {
          if (response) {
            this.skillsTest = response;
            this.skillsScore = response.skillPoints;
            const averageSkills = { ...this.skillsScore };
  
            this.generalScore = Object.keys(this.skillsScore)
              .filter((key) => key !== 'Inteligencia Emocional')
              .reduce((sum, key) => sum + this.skillsScore[key], 0);
            const primarySkills = Object.keys(this.skillsScore).filter((skill) => skill !== 'Inteligencia Emocional');
  
            delete averageSkills['Inteligencia Emocional'];
  
            this.skillsPointsAverage = (this.generalScore / primarySkills.length).toFixed(2);
            this.skillsScore.Promedio = +this.skillsPointsAverage;
            this.getSkills();
            this.AssignBadge();
          } else {
            return false;
          }
        },
        (error) => {
          console.error('Error fetching skills test:', error);
          return false;
        }
      );
    }
    
    hasAlreadyCompletedInitialForm() {
      this.apiService.get({ api: this.checkInitialFormApi + this.studentId }).subscribe(
        (response) => {
          if(response == false){
            this.openSimulationChart();
          }
        },
        (error) => {
          console.error('Error fetching initial form:', error);
        }
      );
    }

  
    AssignBadge() {
      switch (true) {
        case this.averageScore >= 66:
          this.endprofile = 'Nivel Alto';
          this.sourceBadge = '/assets/dashboard-skill/insignias/master.png';
          break;
        case this.averageScore > 33 && this.averageScore < 66:
          this.endprofile = 'Nivel Medio';
          this.sourceBadge = '/assets/dashboard-skill/insignias/avanzado.png';
          break;
        case this.averageScore >= 0 && this.averageScore <= 33:
          this.endprofile = 'Nivel Principiante';
          this.sourceBadge = '/assets/dashboard-skill/insignias/en-construccion.png';
          break;
        default:
          break;
      }
    }


getSkills() {
    this.apiService.get({ api: this.getData + this.userData._id }).subscribe((response) => {
            this.apiService.get({ api: this.ponderationApi }).subscribe((ponderadoResponse) => {
                const ponderado = ponderadoResponse?.result?.ponderado / 100 || 0.1;
                console.log("🔹 Ponderado:", ponderado);
    
                this.skillScoreMap = {
                    "Impacto y Responsabilidad Social": "ImpactoResponsabilidad",
                    "Aprendizaje Continuo": "AprendizajePermanente",
                    "Creatividad E Innovación": "CreatividadInovacion",
                    "Inteligencia Emocional": "InteligenciaEmocional",
                    "Orientación a Resultados": "OrientacionResultados",
                    "Liderazgo": "Liderazgo",
                    "Global Mind": "GlobalMind",
                    "Pensamiento Crítico": "PensamientoCritico"
                };
    
                // Mapeo inverso para recuperar los nombres originales
                const skillLevelMap = {
                    "ImpactoResponsabilidad": "Impacto y Responsabilidad Social",
                    "AprendizajePermanente": "Aprendizaje Continuo",
                    "CreatividadInovacion": "Creatividad E Innovación",
                    "InteligenciaEmocional": "Inteligencia Emocional",
                    "OrientacionResultados": "Orientación a Resultados",
                    "Liderazgo": "Liderazgo",
                    "GlobalMind": "Global Mind",
                    "PensamientoCritico": "Pensamiento Crítico"
                };
    
                const skillPoints = response?.skillPoints || {};
    
                let skillObjects = Object.entries(skillPoints).map(([skillName, score]) => {
                    const mappedName = this.skillScoreMap[skillName] || skillName;
                    return {
                        name: mappedName,
                        skillScore: parseFloat(((score as number) * ponderado).toFixed(2)),
                        proficiencyLevel: "-",
                        description: "Descripción no disponible",
                        desiredScore: null, // Inicializamos desiredScore
                        proficiencyDescription: '' // Inicializamos proficiencyDescription
                    };
                });
    
                console.log("🔹 Skills base con ponderado:", skillObjects);
    
                this.apiService.get({ api: this.plannedExperiences }).subscribe((experienceResponse) => {
                    this.getUniversityType({ id: this.userData._id });
                    const userExperiences = experienceResponse.filter(exp => exp.user === this.userData._id && exp.status === "Aprobada");
    
                    let skillMap = new Map(skillObjects.map(skill => [skill.name, skill]));
    
                    if (userExperiences.length > 0) {
                        userExperiences.forEach((experience) => {
                            const primarySkillName = this.skillScoreMap[experience.principalSkill] || experience.principalSkill;
                            const secondarySkillName = this.skillScoreMap[experience.secondarySkill] || experience.secondarySkill;
    
                            if (!skillMap.has(primarySkillName)) {
                                skillMap.set(primarySkillName, { name: primarySkillName, skillScore: 0, proficiencyLevel: "-", description: "Descripción no disponible", desiredScore: null, proficiencyDescription: '' });
                            }
                            let primarySkill = skillMap.get(primarySkillName);
                            primarySkill.skillScore = Math.min(100, parseFloat((primarySkill.skillScore + experience.skillsScore).toFixed(2)));
    
                            if (!skillMap.has(secondarySkillName)) {
                                skillMap.set(secondarySkillName, { name: secondarySkillName, skillScore: 0, proficiencyLevel: "-", description: "Descripción no disponible", desiredScore: null, proficiencyDescription: '' });
                            }
                            let secondarySkill = skillMap.get(secondarySkillName);
                            secondarySkill.skillScore = Math.min(100, parseFloat((secondarySkill.skillScore + (experience.skillsScore / 2)).toFixed(2)));
                        });
                    }
    
                    skillObjects = Array.from(skillMap.values());
    
                    console.log("🔹 Skills después del merge con experiencias:", skillObjects);
    
                    this.apiService.get({ api: this.getSkill }).subscribe((skillLevelsResponse) => {
                        const skillLevels = skillLevelsResponse.reduce((acc, skill) => {
                            acc[skill.skillName] = {
                                levels: skill.levels,
                                description: skill.skillDescription || "Descripción no disponible"
                            };
                            return acc;
                        }, {});
    
                        skillObjects = skillObjects.map(skill => {
                            const originalName = skillLevelMap[skill.name] || skill.name;
                            const skillData = skillLevels[originalName] || { levels: [], description: "Descripción no disponible" };
                            const levels = skillData.levels;
                            let assignedLevel = "Desconocido";
    
                            for (const level of levels) {
    
                            
                                if (skill.skillScore >= level.range1 && skill.skillScore < level.range2) {
                                  
                                    assignedLevel = level.level;
                                    console.log("🔹 Nivel de proficiencia:", assignedLevel)
                                  console.log("🔹 Skill score:", skill.skillScore, skill.name
                                  );
                                    break;
                                }
                            }
    
                            return {
                                ...skill,
                                name: originalName,
                                proficiencyLevel: assignedLevel,
                                description: skillData.description
                            };
                        });
    
                        console.log("🔹 Skills con niveles y descripciones asignadas:", skillObjects);
    
                        // 🔹 Agregar desiredScore desde filteredResponse[0].selectedSkills
                        if (this.filteredResponse && this.filteredResponse.length > 0) {
                            this.filteredResponse[0].selectedSkills.forEach((selectedSkill) => {
                                const matchingSkill = skillObjects.find((skill) => skill.name === selectedSkill.name);
                                if (matchingSkill) {
                                    matchingSkill.desiredScore = selectedSkill.score; // Agregamos el desiredScore
    
                                }
                            });
                        }
      
                        console.log("🔹 Skills con desiredScore agregado:", skillObjects);
                        this.combinedData = skillObjects;
    
                     
                      
                          // Calculamos el promedio de todos los skillScores en combinedData
                          const totalScore = this.combinedData.reduce((sum, skill) => sum + skill.skillScore, 0);
                            const averageScore = parseFloat((totalScore / this.combinedData.length || 0).toFixed(2));
                      
                          console.log("🔹 Nuevo promedio calculado:", averageScore);
                      
                          // Obtener el nivel usando la misma lógica de asignación de skillObjects
                          let assignedLevel = "Desconocido";
                          let levelDescription = "Descripción no disponible";
                      
                          const institutionSkill = {
                              name: "Desempeño Global Competencias",
                              skillScore: averageScore
                          };

                          this.progress = averageScore;
                          console.log("🔹 Promedio de desempeño global:", this.progress);
                      
                          const originalName = skillLevelMap[institutionSkill.name] || institutionSkill.name;
                          const skillData = skillLevels[originalName] || { levels: [], description: "Descripción no disponible" };
                          const levels = skillData.levels;
                      
                          for (const level of levels) {
                              if (institutionSkill.skillScore >= level.range1 && institutionSkill.skillScore < level.range2) {
                                  assignedLevel = level.level;
                                  levelDescription = level.description;
                                  console.log("🔹 description:", level.description);
                                  break;
                              }
                          }

                       this.level = assignedLevel;
                          this.displayAverageChart({
                            name: 'Desempeño Global Competencias',
                            score: averageScore, // Promedio calculado
                            level: assignedLevel, // Nivel encontrado
                            levelDescription: levelDescription, // Descripción del nivel
                        });
                      
                    });
                });
            });
        });
    }
    get progressOffset(): number {
      const radius = 50; // Radio del círculo
      const totalCircumference = 2 * Math.PI * radius; // Longitud total del círculo
    
      return totalCircumference * (this.progress / 100 - 1); 
      // Esto asegura que empiece lleno (offset = 314) y se reduzca correctamente
    }
    
      
  getSkillsRadar() {
    this.apiService.get({ api: this.getSkill }).subscribe((response) => {

      this.skillScoreMap = {
        'Impacto y Responsabilidad Social': 'ImpactoResponsabilidad',
        'Aprendizaje Continuo': 'AprendizajePermanente',
        'Creatividad E Innovación': 'CreatividadInovacion',
        Liderazgo: 'Liderazgo',
        'Global Mind': 'GlobalMind',
        'Orientación a Resultados': 'OrientacionResultados',
        'Inteligencia Emocional': 'InteligenciaEmocional',
      };
      if (this.desiredInstitutionAverage && this.desiredInstitutionAverage.length > 0) {
        const desiredSkill = response.find((skill) => skill.skillName === 'Desempeño Global Competencias');

        if (desiredSkill) {
          this.institutionLevel = this.findLevel(desiredSkill.levels, this.skillsPointsAverage);

          this.combinedData = this.filteredResponse[0].selectedSkills
            .map((selectedSkill) => {
              const matchingSkill = response.find((skill) => skill.skillName === selectedSkill.name);
              const skillScoreKey = selectedSkill.name;
              const skillScore = this.skillsScore && skillScoreKey ? this.skillsScore[skillScoreKey] : undefined;
              if (matchingSkill && skillScore !== undefined) {
                const level = this.findLevel(matchingSkill.levels, skillScore);
                return {
                  name: selectedSkill.name,
                  desiredScore: selectedSkill.score,
                  description: matchingSkill.skillDescription,
                  skillScore: skillScore,
                  proficiencyLevel: level.level,
                  proficiencyDescription: level.description,
                };
              }

              return null;
            })
            .filter(Boolean);
          this.displayChart(this.combinedData);
        } else {
          console.error("No se encontró el skill 'Desempeño Global Competencias'");
        }
      }
    });
  }

  displayChart(skillsObjects) {
    // const dataToDisplay = {
    //   categories: skillsObjects.map((skill) => skill.name),
    //   data: skillsObjects.map((skill) => skill.score),
    //   levels: skillsObjects.map((skill) => skill.level),
    //   levelsDescription: skillsObjects.map((skill) => skill.levelDescription),
    // };

    // const categories = skillsObjects.map((skill) => {
    //   const formattedSkillName = skill.name.split(' ').join('<br>');
    //   return `${formattedSkillName} ${skill.score}%-${skill.level}`;
    // });

    // this.chartOptions = {
    //   series: [
    //     {
    //       name: 'Porcentaje',
    //       data: dataToDisplay.data,
    //     },
    //   ],
    //   theme: {
    //     palette: 'palette1',
    //   },
    //   chart: {
    //     height: 500,
    //     type: 'bar',
    //   },

    //   fill: {
    //     opacity: 0.8,
    //   },
    //   plotOptions: {
    //     bar: {
    //       columnWidth: '25%',
    //       distributed: true,
    //     },
    //   },
    //   dataLabels: {
    //     enabled: true,
    //     formatter: function (y) {
    //       const index = dataToDisplay.data.indexOf(y);
    //       const level = dataToDisplay.levels[index];
    //       return `${y.toFixed(0)}% - ${level}`;
    //     },
    //     offsetY: -20,
    //     style: {
    //       fontSize: '15px',
    //       colors: ['#304758'],
    //     },
    //   },
    //   legend: {
    //     show: false,
    //   },
    //   grid: {
    //     show: false,
    //   },
    //   xaxis: {
    //     categories: dataToDisplay.categories,
    //     labels: {
    //       style: {
    //         fontSize: '10px',
    //       },
    //     },
    //   },
    //   yaxis: {
    //     labels: {
    //       formatter: function (y) {
    //         return y.toFixed(0) + '%';
    //       },
    //     },
    //   },
    //   tooltip: {
    //     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
    //       const score = series[seriesIndex][dataPointIndex];
    //       const index = dataToDisplay.data.indexOf(score);
    //       const level = dataToDisplay.levels[index];
    //       const levelDescription = dataToDisplay.levelsDescription[index];
    //       return (
    //         '<div class="arrow_box" style="overflow-wrap: break-word; display: flex; flex-direction: column; width: 60vw;">' +
    //         '<span style="text-align: center; font-weight: bold;">' +
    //         score.toFixed(0) +
    //         '%' +
    //         ' - ' +
    //         level +
    //         '</span>' +
    //         '<div style="overflow-wrap: break-word; word-wrap: break-word; text-align: center; white-space: pre-wrap;">' +
    //         levelDescription +
    //         '</div>' +
    //         '</div>'
    //       );
    //     },
    //     fixed: {
    //       enabled: true,
    //       position: 'topRight',
    //       offsetX: 0,
    //       offsetY: 0,
    //     },
    //   },

    //   colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
    //   labels: {
    //     style: {
    //       colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
    //       fontSize: '12px',
    //     },
    //   },
    // };
    const categories = skillsObjects.map((skill) => {
      const formattedSkillName = skill.name.split(' ').join('<br>');
      return `${formattedSkillName} ${skill.skillScore}%-${skill.proficiencyLevel}`;
    });

    const skillValues = skillsObjects.map((skill) => skill.skillScore);

    this.chartOptions = {
      chart: {
        height: 500,
        width: 1500,
        type: 'radar',
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      series: [
        {
          name: 'Skills',
          data: skillValues,
          color: 'rgba(255, 255, 255, 0.5)',
        },
      ],
      plotOptions: {
        radar: {
          size: 200,
          polygons: {
            strokeColors: '#969FFB',
            fill: {
              colors: ['#d7dbff'],
            },
            connectorColors:['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F', '#FFD700']
          },
        },
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            colors: ['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F', '#FFD700'],
            fontSize: '18px',
            fontFamily: '"Montserrat", sans-serif',
            fontWeight: 600,
          },
          formatter: function (value) {
            return value.replace(/<br>/g, '\n');
          },
        },
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    };
  }

  findLevel(levels, skillScore) {
    for (const level of levels) {
      if (skillScore >= level.range1 && skillScore <= level.range2) {
        return level;
      }
    }
    return {
      level: 'N/A',
      description: 'Proficiency level not available for this skill score range.',
    };
  }

      async getUniversityType({ id }: { id: string }) {
        try {
          const response = await this.apiService.get({ api: this.GET_STUDENT + id }).toPromise();
          this.selectedOptionUniversity = response.institutionType;
          await this.getInstitutions();
        } catch (error) {
          console.error('Error al obtener el tipo de universidad:', error);
        }
      }



      displayAverageChart(averageScore) {
        const averageScore2 = averageScore.score;
        console.log("🔹 Promedio de desempeño global:", averageScore2);
      
    
        this.averageChartoptions = {
          series: [averageScore2],
          chart: {
            height: 350,
            width: 300,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
            },
          },
          labels: [`${averageScore.level}`],
        };
      }
      
  async getInstitutions() {
    try {
      const response = await this.apiService.get({ api: this.getInstitutionOptions }).toPromise();
      this.filteredResponse = response.filter(
        (university) => university.institutionOptionName === this.selectedOptionUniversity
      );

      if (this.filteredResponse.length > 0) {
        this.desiredInstitutionAverage = this.filteredResponse[0].selectedSkills.filter(
          (institution) => institution.name === 'Desempeño Global Competencias'
        );

        if (this.desiredInstitutionAverage.length === 0) {
          console.error("No se encontraron promedios de institución para 'Desempeño Global Competencias'");
        }
      } else {
        console.error('No se encontraron instituciones que coincidan con la opción seleccionada');
      }
    } catch (error) {
      console.error('Error al obtener las instituciones:', error);
    }
  }
}
