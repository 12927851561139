export const UNIVERSITY_STUDY_FIELDS = [
    {
        value: 'Artes, Diseño y Arquitectura',
        label: 'Artes, Diseño y Arquitectura',
    },
    {
        value: 'Negocios y Gestión',
        label: 'Negocios y Gestión',
    },
    {
        value: 'Ciencias Computacionales y TI',
        label: 'Ciencias Computacionales y TI',
    },
    {
        value: 'Ingeniería',
        label: 'Ingeniería',
    },
    {
        value: 'Ciencias y Matemáticas',
        label: 'Ciencias y Matemáticas',
    },
    {
        value: 'Ciencias Sociales y Humanidades',
        label: 'Ciencias Sociales y Humanidades',
    },
    {
        value: 'Educación y Deporte',
        label: 'Educación y Deporte',
    },
    {
        value: 'Servicios Comunitarios',
        label: 'Servicios Comunitarios',
    },
    {
        value: 'Estudios Ambientales y Ciencias de la Tierra',
        label: 'Estudios Ambientales y Ciencias de la Tierra',
    },
    {
        value: 'Salud',
        label: 'Salud',
    },
    {
        value: 'Periodismo y Medios',
        label: 'Periodismo y Medios',
    },
    {
        value: 'Derecho y Ciencias Políticas',
        label: 'Derecho y Ciencias Políticas',
    },
    {
        value: 'Idiomas',
        label: 'Idiomas',
    },
    {
        value: 'Turismo y Hospitalidad',
        label: 'Turismo y Hospitalidad',
    },
    {
        value: 'Otros',
        label: 'Otros',
    },

];

export const UNIVERSITY_CAREERS = [
    { value: "Composición Musical", label: "Composición Musical 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Historia del Arte", label: "Historia del Arte 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Pintura", label: "Pintura 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Dirección Orquestal", label: "Dirección Orquestal 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Ilustración", label: "Ilustración 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Diseño de Interiores", label: "Diseño de Interiores 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Arquitectura", label: "Arquitectura 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Interpretación Musical", label: "Interpretación Musical 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Diseño de Joyería", label: "Diseño de Joyería 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Cine y Producción Audiovisual", label: "Cine y Producción Audiovisual 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Urbanismo", label: "Urbanismo 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Educación Musical", label: "Educación Musical 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Música", label: "Música 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Dibujo Técnico", label: "Dibujo Técnico 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Escenografía", label: "Escenografía 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Musicología", label: "Musicología 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Sonido y Acústica", label: "Sonido y Acústica 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Moda y Textiles", label: "Moda y Textiles 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Diseño Industrial", label: "Diseño Industrial 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Arte Digital", label: "Arte Digital 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Producción Musical", label: "Producción Musical 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Diseño Gráfico", label: "Diseño Gráfico 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Bellas Artes", label: "Bellas Artes 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Diseño de Videojuegos", label: "Diseño de Videojuegos 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Artes Escénicas", label: "Artes Escénicas 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Animación y Efectos Visuales", label: "Animación y Efectos Visuales 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Fotografía", label: "Fotografía 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Escultura", label: "Escultura 🎨", faculty: "Artes, Diseño y Arquitectura" },
    { value: "Consultoría Estratégica", label: "Consultoría Estratégica 💼", faculty: "Negocios y Gestión" },
    { value: "Gestión de Proyectos", label: "Gestión de Proyectos 💼", faculty: "Negocios y Gestión" },
    { value: "Estrategia Empresarial", label: "Estrategia Empresarial 💼", faculty: "Negocios y Gestión" },
    { value: "Gestión de Riesgos", label: "Gestión de Riesgos 💼", faculty: "Negocios y Gestión" },
    { value: "Gerencia de Ventas", label: "Gerencia de Ventas 💼", faculty: "Negocios y Gestión" },
    { value: "Logística y Supply Chain", label: "Logística y Supply Chain 💼", faculty: "Negocios y Gestión" },
    { value: "Gestión de Recursos Humanos", label: "Gestión de Recursos Humanos 💼", faculty: "Negocios y Gestión" },
    { value: "Publicidad y Relaciones Públicas", label: "Publicidad y Relaciones Públicas 💼", faculty: "Negocios y Gestión" },
    { value: "Marketing", label: "Marketing 💼", faculty: "Negocios y Gestión" },
    { value: "Administración de Empresas", label: "Administración de Empresas 💼", faculty: "Negocios y Gestión" },
    { value: "Gestión de la Innovación", label: "Gestión de la Innovación 💼", faculty: "Negocios y Gestión" },
    { value: "Negocios Internacionales", label: "Negocios Internacionales 💼", faculty: "Negocios y Gestión" },
    { value: "Comercio Internacional", label: "Comercio Internacional 💼", faculty: "Negocios y Gestión" },
    { value: "Consultoría de Negocios", label: "Consultoría de Negocios 💼", faculty: "Negocios y Gestión" },
    { value: "Gestión de la Calidad", label: "Gestión de la Calidad 💼", faculty: "Negocios y Gestión" },
    { value: "Administración Pública", label: "Administración Pública 💼", faculty: "Negocios y Gestión" },
    { value: "Gestión de Empresas Deportivas", label: "Gestión de Empresas Deportivas 💼", faculty: "Negocios y Gestión" },
    { value: "Contaduría Pública", label: "Contaduría Pública 💼", faculty: "Negocios y Gestión" },
    { value: "Banca y Seguros", label: "Banca y Seguros 💼", faculty: "Negocios y Gestión" },
    { value: "Economía", label: "Economía 💼", faculty: "Negocios y Gestión" },
    { value: "Análisis Financiero", label: "Análisis Financiero 💼", faculty: "Negocios y Gestión" },
    { value: "Finanzas", label: "Finanzas 💼", faculty: "Negocios y Gestión" },
    { value: "Gestión de Empresas Familiares", label: "Gestión de Empresas Familiares 💼", faculty: "Negocios y Gestión" },
    { value: "Emprendimiento", label: "Emprendimiento 💼", faculty: "Negocios y Gestión" },
    { value: "Administración de Bases de Datos", label: "Administración de Bases de Datos 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Gestión de Tecnología de la Información", label: "Gestión de Tecnología de la Información 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Computación Visual", label: "Computación Visual 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Desarrollo de Software", label: "Desarrollo de Software 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Desarrollo de Videojuegos", label: "Desarrollo de Videojuegos 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Arquitectura de Software", label: "Arquitectura de Software 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Bioinformática", label: "Bioinformática 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Ciencias de la Computación", label: "Ciencias de la Computación 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Ciberseguridad", label: "Ciberseguridad 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Seguridad Informática", label: "Seguridad Informática 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Inteligencia Artificial", label: "Inteligencia Artificial 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Ingeniería de Sistemas", label: "Ingeniería de Sistemas 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Ingeniería en Ciberseguridad", label: "Ingeniería en Ciberseguridad 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Redes y Telecomunicaciones", label: "Redes y Telecomunicaciones 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Ingeniería en Blockchain", label: "Ingeniería en Blockchain 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Big Data y Análisis de Datos", label: "Big Data y Análisis de Datos 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Robótica", label: "Robótica 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Ingeniería en Inteligencia Artificial", label: "Ingeniería en Inteligencia Artificial 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Realidad Virtual y Aumentada", label: "Realidad Virtual y Aumentada 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Sistemas Embebidos", label: "Sistemas Embebidos 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Computación en la Nube", label: "Computación en la Nube 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Computación Cuántica", label: "Computación Cuántica 💻", faculty: "Ciencias Computacionales y TI" },
    { value: "Ingeniería Nuclear", label: "Ingeniería Nuclear ⚙️", faculty: "Ingeniería" },
    { value: "Ingeniería en Energías Renovables", label: "Ingeniería en Energías Renovables ⚙️", faculty: "Ingeniería" },
    { value: "Ingeniería Agronómica", label: "Ingeniería Agronómica ⚙️", faculty: "Ingeniería" },


];