<div class="dropdown-container" [class.disabled]="control.disabled">
  <label *ngIf="label">{{ label }}</label>

  <div class="dropdown-wrapper" (click)="toggleDropdown()" [class.open]="dropdownOpen"
    [class.error]="control.invalid && control.touched">
    <span class="selected-value">
      {{ getSelectedText() || 'Selecciona una opción' }}
    </span>
    <span class="dropdown-icon">▼</span>
  </div>

  <div *ngIf="dropdownOpen" class="dropdown-content">
    <ul class="dropdown-list">
      <!-- Optional Search Bar -->
      <input *ngIf="enableSearch" type="text" [(ngModel)]="searchQuery" (ngModelChange)="filterOptions()"
        placeholder="Buscar..." class="search-bar" (click)="$event.stopPropagation()" />

      <li *ngFor="let option of filteredOptions" (click)="selectOption(option.value)"
        [class.selected]="isSelected(option.value)" class="dropdown-item">
        <span>{{ option.label }}</span>
        <img *ngIf="isSelected(option.value)" class="checkmark" src="assets/initial-form/check.svg" alt="Check"/>
      </li>
    </ul>
  </div>

  <p class="error-message" [class.active]="control.invalid && control.touched">
    {{ getErrorMessage() }}
  </p>
</div>
