<div class="page-container">
  <p class="pageTitle">Si es menor de edad:</p>

  <hr class="titleSeparator" />

  <p>
    Por tu condición de menor de edad, tu padre, tu madre o tu tutor legal, debe dar su consentimiento para que recibas
    los beneficios de las universidades tales cómo: Descuentos en admisión, becas, Master Classes, cursos, descuentos en
    Matrículas, admisiones directas, entre otros.
  </p>

  <app-form-input
    class="pageFiveInput"
    [control]="form.get('guardianName')"
    placeholder="Nombres y apellidos del tutor legal"
    type="text"></app-form-input>

  <app-form-input
    class="pageFiveInput"
    [control]="form.get('guardianEmail')"
    placeholder="Correo electrónico personal del tutor"
    type="email"></app-form-input>
  <div *ngIf="form.get('guardianEmail')?.hasError('invalidEmail') && form.get('guardianEmail')?.touched">
    Correo inválido
  </div>

  <app-form-input
    class="pageFiveInput"
    [control]="form.get('guardianPhone')"
    placeholder="Número del tutor"
    type="number"></app-form-input>
  <div class="checkoxDeclaration">
    <app-form-checkbox class="checkbox" [control]="form.get('declaration')"></app-form-checkbox>
    <p>
      Declaro que la información depositada en campo anterior es verídica y he hablado con mis padres para que firmen
      tratamiento de datos en mi nombre.
    </p>
  </div>
</div>
