import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Faculty, UniversityCareerFilter } from '../university-careers.models';

const FACULTIES_ENDPOINT = 'api/faculties';
@Component({
  selector: 'app-university-careers-filter',
  templateUrl: './university-careers-filter.component.html',
  styleUrls: ['./university-careers-filter.component.scss']
})
export class UniversityCareersFilterComponent implements OnInit {
  faculties: Faculty[] = [];
  filter: UniversityCareerFilter;
  @Output() filterChange: EventEmitter<UniversityCareerFilter> = new EventEmitter();

  constructor(private readonly apiService: ApiService) { }

  ngOnInit(): void {
    this.getFaculties();
  }

  getFaculties() {
    this.apiService.get({ api: FACULTIES_ENDPOINT }).subscribe(
      (response: Faculty[]) => {
        this.faculties = response;
      },
      (error) => {
        console.error('Error fetching faculties', error);
      }
    );
  }

  onFacultyChange(facultyIds: string[]) {
    this.filterChange.emit({ ...this.filter, facultyIds });
  }

  onNameChange(name: string) {
    this.filterChange.emit({ ...this.filter, name });
  }

}
