<div class="background-profiles pb-4">
  <div class="row custom-margin">
    <div class="col-xl-12 d-flex justify-content-center mx-0 px-0">
      <h2 class="presentation-title mx-0 px-0 display-mobile-none">
        Descubre y potencia tus competencias, habilidades y talentos, para lograr una exitosa admisión universitaria y
        desarrollar desde ya, un perfil profesional óptimo.
      </h2>
      <h2 class="presentation-title mx-0 px-0 display-mobile">
        Colaboramos en la construcción de perfiles de competencias exitosas, guiándote con éxito para que sobresalgas en
        el mundo universitario y profesional.
      </h2>
    </div>
  </div>

  <swiper
    [spaceBetween]="30"
    [pagination]="{
      clickable: true
    }"
    class="swiper-perfiles display-mobile pt-2 pb-4">
    <ng-template swiperSlide>
      <div class="card-container">
        <ng-container [ngTemplateOutlet]="infoStudents"></ng-container>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="card-container">
        <ng-container [ngTemplateOutlet]="infoColegios"></ng-container>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="card-container">
        <ng-container [ngTemplateOutlet]="infoUniversidades"></ng-container>
      </div>
    </ng-template>
  </swiper>

  <div class="row info-container justify-content-lg-center justify-content-around pb-4 display-mobile-none">
    <div class="col-xl-4 col-6 text-center appear-card-container m-0">
      <div class="row first-view display-mobile-none m-0" id="content-student-image">
        <div class="col-xl-12 px-0">
          <img class="info-images" src="/assets/home/images/para-estudiantes.png" alt="para estudiantes" />
        </div>
        <div class="col-xl-12 px-0" style="margin-top: 5%">
          <span class="footer-images">Para estudiantes</span>
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="infoStudents"></ng-container>
    </div>

    <div class="col-xl-4 col-6 text-center appear-card-container m-0">
      <div class="row first-view display-mobile-none m-0">
        <div class="col-xl-12 px-0">
          <img class="info-images" src="/assets/home/images/para-colegios.png" alt="para colegios" />
        </div>
        <div class="col-xl-12 px-0" style="margin-top: 5%">
          <span class="footer-images">Para colegios</span>
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="infoColegios"></ng-container>
    </div>

    <div class="col-xl-4 col-6 text-center appear-card-container m-0">
      <div class="row first-view display-mobile-none m-0">
        <div class="col-xl-12 px-0">
          <img class="info-images" src="/assets/home/images/para-universidades-2.png" alt="para colegios" />
        </div>
        <div class="col-xl-12 px-0" style="margin-top: 5%">
          <span class="footer-images">Para universidades</span>
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="infoUniversities"></ng-container>
    </div>
  </div>
  <div class="col-xl-12 d-flex justify-content-center mx-0 px-0">
    <h2 class="presentation-title mx-0 px-0 display-mobile-none">
      Ayuda a construir perfiles universitarios y profesionales destacados.
    </h2>
  </div>
</div>

<div class="row box-serviceinfo">
  <img
    class="background-service display-mobile-none"
    src="/assets/home/images/services-home.png"
    alt="Informacion de servicios" />
  <!-- <img
    class="background-service display-mobile"
    src="/assets/home/images/servicios-mobile.png"
    alt="Informacion de servicios" /> -->
   <!-- <div class="col-xl-12 services-info-container d-flex flex-column align-items-end" style="transform: scale(0.6); margin-top: -20px;">
    <img class="rose-circle display-mobile mb-1" src="/assets/home/images/rose-circle.png" alt="Informacion de servicios" />
    <img class="purple-circle display-mobile mb-1" src="/assets/home/images/purple-circle.png" alt="Informacion de servicios" />
    <div class="logoImageContainer mb-2">
      <img class="info-images" src="../../../../assets/home/logos/purple-logo.png" />
    </div>
    <div class="col-lg-8 col-xl-6 col-12 text-right">
      <div class="headlines">
        <h2 class="premium-services footer-images d-inline-block">Conoce</h2>
        <span class="servicesSeparation d-inline-block mx-1">+</span>
        <h2 class="premium-services footer-images d-inline-block">Potencia</h2>
        <span class="servicesSeparation d-inline-block mx-1">+</span>
        <h2 class="premium-services footer-images d-inline-block">Expone</h2>
      </div>
      <div>
        <p class="descriptionText">
          Y llega con éxito a tu universidad soñada mientras preparas tu mejor versión del futuro.
        </p>
      </div>
    </div>
  </div> -->
</div>
<div class="careers">
  <div class="max-width-container">
    <div class="careers-header-container">
      <div class="title-centered">
        <h3 id="title-section" class="ml-0">U360 Competencias</h3>
        <img class="careers-special-logo" src="assets/home/images/birrete-azul.png" alt="u360 popular careers" />
      </div>
    </div>
    <div class="all-program-container">
      <div class="career-top-row">
        <div class="program-container">
          <button *ngFor="let skill of skills">
            {{ skill.skillName }}
          </button>
        </div>
      </div>
      <div class="all-careers-container">
        <h2 class="presentation-title universities-title mx-4 px-4">
          Contribuimos con colegios, en la construcción y certificación de competencias de los estudiantes,
          permitiéndoles medir y mejorar sus perfiles universitarios y profesionales individuales.
        </h2>
      </div>
    </div>
  </div>
</div>

<div class="row text-center">
  <div class="col-md-12"></div>
</div>

<ng-template #infoStudents>
  <div style="text-align: -webkit-center">
    <div id="card-for-student" class="card card-student appear-card">
      <div class="card-body">
        <div style="margin-bottom: 5%">
          <span class="footer-images">Para estudiantes</span>
        </div>

        <div class="row card-detail">
          <div class="row">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Mide y mejora competencias de admisión internacional.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Explora más de 20.000 programas en universidades nacionales e internacionales.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Haz match y conoce el ajuste de perfil actual vs el tipo de universidad soñada.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Crea plan personalizado de desarrollo de competencias.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Evidencia mientras desarrollas actividades académicas y extracurriculares.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>
                Crea tu ruta de vida (Hoja de vida) evidenciando el desarrollo de competencias con perspectiva global.
              </p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Desarrolla perfil de admisiones.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Recibe información, ofertas y posibles becas (Según perfil)</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Prepárate para el mundo real y laboral !</p>
            </div>
          </div>
        </div>

        <footer class="position-button">
          <button class="card-buttons" (click)="openModalInfo()">Quiero más información</button>
        </footer>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #infoColegios>
  <div style="text-align: -webkit-center">
    <div class="card card-school appear-card">
      <div class="card-body">
        <div style="margin-bottom: 5%">
          <span class="footer-images">Para colegios</span>
        </div>

        <div class="row card-detail">
          <div class="row">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Panel de datos por: estudiante, curso, colegio.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Comparar información con nicho / colegio de interés.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Reportes automatizados - comparativos para decisiones internas.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Información sobre procesos de admisión nacional e internacional.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Desarrollo perfil internacional de estudiantes.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Monitoreo de avnace de estudiantes.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Ferias universitarias virtuales o presenciales</p>
            </div>
          </div>
        </div>

        <footer class="position-button">
          <button class="card-buttons" (click)="openModalInfo()">Quiero más información</button>
        </footer>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #infoUniversities>
  <div style="text-align: -webkit-center">
    <div id="card-for-university" class="card card-university appear-card">
      <div class="card-body">
        <div style="margin-bottom: 5%">
          <span class="footer-images">Para Universidades</span>
        </div>

        <div class="row card-detail">
          <div class="row">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check" />
            </div>
            <div class="col-9 text-list">
              <p>Llega a cientos de miles de estudiantes latinoamericanos con información calificada sobre tu institución.</p>
            </div>
          </div>          

          <footer class="position-button">
            <button class="card-buttons" (click)="openModalInfo()">Quiero más información</button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</ng-template>
