import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { Observable } from 'rxjs';
import { flattenFormData, flattenFormDataForUpdatePersonalInformation } from './stepper.service.helper';
import { CityResponse, CountryResponse, GradeLevelsResponse, InitialFormDataResponse, UniversityCareersResponse, UniversityResponse, UniversityTypesResponse } from '../components/initial-form/initialForm.models';

const DEFAULT_ACADEMIC_PERFORMANCE = 'not_available';

@Injectable({ providedIn: 'root' })
export class StepperService {
  private stepperForm: FormGroup;
  private initialDataForm: InitialFormDataResponse;
  private userId = JSON.parse(localStorage.getItem('user'))?._id;
  private step = 0;

  private readonly getInitialDataApi = 'api/getInitialDataFormById/';
  private readonly updateUserInitialDataFormApi = 'api/updateInitialDataForm/';
  private readonly createUserInitialDataFormApi = 'api/createInitialDataForm';
  private readonly publishInitialDataFormApi = 'api/publishInitialDataForm/';

  private readonly getSchoolGradesApi = 'api/getGradeLevelsById/';
  private readonly getSchoolByNameApi = 'api/schoolByName?name=';

  private readonly countriesApi = 'api/pais';
  private readonly colombianCitiesApi = 'api/ciudad/pais/';
  private readonly colombianUniversitiesApi = 'api/colombianUniversities';
  private readonly universityCareersApi = 'api/universityCareers';
  private readonly universityTypesApi = 'api/getInstitutionOptions';

  private readonly updateUserApi = 'api/user';

  private countries: CountryResponse[] = [];
  private colombianCities: CityResponse[] = [];
  private colombianUniversities: UniversityResponse[] = [];
  private universityCareers: UniversityCareersResponse[] = [];
  private universityTypes: UniversityTypesResponse[] = [];

  constructor(private fb: FormBuilder, private apiService: ApiService) {
    this.stepperForm = this.createForm();
    this.loadUserData();
  }


  getStepperForm(): FormGroup {
    return this.stepperForm;
  }

  setCurrentStep(step: number): void {
    this.step = step;
  }

  getStep(): number {
    return this.step;
  }

  private createForm(): FormGroup {
    return this.fb.group({
      personalInformation: this.fb.group({
        name: ['', [Validators.required]],
        secondName: [''],
        lastName: ['', [Validators.required]],
        secondLastName: [''],
        typeOfDocument: ['', [Validators.required]],
        documentNumber: ['', [Validators.required]],
        birthDate: [null, [Validators.required]],
        gender: ['', [Validators.required]],
      }),
      academicBackground: this.fb.group({
        school: [''],
        schoolCity: [''],
        currentGrade: ['', [Validators.required]],
        gradeLevel: ['', [Validators.required]],
      }),
      contactInformation: this.fb.group({
        schoolEmail: [''],
        personalEmail: ['', [Validators.required, Validators.email]],
        verifiedPhone: [''],
        hasNotPhone: [false],
        verifiedWhatsApp: ['', [this.validateColombianPhone]],
        hasNotWhatsApp: [false],
        instagramProfile: [''],
        consent: [false, [Validators.requiredTrue]],
      }),
      studyPreferences: this.fb.group({
        studyInterest: ['', [Validators.required]],
      }),
      locationPreferences: this.fb.group({
        primaryCity: [''],
        secondaryCities: [[]],
        mainColombianUniversity: [''],
        colombianUniversities: this.fb.array([]),
        abroadCountries: this.fb.array([]),
      }),
      futurePlans: this.fb.group({
        postSchoolPlan: ['', [Validators.required]],
      }),
      universityPreference: this.fb.group({
        universityTypePreference: ['', [Validators.required]],
      }),
      careerPreferences: this.fb.group({
        mainCareerInterest: [''],
        careerInterest: [[]],
        desiredCareer: [''],
      }),
      academicPerformance: this.fb.group({
        academicRatings7: [''],
        academicRatings8: [''],
        academicRatings9: [''],
        academicRatings10: [''],
        academicRatings11: [''],
        academicRatings12: [''],
      }),
      languageProficiency: this.fb.group({
        englishLevel: [''],
      }),
      careerMotivations: this.fb.group({
        careerMotivations: this.fb.array([]),
      }),
      standardizedTests: this.fb.group({
        standardizedTests: this.fb.array([
          this.createTestFormGroup('SAT'),
          this.createTestFormGroup('Saber 11 (ICFES)'),
          this.createTestFormGroup('ACT'),
          this.createTestFormGroup('IELTS'),
          this.createTestFormGroup('TOEFL'),
          this.createTestFormGroup('PISA'),
        ]),
      }),
      skills: this.fb.group({
        skills: this.fb.array([]),
      }),
      financialInformation: this.fb.group({
        tuitionRange: ['', [Validators.required]],
        financialSupport: ['', [Validators.required]],
      }),
      icfesReadiness: this.fb.group({
        icfesImportance: [''],
        icfesPreparedness: [''],
        icfesEffortCommitment: [''],
      }),
      guardianInformation: this.fb.group({
        guardianName: ['', [Validators.required]],
        guardianEmail: ['', [Validators.required, Validators.email]],
        guardianPhone: ['', [Validators.required, this.validateColombianPhone]],
        declaration: [false, [Validators.requiredTrue]],
      }),
      isCreatedAlready: [false],
    });
  }

  loadUserData(): Observable<void> {
    const url = `${this.getInitialDataApi}${this.userId}`;

    return new Observable((observer) => {
      this.apiService.get({ api: url }).subscribe(
        (response: InitialFormDataResponse) => {
          if (response) {
            this.initialDataForm = response;
            this.patchFormValues(response);
            localStorage.setItem('isPublished', response.isPublished?.toString() || 'false');
          }
          observer.next();
          observer.complete();
        },
        (error) => {
          console.error('Error fetching user data:', error);
          observer.next();
          observer.complete();
        }
      );
    });
  }

  saveProgress(): void {
    const flattenData = flattenFormData(this.stepperForm);
    const updateData = {
      api: `${this.updateUserInitialDataFormApi}${this.initialDataForm._id}`,
      data: flattenData,
    };

    this.apiService.put(updateData).subscribe(
      () => console.log('Progreso guardado exitosamente'),
      (error) => console.error('Error guardando progreso:', error)
    );
  }

  publishForm(): Observable<void> {
    const formData = flattenFormData(this.stepperForm);

    return new Observable((observer) => {
      this.apiService.put({ api: `${this.publishInitialDataFormApi}${this.initialDataForm._id}`, data: formData }).subscribe(
        () => {
          observer.next();
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });

  }

  savePersonalInformation(): void {
    const personalInformation = flattenFormDataForUpdatePersonalInformation(this.stepperForm);

    this.apiService.put({
      api: `${this.updateUserApi}/${this.userId}`,
      data: personalInformation,
    }).subscribe(
      (response) => {
        this.updateUserSession(response);
      },
      (error) => console.error('Error guardando información personal:', error)
    );
  }

  submitForm(): void {
    const postData = {
      api: this.stepperForm.get('isCreatedAlready')?.value ? this.updateUserInitialDataFormApi + this.userId : this.createUserInitialDataFormApi,
      data: this.stepperForm.value,
    };

    this.apiService.post(postData).subscribe(
      () => {
        console.log('Formulario guardado correctamente');
      },
      (error) => {
        console.error('Error al guardar el formulario:', error);
      }
    );
  }


  patchFormValues(response: InitialFormDataResponse) {
    this.stepperForm.patchValue({
      personalInformation: {
        name: response.name || '',
        secondName: response.secondName || '',
        lastName: response.lastName || '',
        secondLastName: response.secondLastName || '',
        typeOfDocument: response.typeOfDocument || '',
        documentNumber: response.documentNumber || '',
        birthDate: response.birthDate ? this.formatDateForInput(response.birthDate) : null,
        gender: response.gender || '',
      },
      academicBackground: {
        school: response.school || '',
        schoolCity: response.schoolCity || '',
        currentGrade: response.currentGrade || '',
        gradeLevel: response.gradeLevel || '',
      },
      contactInformation: {
        schoolEmail: response.schoolEmail || '',
        personalEmail: response.personalEmail || '',
        verifiedPhone: response.verifiedPhone || '',
        hasNotPhone: response.hasNotPhone || false,
        verifiedWhatsApp: response.verifiedWhatsApp || '',
        hasNotWhatsApp: response.hasNotWhatsApp || false,
        instagramProfile: response.instagramProfile || '',
        consent: response.consent || false,
      },
      studyPreferences: {
        studyInterest: response.studyInterest || '',
      },
      locationPreferences: {
        primaryCity: response.primaryCity || '',
        secondaryCities: response.secondaryCities || '',
        mainColombianUniversity: response.mainColombianUniversity || '',
      },
      futurePlans: {
        postSchoolPlan: response.postSchoolPlan || '',
      },
      universityPreference: {
        universityTypePreference: response.universityTypePreference || '',
      },
      careerPreferences: {
        mainCareerInterest: response.mainCareerInterest || '',
        careerInterest: response.careerInterest || [],
        desiredCareer: response.desiredCareer || '',
      },
      academicPerformance: {
        academicRatings7: response.academicRatings7 || DEFAULT_ACADEMIC_PERFORMANCE,
        academicRatings8: response.academicRatings8 || DEFAULT_ACADEMIC_PERFORMANCE,
        academicRatings9: response.academicRatings9 || DEFAULT_ACADEMIC_PERFORMANCE,
        academicRatings10: response.academicRatings10 || DEFAULT_ACADEMIC_PERFORMANCE,
        academicRatings11: response.academicRatings11 || DEFAULT_ACADEMIC_PERFORMANCE,
        academicRatings12: response.academicRatings12 || DEFAULT_ACADEMIC_PERFORMANCE,
      },
      languageProficiency: {
        englishLevel: response.englishLevel || '',
      },
      careerMotivations: {
        motivations: response.careerMotivations || [],
      },
      financialInformation: {
        tuitionRange: response.tuitionRange || '',
        financialSupport: response.financialSupport || '',
      },
      icfesReadiness: {
        icfesImportance: response.icfesImportance || '',
        icfesPreparedness: response.icfesPreparedness || '',
        icfesEffortCommitment: response.icfesEffortCommitment || '',
      },
      guardianInformation: {
        guardianName: response.guardianName || '',
        guardianEmail: response.guardianEmail || '',
        guardianPhone: response.guardianPhone || '',
        declaration: response.declaration || false,
      },
      isCreatedAlready: response.isCreatedAlready || false,
    });

    // Update FormArrays
    this.updateFormArray('locationPreferences.colombianUniversities', response.colombianUniversities);
    this.updateFormArray('locationPreferences.abroadCountries', response.abroadCountries);
    this.updateFormArray('careerMotivations.careerMotivations', response.careerMotivations);
    this.updateFormArray('standardizedTests.standardizedTests', response.standardizedTests, true);

    // Patch standardized tests separately
    this.patchStandardizedTests(response);
    this.patchSkills(response);
  }

  private updateFormArray(path: string, values: any[], isObjectArray: boolean = false): void {
    const formArray = this.stepperForm.get(path) as FormArray;

    if (!values || values.length === 0) return;
    formArray.clear();

    values.forEach(value => {
      formArray.push(isObjectArray ? this.createFormArrayItem(value) : this.fb.control(value));
    });
  }

  private createFormArrayItem(value: any): FormGroup {
    return this.fb.group(value);
  }


  /** Fetch countries */
  getCountries(): Observable<CountryResponse[]> {
    if (this.countries.length > 0) {
      return new Observable((observer) => {
        observer.next(this.countries);
        observer.complete();
      });
    }
    return new Observable((observer) => {
      this.apiService.get({ api: this.countriesApi }).subscribe(
        (response: CountryResponse[]) => {
          observer.next(response);
          this.countries = response;
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    }
    );
  }

  /** Fetch school details and then get its grades */
  loadSchoolGrades(schoolName: string): Observable<GradeLevelsResponse> {
    if (!schoolName) {
      throw new Error('School name is required.');
    }

    return new Observable((observer) => {
      this.apiService.get({ api: `${this.getSchoolByNameApi}${schoolName}` }).subscribe(
        (response) => {
          if (response.school?.length > 0) {
            const schoolId = response.school[0]._id;

            this.apiService.get({ api: `${this.getSchoolGradesApi}${schoolId}` }).subscribe(
              (gradesResponse: GradeLevelsResponse) => {
                observer.next(gradesResponse);
                observer.complete();
              },
              (error) => {
                observer.error(error);
              }
            );
          } else {
            observer.error(new Error('School not found.'));
          }
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  loadColombianCities(): Observable<CityResponse[]> {
    if (this.colombianCities.length > 0) {
      return new Observable((observer) => {
        observer.next(this.colombianCities);
        observer.complete();
      });
    }

    return new Observable((observer) => {
      this.getColombiaCountry().subscribe(
        (colombia) => {
          this.apiService.get({ api: `${this.colombianCitiesApi}${colombia._id}` }).subscribe(
            (response: CityResponse[]) => {
              this.colombianCities = response;
              observer.next(response);
              observer.complete();
            },
            (error) => {
              observer.error(error);
            }
          );
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  laodColombianUniversities(): Observable<UniversityResponse[]> {
    if (this.colombianUniversities.length > 0) {
      return new Observable((observer) => {
        observer.next(this.colombianUniversities);
        observer.complete();
      });
    }

    return new Observable((observer) => {
      this.apiService.get({ api: `${this.colombianUniversitiesApi}` }).subscribe(
        (response: UniversityResponse[]) => {
          observer.next(response);
          this.colombianUniversities = response;
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  loadUniversityCareers(): Observable<UniversityCareersResponse[]> {

    if (this.universityCareers.length > 0) {
      return new Observable((observer) => {
        observer.next(this.universityCareers);
        observer.complete();
      });
    }

    return new Observable((observer) => {
      this.apiService.get({ api: this.universityCareersApi }).subscribe(
        (response: UniversityCareersResponse[]) => {
          this.universityCareers = response;
          observer.next(response);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });

  }

  loadUniversityTypes(): Observable<UniversityTypesResponse[]> {

    if (this.universityTypes.length > 0) {
      return new Observable((observer) => {
        observer.next(this.universityTypes);
        observer.complete();
      });
    }

    return new Observable((observer) => {
      this.apiService.get({ api: this.universityTypesApi }).subscribe(
        (response: UniversityTypesResponse[]) => {
          observer.next(response);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }


  private getColombiaCountry(): Observable<CountryResponse> {
    return new Observable((observer) => {
      let colombia = this.countries.find((country) => country.nombre === 'Colombia');

      if (colombia) {
        observer.next(colombia);
        observer.complete();
      } else {
        this.getCountries().subscribe(
          (countries) => {
            this.countries = countries;
            colombia = countries.find((country) => country.nombre === 'Colombia');
            if (colombia) {
              observer.next(colombia);
            } else {
              observer.error(new Error('Colombia not found in countries list.'));
            }
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      }
    });
  }

  private formatDateForInput(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  private createTestFormGroup(testName: string): FormGroup {
    return this.fb.group({
      testName: [testName], // Fixed name for predefined tests
      score: [{ value: '', disabled: true }, this.validateScore], // Initially disabled
      notTaken: [true] // Default to "not taken"
    });
  }

  private patchStandardizedTests(response: InitialFormDataResponse) {

    const standardizedTests = response.standardizedTests || [];
    const testFormArray = this.stepperForm.get('standardizedTests.standardizedTests') as FormArray;

    if (standardizedTests.length === 0) {
      return;
    }

    // Clear existing values
    testFormArray.clear();

    // Add new values
    standardizedTests.forEach((test) => {
      testFormArray.push(this.fb.group({
        testName: test.testName,
        score: [{ value: test.score, disabled: test.notTaken }, this.validateScore],
        notTaken: test.notTaken
      }));
    });
  }

  private patchSkills(response: InitialFormDataResponse) {
    const skills = response.skills || [];
    const skillsFormArray = this.stepperForm.get('skills.skills') as FormArray;

    if (skills.length === 0) {
      return;
    }

    // Clear existing values
    skillsFormArray.clear();

    // Add new values
    skills.forEach((skill) => {
      skillsFormArray.push(this.fb.group(skill));
    });
  }

  // Custom Validator for Score
  private validateScore(control: AbstractControl): ValidationErrors | null {
    const parent = control.parent;
    if (!parent) return null;

    const notTaken = parent.get('notTaken')?.value;
    const score = control.value;

    if (!notTaken && (!score || score === '')) {
      return { requiredScore: true };
    }

    return null;
  }

  private validateColombianPhone(control: AbstractControl): ValidationErrors | null {
    const phone = control.value;

    const colombianMobileRegex = /^3\d{9}$/;
    if (!colombianMobileRegex.test(phone)) {
      return { invalidPhone: true };
    }
  }

  private updateUserSession(updatedUserData: any) {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const updatedUser = { ...storedUser, ...updatedUserData };
    localStorage.setItem('user', JSON.stringify(updatedUser));
    localStorage.setItem('username', updatedUser.primerNombre)
  }
}
