import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { MatDialogRef } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { S3Service } from '../../services/s3.service';
import { stat } from 'fs';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-experience-example-modal',
  templateUrl: './experiencesRecordModal.component.html',
  styleUrls: ['./experiencesRecordModal.component.scss'],
})
export class ExperienceRecordModalComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  user_id = localStorage.getItem('user_id');
  token = localStorage.getItem('U360token');
  @Input() experienceData: any;
  readonly experiencesApi = 'api/getExperience/';
  readonly schoolApi = 'api/colegio';
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';
  experienceControl = new FormControl('');
  experiences: any;
  skills: any;
  secondarySkills: any;
  plannedExperience: any;
  activityType: any;
  allowAutocomplete = false;
  location: any;
  executionMethod: any;
  currentPage = 1;
  getSupports: any;
  supportsArray = [];
  incomingSupports: any;
  selectedFileTypes: any[] = [];
  files: File[] = [];
  experienceSupports: any[];
  supports: {};
  linksData: any;
  typeSupport: any[] = [];
  documents: any;
  documentsWithData: any[];
  experienceRequired: any;
  experienceResponse: any;
  supervisorEmail: any;
  status: any;
  category: any;
  filteredExperiences: string[] = []; 
  searchText: string = ''; // 
  readonly optionsOfMultiSelect: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };
  datepickerConfig: Partial<BsDatepickerConfig>;
  grades = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  statusOptions = [
    { value: 'Approve', name: 'Aprobada' },
    { value: 'Denied', name: 'Rechazada' },

    { value: 'Improve', name: 'Mejorar soportes' },
  ];
  typesOfFile = [
    { id: 'Fotos', name: 'Fotos' },
    { id: 'Certificados', name: 'Certificados' },
    { id: 'Escritos', name: 'Escritos' },
    { id: 'Videos', name: 'Videos' },
    { id: 'Audios', name: 'Audios' },
    { id: 'Portafolio', name: 'Portafolio' },
    { id: 'Articulo', name: 'Articulo' },
    { id: 'Investigación', name: 'Investigación' },
    { id: 'Pagina WEB', name: 'Pagina WEB' },
    { id: 'Plan de Negocio', name: 'Plan de Negocio' },
    { id: 'Notas', name: 'Notas' },
    { id: 'Codigo', name: 'Codigo' },
    { id: 'Otros', name: 'Otros' },
  ];
  constructor(
    public dialogRef: MatDialogRef<ExperienceRecordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private alertService: AlertService,
    private s3Service: S3Service,
    private router: Router
  ) {
    this.datepickerConfig = {
      containerClass: 'theme-dark-blue custom-datepicker',
      dateInputFormat: 'DD/MM/YYYY',
    };
  }

  ngOnInit() {
    this.getExperiences();
    this.experienceControl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      if (typeof value === 'string' && value.trim() !== '') {
        this.filterExperiences(value);
      } else {
        this.filteredExperiences = [...this.experiences];
      }
    });
    this.getSkills();
    this.getExecutionMode();
    this.getExecutionMethod();
    this.getExecutionType();
    this.getColegio({ id: this.user.school });
    this.plannedExperience = {};
    if (this.data && this.data.experienceName) {
      this.experienceData = this.data.experienceName;
      this.plannedExperience.experience = this.experienceData;
    } else if (this.data && this.data.experienceData) {
      this.plannedExperience = this.data.experienceData;
    }
  }
  getExecutionType() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getExecutionType' }).subscribe((response) => {
        this.activityType = response.map((item) => item.executionType);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  getExecutionMode() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getExecutionMode' }).subscribe((response) => {
        this.location = response.map((item) => item.executionMode);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  getExecutionMethod() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getExecutionMethod' }).subscribe((response) => {
        this.executionMethod = response.map((item) => item.executionMethod);
        Swal.close();
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  getSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getSkill' }).subscribe(
        (response) => {
          this.skills = response.map((item) => item.skillName);
          if (this.data && this.data.experienceData) {
            this.onDropdownChange(this.plannedExperience.principalSkill);
          }

          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  getExperiences() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    this.apiService.get({ api: this.experiencesApi }).subscribe({
      next: (response) => {
        this.experienceResponse = Array.isArray(response) ? response : [];
        this.experiences = this.experienceResponse.map((item) => item.experienceName);
        this.filteredExperiences = [...this.experiences];

        Swal.close();
      },
      error: (error) => {
        console.error('Error fetching experiences:', error);
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrió un error cargando las experiencias',
        });
      }
    });
  }

  filterExperiences(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredExperiences = this.experiences.filter(exp => 
      exp.toLowerCase().includes(filterValue)
    );
  }

  setExperience(selectedExperience: string) {
    this.plannedExperience.experience = selectedExperience;
    this.experienceControl.setValue(selectedExperience, { emitEvent: false });
  }

  openAutocomplete() {
    this.allowAutocomplete = true; // Habilita la apertura del autocomplete
  }

  onAutocompleteClosed() {
    this.allowAutocomplete = false; // Lo deshabilita cuando se cierra
  }
  getDocumentType() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getDocumentConfig' }).subscribe(
        (response) => {
          const experienceDocuments = this.experienceRequired[0].documentsRequired;

          if (Array.isArray(experienceDocuments)) {
            this.documents = response.filter((doc) => experienceDocuments.some((expDoc) => expDoc === doc.document));
          } else {
            this.documents = response.filter((doc) => experienceDocuments === doc.document);
          }
          this.documentsWithData = this.documents.map((doc) => {
            return { document: doc.document, documentType: doc.documentType };
          });

          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  async getColegio({ id }) {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.schoolApi + '/' + id }).subscribe(
        (response) => {
          this.supervisorEmail = response.aproveEmail;
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }

  updateTheDocumentsRequire() {
    this.experienceRequired = this.experienceResponse.filter(
      (item) => item.experienceName === this.plannedExperience.experience
    );
  }
  onDropdownChange(selectedValue: any): void {
    this.secondarySkills = this.skills.filter((item) => item !== selectedValue);
  }

  nextPage() {
    if (this.currentPage < 3) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  getFileNameFromURL(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  maxCaractersContent(content: string): string {
    const maxLength = 40;
    if (content.length <= maxLength) {
      return content;
    } else {
      const trimmedContent = content.substring(0, maxLength);
      return trimmedContent + '.....';
    }
  }

  onSelectFileType(selectedValue: any) {
    if (!this.selectedFileTypes.includes(selectedValue)) {
      this.selectedFileTypes.push(selectedValue);
    }
  }

  async uploadFileToS3(file, experienceId, name) {
    return new Promise((resolve, reject) => {
      const key = `experience/${experienceId}/${name}`;

      this.s3Service.uploadFile({
        file: file,
        key: key,
        callback: (err, data) => {
          if (err) {
            console.error('Error uploading file to S3:', err);
            reject(err);
          } else {
            const link = `https://${this.s3Service.S3_BUCKET}.s3.amazonaws.com/${key}`;
            resolve(link);
          }
        },
      });
    });
  }

  async deleteFileFromS3(key, index) {
    if (this.plannedExperience.supportsLinks.linksData) {
      if (index >= 0 && index < this.plannedExperience.supportsLinks.linksData.length) {
        this.plannedExperience.supportsLinks.linksData.splice(index, 1);
      }
    }

    this.apiService.delete({ api: `api/deleteExperienceSupports/${this.plannedExperience._id}` }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.alertService.showSuccess({
            msg: 'Experiencia eliminada correctamente',
          });
        } else {
          this.alertService.showError({
            msg: 'Error al eliminar la experiencia',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema al eliminar la experiencia', err);
        this.alertService.showError({
          msg: 'Error al eliminar la experiencia',
        });
      }
    );

    return new Promise((resolve, reject) => {
      this.s3Service.deleteFileS3({
        key: key,
        callback: (err, data) => {
          if (err) {
            console.error('Error deleting file from S3:', err);
            reject(err);
          }

          resolve(key);
        },
      });
    });
  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(index: number) {
    if (this.files) {
      if (index >= 0 && index < this.files.length) {
        this.files.splice(index, 1);
      }
    }
  }

  handlePostRequest(apiService: ApiService, formData) {
    formData.requestApproval = true;
    formData.isEmailSent = false;

    return new Promise<string>((resolve, reject) => {
      const postData = {
        api: 'api/experiences',
        data: formData,
      };

      apiService.post(postData).subscribe(
        (response) => {
          resolve(response.experienceId);
        },
        (error) => {
          console.error('Error en el POST:', error);
          reject(error);
        }
      );
    });
  }

  handlePutRequest(apiService: ApiService, experienceID: string, formData) {
    if (this.plannedExperience.status === 'Aprobada' || this.plannedExperience.status === 'Rechazada') {
      formData.requestApproval = false;
    }
    if (this.plannedExperience.isApproval) {
      formData.isEmailSent = true;
    } else {
      formData.isEmailSent = false;
    }

    const putData = {
      api: `api/experiences/${experienceID}`,
      data: formData,
    };

    apiService.put(putData).subscribe(
      (response) => {},
      (error) => {
        console.error('Error en el PUT:', error);
      }
    );
  }

  async onSubmit() {
    try {
      let experienceId: any;
      const obj = {
        user: this.user,
        experienceName: this.plannedExperience.experienceName,
        experience: this.plannedExperience.experience,
        experienceDescription: this.plannedExperience.experienceDescription,
        principalSkill: this.plannedExperience.principalSkill,
        secondarySkill: this.plannedExperience.secondarySkill,
        activityType: this.plannedExperience.activityType,
        location: this.plannedExperience.location,
        executionMethod: this.plannedExperience.executionMethod,
        startDate: this.plannedExperience.startDate,
        endDate: this.plannedExperience.endDate,
        gradeOfExecution: this.plannedExperience.gradeOfExecution,
        experienceCategory: this.plannedExperience.experienceCategory
          ? this.plannedExperience.experienceCategory
          : this.category,
        skillsScore: this.plannedExperience.skillsScore
          ? this.plannedExperience.skillsScore
          : Number(this.experienceRequired[0].skillsScore),
        supports: this.supports,
        recommendation: this.plannedExperience.improveMessage,
        supervisorEmail: this.supervisorEmail,
        status: this.plannedExperience.status ? this.plannedExperience.status : 'En revisión',
      };

      if (!this.plannedExperience.isEdit) {
        experienceId = await this.handlePostRequest(this.apiService, obj);
      }
      const uploadPromises = this.files.map((file, index) =>
        this.uploadFileToS3(file, this.plannedExperience._id ?? experienceId, file.name)
          .then((link) => {
            return link;
          })
          .catch((error) => {
            console.error(`Error uploading ${file.name}:`, error);
            throw error;
          })
      );
      this.experienceSupports = await Promise.all(uploadPromises);
      for (let i = 0; i < this.experienceSupports.length; i++) {
        this.supportsArray.push({
          links: this.experienceSupports[i],
        });
      }
      obj.supports = this.supportsArray;
      this.handlePutRequest(this.apiService, this.plannedExperience._id ?? experienceId, obj);
      this.supportsArray = [];
      if (!this.plannedExperience.isEdit) {
        Swal.fire({
          title: 'Experiencia creada!',
          html: 'Tu experiencia será revisada lo más pronto posible, muchas gracias!',
          allowOutsideClick: true,
          showCancelButton: true,
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.apiService.delete({ api: `api/deleteExperiencePlan/${this.plannedExperience.experiencePlannedId}` });

            this.dialogRef.close();
          }
        });
      } else {
        Swal.fire({
          title: 'Experiencia editada exitosamente!',
          allowOutsideClick: true,
          showCancelButton: true,
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.dialogRef.close();
            this.router.navigate(['/experience-planner'+this.user_id], { queryParams: { fromRegister: 'true' } });
          }
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      Swal.fire({
        text: 'Recuerda seleccionar el tipo de documento',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  onDelete(): void {
    this.apiService.delete({ api: `api/experiences/${this.plannedExperience._id}` }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.dialogRef.close();
          this.alertService.showSuccess({
            msg: 'Experiencia eliminada correctamente',
          });
          this.getExperiences();
        } else {
          this.alertService.showError({
            msg: 'Error al eliminar la experiencia',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema al eliminar la experiencia', err);
        this.alertService.showError({
          msg: 'Error al eliminar la experiencia',
        });
      }
    );
    this.dialogRef.close();
  }
}
