import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { MatDialogRef } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Router } from '@angular/router';
import { RouterConfig } from 'aws-sdk/clients/directconnect';

@Component({
  selector: 'app-experience-example-modal',
  templateUrl: './experiencePlannerModal.component.html',
  styleUrls: ['./experiencePlannerModal.component.scss'],
})
export class ExperiencePlannerModal implements OnInit {
  @Input() experienceData: any;
  @Input() needsRedirectToPlanner: boolean = false;
  readonly experiencesApi = 'api/getExperience/';
  experiences: any;
  skills: any;
  secondarySkills: any;
  experienceRequired: any;
  plannedExperience: any;
  experiencesData: any;
  documents: any;
  documentsWithData: any[];
  selectedSkillScore: any;
  category: any;
  experienceResponse: any;
  user = JSON.parse(localStorage.getItem('user'));
  readonly optionsOfMultiSelect: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
  };
  datepickerConfig: Partial<BsDatepickerConfig>;

  socioEmotionalSkills: any[] = [];
  selectedSocioEmotionalSkills: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ExperiencePlannerModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.datepickerConfig = {
      containerClass: 'theme-dark-blue custom-datepicker',
      dateInputFormat: 'DD/MM/YYYY',
    };
    if (this.data && this.data.needsRedirectToPlanner !== undefined) {
      this.needsRedirectToPlanner = this.data.needsRedirectToPlanner;
    }
  }
  ngOnInit() {
    this.plannedExperience = {};

    if (this.data && this.data.experienceData) {
      this.experienceData = this.data.experienceData;
      this.plannedExperience = this.experienceData;
    }
    this.getExperiences();
    console.log(this.getExperiences());
    this.getSocioEmotionalSkills();

    if(this.plannedExperience.socioEmotionalSkills){
      this.selectedSocioEmotionalSkills = this.plannedExperience.socioEmotionalSkills;
    }

  }

  getExperiences() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: this.experiencesApi }).subscribe(
        (response) => {
          this.experiencesData = response;
          this.experienceResponse = Array.isArray(response) ? response : [];
        this.experienceRequired = this.experienceResponse.filter(
          (item) => item.experienceName === this.plannedExperience.experience
        );
        this.experiences = this.experienceResponse.map((item) => item.experienceName);
        this.getDocumentType();
          // Si se está editando una experiencia, se obtienen las skills de la experiencia
          if (this.data && this.data.experienceData) {
            this.onExperienceChange(this.plannedExperience.experience);
            this.onDropdownChange(this.plannedExperience.principalSkill);
          }

          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargandos las experiencias',
          });

          console.error('Error fetching experiences:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
  onExperienceChange(selectedExperience: any) {
    const matchingExperience = this.experiencesData.find(
      (experience) => experience.experienceName === selectedExperience
    );
    if (!matchingExperience) {
      return;
    }
    this.category = matchingExperience.experienceCategory;
    this.skills = matchingExperience.requiredSkills;
    this.selectedSkillScore = matchingExperience.skillsScore;
  }


  onDropdownChange(selectedValue: any): void {
    if (!this.skills) {
      return;
    }
    this.secondarySkills = this.skills.filter((item) => item !== selectedValue);
  }

   getDocumentType() {
      Swal.fire({
        title: 'Cargando...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      try {
        this.apiService.get({ api: 'api/getDocumentConfig' }).subscribe(
          (response) => {
  
            
            console.log('response', response);
  
            const experienceDocuments = this.experienceRequired[0].documentsRequired;
            console.log('experienceDocuments', experienceDocuments);
           
            if (Array.isArray(experienceDocuments)) {
              this.documents = response.filter((doc) => experienceDocuments.some((expDoc) => expDoc === doc.document));
            } else {
              this.documents = response.filter((doc) => experienceDocuments === doc.document);
            }
            this.documentsWithData = this.documents.map((doc) => {
              
              return { document: doc.document, documentType: doc.documentType };
            });
              
  
            Swal.close();
          },
          (error) => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrió un error cargando las competencias',
            });
  
            console.error('Error fetching skills:', error);
          }
        );
      } catch (error) {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An unexpected error occurred!',
        });
  
        console.error('Unexpected error:', error);
      }
    }

  onSubmit(): void {
    if (!this.plannedExperience.experience || 
        !this.plannedExperience.experienceDescription || 
        !this.plannedExperience.principalSkill || 
        !this.plannedExperience.startDate || 
        !this.plannedExperience.endDate || 
        this.selectedSocioEmotionalSkills.length === 0) {
      this.alertService.showError({
        msg: 'Todos los campos son obligatorios',
      });
      return;
    }

    const socioEmotionalSkillsIds = this.selectedSocioEmotionalSkills.map(skill => skill._id);
    const obj = {
      userId: this.user._id,
      experience: this.plannedExperience.experience,
      experienceDescription: this.plannedExperience.experienceDescription,
      principalSkill: this.plannedExperience.principalSkill,
      secondarySkill: this.plannedExperience.secondarySkill,
      startDate: this.plannedExperience.startDate,
      endDate: this.plannedExperience.endDate,
      experienceCategory: this.plannedExperience.experienceCategory
        ? this.plannedExperience.experienceCategory
        : this.category,
      skillsScore: this.plannedExperience.skillsScore ? this.plannedExperience.skillsScore : this.selectedSkillScore,
      socioEmotionalSkills: socioEmotionalSkillsIds, 
    };

    if (this.plannedExperience.isEdit) {
      this.updateExperience(obj);
      return;
    }

    this.createExperience(obj);
  }

  onDelete(): void {
    this.apiService.delete({ api: `api/deleteExperiencePlan/${this.plannedExperience._id}` }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.dialogRef.close();
          this.alertService.showSuccess({
            msg: 'Experiencia eliminada correctamente',
          });
          this.getExperiences();
        } else {
          this.alertService.showError({
            msg: 'Error al eliminar la experiencia',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema al eliminar la experiencia', err);
        this.alertService.showError({
          msg: 'Error al eliminar la experiencia',
        });
      }
    );
    this.dialogRef.close();
  }

  updateTheDocumentsRequire() {
    this.experienceRequired = this.experienceResponse.filter(
      (item) => item.experienceName === this.plannedExperience.experience
    );
  }

  createExperience(experience): void {
    this.apiService.post({ api: `api/createExperiencePlan`, data: experience }).subscribe(
      (response) => {
        if (response && !response.errors) {
          console.log(this.needsRedirectToPlanner);
          this.dialogRef.close();
          this.alertService.showSuccess({
            msg: 'Experiencia creada correctamente',
          });
          if (this.needsRedirectToPlanner) {
            this.router.navigate(['/experience-planner']);
          } else {
            this.getExperiences();
          }
        } else {
          this.alertService.showError({
            msg: 'Error al crear la experiencia',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema al crear la experiencia', err);
        this.alertService.showError({
          msg: 'Error al crear la experiencia',
        });
      }
    );
  }

  updateExperience(experience): void {
    this.apiService.put({ api: `api/updateExperiencePlan/${this.plannedExperience._id}`, data: experience }).subscribe(
      (response) => {
        if (response && !response.errors) {
          this.dialogRef.close();
          this.alertService.showSuccess({
            msg: 'Experiencia actualizada correctamente',
          });
          this.getExperiences();
        } else {
          this.alertService.showError({
            msg: 'Error al actualizar la experiencia',
          });
        }
      },
      (err) => {
        console.error('Hubo un problema al actualizar la experiencia', err);
        this.alertService.showError({
          msg: 'Error al actualizar la experiencia',
        });
      }
    );
  }

  getSocioEmotionalSkills() {
    this.apiService.get({ api: 'api/socioEmotionalSkills' }).subscribe(
      (skills) => {
        this.socioEmotionalSkills = skills;
      },
      (error) => {
        console.error('Error cargando habilidades socioemocionales:', error);
        this.alertService.showError({ msg: 'Error cargando habilidades socioemocionales' });
      }
    );
  }
  compareSkills(skill1: any, skill2: any): boolean {
    return skill1 && skill2 ? skill1._id === skill2._id : skill1 === skill2;
  }
}
