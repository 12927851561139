<div class="card menu-container" 
     [ngClass]="{ 
       'menu-collapsed-mobile': !isActiveMenuMobile, 
       'college-menu': isColegio, 
       'full-menu': isStudent 
     }" 
     #boxSidebarMenu 
     *ngIf="isStudent || isRecruitment">

  <div class="card-body menu-options-container">
    
    <!-- Home Estudiante -->
    <div class="menu-option" *ngIf="isStudent">
      <div class="menu-options">
        <img src="/assets/principal-menu/documentos-2-gris.png" alt="U360 Logo" />
        <p routerLink="/student-home" 
           [ngClass]="{ 'option-active': menuOptions.home }" 
           (click)="activeOption('home'); toggleConocete()">
          Home Estudiante
        </p>
      </div>
    </div>

    <!-- Sección: Conócete -->
    <div class="menu-option" (click)="toggleDashboard(); toggleInitialTest()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!(menuOptions.testIA || showDashboard || showTest)"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/cv.png" alt="cv" />
        <p routerLink="leaderTest" 
           [ngClass]="{ 'option-active': showDashboard || menuOptions.testIA }" 
           (click)="activeOption('testIA')">
          Conócete
        </p>
      </div>
    </div>
    <div class="mis-experiencias" *ngIf="showDashboard">
      <div class="optionWrapper">
        <p [ngClass]="{ 'option-active': showTest || menuOptions.testIA }" routerLink="leaderTest" (click)="activeOption('testIA'); toggleInitialTest()">Test Competencias</p>
      </div>
        <div class="progression-chart" *ngIf="showTest">
          <div class="optionWrapper"><p routerLink="leaderTest" [ngClass]="{ 'option-active': menuOptions.testIA }" (click)="activeOption('testIA')">Test</p></div>
          <div class="optionWrapper"><p routerLink="current-test" [ngClass]="{ 'option-active': menuOptions.initialResults }" (click)="activeOption('initialResults')">Resultados Test de Competencias</p></div>
        </div>
        <div class="optionWrapper">
          <p [ngClass]="{ 'option-active': showEnglishTest || menuOptions.englishTest }" routerLink="english-test" (click)="activeOption('englishTest'); toggleEnglishTest()">Test de Idiomas</p>
        </div>
          <div class="progression-chart" *ngIf="showEnglishTest">
            <div class="optionWrapper"><p routerLink="english-test" [ngClass]="{ 'option-active': menuOptions.englishTest }" (click)="activeOption('englishTest')">Test</p></div>
            <div class="optionWrapper"><p [routerLink]="'english-test/'+languageLevel" [ngClass]="{ 'option-active': menuOptions.englishResults }" (click)="activeOption('englishResults')">Resultados Test de Inglés</p></div>
          </div>
    </div>

    <!-- Espaciado adicional entre secciones cerradas -->
    <div class="separator"></div>

    <!-- Sección: Explora -->
    <div class="menu-option" (click)="toggleExplore()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!menuOptions.exploreSkills"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/cv.png" alt="cv" />
        <p routerLink="explore-skills" 
           [ngClass]="{ 'option-active': showExplore || menuOptions.exploreSkills }" 
           (click)="activeOption('exploreSkills')">
          Explora
        </p>
      </div>
    </div>
    <div class="mis-experiencias" *ngIf="showExplore">
      <div class="optionWrapper"><p routerLink="explore-skills" [ngClass]="{ 'option-active': menuOptions.exploreSkills }" (click)="activeOption('exploreSkills')">Competencias</p></div>
      <div class="optionWrapper"><p routerLink="universityType" [ngClass]="{ 'option-active': menuOptions.institutionType }" (click)="activeOption('institutionType')">Tipo de Institución</p></div>
      <div class="optionWrapper"><p [routerLink]="['/university-careers']"  [ngClass]="{ 'option-active': menuOptions.UniversityCarrers }" (click)="activeOption('UniversityCarrers')">Carreras Universitarias</p></div>   
      <div class="optionWrapper"><p [routerLink]="['/countries']"  [ngClass]="{ 'option-active': menuOptions.Contries }" (click)="activeOption('Contries')">Países</p></div>   

    </div>
    
    <div class="separator"></div>
  <!-- Sección: Planea y Registra -->
  <div class="menu-option" (click)="toggleMisExperiencias()" *ngIf="isStudent || isRecruitment">
    <div class="barra" [hidden]="!menuOptions.experiencesPlanner"></div>
    <div class="menu-options">
      <img src="/assets/principal-menu/cv.png" alt="cv" />
      <p routerLink="experience-example" 
        [ngClass]="{ 'option-active': showMisExperiencias || menuOptions.experiencesExample }" 
        (click)="activeOption('experiencesExample')">
        Planea y Registra
      </p>
    </div>
  </div>
  <div class="mis-experiencias" *ngIf="showMisExperiencias">
    <div class="optionWrapper"><p routerLink="experience-example" [ngClass]="{ 'option-active': menuOptions.experiencesExample }" (click)="activeOption('experiencesExample')">Catálogo de Experiencias</p></div>
    <div class="optionWrapper"><p routerLink="experience-planner" [ngClass]="{ 'option-active':showSimulate || menuOptions.experiencesPlanner }" (click)="activeOption('experiencesPlanner'); toggleExperiencePlanner()">Plan de Experiencias</p></div>
    <div class="progression-chart" *ngIf="showSimulate">
      <div class="optionWrapper" >
        <p routerLink="experience-planner" 
          [ngClass]="{ 'option-active': menuOptions.SimulationChartSkills}" 
          (click)="openSimulationChartSkills(experiences[0]); activeOption('SimulationChartSkills')">
          Simula
        </p>
      </div>
    </div>
    <div class="optionWrapper"><p [routerLink]="'experience-record/'+ userId" [ngClass]="{ 'option-active': menuOptions.experiences }"  (click)="activeOption('experiences')">Experiencias Registradas</p></div>
   
   
  </div>
  <div class="separator"></div>


  <div class="menu-option" (click)="toggleProgression()" *ngIf="isStudent || isRecruitment">
    <div class="barra" [hidden]="!menuOptions.dashboard"></div>
    <div class="menu-options">
      <img src="/assets/principal-menu/cv.png" alt="cv" />
      <p routerLink="skill-dashboard" 
         [ngClass]="{ 'option-active': showProgression || menuOptions.dashboard }" 
         (click)="activeOption('dashboard')">
        Progreso
      </p>
    </div>
  </div>
    <div class="progression-chart" *ngIf="showProgression">
      <div class="optionWrapper">
        <p [ngClass]="{ 'option-active': menuOptions.dashboard }" routerLink="/skill-dashboard" (click)="activeOption('dashboard')">Progreso Vs Universidades</p>
      </div>
      <div class="optionWrapper">
        <p [ngClass]="{ 'option-active': menuOptions.progressionChart }" routerLink="progression" (click)="activeOption('progressionChart')">Progreso Real</p>
      </div>
    </div>

  <div class="separator"></div>
    <!-- Sección: Expón -->
    <div class="menu-option" (click)="toggleMatch()" *ngIf="isStudent || isRecruitment">
      <div class="barra" [hidden]="!menuOptions.cv"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/cv.png" alt="cv" />
        <p routerLink="curriculum" 
           [ngClass]="{ 'option-active': showMatch || menuOptions.cv }" 
           (click)="activeOption('cv')">
          Expón
        </p>
      </div>
    </div>
    <div class="mis-experiencias" *ngIf="showMatch">
      <div class="optionWrapper"><p routerLink="curriculum" [ngClass]="{ 'option-active': menuOptions.cv }" (click)="activeOption('cv')">Hoja de Vida</p></div>
      <div class="optionWrapper"><p routerLink="cover-letter" [ngClass]="{ 'option-active': menuOptions.coverLetter }" (click)="activeOption('coverLetter')">Ensayo</p></div>
      <div class="optionWrapper"><p routerLink="profile" [ngClass]="{ 'option-active': menuOptions.documents }" (click)="activeOption('documents')">Perfil de Inicio</p></div>
    </div>
  </div>
</div>


