import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ApiService } from 'src/app/public/services/api.service';

@Component({
  selector: 'app-page-three',
  templateUrl: './pageThree.component.html',
  styleUrls: ['./pageThree.component.scss'],
})
export class PageThreeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() universitiesOfColombia: any;
  @Input() abroadUniversities: any;
  @Input() citiesOfColombia: any;

  studyOptions = ['El exterior', 'Mi pais', 'Aun no lo se'];
  disciplinesList = [
    {
      value: 'Artes, Diseño y Arquitectura',
      name: 'Home.arts-architecture',
      text: '',
    },
    {
      value: 'Negocios y Gestión',
      name: 'Home.business',
      text: '',
    },
    {
      value: 'Ciencias Computacionales y TI',
      name: 'Home.computer-science',
      text: '',
    },
    {
      value: 'Ingeniería',
      name: 'Home.engineering',
      text: '',
    },
    {
      value: 'Ciencias y Matemáticas',
      name: 'Home.sciences-math',
      text: '',
    },
    {
      value: 'Ciencias Sociales y Humanidades',
      name: 'Home.social-sciences',
      text: '',
    },
    {
      value: 'Educación y Deporte',
      name: 'Home.education',
      text: '',
    },
    {
      value: 'Servicios Comunitarios',
      name: 'Home.community',
      text: '',
    },
    {
      value: 'Estudios Ambientales y Ciencias de la Tierra',
      name: 'Home.env-studies',
      text: '',
    },
    {
      value: 'Salud',
      name: 'Home.health',
      text: '',
    },
    {
      value: 'Periodismo y Medios',
      name: 'Home.journalism',
      text: '',
    },
    {
      value: 'Derecho y Ciencias Políticas',
      name: 'Home.law-politics',
      text: '',
    },
    {
      value: 'Idiomas',
      name: 'Home.languages',
      text: '',
    },
    {
      value: 'Turismo y Hospitalidad',
      name: 'Home.tourism-hospitality',
      text: '',
    },
    {
      value: 'Otros',
      name: 'Home.other',
      text: '',
    },
  ];
  showDropdown1: boolean = false;
  showDropdown2: boolean = false;

  constructor(private fb: FormBuilder, private apiService: ApiService) { }

  ngOnInit(): void {
    this.setupForm();

    this.apiService.get({ api: 'api/universidad' }).subscribe(
      (response) => {
        this.universitiesOfColombia = response.docs
          .filter((university) => university.ciudad.pais.nombre === 'Colombia')
          .map((university) => university.nombre);

      },
      (error) => {
        console.error('Error fetching universities:', error);
      }
    );

    this.apiService.get({ api: 'api/pais/' }).subscribe(
      (response) => {
        this.abroadUniversities = response.map((university) => university.nombre);
      },
      (error) => {
        console.error('Error fetching universities:', error);
      }
    );

    this.apiService.get({ api: 'api/ciudad/pais/63f7c7981eb43b0012ea3d84' }).subscribe(
      (response) => {
        this.citiesOfColombia = response.map((city) => city.nombre);
      },
      (error) => {
        console.error('Error fetching universities:', error);
      }
    );
  }

  setupForm() {
    if (!this.form) {
      this.form = this.fb.group({
        colombianUniversities: this.fb.array([]),
        abroadCountries: this.fb.array([]),
      });
    }
    this.form.get('studyInterest')?.valueChanges.subscribe((value) => this.onStudyLocationChange(value));
    this.onStudyLocationChange(this.form.get('studyInterest')?.value || '');
  }

  onStudyLocationChange(selectedOption: string): void {
    if (selectedOption === 'El exterior') {
      this.showDropdown1 = false;
      this.showDropdown2 = true;
      this.setupAbroadCountries();
    } else if (selectedOption === 'Mi pais' || selectedOption === 'Aun no lo se') {
      this.showDropdown1 = true;
      this.showDropdown2 = false;
      this.setupColombianUniversities();
    } else {
      this.showDropdown1 = false;
      this.showDropdown2 = false;
    }
  }

  private setupColombianUniversities() {
    const control = this.form.get('colombianUniversities') as FormArray;
    while (control.length > 5) {
      control.removeAt(0);
    }
    while (control.length < 5) {
      control.push(this.fb.control(''));
    }
  }

  private setupAbroadCountries() {
    const control = this.form.get('abroadCountries') as FormArray;
    while (control.length > 3) {
      control.removeAt(0);
    }
    while (control.length < 3) {
      control.push(this.fb.control(''));
    }
  }

  get colombianUniversities(): FormArray {
    return this.form.get('colombianUniversities') as FormArray;
  }

  get abroadCountries(): FormArray {
    return this.form.get('abroadCountries') as FormArray;
  }

  onCareerInterestChange(event: any): void {
    const selectedValues = event.value;
    if (selectedValues.length > 2) {
      this.form.get('careerInterest')?.setValue(selectedValues.slice(0, 2));
    }
  }
}
