import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UNIVERSITY_CAREERS, UNIVERSITY_STUDY_FIELDS } from './step-career-preferences.constants';
import { StepperService } from '../../../services/stepper.service';

@Component({
  selector: 'app-step-career-preferences',
  templateUrl: './step-career-preferences.component.html',
  styleUrls: ['./step-career-preferences.component.scss']
})
export class StepCareerPreferencesComponent implements OnInit {

  @Input() form: FormGroup;

  universityStudyFieldOptions: { value: string; label: string }[] = UNIVERSITY_STUDY_FIELDS;
  universityCareerOptions: { value: string; label: string }[] = [];
  isLoading = false;


  constructor(private stepperService: StepperService) { }

  ngOnInit() {
    this.loadUniversityCareers();
  }


  loadUniversityCareers() {
    this.stepperService.loadUniversityCareers().subscribe(
      (universityCareers) => {
        this.universityCareerOptions = universityCareers.map((universityCareer) => ({
          value: universityCareer._id,
          label: universityCareer.name
        }),
          (error) => {
            console.error(error);
          }
        );
      });
  }

}
