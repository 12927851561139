import { Component, Input, OnInit } from '@angular/core';
import { Skill } from './skills-progress-level-chart.models';

@Component({
  selector: 'app-skills-progress-level-chart',
  templateUrl: './skills-progress-level-chart.component.html',
  styleUrls: ['./skills-progress-level-chart.component.scss']
})
export class SkillsProgressLevelChartComponent implements OnInit {

  @Input() skills: Skill[] = [];
  @Input() skillsScores: { [skillName: string]: number } = {};
  animated = false;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => this.animated = true, 200); // Small delay to trigger animations
  }

  /**
   * Get the maximum level where range2 is 100 (exclude Visionario)
   */
  getRightLimitLevel(skill: Skill) {
    return skill.levels.find(level => level.range2 === 100);
  }

  /**
   * Get the user's level based on their score
   */
  getUserLevel(skillName: string) {
    const skill = this.skills.find(s => s.skillName === skillName);
    const score = this.skillsScores[skillName];

    if (!skill || score === undefined) return null;

    return skill.levels.find(level => score >= level.range1 && score < level.range2);
  }

  /**
   * Get position for level dividers based on range2 (End of Level)
   * Only show middle ones (Intermedio, Avanzado)
   */
  getLevelEndPosition(skill: Skill, levelIndex: number): number | null {
    const level = skill.levels[levelIndex];
    if (!level) return null;

    const isLast = level.range1 >= 100;

    return (isLast) ? null : level.range1;
  }

  /**
   * Determine if a skill is global performance skill
   */

  isGlobalPerformanceSkill(skillName: string) {
    return skillName === 'Desempeño Global Competencias';
  }
}