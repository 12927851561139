<div class="personal-info">
  <div class="personal-info-header">
    <img routerLink="../" class="back-button" src="assets/utils/back.png" alt="go back" />
    <h3 class="personal-info-title">{{ 'Personal-information.personal-info' | translate }}</h3>
  </div>
  <div class="personal-info-form">
    <div class="form-group">
      <label for="name">
        {{ 'Personal-information.name' | translate }} (
        <span style="color: red">*</span>
        )
      </label>
      <input
        disabled
        [placeholder]="'Personal-information.name' | translate"
        [(ngModel)]="personalInfo.primerNombre"
        class="form-input"
        id="name"
        name="name"
        type="text" />
    </div>
    <div class="form-group">
      <label for="lastname">
        {{ 'Personal-information.last-name' | translate }} (
        <span style="color: red">*</span>
        )
      </label>
      <input
        disabled
        [placeholder]="'Personal-information.last-name' | translate"
        [(ngModel)]="personalInfo.primerApellido"
        class="form-input"
        id="lastname"
        name="lastname"
        type="text" />
    </div>
    <div class="form-group">
      <label for="birth">Fecha de nacimiento</label>
      <input
        disabled
        placeholder="DD/MM/YYYY"
        [(ngModel)]="personalInfo.birthDay"
        class="form-input"
        id="birth"
        name="birth" />
    </div>
    <div class="form-group">
      <label for="email">{{ 'Personal-information.email' | translate }}</label>
      <input
        disabled
        [placeholder]="'Personal-information.email' | translate"
        [(ngModel)]="personalInfo.email"
        class="form-input"
        id="email"
        name="email"
        type="email" />
    </div>
    <div class="form-group">
      <label for="lastname">Grado</label>
      <input
        disabled
        [placeholder]="'Personal-information.last-name' | translate"
        [(ngModel)]="personalInfo.grade"
        class="form-input"
        id="lastname"
        name="lastname"
        type="text" />
    </div>
    <div class="form-group">
      <label for="lastname">Nivel de grado</label>
      <input
        disabled
        [placeholder]="'Personal-information.last-name' | translate"
        [(ngModel)]="personalInfo.gradeLevel"
        class="form-input"
        id="lastname"
        name="lastname"
        type="text" />
    </div>

    <div class="form-group">
      <label for="phone">{{ 'Personal-information.telephone' | translate }}</label>
      <input
        [placeholder]="'Personal-information.telephone' | translate"
        [(ngModel)]="personalInfo.telefono"
        class="form-input"
        id="telephone"
        name="telephone"
        type="tel"
        (keypress)="numberOnly($event)" />
    </div>

    <div class="form-group">
      <label for="gender">{{ 'Personal-information.gender' | translate }}</label>
      <select class="form-input" id="gender" [(ngModel)]="personalInfo.sexo" name="gender">
        <option value="Masculino">{{ 'Personal-information.gender-male' | translate }}</option>
        <option value="Femenino">{{ 'Personal-information.gender-female' | translate }}</option>
        <option value="Not-specified">{{ 'Personal-information.gender-prefer-not-say' | translate }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="country">{{ 'Personal-information.country' | translate }}</label>
      <select
        (change)="onSelectCountry({ id: $event.target.value })"
        [(ngModel)]="personalInfo.pais"
        class="form-input"
        id="country"
        name="country">
        <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="city">{{ 'Personal-information.city' | translate }}</label>
      <select [(ngModel)]="personalInfo.ciudad" class="form-input" id="city" name="city">
        <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="name">
        {{ 'Personal-information.mom-name' | translate }}
      </label>
      <input
        [placeholder]="'Personal-information.mom-name' | translate"
        [(ngModel)]="personalInfo.nombreMama"
        class="form-input"
        id="name"
        name="name"
        type="text" />
    </div>
    <div class="form-group">
      <label for="name">
        {{ 'Personal-information.dad-name' | translate }}
      </label>
      <input
        [placeholder]="'Personal-information.dad-name' | translate"
        [(ngModel)]="personalInfo.nombrePapa"
        class="form-input"
        id="name"
        name="name"
        type="text" />
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="mom-email">{{ 'Personal-information.mom-email' | translate }}</label>
        <input
          [placeholder]="'Personal-information.mom-email' | translate"
          [(ngModel)]="personalInfo.correoMama"
          class="form-input"
          id="mom-email"
          name="mom-email"
          type="email"
          #momEmailModel="ngModel"
          email />

        <div style="min-height: 1.2rem">
          <small *ngIf="momEmailModel.invalid && momEmailModel.touched" style="color: red">Correo inválido</small>
        </div>
      </div>

      <div class="form-group col-md-6">
        <label for="dad-email">{{ 'Personal-information.dad-email' | translate }}</label>
        <input
          [placeholder]="'Personal-information.dad-email' | translate"
          [(ngModel)]="personalInfo.correoPapa"
          class="form-input"
          id="dad-email"
          name="dad-email"
          type="email"
          #dadEmailModel="ngModel"
          email />

        <div style="min-height: 1.2rem">
          <small *ngIf="dadEmailModel.invalid && dadEmailModel.touched" style="color: red">Correo inválido</small>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="name">
        {{ 'Personal-information.mom-telephone' | translate }}
      </label>
      <input
        [placeholder]="'Personal-information.mom-telephone' | translate"
        [(ngModel)]="personalInfo.celularPapa"
        class="form-input"
        id="name"
        name="name"
        type="tel"
        (keypress)="numberOnly($event)" />
    </div>
    <div class="form-group">
      <label for="name">
        {{ 'Personal-information.dad-telephone' | translate }}
      </label>
      <input
        [placeholder]="'Personal-information.dad-telephone' | translate"
        [(ngModel)]="personalInfo.celularMama"
        class="form-input"
        id="name"
        name="name"
        type="text"
        type="tel"
        (keypress)="numberOnly($event)" />
    </div>
    <div class="form-group">
      <label for="name">
        {{ 'Personal-information.other-languages' | translate }}
      </label>
      <input
        [placeholder]="'Personal-information.other-languages' | translate"
        [(ngModel)]="personalInfo.otrosIdiomas"
        class="form-input"
        id="name"
        name="name"
        type="text" />
    </div>
    <div class="form-group">
      <label for="discipline">{{ 'Personal-information.college-interest' | translate }}</label>
      <select
        [(ngModel)]="personalInfo.facultadInteres"
        class="form-input"
        id="discipline"
        name="discipline">
        <option *ngFor="let discipline of disciplinesList" value="{{ discipline.value }}">
          {{ discipline.text }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="facebook">Facebook</label>
      <input
        [placeholder]="'Personal-information.your-facebook' | translate"
        [(ngModel)]="personalInfo.facebook"
        class="form-input"
        id="facebook"
        name="facebook"
        type="text" />
    </div>
    <div class="form-group">
      <label for="linked-in">LinkedIn</label>
      <input
        [placeholder]="'Personal-information.your-linkedin' | translate"
        [(ngModel)]="personalInfo.linkedIn"
        class="form-input"
        id="linked-in"
        name="linked-in"
        type="text" />
    </div>
    <div class="form-group">
      <label for="instagram">Instagram</label>
      <input
        [placeholder]="'Personal-information.your-instagram' | translate"
        [(ngModel)]="personalInfo.instagram"
        class="form-input"
        id="instagram"
        name="instagram"
        type="text" />
    </div>
    <div class="form-footer">
      <button (click)="onChangePasswordClick()" type="button" id="change-password-button">
        {{ 'Personal-information.change-password' | translate }}
      </button>
      <button (click)="onSaveChanges()" *ngIf="!loading" type="submit" id="save-button">
        {{ 'Personal-information.save-changes' | translate }}
      </button>
      <mat-spinner class="profile-loading-spinner" *ngIf="loading" diameter="40"></mat-spinner>
    </div>
  </div>
</div>
