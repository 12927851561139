import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-experience-example-modal',
  templateUrl: './experienceExampleModal.component.html',
  styleUrls: ['./experienceExampleModal.component.scss'],
})
export class ExperiencesExampleModalComponent implements OnInit {
  @Input() experienceData: any;
  readonly documentsApi = 'api/getDocumentConfig/';
  readonly skillsApi = 'api/getSkill/';
  documents: any;
  documentsWithData: any[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService) {
    this.experienceData = data.experienceData;
    console.log('Experience data:', this.experienceData);
  }

  ngOnInit() {
    this.getSkills();
  }

  getSkills() {
    Swal.fire({
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      this.apiService.get({ api: 'api/getDocumentConfig' }).subscribe(
        (response) => {
          const experienceDocuments = this.experienceData.documentsRequired;
          if (Array.isArray(experienceDocuments)) {
            this.documents = response.filter((doc) => experienceDocuments.some((expDoc) => expDoc === doc.document));
          } else {
            this.documents = response.filter((doc) => experienceDocuments === doc.document);
          }
          this.documentsWithData = this.documents.map((doc) => {
            return { document: doc.document, documentType: doc.documentType };
          });
          Swal.close();
        },
        (error) => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrió un error cargando las competencias',
          });

          console.error('Error fetching skills:', error);
        }
      );
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred!',
      });

      console.error('Unexpected error:', error);
    }
  }
}
