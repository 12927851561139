import { Component, Input} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-step-location-preferences',
  templateUrl: './step-location-preferences.component.html',
  styleUrls: ['./step-location-preferences.component.scss']
})
export class StepLocationPreferencesComponent {
  @Input() form: FormGroup;

  constructor() { }

}
