<div class="stepper-container">
  <div class="stepper__header">
    <img class="stepper__image" src="assets/navbar/logo.png" alt="Stepper" />
    <h3 class="stepper__title">{{ steps[currentStep].title }}</h3>
    <p class="stepper__description">{{ steps[currentStep].description }}</p>
  </div>

  <div class="step-content">
    <!-- Show loading state if data is not ready -->
    <div *ngIf="isLoading" class="loading">
      <mat-spinner diameter="40"></mat-spinner>
    </div>

    <!-- Render stepper only when form data is ready -->
    <div *ngIf="!isLoading" class="step-content__container">

      <ng-container *ngIf="currentStep === 0">
        <app-step-welcome></app-step-welcome>
      </ng-container>

      <ng-container *ngIf="currentStep === 1">
        <app-step-personal-information [form]="getCurrentStepForm()"></app-step-personal-information>
      </ng-container>

      <ng-container *ngIf="currentStep === 2">
        <app-step-academic-background [form]="getCurrentStepForm()"></app-step-academic-background>
      </ng-container>

      <ng-container *ngIf="currentStep === 3">
        <app-step-contact-information [form]="getCurrentStepForm()"></app-step-contact-information>
      </ng-container>

      <ng-container *ngIf="currentStep === 4">
        <app-step-study-preference [form]="getCurrentStepForm()"></app-step-study-preference>
      </ng-container>

      <ng-container *ngIf="currentStep === 5">
        <app-step-location-preferences [form]="stepperForm"></app-step-location-preferences>
      </ng-container>

      <ng-container *ngIf="currentStep === 6">
        <app-step-future-plans [form]="getCurrentStepForm()"></app-step-future-plans>
      </ng-container>

      <ng-container *ngIf="currentStep === 7">
        <app-step-university-preferences [form]="getCurrentStepForm()"></app-step-university-preferences>
      </ng-container>

      <ng-container *ngIf="currentStep === 8">
        <app-step-career-preferences [form]="getCurrentStepForm()"></app-step-career-preferences>
      </ng-container>

      <ng-container *ngIf="currentStep === 9">
        <app-step-academic-performance [form]="getCurrentStepForm()"></app-step-academic-performance>
      </ng-container>

      <ng-container *ngIf="currentStep === 10">
        <app-step-language-proficiency [form]="getCurrentStepForm()"></app-step-language-proficiency>
      </ng-container>

      <ng-container *ngIf="currentStep === 11">
        <app-step-career-motivations [form]="getCurrentStepForm()"></app-step-career-motivations>
      </ng-container>

      <ng-container *ngIf="currentStep === 12">
        <app-step-standardized-tests [form]="getCurrentStepForm()"></app-step-standardized-tests>
      </ng-container>

      <ng-container *ngIf="currentStep === 13">
        <app-step-skills [form]="getCurrentStepForm()"></app-step-skills>
      </ng-container>

      <ng-container *ngIf="currentStep === 14">
        <app-step-financial-information [form]="getCurrentStepForm()"></app-step-financial-information>
      </ng-container>

      <ng-container *ngIf="currentStep === 15">
        <app-step-icfes-readiness [form]="getCurrentStepForm()"></app-step-icfes-readiness>
      </ng-container>

      <ng-container *ngIf="currentStep === 16">
        <app-step-guardian-information [form]="getCurrentStepForm()"></app-step-guardian-information>
      </ng-container>

    </div>

  </div>

  <div class="stepper-controls" [class.centered]="currentStep === 0">
    <button *ngIf="currentStep > 0" class="btn-prev" (click)="prevStep()">Anterior</button>
    <button *ngIf="currentStep < steps.length - 1" class="btn-next" (click)="nextStep()"
      [disabled]="isNextStepDisabled()">Siguiente</button>

    <button *ngIf="currentStep  == steps.length - 1" class="btn-next" (click)="publishForm()"
      [disabled]="isNextStepDisabled()">Publicar</button>

  </div>
</div>