import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-inital-form-card-selection',
  templateUrl: './inital-form-card-selection.component.html',
  styleUrls: ['./inital-form-card-selection.component.scss']
})
export class InitalFormCardSelectionComponent {
  @Input() control: FormControl | FormArray;
  @Input() items: any[] = [];
  @Input() multiSelect: boolean = false;
  @Input() maxSelections: number = 3;

  /** Allows custom card rendering */
  @ContentChild(TemplateRef) customTemplate!: TemplateRef<any>;

  selectItem(value: string): void {
    console.log('selectItem', value);
  
    if (this.multiSelect && this.control instanceof FormArray) {
      const values = this.control.value || [];
  
      // Check if the item is already selected
      const index = values.indexOf(value);
  
      if (index !== -1) {
        // If the item is selected, remove it
        this.control.removeAt(index);
      } else if (values.length < this.maxSelections) {
        // If the item is not selected, add it
        this.control.push(new FormControl(value));
      }
  
      this.control.markAsTouched();
    } else if (this.control instanceof FormControl) {
      if (this.control.value === value) {
        this.control.setValue(null); // Deselect if already selected
      } else {
        this.control.setValue(value); // Select new value
      }
      this.control.markAsTouched();
    }
  }
  

  isSelected(value: string): boolean {
    return this.multiSelect
      ? (this.control instanceof FormArray ? this.control.value.includes(value) : false)
      : (this.control.value === value);
  }

}
