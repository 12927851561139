import { AlertService } from '../../services/alert.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  /** CONSTANTS */
  /** Api path that handles the password changing */
  readonly FORGOT_PASSWORD_API = 'users/forgot/';
  /** Account to change password */
  public email: string;

  form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { userId: string, userEmail: string },
    private apiService: ApiService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    @Optional() private dialogRef: MatDialogRef<ForgotPasswordComponent>,
  ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  /**
   * Change password and send email with password
   */
  public onRecuperar() {
    if (this.form.valid) {
      this.apiService.post({ api: this.FORGOT_PASSWORD_API, data: this.form.value })
        .subscribe((response) => {
          if (response.success) {
            this.alertService.showSuccess({ msg: 'Hemos enviado tu nueva contraseña a tu correo.' });
            this.dialogRef.close();

          } else {
            this.alertService.showError({ msg: response.msg });
          }
        }, err => {
          this.alertService.showError({ msg: 'Ocurrio un error. Intentalo más tarde' });
        })
    }
  }

}
