import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-initial-form-input',
  templateUrl: './initial-form-input.component.html',
  styleUrls: ['./initial-form-input.component.scss']
})
export class InitialFormInputComponent implements OnInit {
  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() label?: string;
  @Input() type: string = 'text';
  @Input() disabled = false;

  ngOnInit(): void {
    if (!this.control) {
      console.warn('⚠️ No FormControl was passed to InitialFormInputComponent!', this.placeholder);
    }
  }


  getErrorMessage(): string {
    if (this.control.hasError('required')) {
      return 'Este campo es obligatorio.';
    }
    if (this.control.hasError('email')) {
      return 'Ingresa un correo válido.';
    }
    if (this.control.hasError('minlength')) {
      return `Debe tener al menos ${this.control.errors?.['minlength'].requiredLength} caracteres.`;
    }
    if(this.control.hasError('invalidPhone')) {
      return 'Ingresa un número de teléfono válido.';
    }
    return '';
  }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    if (this.type === 'tel') {
      const inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      this.control.setValue(inputValue, { emitEvent: false });
    }
  }

}
