import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-study-preference',
  templateUrl: './step-study-preference.component.html',
  styleUrls: ['./step-study-preference.component.scss']
})
export class StepStudyPreferenceComponent  {
  @Input() form: FormGroup;

  placesToStudy = [
    { img: 'assets/initial-form/colombia.png', name: 'En Colombia', value: 'Mi pais' },
    { img: 'assets/initial-form/world.png', name: 'En el exterior', value: 'El exterior' },
    { img: 'assets/initial-form/question.png', name: 'No lo sé', value: 'Aun no lo se' }
  ];

}
